import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

import {
  Box,
  Card,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Typography,
} from '@mui/material';
import  {
  Login,
  Logout,
} from '@mui/icons-material';

import { v4 as uuidv4 } from 'uuid';

import {
  FormInputCheckbox,
  FormInputText,
} from "../form";
import { objectEmptyCheck } from "../../utils";

import * as gtypeDetailActions from "../../store/gtypeDetail";
import * as g04docuGCertificationActions from "../../store/g04docuGCertification";

import GGlassComponent from "./GGlassComponent";

const GGlassForm = ({
  errors,
  open,
  selectedRow,
  gtypeDetailsWithGComponent,
  selectedGcomponentItems,
  setSelectedGcomponentItems,
  gtypes,
  control,
  setValue,
  gtypeDisabled,
  fromwhere,
  gtypeDetailsWithGComponentLength,
  selectedG04docuGCertifications,
  setSelectedG04docuGCertifications,
}) => {
  const [loadedGcomponents, setLoadedGcomponents] = useState(true);
  const [markPosition, setMarkPosition] = useState("BottomRight");
  const [markPositionview, setMarkPositionView] = useState("inout");
  const [dependentGcomponentStateMap, setDependentGcomponentStateMap] = useState(new Map());

  
  const dispatch = useDispatch();
  const selectGTypeDetailsWithGComponent = (gtypeId) => dispatch(gtypeDetailActions.selectGTypeDetailsWithGComponent(gtypeId))
  
  const selectG04docusByDivisionByQueryDirect = (division) => g04docuGCertificationActions.selectByDivisionByQueryDirect(division)
  
  useEffect(
    async () => {
      if (!objectEmptyCheck(selectedRow)) {
        let values = [];
        if (fromwhere?.from === "project") {
          values = ["id", "no", "name", "standard", "specification", "otherSpecs", "comments", "gtypeId", "labelPositionView", "labelPosition"];
        } else {
          values = ["id", "name", "specification", "otherSpecs", "comments", "gtypeId", "labelPositionView", "labelPosition", "selectedG04docuGCertifications"];
        }
        
        values.forEach(item => {
          if (item === "id") {
            if (fromwhere?.from === "project" && fromwhere?.side === 'left') {
              setValue("id", uuidv4());
            } else {
              setValue("id", selectedRow && selectedRow[item] || "");
            }
          } else if (item === "labelPositionView") {
            selectedRow && selectedRow[item] ? setValue(item, selectedRow[item]) : setValue('labelPositionView', 'inout');
            setMarkPositionView(selectedRow && selectedRow[item] ? selectedRow[item] : '');
          } else if (item === "labelPosition") {
            selectedRow && selectedRow[item] ? setValue(item, selectedRow[item]) : setValue('labelPosition', 'none');
            setMarkPosition(selectedRow && selectedRow[item] ? selectedRow[item] : '');
          } else {
            setValue(item, selectedRow && selectedRow[item] || "");
          }
        });
      }
    }, [selectedRow]
  );

  useEffect(
    async () => {
      if (open) {
        if (objectEmptyCheck(selectedRow)) {
          setValue('labelPositionView', 'inout');
          setValue('labelPosition', 'BottomRight');
          setValue("id", uuidv4());
        }

        const g04docuGCertifications = await selectG04docusByDivisionByQueryDirect('PROCESS');
        if (selectedRow.selectedG04docuGCertifications) {
          const merged = g04docuGCertifications.map(item => {
            const matchingItem = selectedRow.selectedG04docuGCertifications.find(
              selectedItem => selectedItem.id === item.id
            );
            return matchingItem || item;
          });
          setSelectedG04docuGCertifications(merged);
        } else {
          setSelectedG04docuGCertifications(g04docuGCertifications);
        }
      }
    }, [open]
  )

  const handleChangeLabelPositionView = (e) => {
    const positionView = e.target.value;
    setMarkPositionView(positionView);
    setValue('labelPositionView', positionView);
  }
  
  const handleChangeGType = async (e) => {
    const gtypeId = e.target.value;
    setValue("gtypeId", gtypeId);
    setLoadedGcomponents(false);
    await selectGTypeDetailsWithGComponent(gtypeId);
    setTimeout(() => setLoadedGcomponents(true), 300);
  }

  useEffect(
    () => {
      selectedGcomponentItems && selectedGcomponentItems.forEach((gtypeDetails, gtypeDetailsOrder) => {
        gtypeDetails.forEach((gcomponentItem, gcomponentItemOrder) => {
          dependentGcomponentStateMap.set(`${gtypeDetailsOrder}_${gcomponentItemOrder}_${gcomponentItem.id}`, gcomponentItem.value.id);
        });
      });
      setDependentGcomponentStateMap(dependentGcomponentStateMap);
    }, [selectedGcomponentItems]
  )

  const handleClickLabelSection = (position) => {
    setMarkPosition(position);
    setValue("labelPosition", position);
  }

  const handleClickInOutButton = () => {
    let mode = "";
    if (markPositionview === "inout") {
      mode = "outin";
    } else {
      mode = "inout";
    }

    setMarkPositionView(mode);
    setValue('labelPositionView', mode);
  }

  const generateGlass = () => {
    const colGrid = (row, rowIdx) => {
      return ['Left', 1, 2, 3, 'Right'].map((col, colIdx) => {
        return (
          <Grid key={`${row}${col}`} item onClick={() => (`${row}${col}` === 'TopLeft' || `${row}${col}` === 'TopRight' || `${row}${col}` === 'BottomLeft' || `${row}${col}` === 'BottomRight') && handleClickLabelSection(`${row}${col}`)}>
            <Box
              display="flex"
              sx={{
                height: 50,
                width: 70,
                bgcolor: `${row}${col}` === markPosition ? '#bbdefb' : '#e3f2fd',
                border: `${row}${col}` === 'TopLeft' || `${row}${col}` === 'TopRight' || `${row}${col}` === 'BottomLeft' || `${row}${col}` === 'BottomRight' ? '1px dashed #1976d2' : '',
                cursor: `${row}${col}` === 'TopLeft' || `${row}${col}` === 'TopRight' || `${row}${col}` === 'BottomLeft' || `${row}${col}` === 'BottomRight' ? 'pointer' : '',
                borderRadius: '5px',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography color="primary.main">
                {
                  `${row}${col}` === markPosition ?
                    '라벨 '
                  :
                    (
                      rowIdx === 2 && colIdx == 2 ?
                      (
                        markPositionview === "inout" ?
                          <IconButton aria-label="inout" size="large" color="primary" onClick={handleClickInOutButton}>
                            <Logout />
                          </IconButton>
                          // <Icon fontSize="large">logout</Icon>
                        :
                          <IconButton aria-label="inout" size="large" color="primary" onClick={handleClickInOutButton}>
                            <Login />
                          </IconButton>
                          // <Icon fontSize="large">login</Icon>
                      )
                      :
                        ''
                    )
                }
              </Typography>
            </Box>
          </Grid>
        )
      })
    }
    
    return ['Top', 1, 2, 3, 'Bottom'].map((row, rowIdx) => {
      return (
        <Grid container justifyContent="center" spacing={0}>
          {colGrid(row, rowIdx)}
        </Grid>
      );
    });
  }

  const handleChangeLabelPosition = (e) => {
    const position = e.target.value;
    
    setMarkPosition(position);

    setValue("labelPosition", position);
  }

  const handleChangeCheckButton = (e, item, i) => {
    const { checked } = e.target;
    console.log({ checked, item, i })

    let itemNew = Object.assign(item, selectedG04docuGCertifications[i]);
    
    itemNew.checked = checked;
    itemNew.index = i;

    console.log(itemNew);
    
    let items = selectedG04docuGCertifications;

    // 아래처럼 하면 배열안에 null인 요소가 발생한다.
    const selectedNew = [].concat(items);
    selectedNew[i] = itemNew;

    console.log({ items, selectedNew })
    
    setSelectedG04docuGCertifications(selectedNew);
  }

  return (
    <>
      <ul>
        {errors.map((error, idx) => <li key={idx}>{error}</li>)}
      </ul>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={7}>
          <Grid container spacing={2}>
            <Grid item xs={12} sx={{ display: 'none' }}>
              <FormInputText
                name={"id"}
                control={control}
                label={"아이디"}
              />
            </Grid>
            {
              fromwhere?.from === "project" && (
                <Grid item xs={12}>
                  <FormInputText
                    name={"no"}
                    control={control}
                    label={"품번"}
                  />
                </Grid>
              )
            }
            <Grid item xs={12}>
              <FormInputText
                name={"name"}
                control={control}
                label={"품명"}
              />
            </Grid>
            {
              fromwhere?.from === "project" && (
                <Grid item xs={12}>
                  <FormInputText
                    name={"standard"}
                    control={control}
                    label={"규격"}
                  />
                </Grid>
              )
            }
            <Grid item xs={12} sx={{ display: 'none' }}>
              <FormInputText
                name={"specification"}
                control={control}
                label={"사양"}
                inputProps={
                  { readOnly: true, }
                }
              />
            </Grid>
            <Grid item xs={12}>
              <FormInputText
                name={"specificationDisplay"}
                control={control}
                label={"사양"}
                inputProps={
                  { readOnly: true, }
                }
              />
            </Grid>
            <Grid item xs={12} sx={{ display: 'none' }}>
              <FormInputText
                name={"otherSpecs"}
                control={control}
                label={"비고"}
                multiline
                maxRows={5}
                inputProps={
                  { readOnly: true, }
                }
              />
            </Grid>
            <Grid item xs={12}>
              <FormInputText
                name={"otherSpecsDisplay"}
                control={control}
                label={"비고"}
                multiline
                maxRows={5}
                inputProps={
                  { readOnly: true, }
                }
              />
            </Grid>
            <Grid item xs={12}>
              <FormInputText
                name={"comments"}
                control={control}
                label={"설명"}
                multiline
                maxRows={5}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={5}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormInputText
                select
                name="labelPositionView"
                control={control}
                label={"보는 방향"}
                onChange={handleChangeLabelPositionView}
                options={[
                  {
                    label: "실내에서 봤을 때",
                    value: "inout",
                  },
                  {
                    label: "실외에서 봤을 때",
                    value: "outin",
                  },
                ]}
                // 아래 코드가 없으면 warning 발생 -> MUI: You have provided an out-of-range value `undefined` for the select component.
                defaultValue={""}
                // initialValue={"inout"}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormInputText
                select
                name="labelPosition"
                control={control}
                label={"라벨위치"}
                onChange={handleChangeLabelPosition}
                options={[
                  {
                    label: "라벨없음",
                    value: "none",
                  },
                  {
                    label: "좌측상단",
                    value: "TopLeft",
                  },
                  {
                    label: "우측상단",
                    value: "TopRight",
                  },
                  {
                    label: "좌측하단",
                    value: "BottomLeft",
                  },
                  {
                    label: "우측하단",
                    value: "BottomRight",
                  },
                ]}
                // 아래 코드가 없으면 warning 발생 -> MUI: You have provided an out-of-range value `undefined` for the select component.
                defaultValue={""}
                // initialValue={"BottomRight"}
              />
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="center">
              <Paper sx={{ height: 270, width: 370, backgroundColor: '#e3f2fd', pt: 1 }}>
                { generateGlass(/*markPosition*/) }
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        {
          fromwhere?.from !== "project" && (<Grid item xs={12}><Card sx={{ bgcolor: '#eee'}}>
            <Typography sx={{ mt: 1, mb: 1, ml: 2 }} variant="h6" component="div">
              {"인증규격(공정)을 선택합니다."}
            </Typography>
            <Grid container>
            {
              selectedG04docuGCertifications.map((g04docu, i) => (<Grid item display="flex" alignItems="center" sx={{ ml : i===0 ? 0 : 2 }}>
                <FormInputCheckbox
                  name={g04docu.id}
                  control={control}
                  onChangeCheckValue={(e) => handleChangeCheckButton(e, g04docu, i)}
                  checked={g04docu.checked}
                />
                <Typography>{g04docu.name}</Typography>
              </Grid>))
            }
            </Grid>
          </Card></Grid>)
        }
        {
          fromwhere?.from !== "project" && (
            <Grid item xs={12}>
              <Typography sx={{ mt: 1, mb: 1 }} variant="h6" component="div">
                {"템플릿 구조를 선택하고 유리샘플을 구성합니다."}
              </Typography>
            </Grid>
          )
        }
        <Grid item xs={12}>
          <FormInputText
            select
            name="gtypeId"
            control={control}
            label={"템플릿 구조"}
            onChange={handleChangeGType}
            options={
              gtypes.map(gtype => {
                return {
                  label: gtype.name,
                  value: gtype.id,
                }
              })
            }
            // 아래 코드가 없으면 warning 발생 -> MUI: You have provided an out-of-range value `undefined` for the select component.
            defaultValue={""}
            disabled={gtypeDisabled}
          />
        </Grid>
        <Grid item xs={12}>
          {
            !loadedGcomponents && (
              <Box sx={{ mt: 2, width: '100%' }}>
                <LinearProgress />
              </Box>
            )
          }
          {
            // gtypeDetailsWithGComponent의 길이가 gtype 길이와 같을 때가 조건이 되어야 함. 그렇지 않으면 GGlassComponent안에서 최초의 defaultValues가 이전 것으로 구성되어 setValue가 되지 않음 (uncontrolled)
            loadedGcomponents && gtypeDetailsWithGComponent.length === gtypeDetailsWithGComponentLength && (
              <GGlassComponent
                gtypeDetailsWithGComponent={gtypeDetailsWithGComponent}
                selectedGcomponentItems={selectedGcomponentItems}
                setSelectedGcomponentItems={setSelectedGcomponentItems}
                setSpecValue={setValue}
              />
            )
          }
        </Grid>
      </Grid>
    </>
  );
};

export default GGlassForm;
