import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from '@mui/material';
import {
  DialogTitleClose,
  PaperComponent,
} from "./components/dialog";
import "./Consent.css";
import * as consentActions from "./store/consent";

const ConsentDialog = ({
  open,
  setOpen,
  setConsented,
  from,
}) => {

  const [consent, setConsent] = useState(false);
  const [checkPrivacyInfo, setCheckPrivacyInfo] = useState(false);
  const [check04docuInfo, setCheck04docuInfo] = useState(false);
  const [checkAll, setCheckAll] = useState(false);

  const handleDialogClose = (e, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
      initDialog();
    }
  };

  const handleEmptyClose = () => {};


  const initDialog = () => {
    setConsent(false);
    setCheckPrivacyInfo(false);
    setCheck04docuInfo(false);
    setCheckAll(false);
  }

  const sessionUser = useSelector(state => state.session.sessionUser);

  // 데이터 관리
  const dispatch = useDispatch();

  const createOrModifyConsent = (credential) => dispatch(consentActions.createOrModify(credential))

  useEffect(
    () => {
      if (open) {
        if (from && (from.source === 'SignupGClient' || from.source === 'SignupGUser')) {
          setConsented && setConsented(false);
        }
      }
    }, [open]
  )

  const handleClickConsent = async () => {
    // console.log({ checkPrivacyInfo, check04docuInfo, checkAll })
    // return;
    if (checkPrivacyInfo && check04docuInfo && checkAll) {
      if (from && (from.source === 'SignupGClient'  || from.source === 'SignupGUser')) {
        setOpen(false);
        setConsented(true);
      } else {
        let credential = "";
        const { sessionUserLoginType, bizRegNumber, user } = sessionUser;
        if (sessionUserLoginType === 'GCLIENT') {
          credential =  bizRegNumber;
        } else if (sessionUserLoginType === 'USER') {
          if (user) {
            credential = user.userId;
          }
        }
    
        if (credential) {
          console.log(credential)
          await createOrModifyConsent(credential);
          setOpen(false);
        }
      }
    }

    // initDialog();
  }

  useEffect(
    () => {
      if (checkPrivacyInfo && check04docuInfo) {
        setCheckAll(true);
        setConsent(true);
      } else {
        setCheckAll(false);
        setConsent(false);
      }
    }, [checkPrivacyInfo, check04docuInfo]
  )

  const handleClickPrivacyInfo = (e, checked) => {
    setCheckPrivacyInfo(checked);
  }

  const handleClick04docuInfo = (e, checked) => {
    setCheck04docuInfo(checked);
  }

  const handleClickAll = (e, checked) => {
    setCheckAll(checked);
    setCheckPrivacyInfo(checked);
    setCheck04docuInfo(checked);
    setConsent(checked);
  }

  return (
    <Dialog
      open={open}
      // onClose={handleDialogClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      maxWidth="lg"
    >
      <DialogTitleClose
        id="draggable-dialog-title"
        onClose={handleEmptyClose}
        style={{ cursor: 'move' }}
        hideCloseButton={true} 
      >
        {"정보수집 및 활용 동의"}
      </DialogTitleClose>
      <DialogContent dividers>
        <Card sx={{ p: 3, mt: 1, mb: 1 }}>
          <Grid container spacing={2}>
            {
              sessionUser?.needConsent === true && (
                <Grid item xs={12}>
                  <table width="100%" style={{ border: "2px solid #1976d2" }}>
                    <tr>
                      <td>
                        <Grid container spacing={1} display="flex" justifyContent="center" sx={{ color: '#1976d2' }}>
                          <Grid item xs={12} display="flex" justifyContent="center">{`"개인정보 보호법" 및 "표준 개인정보 보호지침"에 따라`}</Grid>
                          <Grid item xs={12} display="flex" justifyContent="center">{`정상적인 서비스 이용을 위해 2년마다 개인정보 수집·이용에 대한 재동의를 시행하고 있습니다.`}</Grid>
                          <Grid item xs={12} display="flex" justifyContent="center">{"재동의 절차가 이루어지지 않는 경우 서비스를 이용하실 수 없게 되오니,"}</Grid>
                          <Grid item xs={12} display="flex" justifyContent="center">{"지속적으로 서비스 이용을 원하시면 개인정보 재동의 절차를 거쳐주시기 바랍니다."}</Grid>
                        </Grid>
                      </td>
                    </tr>
                  </table>
                </Grid>
              )
            }
            <Grid item xs={12} sx={{ fontSize: '0.9rem' }}>
              <Typography variant='h6' style={{ fontWeight: 'bold' }}><u>{"개인정보수집 및 활용동의서"}</u></Typography><br />
              {`『개인정보보호법』 제15조 법규에 의거하여 (사)한국판유리창호협회는 사용자의 개인정보 수집 및 활용에 대해 개인정보 수집 및 활용 동의서를 받고 있습니다.`}<br /><br />
              {"개인정보 제공자가 동의한 내용 외의 다른 목적으로 활용하지 않으며, 제공된 개인정보의 이용을 거부하고자 할 때에는 개인정보 관리책임자를 통해 열람, 정정 혹은 삭제를 요구할 수 있습니다."}<br /><br />
              {"※ 제공된 개인정보는 KFGWA 공사다큐 서비스의 아래 항목에 제한된 범위에서만 활용됩니다."}<br /><br />
              <table width="100%">
                <tr>
                  <td width="20%" style={{ backgroundColor: '#eee' }}>
                    <Grid display="flex" justifyContent={"center"}>
                      {"개인정보수집"}
                    </Grid>
                    <Grid display="flex" justifyContent={"center"}>
                      {"및"}
                    </Grid>
                    <Grid display="flex" justifyContent={"center"}>
                      {"이용목적"}
                    </Grid>
                  </td>
                  <td>
                      {"【개인정보 수집 항목】"}
                      <ul className="dashed">
                        <li>
                          {"성명, 이메일 주소, 휴대전화번호, 비밀번호, 서비스 이용 기록"}
                        </li>
                      </ul><br />                      
                      {"【개인정보 이용목적】"}
                      <ul className="dashed">
                        <li>
                          {"KFGWA 공사다큐 서비스 관련 업무 연락, 사후관리"}
                        </li>
                        <li>
                          {"접속기록, 접근통제 관리, 계정생성 등 서비스 이용과정이나 사업처리 과정에서의 정보수집"}
                        </li>
                        <li>
                          {"새로운 서비스 및 공지 등의 안내"}
                        </li>
                        <li>
                          {"신규 서비스 개발을 위한 방문 고객 분석자료 작성에 이용"}
                        </li>
                      </ul><br />
                      {"【보유 및 이용기간】"}
                      <ul className="dashed">
                        <li>
                          {"서비스 탈퇴일로부터 관련 법령에 따라 5년간 보유하며, 이후 지체 없이 파기"}
                        </li>
                      </ul><br />
                      {"【개인정보의 수집 및 이용 거부권리 및 동의 거부에 따른 불이익 】"}
                      <ul className="dashed">
                        <li>
                          {"사용자는 개인정보 수집 및 이용에 대해 거부할 권리가 있으며, 동의를 거부할 경우,"}<br />
                          <span style={{ marginLeft: '-17px' }}>{"KFGWA 공사다큐” 서비스 이용이 제한될 수 있음"}</span>
                        </li>
                      </ul>
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <Grid container sx={{ fontSize: '1rem' }}>
                      <Grid item xs={12} sm={9} display="flex" alignItems="center">{"위와 같이 개인정보를 수집 및 활용하는데 동의하십니까?"}</Grid>
                      <Grid item xs={12} sm={3} display="flex" justifyContent={"flex-end"}>
                        <FormGroup>
                          <FormControlLabel control={
                              <Checkbox size="small" onChange={handleClickPrivacyInfo} checked={checkPrivacyInfo} />
                            } label="예"
                          />
                        </FormGroup>
                      </Grid>
                    </Grid>
                  </td>
                </tr>
              </table>
              <Typography variant='h6' sx={{ fontWeight: 'bold', mt: 3 }}><u>{"정보수집 및 활용동의서"}</u></Typography><br />
              {`(사)한국판유리창호협회는 KFGWA 공사다큐 서비스의 운영을 위해 사용자의 문서 및 프로젝트 정보수집 및 활용에 대해 활용 동의서를 받고 있습니다.`}<br /><br />
              {"사용자가 동의한 내용 외의 다른 목적으로 활용하지 않으며, 제공된 문서 및 프로젝트 정보의 활용을 거부하고자 할 때에는 개인정보 관리책임자를 통해 열람, 정정 혹은 삭제를 요구할 수 있습니다."}<br /><br />
              {"※ 제공된 문서 및 프로젝트 정보는 KFGWA 공사다큐 서비스의 아래 항목에 제한된 범위에서만 활용됩니다."}<br /><br />
              <table width="100%">
                <tr>
                  <td width="20%" style={{ backgroundColor: '#eee' }}>
                    <Grid display="flex" justifyContent={"center"}>
                      {"정보수집"}
                    </Grid>
                    <Grid display="flex" justifyContent={"center"}>
                      {"및"}
                    </Grid>
                    <Grid display="flex" justifyContent={"center"}>
                      {"이용목적"}
                    </Grid>
                  </td>
                  <td>
                      {"【정보 수집 항목】"}
                      <ul className="dashed">
                        <li>
                          {"사업자등록번호, 대표자 성명, 사업자 이메일 주소, 전화번호, 비밀번호, 서비스 이용 기록"}
                        </li>
                        <li>
                          {"프로젝트 위치, 수행기간, 적용 품목 및 품목 세부 내용"}
                        </li>
                        <li>
                          {"서비스 수행을 위해 사용자 계정으로 등록된 성적서, 인증서를 포함한 각종 기술문서 "}
                        </li>
                      </ul><br />                
                      {"【정보 이용목적】"}
                      <ul className="dashed">
                        <li>
                          {"KFGWA 공사다큐 서비스 수행 및 관련 업무 연락, 사후관리"}
                        </li>
                        <li>
                          {"사용자가 동의한 대상에게 정보 수집 항목 제공"}
                        </li>
                        <li>
                          {"접속기록, 접근통제 관리, 계정생성 등 서비스 이용과정이나 사업처리 과정에서의 정보수집"}
                        </li>
                        <li>
                          {"새로운 서비스 및 공지 등의 안내"}
                        </li>
                        <li>
                          {"신규 서비스 개발을 위한 방문 고객 분석자료 작성에 이용"}
                        </li>
                      </ul><br />
                      {"【보유 및 이용기간】"}
                      <ul className="dashed">
                        <li>
                          {"서비스 탈퇴일로부터 관련 법령에 따라 5년간 보유하며, 이후 지체 없이 파기"}
                        </li>
                      </ul><br />
                      {"【정보의 수집 및 이용 거부권리 및 동의 거부에 따른 불이익 】"}
                      <ul className="dashed">
                        <li>
                          {"사용자는 정보수집 및 이용에 대해 거부할 권리가 있으며, 동의를 거부할 경우,"}<br />
                          <span style={{ marginLeft: '-17px' }}>{"KFGWA 공사다큐” 서비스 이용이 제한될 수 있음"}</span>
                        </li>
                      </ul>
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <Grid container sx={{ fontSize: '1rem' }}>
                      <Grid item xs={12} sm={9} display="flex" alignItems="center">{"위와 같이 정보를 수집 및 활용하는데 동의하십니까?"}</Grid>
                      <Grid item xs={12} sm={3} display="flex" justifyContent={"flex-end"}>
                        <FormGroup>
                          <FormControlLabel control={
                              <Checkbox size="small" onChange={handleClick04docuInfo} checked={check04docuInfo} />
                            } label="예"
                          />
                        </FormGroup>
                      </Grid>
                    </Grid>
                  </td>
                </tr>
              </table>
              <Grid display="flex" justifyContent={"flex-end"} sx={{ mt: 2 }}>
                <FormGroup>
                  <FormControlLabel control={
                    <Checkbox size="small" onChange={handleClickAll} checked={checkAll} />
                  } label="전체 체크" />
                </FormGroup>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </DialogContent>
      <DialogActions>
        <Grid sx={{ p: 1, width: '100%' }}>
          <Button variant="contained" onClick={handleClickConsent} fullWidth sx={{ fontSize: '1.1rem' }} disabled={!consent}>{"위 내용에 동의합니다."}</Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default ConsentDialog;
