import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useForm } from "react-hook-form";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DataGridPro, GridActionsCellItem, koKR } from '@mui/x-data-grid-pro';
import {
  Backdrop,
  Chip,
  CircularProgress,
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography,
  Link,
} from '@mui/material';
import  {
  Delete,
  OpenInNew,
  FilePresent,
  Refresh,
  ErrorOutline,
  Send,
} from '@mui/icons-material';
import qs from 'query-string';
// import { FormInputText } from "../form";
import {
  ConfirmDialog,
} from "../dialog";
import {
  CustomNoRowsOverlay,
  // CustomLoadingOverlay,
} from "../datagrid";
import { StyledTooltip } from "../tooltip";
import {
  dateFormat,
  hideWatermark,
} from "../../utils";
import { uploadFilePath, fileServerUrl } from '../../config';
import * as g04docuMaterialApprovalActions from "../../store/g04docuMaterialApproval";
import * as g04docuFileActions from "../../store/g04docuFile";
import * as dialogActions from "../../store/components/dialog";
import * as confirmDialogActions from "../../store/components/confirmDialog";
import * as materialApprovalProgressActions from "../../store/materialApprovalProgress";

import G04docuMaterialApprovalDialog from "./G04docuMaterialApprovalDialog";
import G04docuMaterialApprovalEmailDialog from "./G04docuMaterialApprovalEmailDialog";
import G04docuMaterialApprovalDeleteConfirmDialog from "./G04docuMaterialApprovalDeleteConfirmDialog";

const theme = createTheme();

// const ITEM_HEIGHT = 48;
// let listTimerId = null; // 보관함 타이머
// let makeTimerId = null; // 발급 타이머

const tooltipTop = {
  "& .MuiTooltip-tooltip": {
    borderRadius: "0px",
  }
};

const G04docuMaterialApprovalManagement = ({
  title,
  from,
}) => {
  // const apiRef = React.useRef();

  // const [anchorEl, setAnchorEl] = useState(null);
  const [crudMode, setCrudMode] = useState('');
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState([]);
  const [loaded, setLoaded] = useState(true);
  const [show, setShow] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [params, setParams] = useState({});
  const [pageSize, setPageSize] = useState(100);
  const [page, setPage] = useState(0);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [removeObject, setRemoveObject] = useState({});
  // const [rowCount, setRowCount] = useState(100);
  
  // const [selectedRows, setSelectedRows] = useState([]);  

  // const openFunctionMenu = Boolean(anchorEl);
  
  // 기능버튼 메뉴
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };
  
  const handleSelect = async ({ type, params }) => {
    // setAnchorEl(null);
    console.log(params)
    setOpenBackdrop(true);
    // const id = selectedRows[0].id;
    const { id, row } = params;
    
    if (type === "detail") {
      await select(id);
      setCrudMode('R');
      setOpen(true);
    } else if (type === "edit") {
      await select(id);
      setCrudMode('U');
      setOpen(true);
    } else if (type === "delete") {
      dispatch(confirmDialogActions.setOptions({ open: true, params, action: remove }));
      // setRemoveObject({ id, documentPath: params.row.documentPath });
      // setParams(params);
      // setConfirmOpen(true);
    } else if (type === "sendEmail") {
      handleClickSendEmail({ id, documentPath: row.documentPath });
    }

    setOpenBackdrop(false);
  }

  const remove = async (removeObject) => {
    console.log(removeObject);
    const { id, row } = removeObject;

    removeWithFile({ id, documentPath: row.documentPath })
      .then(res => {
        const deleteOption = true;
        selectPaging(deleteOption);
        
        if (options?.open) {
          showMaterialApprovalProgress({ open: false });
        }
        
        if (from && from.params && from.params.closeDrawer) {
          from.params.closeDrawer();
        }
      })
      .catch(async (res) => {
        const data = await res.json();
        if (data && data.errors) setErrors(data.errors);
        
        if (options?.open) {
          showMaterialApprovalProgress({ open: false });
        }
        
        if (from && from.params && from.params.closeDrawer) {
          from.params.closeDrawer();
        }
      });
  }

  const generateActions = (params) => {
    // console.log(params)
    // let arrActions = [
    //   <GridActionsCellItem
    //     icon={<OpenInNew />}
    //     label={"상세"}
    //     onClick={() => handleSelect({ type: 'detail', params })}
    //     showInMenu
    //   />,
    //   <GridActionsCellItem
    //     icon={<Delete />}
    //     label={"삭제"}
    //     onClick={() => handleSelect({ type: 'delete', params })}
    //     showInMenu
    //   />,
    //   <GridActionsCellItem
    //     icon={<Send />}
    //     label={"메일전송"}
    //     onClick={() => handleSelect({ type: 'sendEmail', params })}
    //     showInMenu
    //   />,
    // ];

    // if (params.row.results?.status === 'PROCESSING') {
    //   arrActions.pop();
    // }

    // return arrActions;
    return params.row.results?.status === 'PROCESSING' ? [] : [
      <GridActionsCellItem
        icon={<OpenInNew />}
        label={"상세"}
        onClick={() => handleSelect({ type: 'detail', params })}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<Delete />}
        label={"삭제"}
        onClick={() => handleSelect({ type: 'delete', params })}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<Send />}
        label={"메일전송"}
        onClick={() => handleSelect({ type: 'sendEmail', params })}
        showInMenu
      />,
    ]
  }

  const columns = [
    {
      field: 'gclient',
      headerName: '거래선명',
      width: 200,
      // editable: true,
      // sessionUser에 따라서 다르게 하려면 url querystring을 메뉴에 설정해서 사용
      // 참고로 여기서는 sessionUser 사용못함 (Cannot access 'sessionUser' before initialization)
      // hide: sessionUser?.type === 'ADMIN' ? false : true, => X
      hide: qs.parse(window.location.search)?.userType === 'admin' ? false : true,
      valueGetter: (params) => {
        return params.value.name
      },
    },
    {
      field: 'gproject',
      headerName: '사이트',
      width: 300,
      // editable: true,
      valueGetter: (params) => {
        return params.value.site
      }
    },
    {
      field: 'results',
      headerName: '상태',
      width: 150,
      // editable: true,
      headerAlign: 'center',
      align: 'center',
      // renderCell: (params) => {
      valueGetter: (params) => {
        // console.log(params)
        let value = "생성중";
        if (params.value?.status === 'SUCCESS') {
          value = "완료";
        } else if (params.value?.status === 'FAIL') {
          value = "생성중 오류발생";
        }
        return value;
      },
    },
    {
      field: 'documentPath',
      headerName: '자재승인서류',
      width: 150,
      // editable: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        if (params.value) {
          if (params.row?.results?.status === 'SUCCESS') {
            const { documentPath } = params.row;
            return (
              <StyledTooltip
                title={`[다운로드] ${documentPath?.split("/")[documentPath?.split("/")?.length-1]}`}
                placement="right"
                sx={tooltipTop}
              >
                <a href={`${documentPath.replace(uploadFilePath, fileServerUrl)}`} download style={{ color: '#1976d2' }}>
                  <Box display="flex" alignItems="center">
                    <FilePresent />
                  </Box>
                </a>
                {/* <IconButton
                  color="primary"
                  aria-label="file"
                  // edge="end"
                  onClick={(e) => handleClickFileDownload(e, documentPath)}
                >
                  <FilePresent />
                </IconButton> */}
              </StyledTooltip>
            );
          } else if (params.row?.results?.status === 'PROCESSING') {
            return (<CircularProgress size={13} />)
          } else if (params.row?.results?.status === 'FAIL') {
            return (
            // <IconButton>
              <ErrorOutline size={13} sx={{ color: '#ef5350' }} />
            // </IconButton>
            )
          }
        }
      }
    },
    // {
    //   field: 'documentPath1',
    //   headerName: '자재승인서류',
    //   width: 150,
    //   // editable: true,
    //   headerAlign: 'center',
    //   align: 'center',
    //   renderCell: (params) => {
    //     const { documentPath } = params.row;
    //     // alert(documentPath)
    //     return (
    //       <a href={"http://220.86.31.114:3001/files/Material Approvals/공사다큐어드민(bc7f545c-9545-4268-8d05-f631924773bf)/서울XXX아파트(114381b9-d276-41dd-8766-c57df0345cb5)/서울XXX아파트_ma_2024-04-03-063637-605.pdf"} download>{"111"}</a>
    //     )
    //   }
    // },
    {
      field: 'generateOptions',
      headerName: '발급옵션',
      width: 500,
      renderCell: (params) => {
        if (params.value) {
          let signLabel = "";
          /*if (params.value.sign === 'CERTIFIED_TRUE_COPY') {
            signLabel = "원본대조필";
          } else */if (params.value.sign === 'CERTIFIED_TRUE_COPY_AND_SIGN') {
            // signLabel = "원본대조필 + 날인";
            signLabel = "날인";
          } else/* if (params.value.sign === 'CERTIFIED_TRUE_COPY_AND_SIGN')*/ {
            signLabel = "원본대조필 없음";
          }

          const options = [];
          for (const [item, value] of Object.entries(params.value)) {
            if (value === 'Y') {
              options.push(item);
            }
          }

          const otherOptions = options.map(option => {
            let optionLabel = "";
            if (option === "docAttachedNValidDate") {
              optionLabel = "유효문서만";
            } else if (option === "docValidDate") {
              optionLabel = "만료일 내 문서만";
            } else if (option === "docInvalidDate") {
              optionLabel = "만료일 경과 문서 포함";
            } else if (option === "docAttached") {
              optionLabel = "첨부된 문서만";
            } else if (option === "docUnattached") {
              optionLabel = "첨부되지 않은 문서 포함";
            } else if (option === "myCertified") {
              optionLabel = "제공사 인감 부재시 내 인감 날인";
            }

            return <Chip label={optionLabel} size="small" sx={{ ml: 1 }}></Chip>
          })

          return (
            <>
              <Chip label={signLabel} size="small"></Chip> 
              {otherOptions}
            </>
          )
        }
      }
    },
    // {
    //   field: 'comments',
    //   headerName: '설명',
    //   width: 300,
    // },
    {
      field: 'createdAt',
      headerName: '생성일시',
      width: 160,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => dateFormat(params.value),
    },
    {
      field: 'updatedAt',
      headerName: '수정일시',
      width: 160,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => dateFormat(params.value),
    },
    {
      field: 'actions',
      headerName: <Tooltip title={"상세/삭제"} followCursor><Box>{"기능"}</Box></Tooltip>,
      width: 70,
      // description: "수정/삭제", // 불편해서 주석처리
      type: 'actions',
      getActions: (params) => generateActions(params),
      // renderCell: (params) => {
      //   console.log(params)
      //   return (
      //     <div>
      //       <IconButton
      //         aria-label="more"
      //         id="long-button"
      //         aria-controls={openFunctionMenu ? 'long-menu' : undefined}
      //         aria-expanded={openFunctionMenu ? 'true' : undefined}
      //         aria-haspopup="true"
      //         onClick={handleClick}
      //       >
      //         <MoreVert />
      //       </IconButton>
      //       <Menu
      //         id="long-menu"
      //         MenuListProps={{
      //           'aria-labelledby': 'long-button',
      //         }}
      //         anchorEl={anchorEl}
      //         open={openFunctionMenu}
      //         onClose={handleClose}
      //         PaperProps={{
      //           style: {
      //             maxHeight: ITEM_HEIGHT * 4.5,
      //             width: '20ch',
      //           },
      //         }}
      //       >
      //         {options.map((option) => (
      //           <MenuItem key={option.type} onClick={() => handleSelect({ type: option.type, id: params.id })}>
      //             <ListItemIcon>{option.icon}</ListItemIcon>
      //             <ListItemText>{option.text}</ListItemText>
      //           </MenuItem>
      //         ))}
      //       </Menu>
      //     </div>
      //   );
      // },
    },
  ];

  const sessionUser = useSelector(state => state.session.sessionUser);
  // TODO : 아래 rows와 rowCount 따로 받아서 두번 렌더링 되는지 확인해볼 것. 그렇다면 하나의 객체로 통합할 것
  const rows = useSelector((state) => state.g04docuMaterialApproval.g04docuMaterialApprovals);
  const rowsGenerate = useSelector((state) => state.g04docuMaterialApproval.g04docuMaterialApprovalsGenerate);  
  // const rowCount = useSelector((state) => state.gunit.count);
  const selectedRow = useSelector((state) => state.g04docuMaterialApproval.g04docuMaterialApproval);
  const options = useSelector((state) => state.materialApprovalProgress.options);

  // 데이터 관리
  const dispatch = useDispatch();

  const selectAll = (queryOptions) => dispatch(g04docuMaterialApprovalActions.selectPaging(queryOptions))
  const selectByGClient = (gclientId) => dispatch(g04docuMaterialApprovalActions.selectByGClient(gclientId))
  const selectByGProject = (gprojectId) => dispatch(g04docuMaterialApprovalActions.selectByGProject(gprojectId))
  const selectG04docuMaterialApprovalById = (id) => dispatch(g04docuMaterialApprovalActions.selectById(id))
  const select = (id) => dispatch(g04docuMaterialApprovalActions.select(id))
  const handleClickSendEmail = (value) => dispatch(dialogActions.setOptions({ open: true, crudMode: 'C', value }))

  const downloadG04docuFile = (documentPath) => g04docuFileActions.downloadDirect(documentPath)
  const removeWithFile = ({ id, documentPath }) => dispatch(g04docuMaterialApprovalActions.removeWithFile({ id, documentPath }))

  const showMaterialApprovalProgress = (options) => dispatch(materialApprovalProgressActions.showMaterialApprovalProgress(options))

  const queryPaging = useMemo(
    () => ({
      page,
      pageSize,
    }), [page]
  );

  // console.log("queryPaging")
  // console.log(queryPaging)

  useEffect(
    async () => {
      // console.log(qs.parse(window.location.search))
      // 아래 코드를 selectPaging으로 바꾸고 페이지 변경시 호출. 단점은 hideWatermark가 매번 호출됨
      // await selectAll();

      // await hideWatermark();
      // // watermark 안보이면서 로딩바 보이도록 하기 위한 임시 코드
      // setShow(true);
      // setTimeout(() => setLoaded(true), 300);
      // if (listTimerId) {
      //   clearTimeout(listTimerId);
      // }

      // if (makeTimerId) {
      //   clearTimeout(makeTimerId);
      // }
    }, [dispatch]
  );

  const handleClickFileDownload = async (e, documentPath) => {
    e.stopPropagation();

    const arr = documentPath.split("/");
    const fileName = arr[arr.length - 1];

    const res = await downloadG04docuFile(documentPath);
    const blob = await res.blob(); // res.blob는 promise를 리턴함???
    
    // 2. Create blob link to download
    const url = window.URL.createObjectURL(new Blob([blob]));            
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    // 3. Append to html page
    document.body.appendChild(link);
    // 4. Force download
    link.click();
    // 5. Clean up and remove the link
    link.parentNode.removeChild(link);
  }

  const startInterval = (ms, callback) => {
    callback();
    return setInterval(callback, ms);
  };

  const selectPaging = async (deleteOption) => {
    // console.log({ title, from });
    // setLoaded(false)

    let func = null;
    let search = null;
    let interval;

    const { id: gclientId, type } = sessionUser;

    // if (from?.params?.g04docuMaterialApprovalId) {
    //   interval = 5000; // 자재승인서류 발급 화면에서 발급 버튼 누르면 생성 여부를 기다리므로 결과 확인 주기를 짧게 했다.
    //   // search = from.params.gprojectId;
    //   // func = selectByGProject;
    //   search = from.params.g04docuMaterialApprovalId;
    //   func = selectG04docuMaterialApprovalById;

    //   alert(`interval: ${interval}`)
    //   makeTimerId = startInterval(interval, () => func(search));
    //   alert(`start makeTimerId: ${makeTimerId}`)
    //   showMaterialApprovalProgress({ open: true, values: { id: from.params.g04docuMaterialApprovalId } });
    // } else {
    //   interval = 15000; // 자재승인서류 보관함 메뉴로 들어오면 생성 후 목록을 확인하는 경우가 대부분일 것이므로 결과 확인 주기를 길게 했다.
    //   if (type === 'ADMIN') {
    //     search = queryPaging;
    //     func = selectAll;
    //   } else if (type === 'GCLIENT') {
    //     search = gclientId;
    //     func = selectByGClient;
    //   }

    //   if (func) {
    //     listTimerId = startInterval(interval, () => func(search));
    //     alert(`start listTimerId: ${listTimerId}`)
    //   }
    // }
    if (from?.params?.g04docuMaterialApprovalId) {
      selectG04docuMaterialApprovalById(from.params.g04docuMaterialApprovalId);
      if (!deleteOption) {
        showMaterialApprovalProgress({
          open: true,
          values: {
            id: from.params.g04docuMaterialApprovalId,
            // func: selectG04docuMaterialApprovalById,
            // funcParams: {
            //   id: from.params.g04docuMaterialApprovalId,
            // }
          },
        });
      }
    } else {
      if (type === 'ADMIN') {
        search = queryPaging;
        func = selectAll;
      } else if (type === 'GCLIENT') {
        search = gclientId;
        func = selectByGClient;
      }

      if (func) {
        setLoaded(false);
        await func(search);
        setTimeout(() => setLoaded(true), 300);
        
        // showMaterialApprovalProgress({
        //   open: true,
        //   values: {
        //     id: from.params.g04docuMaterialApprovalId,
        //     func: selectG04docuMaterialApprovalById,
        //     fucnParams: {
        //       id: from.params.g04docuMaterialApprovalId,
        //     }
        //   },
        // });
      }
    }
  }
  
  // useEffect(
  //   () => {
  //     // console.log(rows)
  //     const rowsProcessing = rows.filter(row => row.results.status === 'PROCESSING' ? true : false);
  //     console.log(rowsProcessing)
  //     if (rowsProcessing.length === 0) {
  //       if (listTimerId) {
  //         clearTimeout(listTimerId);
  //         // setLoaded(true);
  //         alert(`clear listTimerId: ${listTimerId}`)
  //       }

  //       if (makeTimerId) {
  //         clearTimeout(makeTimerId);
  //         // setLoaded(true);
  //         alert(`clear makeTimerId: ${makeTimerId}`)
  //       }
  //     }
  //   }, [rows]
  // )

  useEffect(
    () => {
      selectPaging();
    }, [queryPaging]
  );

  const handlePageChange = (newPage) => {
    setPage(newPage);
  }

  const refresh = () => {
    selectPaging();
  }

  return (
    <ThemeProvider theme={theme}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container component="main" maxWidth="false">
        <CssBaseline />
        <G04docuMaterialApprovalDialog
          crudMode={crudMode}
          setCrudMode={setCrudMode}
          open={open}
          setOpen={setOpen}
          selectedRow={selectedRow}
          queryPaging={queryPaging}
          refresh={selectAll}
        />
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={10} sx={{ mb: 2 }}>
              {
                title && (
                  <Typography variant="h5">
                    {title}
                  </Typography>
                )
              }
            </Grid>
            <Grid item xs={2} display="flex" justifyContent="flex-end">
              {/* <Button
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                startIcon={<Refresh />}
                onClick={refresh}
              >
                {"다시 검색하기"}
              </Button> */}
              <Button
                variant="contained"
                sx={{ mt: 3, mb: 2, display: from ? 'none' : "" }}
                // startIcon={<Refresh />}
                onClick={refresh}
              >
                {"검색하기"}
              </Button>
              {/* 그리드가 다 로드 된 후에는 아래처럼 동적으로 컬럼 제어 가능 */}
              {/* <Button
                onClick={() => {
                  apiRef.current.updateColumn({
                    field: 'gclient',
                    headerName: '거래선명',
                    width: 200,
                    hide: false,
                    // editable: true,
                    valueGetter: (params) => {
                      return params.value.name
                    },
                  });
                }}
              >
                {"test"}
              </Button> */}
            </Grid>
          </Grid>
          <div style={{ height: from?.params?.g04docuMaterialApprovalId ? 300 : 800, width: '100%' }}>
            <DataGridPro
              // apiRef={apiRef}
              localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
              sx={{ cursor: 'pointer', fontSize: '0.85em' }}
              initialState={{ pinnedColumns: { /*left: ['id', 'name', 'code'], */right: ['actions'] } }}
              slots={{
                noRowsOverlay: CustomNoRowsOverlay,
                loadingOverlay: LinearProgress,
              }}
              autoHeight={from?.params?.g04docuMaterialApprovalId ? true : false}
              columnHeaderHeight={38}
              rowHeight={34}
              loading={!loaded}
              rows={from?.params?.g04docuMaterialApprovalId ? rowsGenerate : rows}
              columns={columns}
              // pageSize={5}
              // rowsPerPageOptions={[5]}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[1, 10, 20, 50, 100]}
              onPageChange={handlePageChange}
              page={page}
              // rowCount={rowCount}
              // pagination
              pagination={from?.params?.g04docuMaterialApprovalId ? false : true}
              hideFooter={from?.params?.g04docuMaterialApprovalId ? true : false}
              onRowDoubleClick={(params) => handleSelect({ type: 'edit', params })}
              // checkboxSelection
              // disableSelectionOnClick
              // onRowSelectionModelChange={(ids) => {
              //   const selectedIDs = new Set(ids);
              //   const selectedRows = rows.filter((row) =>
              //     selectedIDs.has(row.id),
              //   );
      
              //   setSelectedRows(selectedRows);
              // }}
            />
          </div>
        </Box>
      </Container>
      {/* <ConfirmDialog
        removeId={removeObject}
        title={"삭제"}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={remove}
        onCancel={() => {}}
      >
        {`선택한 자재승인서류를 삭제하시겠습니까?`}
      </ConfirmDialog> */}
      <G04docuMaterialApprovalEmailDialog />
      <G04docuMaterialApprovalDeleteConfirmDialog /> {/* 삭제 예/아니오 다이얼로그 */}
    </ThemeProvider>
  );
};

export default G04docuMaterialApprovalManagement;
