import React, { useEffect } from "react";
import {
  FormControl,
  TextField,
} from '@mui/material';
import { Controller } from "react-hook-form";
// TODO : 추후 Locale 적용할 수 있음
import koLocale from 'date-fns/locale/ko';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import DatePicker from '@mui/lab/DatePicker';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

// TODO : 시간 부분 처리 고려할 것
const FormInputDate1 = React.forwardRef(({ value, setValue, name, control, size = "small", label, color, focused, onEdit, onChangePeriodValue, views=null, inputFormat='yyyy-MM-dd', mask='____-__-__', ...props }, ref) => {
  const [datePickerValue, setDatePickerValue] = React.useState(null);

  // 부모 컴포넌트에서 사용할 함수를 선언
  React.useImperativeHandle(ref, () => ({
    setDatePickerValue1,
  }))

  const setDatePickerValue1 = (value) => {
    setDatePickerValue(value);
    setValue && setValue(name, value);
  }

  const displayDateValue = () => {
    // console.log(value);
    console.log(name)
    console.log(datePickerValue)
    return datePickerValue || value;
  }

  useEffect(() => {
    if (datePickerValue) {
      setValue && setValue(name, datePickerValue); // setValue에서 렌더링 발생
    }
  }, [datePickerValue]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={koLocale}>
      <FormControl size={size} fullWidth>
        <Controller
          name={name}
          control={control}
          render={({
            field: { onChange, value },
            fieldState: { error },
            formState,
          }) => (
            <DatePicker
              // views={['year']}
              views={views}
              inputFormat={inputFormat}
              mask={mask}
              label={label}
              // value={value}
              value={displayDateValue()}
              // onChange={onChange}
              onChange={
                (newValue) => {
                  if (onChangePeriodValue && !onChangePeriodValue(newValue)) {
                    return;
                  }

                  setDatePickerValue(newValue);
                  onChange(newValue);
                  // setValue && setValue(name, value || datePickerValue);
                  onEdit && onEdit();
                }
              }
              renderInput={(params) => {
                // console.log(params)
                return <TextField size={size} color={color} focused={focused} {...params} sx={{ input: { color: color === "error" ? "red" : "" } }} />
              }}
              // <TextField label="Outlined secondary" color="error" focused />
              {...props}
            />
          )}
        />
      </FormControl>
    </LocalizationProvider>
  )
});

export default FormInputDate1;