import React from 'react';
import { Route, Routes } from "react-router-dom";
import { GProjectManagement } from "../components/GProject";

import RequireAuth from './RequireAuth';
import DashboardRoute from './DashboardRoute';
import ProfileRoute from './ProfileRoute';
import IndexRoute from './IndexRoute';
import SettingsRoute from './SettingsRoute';
import MaterialApproveRoute from './MaterialApproveRoute';

export const AppRoutes = ({ sessionUser, isLoaded }) => (
    <>
    <Routes>
      {/* 대시보드 */}
      {DashboardRoute({sessionUser})}

      {/* Intro, Login, Signup */}
      {IndexRoute()}

      {/* Profile */}
      {ProfileRoute({sessionUser})}
      
      {/* 설정 */}
      {SettingsRoute({sessionUser})}
      
      {/* 자재승인서 */}
      {MaterialApproveRoute({sessionUser})}
      
      <Route path="/projects" element={
        <RequireAuth sessionUser={sessionUser}>
          <GProjectManagement />
        </RequireAuth>
      } />

    </Routes>
    </>
);

export default AppRoutes;