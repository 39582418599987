export const MATERIAL_ITEM_TYPES = ['RAW_MATERIAL', 'SUB_MATERIAL_PROCESS', 'SUB_MATERIAL_BUILD'];
export const PREFERENCE_ITEM_TYPES = ['NONE'];

export const MATERIAL_ITEM_TYPE = {
    RAW_MATERIAL: MATERIAL_ITEM_TYPES[0],
    SUB_MATERIAL_PROCESS: MATERIAL_ITEM_TYPES[1],
    SUB_MATERIAL_BUILD: MATERIAL_ITEM_TYPES[2]
};
export const PREFERENCE_ITEM_TYPE = {
    NONE: PREFERENCE_ITEM_TYPES[0]
};

export const MATERIAL_TYPE_LABELS = {
    RAW_MATERIAL: '원부자재',
    SUB_MATERIAL_PROCESS: '가공부자재',
    SUB_MATERIAL_BUILD: '시공부자재',
    NONE: '없음'
};

export const getMaterialItemTypeOptions = () => 
    MATERIAL_ITEM_TYPES.map(type => ({
    label: MATERIAL_TYPE_LABELS[type] || type,
    value: type
}));

export const getPreferenceItemTypeOptions = () => 
    PREFERENCE_ITEM_TYPES.map(type => ({
    label: MATERIAL_TYPE_LABELS[type] || type,
    value: type
}));

export const getAllItemTypeOptions = () => 
    [...MATERIAL_ITEM_TYPES, ...PREFERENCE_ITEM_TYPES].map(type => ({
    label: MATERIAL_TYPE_LABELS[type] || type,
    value: type
}));

export const getAllItemTypes = () => [...MATERIAL_ITEM_TYPES, ...PREFERENCE_ITEM_TYPES];