const SET_SHOW_MATERIAL_APPROVAL_PROGRESS = 'materialApprovalProgress/setShowMaterialApprovalProgress';

const setShowMaterialApprovalProgress = (options) => ({
  type: SET_SHOW_MATERIAL_APPROVAL_PROGRESS,
  payload: options,
});

export const showMaterialApprovalProgress = (options) => async dispatch => {
  dispatch(setShowMaterialApprovalProgress(options));
};

const initialState = {
  options: null,
  // materialApprovalProgress: false,
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case SET_SHOW_MATERIAL_APPROVAL_PROGRESS:
      newState = Object.assign({}, state, { options: action.payload });
      return newState;
    default:
      return state;
  }
}

export default reducer;
