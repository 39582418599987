// import id from "date-fns/locale/id/index.js";
import { csrfFetch } from "./csrf.js";
import { projectServerUrl } from '../config';

// const SET_GPROJECT_GLASS = 'gprojectGlass/setGProjectGlass';
// const REMOVE_GPROJECT_GLASS = "gprojectGlass/removeGProjectGlass";
const SET_GPROJECT_GLASSES = 'gprojectGlass/setGProjectGlasses';

// const setGProjectGlass = (gprojectGlass) => ({
//   type: SET_GPROJECT_GLASS,
//   payload: gprojectGlass,
// });

// const removeGProjectGlass = () => ({
//   type: REMOVE_GPROJECT_GLASS,
// });

const setGProjectGlasses = (gprojectGlasses) => ({
  type: SET_GPROJECT_GLASSES,
  payload: gprojectGlasses,
});

// export const create = ({ name, code, site, siteAddress, period, gclientDetails, comments, doneYN, selectedGlasses }) => async dispatch => {
//   const response = await csrfFetch(dispatch, "/api/gprojectGlasses", {
//     method: "POST",
//     body: JSON.stringify({ name, code, site, siteAddress, period, gclientDetails, comments, doneYN, selectedGlasses }),
//   });
//   const data = await response.json();
//   dispatch(setGProjectGlass(data.gprojectGlass));
//   return response;
// };

// export const modify = ({ id, name, code, site, siteAddress, period, gclientDetails, comments, doneYN, selectedGlasses }) => async dispatch => {
//   const response = await csrfFetch(dispatch, "/api/gprojectGlasses", {
//     method: "PUT",
//     body: JSON.stringify({ id, name, code, site, siteAddress, period, gclientDetails, comments, doneYN, selectedGlasses }),
//   });
//   const data = await response.json();
//   dispatch(setGProjectGlass(data.gprojectGlass));
//   return response;
// };

// export const modifyDoneYN = ({ id, doneYN }) => async dispatch => {
//   const response = await csrfFetch(dispatch, "/api/gprojectGlasses/doneYN", {
//     method: "PUT",
//     body: JSON.stringify({ id, doneYN }),
//   });
//   const data = await response.json();
//   dispatch(setGProjectGlass(data.gprojectGlass));
//   return response;
// };

export const selectAll = () => async dispatch => {
  const response = await csrfFetch(dispatch, `${projectServerUrl}/api/gprojectGlasses`);
  const data = await response.json();
  console.log(data.gprojectGlasses)
  dispatch(setGProjectGlasses(data.gprojectGlasses));
  return response;
}

export const selectAllByQuery = () => async dispatch => {
  const response = await csrfFetch(dispatch, `${projectServerUrl}/api/gprojectGlasses/query/selectAll`);
  const data = await response.json();
  console.log(data.gprojectGlasses)
  dispatch(setGProjectGlasses(data.gprojectGlasses));
  return response;
}

export const selectAllByProjectByQuery = (gprojectId) => async dispatch => {
  const response = await csrfFetch(dispatch, `${projectServerUrl}/api/gprojectGlasses/query/selectAll/project/${gprojectId}`);
  const data = await response.json();
  console.log(data.gprojectGlasses)
  dispatch(setGProjectGlasses(data.gprojectGlasses));
  return response;
}

export const selectAllByProjectAndAreaTypeByQuery = (gprojectId, areaType) => async dispatch => {
  const response = await csrfFetch(dispatch, `${projectServerUrl}/api/gprojectGlasses/query/selectAll/project/${gprojectId}/area/${areaType}`);
  const data = await response.json();
  console.log(data.gprojectGlasses)
  dispatch(setGProjectGlasses(data.gprojectGlasses));
  return response;
}

// export const select = (id) => async dispatch => {
//   const response = await csrfFetch(dispatch, `/api/gprojectGlasses/${id}`);
//   const data = await response.json();
//   console.log(data.gprojectGlass)
//   dispatch(setGProjectGlass(data.gprojectGlass));
//   return response;
// }

// export const selectDirect = async (id) => {
//   const response = await csrfFetch(null, `/api/gprojectGlasses/${id}`);
//   const data = await response.json();
//   console.log(data.gprojectGlass)
//   return data.gprojectGlass;
// }

// export const remove = (id) => async (dispatch) => {
//   const response = await csrfFetch(dispatch, `/api/gprojectGlasses/${id}`, {
//     method: "DELETE",
//   });
//   dispatch(removeGProjectGlass());
//   return response;
// };

// export const reorder = async ({ type, id }) => {
//   const response = await csrfFetch(null, "/api/gprojectGlasses/reorder", {
//     method: "POST",
//     body: JSON.stringify({ type, id }),
//   });
  
//   return response;
// }

const initialState = {
  // gprojectGlass: null,
  gprojectGlasses: [],
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    // case SET_GPROJECT_GLASS:
    //   newState = Object.assign({}, state, { gprojectGlass: action.payload });
    //   return newState;
    // case REMOVE_GPROJECT_GLASS:
    //   newState = Object.assign({}, state, { gprojectGlass: null });
    //   return newState;
    case SET_GPROJECT_GLASSES:
      newState = Object.assign({}, state, { gprojectGlasses: action.payload });
      return newState;
    default:
      return state;
  }
}

export default reducer;
