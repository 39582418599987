import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Drawer,
  Grid,
  IconButton,
  SpeedDial,
  SpeedDialIcon,
  SpeedDialAction,
  Tooltip,
} from '@mui/material';
import {
  KeyboardArrowDown,
  StackedBarChart,
} from '@mui/icons-material';
import usePrevious from "../hook/usePrevious";
import {
  DialogTitleClose,
  PaperComponent,
} from "../dialog";
import GTypeManagement from "../GType/GTypeManagement";
import GGlassForm from "./GGlassForm";
import * as gtypeDetailActions from "../../store/gtypeDetail";
import * as gtypeActions from "../../store/gtype";
import * as gglassActions from "../../store/gglass";

// // 아래 form components의 name과 연계
// const defaultValues = {
//   id: "",
//   name: "",
//   // code: "",
//   comments: "",
//   gtypeId: "",
//   specification: "",
//   otherSpecs: "",
//   labelPositionView: "inout",
//   labelPosition: "TopLeft",
//   // ["0_6_gl_process_edge_grinding"]: "",
// };

const GGlassDialog = ({
  modify,
  paste,
  open,
  setOpen,
  selectedRow,
  setSelectedRow,
  gtypeDetailsWithGComponent,
  selectedGcomponentItems,
  setSelectedGcomponentItems,
  gtypes,
  refresh,
  gtypeDetailsWithGComponentLength,
}) => {
  // const dependentGcomponentMap = new Map();

  // const defaultValuesForGcomponent = {};
  
  // // Gcomponentitem 설정시 다른 item에 영향을 주는 동작을 위한 설정
  // // 1. defaultVaues 초기화 (undefined일 경우 setValue로 값은 설정되나 selectbox에 적용안됨)
  // // 2. "없음" 설정시 영향을 받는 item도 "없음"으로 설정하기 위한 map 구성. ("없음"은 item에 등록되어 있지 않으므로 연결고리를 찾을 수 없기 때문)
  // const generateComponent = (gcomponent, gtypeDetailsIndex) => {
  //   gcomponent.forEach((item, gcomponentItemIndex) => {
  //     if (item.level > gcomponent[0].level) {
  //       if (item.type === 'property') {
  //         defaultValuesForGcomponent[`${gtypeDetailsIndex}_${gcomponentItemIndex}_${item.id}`] = "";
  //         // console.log(item)
  //         const properties = item.properties;
  //         // console.log(properties);
  //         if (properties.length > 0) {
  //           properties.forEach(property => {
  //             // console.log(property.dependentGcomponentItem);
  //             if (property.dependentGcomponentItem?.length > 0) {
  //               const dependentGcomponentItem = JSON.parse(property.dependentGcomponentItem);

  //               let targetGcomponentItemOrder = "";
  //               gcomponent.forEach((component, idx) => {
  //                 if (component.id === dependentGcomponentItem.gcomponentId) {
  //                   targetGcomponentItemOrder = idx;
  //                 }
  //               });
                
  //               dependentGcomponentMap.set(`${gtypeDetailsIndex}_${gcomponentItemIndex}_${item.id}`, {
  //                 id: dependentGcomponentItem.gcomponentId,
  //                 name: dependentGcomponentItem.name,
  //                 value: dependentGcomponentItem,
  //                 gtypeOrder: gtypeDetailsIndex,
  //                 gcomponentOrder: targetGcomponentItemOrder,
  //               });

  //               // console.log(dependentGcomponentMap)
  //             }
  //           })
  //         }
  //       }
  //     };
  //   });
  // }

  // gtypeDetailsWithGComponent.forEach((gtypeDetail, index) => {
  //   generateComponent(gtypeDetail, index)
  // });

  // 아래 form components의 name과 연계
  // const defaultValues = Object.assign({
  const defaultValues = {
    id: "",
    name: "",
    // code: "",
    comments: "",
    gtypeId: "",
    specification: "",
    specificationDisplay: "", // 없으면 사양 설정시 레이블과 겹침
    otherSpecs: "",
    otherSpecsDisplay: "", // 없으면 사양 설정시 레이블과 겹침
    labelPositionView: "inout",
    labelPosition: "TopLeft",
  };
  //   id: "",
  //   name: "",
  //   // code: "",
  //   comments: "",
  //   gtypeId: "",
  //   specification: "",
  //   otherSpecs: "",
  //   labelPositionView: "inout",
  //   labelPosition: "TopLeft",
  // }, defaultValuesForGcomponent);
  const dialogRef = useRef();

  const [errors, setErrors] = useState([]);
  const [fullScreen, setFullScreen] = useState(true);
  const [selectedG04docuGCertifications, setSelectedG04docuGCertifications] = useState([]);
  const [drawerState, setDrawerState] = useState({
    // top: false,
    // left: false,
    // bottom: false,
    // right: false,
  });
  const prevDrawerState = usePrevious(drawerState);

  const [show, setShow] = useState(true);

  const initDialog = () => {
    initGTypeDetailsWithGComponent();
    initGcomponentItems();
    setSelectedRow({});
    setSelectedGcomponentItems([]);
    
    // defaultValues에 동적인 값 설정해서 모두 초기화하는 방법도 있지만 아래처럼 해당 값만 초기화하는 방법도 있음
    if (selectedG04docuGCertifications.length > 0) {
      selectedG04docuGCertifications.forEach(selectedG04docu => setValue(selectedG04docu.id, ""));
    }
    setSelectedG04docuGCertifications([]);
    
    // TODO : GGlassForm 내부에서 초기화하는 부분과 연결해야 점검할 것
    setValue("name", "");
    setValue("comments", "");
    setValue("gtypeId", "");
    // specification: "",
    // otherSpecs: "",
    // labelPositionView: "inout",
    // labelPosition: "TopLeft",
  }
  const handleDialogClose = async () => {
    setOpen(false);
    initDialog();
  };

  const handleDialogMinMax = () => {
    console.log(dialogRef.current.offsetTop)
    setFullScreen(!fullScreen);
  }

  /**
   * userForm에 인자 { defaultValues: defaultValues }를 넘기지 않고 useForm() 형태로 사용하면 아래 에러 발생
   * Warning: A component is changing an uncontrolled input to be controlled. This is likely caused by
   * the value changing from undefined to a defined value, which should not happen. Decide between using
   * a controlled or uncontrolled input element for the lifetime of the component.
   */
  const { handleSubmit, reset, control, setValue, getValues } = useForm({ defaultValues: defaultValues });

  const dispatch = useDispatch();

  // 데이터 관리
  const addGGlass = ({ id, name, /*code, */specification, otherSpecs, comments, gtypeId, selectedGcomponentItems, labelPositionView, labelPosition, selectedG04docuGCertifications }) => dispatch(gglassActions.create({ id, name, /*code, */specification, otherSpecs, comments, gtypeId, selectedGcomponentItems, labelPositionView, labelPosition, selectedG04docuGCertifications }))
  const modifyGGlass = ({ id, name, /*code, */specification, otherSpecs, comments, gtypeId, selectedGcomponentItems, labelPositionView, labelPosition, selectedG04docuGCertifications }) => dispatch(gglassActions.modify({ id, name, /*code, */specification, otherSpecs, comments, gtypeId, selectedGcomponentItems, labelPositionView, labelPosition, selectedG04docuGCertifications }))
  const initGTypeDetailsWithGComponent = () => dispatch(gtypeDetailActions.initWithComponent())
  const selectAllGTypes = () => dispatch(gtypeActions.selectAll())

  const onSubmit = ({ id, name, /*code, */specification, otherSpecs, comments, gtypeId, labelPositionView, labelPosition }) => {
    
    // setErrors([]); // TODO : 이 코드로 인해 FormInputText select에서 없음 처리해도 잔상이 남음

    let func;
    if (modify) {
      func = modifyGGlass;
    } else {
      func = addGGlass;
    }

    console.log({ id, name, /*code, */specification, otherSpecs, comments, gtypeId, selectedGcomponentItems, labelPositionView, labelPosition, selectedG04docuGCertifications: selectedG04docuGCertifications.filter(cert => cert.checked) })
    // return;

    func({
      id,
      name,
      specification,
      otherSpecs,
      comments,
      gtypeId,
      selectedGcomponentItems,
      labelPositionView,
      labelPosition,
      selectedG04docuGCertifications: selectedG04docuGCertifications.filter(cert => cert.checked), // 선택된 것만 저장하도록 수정
    })
      .then (res => {
        handleDialogClose();
        refresh();
      })
      .catch (async (res) => {
        setErrors([]);
        const data = await res.json();
        if (data && data.errors) setErrors(data.errors);
      });
  }
  
  const initGcomponentItems = () => {
    // 설정한 값들을 select 박스에서 선택
    selectedGcomponentItems && selectedGcomponentItems.forEach((gtypeDetails, gtypeDetailsOrder) => {
      gtypeDetails.forEach((gcomponentItem, gcomponentItemOrder) => {
        setValue(`${gtypeDetailsOrder}_${gcomponentItemOrder}_${gcomponentItem.id}`, "");
      });
    });
  }

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    // console.log({ ...state, [anchor]: open });
    setDrawerState({ ...drawerState, [anchor]: open });

    if (!open) {
      setShow(true);
    }
  };

  const actions = [
    { icon: <StackedBarChart />, name: '템플릿 구조 관리', code: "GGLASS_TYPE", component: <GTypeManagement title={"템플릿 구조"} /> },
    // { icon: <Icon baseClassName="fas" className="fa-building" /*color="primary"*/ fontSize="small" />, name: '거래처 관리', code: "GCLIENT", component: <GClientManagement /> },
  ];

  // 아래 두가지 방식 검토
  const handleSpeedDialActionClick = (action) => (e) => {
  // const handleSpeedDialActionClick = (e, action) => {
    // setOpen(false);
    setShow(false);
    toggleDrawer(action.code, true)(e);
  }

  useEffect(
    async () => {
      // console.log(prevDrawerState);
      // console.log(drawerState);
      if (prevDrawerState?.GGLASS_TYPE === true && drawerState?.GGLASS_TYPE === false ) {
        // await selectAllGclientType();
        await selectAllGTypes();
      }
    }, [drawerState]
  )

  // useEffect(
  //   () => {
  //     // alert("111")
  //   }, [gtypeDetailsWithGComponent]
  // )

  return (
    <>
      <Dialog
        ref={dialogRef}
        fullScreen={fullScreen}
        open={open}
        onClose={handleDialogClose}
        PaperComponent={PaperComponent}
        aria-labelledby={fullScreen ? "no-draggable-dialog-title" : "draggable-dialog-title"}
        maxWidth="lg"
        scroll="body"
        sx={{ visibility: show ? 'visible' : 'hidden' }}
      >
        <DialogTitleClose
          id="draggable-dialog-title"
          onClose={handleDialogClose}
          onMinMax={handleDialogMinMax}
          fullScreen={fullScreen}
          color={fullScreen ? "white" : ""}
          style={{ cursor: !fullScreen && 'move', backgroundColor: fullScreen ? "#1976d2" : "" }}
        >
          {modify ? "유리 템플릿 수정" : "유리 템플릿 등록"}
          <SpeedDial
            ariaLabel="SpeedDial basic example"
            sx={{ position: 'absolute', top: 61, right: 24 }}
            icon={<SpeedDialIcon />}
            FabProps={{ size: "small" }}
            direction="left"
          >
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={handleSpeedDialActionClick(action)}
              // onClick={(e) => handleSpeedDialActionClick(e, action)}
            />
          ))}
        </SpeedDial>
        </DialogTitleClose>
        <DialogContent dividers sx={{ overflowY: 'hidden' }}>
          <Grid container sx={{ mt: 1 }}>
            <GGlassForm
              errors={errors}
              open={open}
              modify={modify}
              paste={paste}
              selectedRow={selectedRow}
              gtypeDetailsWithGComponent={gtypeDetailsWithGComponent}
              selectedGcomponentItems={selectedGcomponentItems}
              setSelectedGcomponentItems={setSelectedGcomponentItems}
              gtypes={gtypes}
              control={control}
              setValue={setValue}
              getValues={getValues}
              // dependentGcomponentMap={dependentGcomponentMap}
              gtypeDetailsWithGComponentLength={gtypeDetailsWithGComponentLength}
              selectedG04docuGCertifications={selectedG04docuGCertifications}
              setSelectedG04docuGCertifications={setSelectedG04docuGCertifications}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit(onSubmit)}>{modify ? "수정" : "저장"}</Button>
          {/* TODO : 아래 코드 안되는 이유? -> Uncaught RangeError: Maximum call stack size exceeded*/}
          {/* <Button onClick={reset}>{"초기화"}</Button> */}
          <Button
            onClick={() => {
              reset();
              // setSelectedGcomponentItems([]); // TODO : react-hook-form 사용이 아닌 컴포넌트 초기화 방법 강구할 것
            }}>
              {"초기화"}
            </Button>
          <Button onClick={handleDialogClose}>{"닫기"}</Button>
        </DialogActions>
      </Dialog>
      {
        actions.map((action) => (
          <Drawer
            anchor={"bottom"} // TODO : 추후 사용자가 환경설정에서 위치 설정하면 전체 반영하는 방법 강구
            open={drawerState[action.code]}
            PaperProps={{
              sx: { width: "100%" },
            }}
            onClose={toggleDrawer(action.code, false)}
          >
            <Grid display="flex" justifyContent={"center"} alignItems="center" sx={{ backgroundColor: "#f3f3f3" }}>
              <Tooltip title={"닫기"}>
                <IconButton aria-label="close drawer" size="small" component="span" onClick={toggleDrawer(action.code, false)}>
                  <KeyboardArrowDown />
                </IconButton>
              </Tooltip>
            </Grid>
            {action.component}
          </Drawer>
        ))
      }
    </>
  );
};

export default GGlassDialog;
