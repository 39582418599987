import { csrfFetch } from "./csrf.js";
import { gcoreServerUrl } from '../config.js';

const SET_GSEND_G04_DOCU = 'gsendG04docu/setGSendG04docu';
const REMOVE_GSEND_G04_DOCU = "gsendG04docu/removeGSendG04docu";
const SET_GSEND_G04_DOCUS = 'gsendG04docu/setGSendG04docus';

const setGSendG04docu = (gsendG04docu) => ({
  type: SET_GSEND_G04_DOCU,
  payload: gsendG04docu,
});

const removeGSendG04docu = () => ({
  type: REMOVE_GSEND_G04_DOCU,
});

const setGSendG04docus = (data) => ({
  type: SET_GSEND_G04_DOCUS,
  payload: data,
});

export const selectAll = () => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gsendG04docus`);
  const data = await response.json();
  dispatch(setGSendG04docus(data.gsendG04docus));
  return response;
}

export const selectAllByQuery = ({ gclientIdFrom, gclientIdTo, searchWord }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gsendG04docus/query/selectAll`, {
    method: "POST",
    body: JSON.stringify({ gclientIdFrom, gclientIdTo, searchWord }),  
  });
  const data = await response.json();
  dispatch(setGSendG04docus(data.gsendG04docus));
  return response;
}

export const selectAllDirect = async () => {
  const response = await csrfFetch(null, `${gcoreServerUrl}/api/gsendG04docus`);
  const data = await response.json();  
  return data.gsendG04docus;
}

export const selectAllByGClient = (gclientId) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gsendG04docus/gclient/${gclientId}`);
  const data = await response.json();
  dispatch(setGSendG04docus(data.gsendG04docus));
  return response;
}

export const selectAllByGClientDirect = async (gclientId) => {
  const response = await csrfFetch(null, `${gcoreServerUrl}/api/gsendG04docus/gclient/${gclientId}`);
  const data = await response.json();
  return data.gsendG04docus;
}

export const select = (id) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gsendG04docus/${id}`);
  const data = await response.json();
  dispatch(setGSendG04docu(data.gsendG04docu));
  return response;
}

export const initGSendG04docu = () => async dispatch => {
  dispatch(setGSendG04docu(null));
}

export const initGSendG04docus = () => async dispatch => {
  dispatch(setGSendG04docus([]));
}

export const remove = (id) => async (dispatch) => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gsendG04docus/${id}`, {
    method: "DELETE",
  });
  dispatch(removeGSendG04docu());
  return response;
};

export const removeSelectedDirect = async (ids) => {
  const response = await csrfFetch(null, `${gcoreServerUrl}/api/gsendG04docus/delete/selected`, {
    method: "DELETE",
    body: JSON.stringify({ ids }),
  });
  
  const data = await response.json();
  return data.deletedIds;
};

const initialState = {
  gsendG04docu: null,
  gsendG04docus: [],
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case SET_GSEND_G04_DOCU:
      newState = Object.assign({}, state, { gsendG04docu: action.payload });
      return newState;
    case REMOVE_GSEND_G04_DOCU:
      newState = Object.assign({}, state, { gsendG04docu: null });
      return newState;
    case SET_GSEND_G04_DOCUS:
      newState = Object.assign({}, state, { gsendG04docus: action.payload });
      return newState;
    default:
      return state;
  }
}

export default reducer;
