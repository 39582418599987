import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
} from '@mui/material';
import { SketchPicker } from "react-color";
import { v4 as uuidv4 } from 'uuid';
import {
  FormInputDropdown,
  FormInputText,
} from "../form";
import {
  DialogTitleClose,
  PaperComponent,
} from "../dialog";
import * as constructionWorkTypeActions from "../../store/constructionWorkType";

// 아래 form components의 name과 연계
const defaultValues = {
  id: "",
  name: "",
  code: "",
  comments: "",
  url: "",

};

const ConstructionWorkTypeDialog = ({
  crudMode,
  setCrudMode,
  open,
  setOpen,
  selectedRow,
  queryPaging,
  refresh,
}) => {
  const [errors, setErrors] = useState([]);
  const [hexColor, setHexColor] = useState('');
  const [showColorPicker, setShowColorPicker] = useState(false);

  const handleDialogClose = () => {
    setOpen(false);
    setCrudMode('');

    initDialog();
  };

  const initDialog = () => {
    for (const [item, value] of Object.entries(defaultValues)) {
      setValue(item, value);
    }

    // 그외 초기화할 것들은 여기서 초기화
  }

  /**
   * userForm에 인자 { defaultValues: defaultValues }를 넘기지 않고 useForm() 형태로 사용하면 아래 에러 발생
   * Warning: A component is changing an uncontrolled input to be controlled. This is likely caused by
   * the value changing from undefined to a defined value, which should not happen. Decide between using
   * a controlled or uncontrolled input element for the lifetime of the component.
   */
  const { handleSubmit, reset, control, setValue } = useForm({ defaultValues: defaultValues });
  
  // 데이터 관리
  const dispatch = useDispatch();

  const addConstructionWorkType = ({ id, name, code, comments, color, url }) => dispatch(constructionWorkTypeActions.create({ id, name, code, comments, color, url }))
  const modifyConstructionWorkType = ({ id, name, code, comments, color, url }) => dispatch(constructionWorkTypeActions.modify({ id, name, code, comments, color, url }))

  const onSubmit = ({ id, name, code, comments, url }) => {
    setErrors([]);
    
    // console.log({ id, name, code, comments });
    // return;
    let func;
    if (crudMode === 'C') {
      func = addConstructionWorkType;
    } else if ((crudMode === 'U')) {
      func = modifyConstructionWorkType;
    }
    func({ id, name, code, comments, color: hexColor, url })
      .then (res => {
        handleDialogClose();
        refresh(queryPaging);
      })
      .catch (async (res) => {
        const data = await res.json();
        if (data && data.errors) setErrors(data.errors);
      });
  }

  useEffect(
    () => {
      if (selectedRow) {
        for (const [item, value] of Object.entries(defaultValues)) {
          setValue(item, selectedRow[item] || value);
        }

        // 그외 설정할 것들은 여기서 한다.
        setHexColor(selectedRow?.color || "");
        setShowColorPicker(false);
      }
    }, [selectedRow]
  );

  useEffect(
    () => {
      if (crudMode === 'C') {
        setValue("id", uuidv4());
      }
      // for(let i = 0; i< 210; i++) {
      //   console.log(uuidv4())
      // }
    }, [crudMode]
  );

  const popover = {
    // position: "absolute",
    zIndex: "2"
  };
  
  const cover = {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
    '&:hover': {
      backgroundColor: 'red',
    },
  };

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitleClose
        id="draggable-dialog-title"
        onClose={handleDialogClose}
        style={{ cursor: 'move' }}
      >
        { crudMode === 'C' && "공종 등록"}
        { crudMode === 'R' && "공종 상세"}
        { crudMode === 'U' && "공종 수정"}
      </DialogTitleClose>
      <DialogContent dividers>
        <ul>
          {errors.map((error, idx) => <li key={idx}>{error}</li>)}
        </ul>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ display: 'none' }}>
            <FormInputText
              name={"id"}
              control={control}
              label={"아이디"}
              inputProps={
                { readOnly: true }
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormInputText
              name={"name"}
              control={control}
              label={"이름"}
              inputProps={
                { readOnly: crudMode === 'R' }
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormInputText
              name={"code"}
              control={control}
              label={"코드"}
              inputProps={
                { readOnly: crudMode === 'R' }
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormInputText
              name={"comments"}
              control={control}
              label={"설명"}
              multiline
              maxRows={5}
              inputProps={
                { readOnly: crudMode === 'R' }
              }
            />
          </Grid>
          <Grid item xs={12}>
            {/* TODO : 읽기전용 기능 추가 필요 */}
            <Button
              fullWidth
              variant={hexColor === "" ? "outlined" : "contained"}
              sx={{
                bgcolor: hexColor,
                ':hover': {
                  bgcolor: hexColor,
                },
              }}
              onClick={() => setShowColorPicker(!showColorPicker)}
            >
              {hexColor === "" ? "색상없음" : "색상"}
            </Button>
            {
              showColorPicker && (
                <div style={popover}>
                  <div style={cover} onClick={() => setShowColorPicker(false)} />
                  <SketchPicker
                    color={hexColor}
                    onChange={(color) => {
                      setHexColor(color.hex);
                      // console.log(color)
                    }}
                    // onSwatchHover={(color, e) => {
                    //   console.log("color", color);
                    // }}
                  />
                </div>
              )
            } 
          </Grid>
          <Grid item xs={12}>
            <FormInputText
              name={"url"}
              control={control}
              label={"URL"}
              inputProps={
                { readOnly: crudMode === 'R' }
              }
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        { crudMode === 'C' && (
            <>
              <Button onClick={handleSubmit(onSubmit)}>{"저장"}</Button>
              {/* TODO : 아래 코드 안되는 이유? */}
              {/* <Button onClick={reset}>{"초기화"}</Button> */}
              <Button onClick={() => reset()}>{"초기화"}</Button>
            </>
          )
        }
        { crudMode === 'U' && <Button onClick={handleSubmit(onSubmit)}>{"수정"}</Button> }
        <Button onClick={handleDialogClose}>{"닫기"}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConstructionWorkTypeDialog;
