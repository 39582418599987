// import id from "date-fns/locale/id/index.js";
import { csrfFetch } from "./csrf.js";
import { gcoreServerUrl } from '../config';

const SET_GGLASS = 'gglass/setGGlass';
const REMOVE_GGLASS = "gglass/removeGGlass";
const SET_GGLASSS = 'gglass/setGGlasss';

const setGGlass = (gglass) => ({
  type: SET_GGLASS,
  payload: gglass,
});

const removeGGlass = () => ({
  type: REMOVE_GGLASS,
});

const setGGlasss = (gglasses) => ({
  type: SET_GGLASSS,
  payload: gglasses,
});

export const create = ({ id, name, /*code, */specification, otherSpecs, comments, gtypeId, selectedGcomponentItems, labelPositionView, labelPosition, selectedG04docuGCertifications }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gglasses`, {
    method: "POST",
    body: JSON.stringify({ id, name, specification, otherSpecs, /* code, */comments, gtypeId, selectedGcomponentItems, labelPositionView, labelPosition, selectedG04docuGCertifications }),
  });
  const data = await response.json();
  dispatch(setGGlass(data.gglass));
  return response;
};

export const modify = ({ id, name, /*code, */specification, otherSpecs, comments, gtypeId, selectedGcomponentItems, labelPositionView, labelPosition, selectedG04docuGCertifications }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gglasses`, {
    method: "PUT",
    body: JSON.stringify({ id, name, /*code, */specification, otherSpecs, comments, gtypeId, selectedGcomponentItems, labelPositionView, labelPosition, selectedG04docuGCertifications }),
  });
  const data = await response.json();
  dispatch(setGGlass(data.gglass));
  return response;
};

export const selectAll = () => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gglasses`);
  const data = await response.json();
  dispatch(setGGlasss(data.gglasses));
  return response;
}

export const selectAllDirect = async () => {
  const response = await csrfFetch(null, `${gcoreServerUrl}/api/gglasses`);
  const data = await response.json();
  return data.gglasses;
}

export const selectAllByQuery = () => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gglasses/query/selectAll`);
  const data = await response.json();
  dispatch(setGGlasss(data.gglasses));
  return response;
}

export const selectAllByQueryDirect = async () => {
  const response = await csrfFetch(null, `${gcoreServerUrl}/api/gglasses/query/selectAll`);
  const data = await response.json();
  return data.gglasses;
}

export const select = (id) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gglasses/${id}`);
  const data = await response.json();
  dispatch(setGGlass(data.gglass));
  return response;
}

export const selectDirect = async (id) => {
  const response = await csrfFetch(null, `${gcoreServerUrl}/api/gglasses/${id}`);
  const data = await response.json();
  return data.gglass;
}

export const remove = (id) => async (dispatch) => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gglasses/${id}`, {
    method: "DELETE",
  });
  dispatch(removeGGlass());
  return response;
};

export const reorder = async ({ type, id }) => {
  const response = await csrfFetch(null, `${gcoreServerUrl}/api/gglasses/reorder`, {
    method: "POST",
    body: JSON.stringify({ type, id }),
  });
  
  return response;
}

export const selectGTypesByQueryDirect = async () => {
  const response = await csrfFetch(null, `${gcoreServerUrl}/api/gglasses/query/selectGTypes`);
  const data = await response.json();
  return data.gtypes;
}

const initialState = {
  gglass: null,
  gglasses: [],
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case SET_GGLASS:
      newState = Object.assign({}, state, { gglass: action.payload });
      return newState;
    case REMOVE_GGLASS:
      newState = Object.assign({}, state, { gglass: null });
      return newState;
    case SET_GGLASSS:
      newState = Object.assign({}, state, { gglasses: action.payload });
      return newState;
    default:
      return state;
  }
}

export default reducer;
