import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import {
  Avatar,
  Box,
  Button,
  Card,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  LinearProgress,
  Stack,
  Typography,
} from '@mui/material';
import  {
  Delete,
} from '@mui/icons-material';
import {
  DragDropContext,
  Draggable,
  Droppable,
} from 'react-beautiful-dnd';
import { v4 as uuidv4 } from 'uuid';
import { FormInputText } from "../form";
import {
  DialogTitleClose,
  PaperComponent,
} from "../dialog";
import * as gtypeActions from "../../store/gtype";
import * as gtypeDetailActions from "../../store/gtypeDetail";
import * as gcomponentActions from "../../store/gcomponent";

import './GTypeDialog.scss';

// 아래 form components의 name과 연계
const defaultValues = {
  id: "",
  name: "",
  code: "",
  comments: "",
};

const GTypeDialog = ({
  modify,
  open,
  setOpen,
  selectedRow,
  refresh,
  loadedGcomponents,
  setLoadedGcomponents,
  setOpenBackdrop,
}) => {
  const [errors, setErrors] = useState([]);
  const [selectedGcomponents, setSelectedGcomponents] = useState([]);
  const ref = useRef(null);
  const [dragStartGcomponentId, setDragStartGcomponentId] = useState("");

  const handleDialogClose = () => {
    setOpen(false);
    setSelectedGcomponents([]);
  };

  /**
   * userForm에 인자 { defaultValues: defaultValues }를 넘기지 않고 useForm() 형태로 사용하면 아래 에러 발생
   * Warning: A component is changing an uncontrolled input to be controlled. This is likely caused by
   * the value changing from undefined to a defined value, which should not happen. Decide between using
   * a controlled or uncontrolled input element for the lifetime of the component.
   */
  const { handleSubmit, reset, control, setValue } = useForm({ defaultValues: defaultValues });
  
  const gcomponentsTopLevel = useSelector((state) => state.gcomponent.gcomponentsTopLevel);
  const gtypeDetails = useSelector((state) => state.gtypeDetail.gtypeDetails);

  // 데이터 관리
  const dispatch = useDispatch();
  
  const selectByQueryGTypeId = (id) => dispatch(gtypeDetailActions.selectByQueryGTypeId(id))
  const selectAllTopLevelGComponent = () => dispatch(gcomponentActions.selectAllTopLevel());
  const addGType = ({ id, name, code, comments, gcomponents }) => dispatch(gtypeActions.create({ id, name, code, comments, gcomponents }))
  const modifyGType = ({ id, name, code, comments, gcomponents }) => dispatch(gtypeActions.modify({ id, name, code, comments, gcomponents }))

  const onSubmit = ({ id, name, code, comments }) => {
    setErrors([]);
    
    let func;
    if (modify) {
      func = modifyGType;
    } else {
      func = addGType;
    }
    
    const gcomponents = selectedGcomponents.map(component => {
      return {
        gcomponentId: component.gcomponentId,
        gcomponentCode: component.gcomponentCode,
      }
    });

    func({ id, name, code, comments, gcomponents })
      .then (res => {
        handleDialogClose();
        refresh();
      })
      .catch (async (res) => {
        const data = await res.json();
        if (data && data.errors) setErrors(data.errors);
      });
  }

  useEffect(
    async () => {
      await selectAllTopLevelGComponent();
    }, [dispatch]
  )

  useEffect(
    async () => {
      ["id", "name", "code", "comments"].forEach(item => setValue(item, selectedRow && selectedRow[item] || (item === "id" ? uuidv4() : "")))
      if (selectedRow) {
        await selectByQueryGTypeId(selectedRow.id);
      } else {
        setSelectedGcomponents([]);
      }
    }, [selectedRow]
  );

  // await dispatch(...)의 리턴값으로 받지 않고 함수 실행 후 값이 변함을 감지하는 useEffect 사용
  useEffect(
    () => {
      setSelectedGcomponents(gtypeDetails);
      setOpenBackdrop(false);
      setLoadedGcomponents(true);
    }
    , [gtypeDetails]
  );

  const handleSelectComponent = (gcomponent) => {
    const gcomponents = selectedGcomponents;
    setSelectedGcomponents(gcomponents.concat({
      ...gcomponent,
      gcomponentId: gcomponent.id,
      gcomponentCode: gcomponent.code,
      order: gcomponents.length,
    }));
  }

  const handleDeleteGcomponent = (index) => {
    const gcomponents = selectedGcomponents.filter(component => component.order !== index);
    setSelectedGcomponents(gcomponents);
  }

  const onDragStart = ({ destination, source }) => {
    setDragStartGcomponentId(selectedGcomponents[source.index].gcomponentId);
  }

  const onDragEnd = ({ destination, source }) => {
    // dropped outside the list
    if (!destination) {
      return;
    }

    if (destination.droppableId === "droppable_waste") {
      handleDeleteGcomponent(source.index);
    } else {
      const itemsNew = reorderGComponents(
        selectedGcomponents,
        source.index,
        destination.index
      );
      
      setSelectedGcomponents(itemsNew);
    }
  };

  const grid = /*8*/4;
  const dndHeight = 500;
  const wasteHeight = 40;
  const wasteMarginBottom = 20;
  
  const reorderGComponents = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    
    return result.map((item, index) => ({ ...item, order: index }));
  };

  // 삭제 영역이 삭제 동작시(드래그 해서 삭제영역 위에 놓으면) 유리 컴포넌트 두께 만큼 줄기 때문에 휴지통 부분은 컴포넌트 영역과 다르게 처리
  const getListStyle = (type) => ({
    display: type === "product" ? "flex" : "",
    padding: grid,
    height: type === "product" ? dndHeight : wasteHeight,
    marginBottom: type === "product" ? "0px" : `${wasteMarginBottom}px`, // m: 1이 10px
    // justifyContent: "center", // TODO : 가운데 정렬시 드래그 위치 관련 계산 필요
    // width: '100%',
  });

  const getItemStyle = (index, isDragging, draggableStyle, item) => {
    if (isDragging) {
      console.log(`${draggableStyle.left}, ${draggableStyle.top}, ${index}`)
      // TODO : 가로 스크롤 생성시 계산 필요. 현재는 스크롤 없을 경우만 대응. 가운데 정렬시도 계산 필요. 참고로 전체 개수와 자치 위치별로 바뀌는 것으로 보임
      // draggableStyle.left = undefined; // undefined: 첫 아이템 left, 0: 다이얼로그 left
      const arr = selectedGcomponents.filter((gcomponent, idx) => idx < index);
      const gap = arr.reduce((prev, curr) => {
        // let width = curr.gcomponentId === "lamination" ? 20 + grid : 32 + grid; // TODO : 추후 각 유리 컴포넌트의 너비가 설정될 경우(유리샘플구성) 적용하여 보여주는 화면 필요
        let width = curr.gcomponentCode === "LAMINATION" ? 20 + grid : 32 + grid; // TODO : 추후 각 유리 컴포넌트의 너비가 설정될 경우(유리샘플구성) 적용하여 보여주는 화면 필요
        return prev + width;
      }, 45 /* 다이얼로그 left로부터 첫 유리 컴포넌트의 left까지의 폭 */);

      draggableStyle.left = gap;
      draggableStyle.top = undefined;
    }

    // TODO : 추후 최상위 계층 구성요소에 패턴 및 색상/너비 등 설정하여 반영하는 방법 강구 (유리 원판 및 중공층은 설정된 두께?)
    let background = null;
    // if (item.gcomponentId === "spacer") {
    //   background = "#dfdfdf";
    // } else if (item.gcomponentId === "lamination") {
    //   background = "gray";
    // } else {
    //   background = "repeating-linear-gradient(-55deg, #dfdfdf, #fafafa 15px, #ffffff 15px, #ffffff 30px)";
    // }
    if (item.gcomponentCode === "SPACER") {
      background = "#dfdfdf";
    } else if (item.gcomponentCode === "LAMINATION") {
      background = "gray";
    } else {
      background = "repeating-linear-gradient(-55deg, #dfdfdf, #fafafa 15px, #ffffff 15px, #ffffff 30px)";
    }

    return {
      userSelect: "none",
      // padding: grid * 2,
      margin: `0 ${grid}px 0 0`,
      // width: item.gcomponentId === "lamination" ? 20 : 32,
      width: item.gcomponentCode === "LAMINATION" ? 20 : 32,
      border: isDragging ? '3px dashed  #1976d2' : '1px solid grey',
      borderRadius: 4,
      writingMode: 'tb-rl',
      display: 'flex',
      justifyContent: 'center',
      alignItems: "center",
      background: isDragging ? "#42a5f5" : background,
      fontWeight: isDragging ? 'bold' : '',
      color: isDragging ? 'white' : '',
      ...draggableStyle
    };
  }

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      maxWidth="lg"
    >
      <DialogTitleClose
        id="draggable-dialog-title"
        onClose={handleDialogClose}
        style={{ cursor: 'move' }}
      >
        {modify ? "템플릿 구조 수정" : "템플릿 구조 등록"}
      </DialogTitleClose>
      <DialogContent dividers>
        <ul>
          {errors.map((error, idx) => <li key={idx}>{error}</li>)}
        </ul>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormInputText
              name={"id"}
              control={control}
              label={"아이디"}
              // disabled={modify}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <FormInputText
              name={"name"}
              control={control}
              label={"이름"}
            />
          </Grid>
          <Grid item xs={12}>
            <FormInputText
              name={"code"}
              control={control}
              label={"코드"}
            />
          </Grid>
          <Grid item xs={12}>
            <FormInputText
              name={"comments"}
              control={control}
              label={"설명"}
              multiline
              maxRows={5}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ mt: 2, mb: 2 }} variant="h6" component="div">
              {"구성요소를 선택하여 유리의 기본 구조를 구성합니다."}
            </Typography>
          </Grid>
          <Grid item xs={12} direction="row" sx={{ border: "1px solid #C4C4C4", ml: 2, borderRadius: "4px"}}>
            <Stack direction="row" spacing={2} sx={{ mb:2 }}>
            {/* TODO : 각 gcomponent에 대표 아이콘 및 이미지 설정 검토할 것 */}
            {
              gcomponentsTopLevel && gcomponentsTopLevel.map(gcomponent => {
                const { code } = gcomponent
                console.log(code)
                // TODO : 추후 패턴 속성화 필요
                let className = "";
                // if (gcomponent.id === 'spacer') {
                //   className = "spacer";
                // } else if (gcomponent.id === 'lamination') {
                //   className = "lamination";
                // } else if (gcomponent.id === 'gl_pane') {
                //   className = "gl_pane";
                // }
                if (code === 'SPACER') {
                  className = "spacer";
                } else if (code === 'LAMINATION') {
                  className = "lamination";
                } else if (code === 'GL_FLAT') {
                  className = "gl_pane";
                }
                // if (gcomponent.id === '46de20bc-5ad0-4d2e-a190-f16a11e869d2') {
                //   className = "spacer";
                // } else if (gcomponent.id === '267f0f7e-c415-4668-9ae7-75082d435df7') {
                //   className = "lamination";
                // } else if (gcomponent.id === 'd5aa2e5c-d08a-44d7-b2e9-b8714a98fe9b') {
                //   className = "gl_pane";
                // }
                
                return (
                  <>
                  <Box
                    className={className}
                    sx={{
                      width: wasteHeight,
                      // height: 50,
                      borderRadius: '4px',
                      // backgroundColor: 'primary.dark',
                      // '&:hover': {
                      //   backgroundColor: 'primary.main',
                      //   opacity: [0.9, 0.8, 0.7],
                      // },
                      // mb: 2,
                    }}
                  />
                  <Button
                    // className={className} // 버튼에는 패턴이 적용되었다 안되었다 함???
                    label={gcomponent.name}
                    // variant="outlined"
                    variant="text"
                    color="primary"
                    onClick={() => handleSelectComponent(gcomponent)}
                  >
                    {`${gcomponent.name} 추가`}
                  </Button>
                  </>
                )
              })
            }
            </Stack>
          </Grid>
          <Grid item xs={12}>
            {
              !loadedGcomponents && (
                <Box sx={{ width: '100%' }}>
                  <LinearProgress />
                </Box>
              )
            }
            <Card sx={{ p: 2 }}>
            <div style={{ height: dndHeight + wasteHeight + wasteMarginBottom, /*display: "flex"*/ }}>
              <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd} >
                {
                  ["waste", "product"].map(type => {
                    return (
                      // <div
                      //   style={{
                      //     display: "flex",
                      //     flexDirection: "column",
                      //   }}
                      //   key={type}
                      // >
                        <Droppable droppableId={`droppable_${type}`} direction="horizontal">
                          {(provided, snapshot) => {
                            return (
                              <div
                                ref={provided.innerRef}
                                style={getListStyle(type)}
                                {...provided.droppableProps}
                              >
                                {/* TODO : selectedGcomponents가 조회했을 경우가 추가시 다름. 검토할 것. 패턴 적용 중 발견한 문제로 우선은 gcomponentId로 하여 패턴은 적용 */}
                                {
                                  type === "product" ? (selectedGcomponents.map((item, index) => (
                                    <Draggable key={`${item.id}_${index}`} draggableId={`${item.id}_${index}_${type}`} index={index}>
                                      {(provided, snapshot) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={getItemStyle(
                                            index,
                                            snapshot.isDragging,
                                            provided.draggableProps.style,
                                            item,
                                          )}
                                        >
                                          {/* <IconButton
                                            edge="end"
                                            aria-label="delete"
                                            size="small"
                                            sx={{ mb: 1 }}
                                            onClick={() => handleDeleteGcomponent(index)}
                                          >
                                            <Delete fontSize="inherit" />
                                          </IconButton> */}
                                          {/* { item.gcomponentId === "spacer" ? "" : item.name } */}
                                          {
                                            // item.gcomponentId === "spacer" && (
                                            item.gcomponentCode === "SPACER" && (
                                              <Box
                                                className={"spacer"}
                                                sx={{
                                                  width: 32,
                                                  height: dndHeight-90, // TODO : 왜 90인지 계산 필요
                                                }}
                                              />
                                            )
                                          }
                                        </div>
                                      )}
                                    </Draggable>
                                  ))) : (
                                    // 휴지통
                                    selectedGcomponents?.length > 0 && <Box
                                      ref={ref}
                                      sx={{
                                        borderRadius: "4px", 
                                        border: snapshot.isDraggingOver ? "2px dashed #1976d2" : "2px dashed #C4C4C4",
                                        // width: '100%',
                                        height: wasteHeight,
                                        display: "flex",
                                        justifyContent: "center",
                                        bgcolor: snapshot.isDraggingOver ? "#42a5f5" : "",
                                      }}
                                    >
                                      <IconButton
                                        edge="end"
                                        aria-label="delete"
                                        size="large"
                                        disabled={snapshot.isDraggingOver ? false : true}
                                        // sx={{ mb: 1 }}
                                      >
                                        <Delete fontSize="inherit" />
                                      </IconButton>
                                    </Box>
                                  )
                                }
                                {provided.placeholder}
                              </div>
                            )
                          }}
                        </Droppable>
                      // </div>
                    )
                  })
                }
              </DragDropContext>
            </div>
            </Card>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit(onSubmit)}>{modify ? "수정" : "저장"}</Button>
        {/* TODO : 아래 코드 안되는 이유? */}
        {/* <Button onClick={reset}>{"초기화"}</Button> */}
        <Button
          onClick={() => {
            reset();
            setSelectedGcomponents([]);
          }}>
            {"초기화"}
          </Button>
        <Button onClick={handleDialogClose}>{"닫기"}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default GTypeDialog;
