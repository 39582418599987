// import id from "date-fns/locale/id/index.js";
import { csrfFetch } from "./csrf.js";

const SET_GWORKORDER = 'gworkOrder/setGWorkOrder';
const REMOVE_GWORKORDER = "gworkOrder/removeGWorkOrder";
const SET_GWORKORDERS = 'gworkOrder/setGWorkOrders';
const SET_GWORKORDERS_BY_GRECEPTION = 'gworkOrder/setGWorkOrdersByGReception';

const setGWorkOrder = (gworkOrder) => ({
  type: SET_GWORKORDER,
  payload: gworkOrder,
});

const removeGWorkOrder = () => ({
  type: REMOVE_GWORKORDER,
});

const setGWorkOrders = (gworkOrders) => ({
  type: SET_GWORKORDERS,
  payload: gworkOrders,
});

const setGWorkOrdersByGReception = (gworkOrdersByGReception) => ({
  type: SET_GWORKORDERS_BY_GRECEPTION,
  payload: gworkOrdersByGReception,
});

export const create = ({
  gorderId,
  // gprocessId,
  // etc,
  gworkOrderDetails,
}) => async dispatch => {
  const response = await csrfFetch(dispatch, "/api/gworkOrders", {
    method: "POST",
    body: JSON.stringify({
      gorderId,
      // gprocessId,
      // etc,
      gworkOrderDetails,
    }),
  });
  const data = await response.json();
  dispatch(setGWorkOrder(data.gworkOrder));
  return response;
};

export const createDirect = async ({
  greceptionId,
  // gprocessId,
  gclientName,
  site,
  orderNo,
  // etc,
  gworkOrderDetails,
}) => {
  const response = await csrfFetch(null, "/api/gworkOrders", {
    method: "POST",
    body: JSON.stringify({
      greceptionId,
      // gprocessId,
      gclientName,
      site,
      orderNo,
      // etc,
      gworkOrderDetails,
    }),
  });
  const data = await response.json();
  return data.gworkOrders;
};

export const modify = ({
  id,
  gorderId,
  // gprocessId,
  // etc,
  gworkOrderDetails,
}) => async dispatch => {
  const response = await csrfFetch(dispatch, "/api/gworkOrders", {
    method: "PUT",
    body: JSON.stringify({
      id,
      gorderId,
      // gprocessId,
      // etc,
      gworkOrderDetails,
    }),
  });
  const data = await response.json();
  dispatch(setGWorkOrder(data.gworkOrder));
  return response;
};

export const selectAll = () => async dispatch => {
  const response = await csrfFetch(dispatch, "/api/gworkOrders");
  const data = await response.json();
  dispatch(setGWorkOrders(data.gworkOrders));
  return response;
}

export const selectAllByQuery = () => async dispatch => {
  const response = await csrfFetch(dispatch, "/api/gworkOrders/query/selectAll");
  const data = await response.json();
  dispatch(setGWorkOrders(data.gworkOrders));
  return response;
}

export const selectAllProcessDetailsByQuery = () => async dispatch => {
  const response = await csrfFetch(dispatch, "/api/gworkOrders/query/selectAll/processDetails");
  const data = await response.json();
  dispatch(setGWorkOrders(data.gworkOrders));
  return response;
}

export const selectAllByGreceptionIdByQuery = (greceptionId) => async dispatch => {
  const response = await csrfFetch(dispatch, `/api/gworkOrders/query/selectAll/greceptionId/${greceptionId}`);
  const data = await response.json();
  dispatch(setGWorkOrders(data.gworkOrders));
  return response;
}

export const selectAllByStatus = (status) => async dispatch => {
  const response = await csrfFetch(dispatch, `/api/gworkOrders/status/${status}`);
  const data = await response.json();
  dispatch(setGWorkOrders(data.gworkOrders));
  return response;
}

export const selectAllByStatusByQuery = (status) => async dispatch => {
  const response = await csrfFetch(dispatch, `/api/gworkOrders/query/selectAll/status/${status}`);
  const data = await response.json();
  dispatch(setGWorkOrders(data.gworkOrders));
  return response;
}

export const selectAllByGReception = (status) => async dispatch => {
  const response = await csrfFetch(dispatch, `/api/gworkOrders/query/selectAllByGReception`);
  const data = await response.json();
  dispatch(setGWorkOrdersByGReception(data.gworkOrdersByGReception));
  return response;
}

export const selectAllByGReceptionByWorkingAmount = ({ condition }) => async dispatch => {
  const response = await csrfFetch(dispatch, "/api/gworkOrders/query/selectAllByGReception", {
    method: "POST",
    body: JSON.stringify({
      condition,
    }),
  });
  const data = await response.json();
  dispatch(setGWorkOrdersByGReception(data.gworkOrdersByGReception));
  return response;
};


// export const selectAllByStatusByQuery = (statusArray) => async dispatch => {
//   const response = await csrfFetch(dispatch, "/api/gworkOrders/query/selectAll/statusArray", {
//     method: "POST",
//     body: JSON.stringify({ statusArray }),
//   });
//   const data = await response.json();
//   dispatch(setGWorkOrders(data.gworkOrders));
//   return response;
// }

/**
 *                           status
 * 작업의뢰                   PRE
 * 일정할당                   ASSIGN
 * TODO : ING, DONE 등 이후 상태 필요
 */

export const select = (id) => async dispatch => {
  const response = await csrfFetch(dispatch, `/api/gworkOrders/${id}`);
  const data = await response.json();
  dispatch(setGWorkOrder(data.gworkOrder));
  return response;
}

export const selectByQuery = (id) => async dispatch => {
  const response = await csrfFetch(dispatch, `/api/gworkOrders/query/select/${id}`);
  const data = await response.json();
  dispatch(setGWorkOrder(data.gworkOrder));
  return response;
}

export const selectByGOrderId = (gorderId) => async dispatch => {
  const response = await csrfFetch(dispatch, `/api/gworkOrders/gorderId/${gorderId}`);
  const data = await response.json();
  dispatch(setGWorkOrders(data.gworkOrders));
  return response;
}

export const initGWorkOrder = () => async dispatch => {
  dispatch(setGWorkOrder(null));
}

export const receive = ({ id, status, comments }) => async dispatch => {
  const response = await csrfFetch(dispatch, "/api/gworkOrders/status", {
    method: "PUT",
    body: JSON.stringify({ id, status, comments }),
  });
  const data = await response.json();
  dispatch(setGWorkOrder(data.gworkOrder));
  return response;
};

export const receiveDirect = async ({ id, status, comments }) => {
  const response = await csrfFetch(null, "/api/gworkOrders/status", {
    method: "PUT",
    body: JSON.stringify({ id, status, comments }),
  });
  const data = await response.json();
  return data.gworkOrder;
};

export const assignToGPlan = ({ id, comments }) => async dispatch => {
  const response = await csrfFetch(dispatch, "/api/gworkOrders/status", {
    method: "PUT",
    body: JSON.stringify({ id, status: "ASSIGN", comments }),
  });
  const data = await response.json();
  dispatch(setGWorkOrder(data.gworkOrder));
  return response;
};

export const cancelFromGPlan = ({ id, comments }) => async dispatch => {
  const response = await csrfFetch(dispatch, "/api/gworkOrders/status", {
    method: "PUT",
    body: JSON.stringify({ id, status: "PRE", comments }),
  });
  const data = await response.json();
  dispatch(setGWorkOrder(data.gworkOrder));
  return response;
};

export const setAmounts = ({ id, amountCompleted, amountIncomplete, amountDefect, amountDefects }) => async dispatch => {
  const response = await csrfFetch(dispatch, "/api/gworkOrders/amounts", {
    method: "PUT",
    body: JSON.stringify({ id, amountCompleted, amountIncomplete, amountDefect, amountDefects }),
  });
  const data = await response.json();
  dispatch(setGWorkOrder(data.gworkOrder));
  return response;
};

export const setAmountsDirect = async ({ id, amountCompleted, amountIncomplete, amountDefect, amountDefects }) => {
  const response = await csrfFetch(null, "/api/gworkOrders/amounts", {
    method: "PUT",
    body: JSON.stringify({ id, amountCompleted, amountIncomplete, amountDefect, amountDefects }),
  });
  const data = await response.json();
  return data.gworkOrder;
};

// export const sendDirect = async ({ id, status, comments }) => {
//   const response = await csrfFetch(null, "/api/gworkOrders/status", {
//     method: "PUT",
//     body: JSON.stringify({ id, status, comments }),
//   });
//   const data = await response.json();
//   return data.gworkOrder;
// };

export const remove = (id) => async (dispatch) => {
  const response = await csrfFetch(dispatch, `/api/gworkOrders/${id}`, {
    method: "DELETE",
  });
  dispatch(removeGWorkOrder());
  return response;
};

export const reorder = async ({ type, id }) => {
  const response = await csrfFetch(null, "/api/gworkOrders/reorder", {
    method: "POST",
    body: JSON.stringify({ type, id }),
  });
  
  return response;
}

export const countNoWorkOrderByGReceptionIdByQueryByDirect = async (greceptionId) => {
  const response = await csrfFetch(null, `/api/gworkOrders/query/noWorkOrder/greceptionId/${greceptionId}`);
  const data = await response.json();
  return data.count;
}

// export const selectAllByProject = (gprojectId) => async dispatch => {
//   const response = await csrfFetch(dispatch, `/api/gworkOrders/project/${gprojectId}`);
//   const data = await response.json();
//   dispatch(setGWorkOrders(data.gworkOrders));
//   return response;
// }

// export const selectAllByProjectByDirect = async (gprojectId) => {
//   const response = await csrfFetch(null, `/api/gworkOrders/project/${gprojectId}`);
//   const data = await response.json();
//   return data;
// }

const initialState = {
  gworkOrder: null,
  gworkOrders: [],
  gworkOrdersByGReception: [],
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case SET_GWORKORDER:
      newState = Object.assign({}, state, { gworkOrder: action.payload });
      return newState;
    case REMOVE_GWORKORDER:
      newState = Object.assign({}, state, { gworkOrder: null });
      return newState;
    case SET_GWORKORDERS:
      newState = Object.assign({}, state, { gworkOrders: action.payload });
      return newState;
    case SET_GWORKORDERS_BY_GRECEPTION:
      newState = Object.assign({}, state, { gworkOrdersByGReception: action.payload });
      return newState;
    default:
      return state;
  }
}

export default reducer;
