import { csrfFetch } from "./csrf.js";
import { gcoreServerUrl, mode } from '../config.js';

const INIT_GCLIENT_G04_DOCU_GTEST_MAP = "gclientG04docuGTestMaps/initGClientG04docuGTestMap";
const SET_GCLIENT_G04_DOCU_GTEST_MAP = "gclientG04docuGTestMaps/setGClientG04docuGTestMap";
const INIT_GCLIENT_G04_DOCU_GTEST_MAPS = "gclientG04docuGTestMaps/initGClientG04docuGTestMaps";
const SET_GCLIENT_G04_DOCU_GTEST_MAPS = "gclientG04docuGTestMaps/setGClientG04docuGTestMaps";
const REMOVE_GCLIENT_G04_DOCU_GTEST_MAP = "gclientG04docuGTestMaps/removeGClientG04docuGTestMap";

const initGClientG04docuGTestMap = () => ({
  type: INIT_GCLIENT_G04_DOCU_GTEST_MAP,
});

const setGClientG04docuGTestMap = (gclientG04docuGTestMap) => ({
  type: SET_GCLIENT_G04_DOCU_GTEST_MAP,
  payload: gclientG04docuGTestMap,
});

const initGClientG04docuGTestMaps = () => ({
  type: INIT_GCLIENT_G04_DOCU_GTEST_MAPS,
});

const setGClientG04docuGTestMaps = (gclientG04docuGTestMaps) => ({
  type: SET_GCLIENT_G04_DOCU_GTEST_MAPS,
  payload: gclientG04docuGTestMaps,
});

const removeGClientG04docuGTestMap = () => ({
  type: REMOVE_GCLIENT_G04_DOCU_GTEST_MAP,
});

export const create = ({ gclientId, g04docuGTestId, gcomponentItemId, g04docuGCertificationId, gclient, documentPath, documentType, validYN, startDate, endDate }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gclientG04docuGTestMaps`, {
    method: "POST",
    body: JSON.stringify({ gclientId, g04docuGTestId, gcomponentItemId, g04docuGCertificationId, gclient, documentPath, documentType, validYN, startDate, endDate }),
  });
  const data = await response.json();
  dispatch(setGClientG04docuGTestMap(data.gclientG04docuGTestMap));
  return response;
};

export const modifyDate = ({ gclientId, g04docuGTestId, documentPath, validYN, startDate, endDate }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gclientG04docuGTestMaps/modifyDate`, {
    method: "PUT",
    body: JSON.stringify({ gclientId, g04docuGTestId, documentPath, validYN, startDate, endDate }),
  });
  const data = await response.json();
  dispatch(setGClientG04docuGTestMap(data.gclientG04docuGTestMap));
  return response;
};

export const initializeGClientG04docuGTestMap = () => async dispatch => {
  dispatch(initGClientG04docuGTestMap());
}

export const initializeGClientG04docuGTestMaps = () => async dispatch => {
  dispatch(initGClientG04docuGTestMaps());
}

export const selectAllByGComponentItemOrProcessByQuery = (id, division, gclientId) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gclientG04docuGTestMaps/query/selectAll/gcomponentItemOrProcess/${id}/division/${division}`, {
    method: 'POST',
    body: JSON.stringify({ gclientId }),
  });
  const data = await response.json();
  dispatch(setGClientG04docuGTestMaps(data.gclientG04docuGTestMaps));
  return response;
}

// export const generateDirect = async (treeData) => {
//   const response = await csrfFetch(null, `${gcoreServerUrl}/api/gclientG04docuGTestMaps/generate`, {
//     method: "POST",
//     body: JSON.stringify({ data: treeData }),
//   });
  
//   const data = await response.json();
//   return data.generatedTreeData;
// };

export const selectByQueryDirect = async (id) => {
  const response = await csrfFetch(null, `${gcoreServerUrl}/api/gclientG04docuGTestMaps/query/select/${id}`);
  const data = await response.json();
  return data.gclientG04docuGTestMaps;
}

// 성적서의 경우 gcomponentItemId 필요하다 생각했으나 => documentPath가 있으므로 문제 안됨
export const remove = ({ gclientId, g04docuGTestId, documentPath }) => async (dispatch) => {
  let url = "";
  let body = null;
  if (mode.indexOf('java') > 0) {
    console.log(url)
    url = `${gcoreServerUrl}/api/gclientG04docuGTestMaps/gclient/${gclientId}/g04docuGTest/${g04docuGTestId}`;
    body = JSON.stringify({ documentPath });
  } else {
    url = `${gcoreServerUrl}/api/gclientG04docuGTestMaps/gclient/${gclientId}/g04docuGTest/${g04docuGTestId}/g04docuGTestPath/${encodeURIComponent(documentPath)}`;
  }

  const response = await csrfFetch(dispatch, url, {
    method: "DELETE",
    body,
  });
  dispatch(removeGClientG04docuGTestMap());
  return response;
};

export const removeWithFile = ({ gclientId, g04docuGTestId, documentPath }) => async (dispatch) => {
  let url = "";
  let body = null;
  if (mode.indexOf('java') > 0) {
    url = `${gcoreServerUrl}/api/gclientG04docuGTestMaps/gclient/${gclientId}/g04docuGTest/${g04docuGTestId}`;
    body = JSON.stringify({ documentPath });
  } else {
    url = `${gcoreServerUrl}/api/gclientG04docuGTestMaps/gclient/${gclientId}/g04docuGTest/${g04docuGTestId}/g04docuGTestPath/${encodeURIComponent(documentPath)}`;
  }

  const response = await csrfFetch(dispatch, url, {
    method: "DELETE",
    body,
  });
  dispatch(removeGClientG04docuGTestMap());
  return response;
};

export const modifyMajority = ({ id, g04docuGCertificationId, gcomponentItemId, majority, division, gclientId }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gclientG04docuGTestMaps/${id}`, {
    method: "PUT",
    body: JSON.stringify({ g04docuGCertificationId, gcomponentItemId, majority, division, gclientId }),
  });
  const data = await response.json();
  dispatch(setGClientG04docuGTestMaps(data.gclientG04docuGTestMaps));
  return response;
}

const initialState = {
  gclientG04docuGTestMap: null,
  gclientG04docuGTestMaps: [],
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case INIT_GCLIENT_G04_DOCU_GTEST_MAP:
      newState = Object.assign({}, state, { gclientG04docuGTestMap: initialState.gclientG04docuGTestMap });
      return newState;
    case SET_GCLIENT_G04_DOCU_GTEST_MAP:
      newState = Object.assign({}, state, { gclientG04docuGTestMap: action.payload });
      return newState;
    case INIT_GCLIENT_G04_DOCU_GTEST_MAPS:
      newState = Object.assign({}, state, { gclientG04docuGTestMaps: initialState.gclientG04docuGTestMaps });
      return newState;
    case SET_GCLIENT_G04_DOCU_GTEST_MAPS:
      newState = Object.assign({}, state, { gclientG04docuGTestMaps: action.payload });
      return newState;
    default:
      return state;
  }
}

export default reducer;
