import DateFnsAdapter from "@date-io/date-fns";

/**********************************************************************************************************
 * getWeek() was developed by Nick Baicoianu at MeanFreePath: http://www.meanfreepath.com
 * ********************************************************************************************************
 * 원본코드
 * Date.prototype.getWeek = function (dowOffset) {
    dowOffset = typeof(dowOffset) == 'number' ? dowOffset : 0; // dowOffset이 숫자면 넣고 아니면 0
    var newYear = new Date(this.getFullYear(),0,1);
    var day = newYear.getDay() - dowOffset; //the day of week the year begins on
    day = (day >= 0 ? day : day + 7);
    var daynum = Math.floor((this.getTime() - newYear.getTime() -
      (this.getTimezoneOffset()-newYear.getTimezoneOffset())*60000)/86400000) + 1;
    var weeknum;
    //if the year starts before the middle of a week
    if(day < 4) {
      weeknum = Math.floor((daynum+day-1)/7) + 1;
      if(weeknum > 52) {
        let nYear = new Date(this.getFullYear() + 1,0,1);
        let nday = nYear.getDay() - dowOffset;
        nday = nday >= 0 ? nday : nday + 7;
        // if the next year starts before the middle of the week, it is week #1 of that year
        weeknum = nday < 4 ? 1 : 53;
      }
    }
    else {
      weeknum = Math.floor((daynum+day-1)/7);
    }
    return weeknum;
  };
  * ********************************************************************************************************
  * 일부수정: 월~일이 주의 시작과 끝
  * 참고: 1월1일이 월~목이면 1주차, 금~일이면 전년도 마지막주차(52 또는 53주차)
*/
Date.prototype.getWeek = function () {
  var newYear = new Date(this.getFullYear(),0,1); // 입력받은 날짜의 해당년도 1월1일
  var day = newYear.getDay(); // 해당년도 1월1일의 요일 (월: 1 ~ 토: 6. 일: 0)

  // 1월1일로부터 입력받은 날짜와의 차이 일수. 예: 입력받은 날짜가 1월4일이면 => 3
  var daynum = Math.floor((this.getTime() - newYear.getTime() - (this.getTimezoneOffset()-newYear.getTimezoneOffset())*60000)/86400000);
  var weeknum;    
  if(day <= 4/* && day >= 1*/) {
    weeknum = Math.floor((daynum+day-1)/7) + 1;
  }
  else {
    weeknum = Math.floor((daynum+day-1)/7);
  }
  return weeknum;
};

Date.prototype.getWeekDates = function() {
  var today = new Date(this.setHours(0, 0, 0, 0));
  var day = today.getDay();
  var startDate = addDays(today, -day+1);
  var endDate = addDays(today, 7-day);
  return [dateFormat(startDate, 'yyyy-MM-dd'), dateFormat(endDate, 'yyyy-MM-dd')];
  // return `${dateFormat(startDate, 'yyyy-MM-dd')} ~ ${dateFormat(endDate, 'yyyy-MM-dd')}`;
}

Date.prototype.getDateWithStartHours = function () {
  this.setHours(0, 0, 0, 0);
  return this;
}

Date.prototype.getDateWithEndHours = function () {
  this.setHours(23, 59, 59, 999);
  return this;
}

const dateFns = new DateFnsAdapter();

export const dateFormat = (dateValue, format='yyyy-MM-dd HH:mm:ss') => {
  const initialDateFnsDate = dateFns.date(dateValue);
  return `${dateFns.formatByString(initialDateFnsDate, format)}`;
}

export const addDays = (dateValue, days, format='yyyy-MM-dd HH:mm:ss') => {
  // console.log(dateValue)
  // console.log(typeof dateValue)
  const newDateValue = dateFns.addDays(dateValue, days);
  // console.log(newDateValue)
  const initialDateFnsDate = dateFns.date(newDateValue);
  return `${dateFns.formatByString(initialDateFnsDate, format)}`;
}

export const differenceInDays = (date1, date2) => {
  return dateFns.getDiff(date1, date2)/(1000 * 3600 * 24);
}