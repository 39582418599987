import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import { LicenseInfo } from '@mui/x-license-pro';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import {
  Alert,
  Box,
  Grid,
  Snackbar,
  Slide,
  Typography,
} from '@mui/material';
import { registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginPdfPreview from "filepond-plugin-pdf-preview";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
// import "filepond-plugin-get-file/dist/filepond-plugin-get-file.min.css";
// import FilePondPluginGetFile from 'filepond-plugin-get-file';
import { mode } from "./config";
import MainRoute from './router/MainRoute';

import * as sessionActions from "./store/session";
import * as menuActions from "./store/menu";
import * as errorActions from "./store/error";
import * as gsendBasketActions from './store/gsendBasket';
import * as footerActions from './store/footer';
import * as notificationActions from './store/notification';

import { Navigation } from "./components/Navigation";
import Header from "./components/layout/Header";
// import StickyFooter from "./components/layout/StickyFooter";
import Copyright from "./components/layout/Copyright";
import CopyrightKFGWA from "./components/layout/CopyrightKFGWA";
import { ErrorPage } from "./components/error";
import ConsentDialog from "./ConsentDialog";

import NotificationDialog from "./NotificationDialog";
import { adminMenus, gclientMenus, userMenus } from './menus'

import G04docuMaterialApprovalProgress from "./components/G04docuMaterialApproval/G04docuMaterialApprovalProgress";

import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';

registerPlugin(
  FilePondPluginFileValidateType,
  FilePondPluginImageExifOrientation,
  // FilePondPluginImagePreview,
  // // FilePondPluginGetFile,
  // FilePondPluginPdfPreview
);

export const isProduction = process.env.NODE_ENV === 'production';

// 배포 모듈에서는 로그가 나오지 않도록 한다.
if (isProduction) {
  console = window.console || {};
  console.log = function no_console() {};
  console.warn = function no_console() {};
  console.error = function () {};
}

LicenseInfo.setLicenseKey('2e77f6b58977c6ec2fad2a37e65cb035Tz04NTEyOCxFPTE3NDA2NDU4NDEwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

function App() {
  
  const sessionUser = useSelector(state => state.session.sessionUser);
  const error = useSelector(state => state.error.error);
  const footer = useSelector(state => state.footer.footer);

  const [isLoaded, setIsLoaded] = useState(false);
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();

  const selectGSendBasketsByCreatorByQuery = (creatorId) => dispatch(gsendBasketActions.selectAllByCreatorByQuery(creatorId))
  const displayFooter = (show) => dispatch(footerActions.displayFooter(show))
  const displayNotification = (show) => dispatch(notificationActions.displayNotification(show))

  // F5키를 누르면 useEffect의 /api/session 호출 후 sessionUser가 undefined가 되면서 RequireAuth에서 sigin으로 이동
  useEffect(() => {
    const initializeApp = () => {
      // TODO : 자바 테스트중
      // dispatch(sessionActions.restoreSessionUser()).then(() => setIsLoaded(true)); // TODO : 이거 주석처리하면 F5(refresh) 누르면 로그인 페이지로 이동
      setIsLoaded(true)

      /**
       * footer 보이기
       * 메뉴 클릭하면 로딩할 때마다 footer가 미리 보였다가 해당 메뉴 프로그램 내용이 나오면서 footer가 아래로 이동하는 듯 보여 화면로딩과 거의 동시에 footer가 보이도록 하기 위함
       * 그런데,
       *  - F5(refresh) 하면 이 함수가 다시 실행되면서 footer를 다시 보이게 하는데 화면로딩이 오래 걸리는 경우의 화면일 경우 footer가 먼저 보였다가 아래로 이동하는 듯 보이는 현상은 동일함.
       *  - TODO : 따라서, 그것이 문제라면 각 화면에서 아래 displayFooter를 실행해야 하지 않을까 생각함
       *           현재 그대로 두면 반복 코딩 피하고 F5가 아닌 경우(대다수) 최초 이후 Footer가 그대로 있기 때문에 문제가 크지 않음
       */
      setTimeout(() => displayFooter(true), 200);
    }
    
    initializeApp();

  }, [dispatch]);

  useEffect(() => {
    
      if (sessionUser) {
        const { id, type, sessionUserLoginType, loginCount, consented, needConsent } = sessionUser;
        // TODO : sessionUser.consented가 true가 아니면 
        // 동의 다이얼로그 띄운다.
        // 동의 다이얼로그는 클릭하고 동의하지 않으면 닫히지 않도록 한다. handleDialogClose(e, reason)
        if (!consented || needConsent) {
          setOpen(true)
          return;
        }

        // if (type === 'ADMIN' || type === 'GCLIENT') {
        //   selectGSendBasketsByCreatorByQuery(id);
        // }
        let creatorId = "";
        if (sessionUserLoginType === 'GCLIENT') {
          creatorId = id;
        } else {
          if (sessionUser.user) {
            creatorId = sessionUser.user.id;
          }
        }

        selectGSendBasketsByCreatorByQuery(creatorId);
        if (loginCount < 5) { // TODO : 공지사항을 설정하는 기능 추가 필요
          displayNotification(true);
        }
      }

    }, [sessionUser]
  )

  const handleClose = () => {
    dispatch(errorActions.occurError(null));
  }

  const { pathname } = useLocation();
  console.log(pathname)

  if (error) {
    // error의 severityLevel에 따라 ErrorPage 또는 Snackbar로 표시
    if (error.serverResponse?.details?.severityLevel >= 3) { // 1. Snackbar로 표시
      return (
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <MainRoute 
              isLoaded={isLoaded}
              sessionUser={sessionUser}
              error={error}
              handleClose={handleClose}
              open={open}
              setOpen={setOpen}
            />
          </ThemeProvider>
      );
    } else { // 2. ErrorPage로 표시
      return (<>
        <Navigation isLoaded={false} menus={sessionUser?.type === 'ADMIN' ? adminMenus : ( sessionUser?.type === 'GCLIENT' ? gclientMenus : userMenus )} />
        <ErrorPage error={error}/>
      </>);
    }
  } else { // 3. error가 없는 경우
    dispatch(errorActions.occurError(null)); // 이전에 발생한 에러 지워줌
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <MainRoute 
          isLoaded={isLoaded}
          sessionUser={sessionUser}
          error={error}
          handleClose={handleClose}
          open={open}
          setOpen={setOpen}
        />
      </ThemeProvider>
    );
  }
}

export default App;
