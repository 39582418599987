// TODO : 삭제 예정
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DataGridPro, GridActionsCellItem, koKR } from '@mui/x-data-grid-pro';
import {
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  CssBaseline,
  Drawer,
  FormControlLabel,
  Grid,
  IconButton,
  LinearProgress,
  Skeleton,
  Switch,
  ToggleButtonGroup,
  ToggleButton,
  Tooltip,
} from '@mui/material';
import  {
  Add,
  ContentPaste,
  Delete,
  Edit,
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowUp,
  KeyboardArrowDown,
  KeyboardArrowUp,
  OpenInNew,
} from '@mui/icons-material';
import {
  ConfirmDialog,
} from "../dialog";
import {
  CustomNoRowsOverlay,
  // CustomLoadingOverlay,
} from "../datagrid";
import {
  dateFormat,
  hideWatermark,
} from "../../utils";
import * as gsubItemActions from "../../store/gsubItem";
import GSubItemDialog from "./GSubItemDialog";
import { GClientManagement } from "../GClient";

const theme = createTheme();

const GSubItemManagement = () => {
  const [crudMode, setCrudMode] = useState('');
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [show, setShow] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [removeId, setRemoveId] = useState();
  const [params, setParams] = useState({});
  const [checked, setChecked] = useState(false);
  const [pageSize, setPageSize] = useState(100);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selManufacturers, setSelManufacturers] = useState([]);
  
  const handleSelect = async ({ type, params }) => {
    const { id } = params;

    setOpenBackdrop(true);

    if (type === "detail") {
      await select(id);
      setCrudMode('R');
      setOpen(true);
    } else if (type === "edit") {
      // TODO : await 재검토 필요. 대부분 await 가 필요
      await select(id);
      setCrudMode('U');
      setOpen(true);
    } else if (type === "paste") {
      await select(id);
      setCrudMode('C');
      setOpen(true);
    } else if (type === "delete") {
      setRemoveId(id);
      setParams(params);
      setConfirmOpen(true);
    }

    setOpenBackdrop(false);
  }

  const handleSelectUpDown = async ({ type, id }) => {
    setOpenBackdrop(true);

    await reorder({ type, id });
    setLoaded(false);
    await selectAllByQuery();

    setOpenBackdrop(false);
    
    setTimeout(() => setLoaded(true), 300)
  }

  const remove = (removeId) => {
    dispatch(gsubItemActions.remove(removeId))
    .then(res => selectAllByQuery())
    .catch(async (res) => {
      const data = await res.json();
      if (data && data.errors) setErrors(data.errors);
    });
  }

  // 저장/수정 다이얼로그
  const handleClickOpen = () => {
    // initGSubItem();
    setCrudMode('C');
    setOpen(true);
  };

  const handleChangeOrder = () => {
    setChecked(!checked);
  }

  const handleViewManufacturers = (manufacturers) => {
    setSelManufacturers(manufacturers);
    console.log(manufacturers);
    setDrawerOpen(true);
  }

  const generateActions = (params) => {
    let arrActions = [
      <GridActionsCellItem
        icon={<OpenInNew />}
        label={"상세"}
        onClick={() => handleSelect({ type: 'detail', params })}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<Edit />}
        label={"수정"}
        onClick={() => handleSelect({ type: 'edit', params })}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<ContentPaste />}
        label={"복사하여 등록"}
        onClick={() => handleSelect({ type: 'paste', params })}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<Delete />}
        label={"삭제"}
        onClick={() => handleSelect({ type: 'delete', params })}
        showInMenu
      />,
    ];

    if (checked) {
      arrActions = arrActions.concat([
          <GridActionsCellItem
            icon={<KeyboardDoubleArrowUp />}
            label={"맨위로"}
            onClick={() => handleSelectUpDown({ type: 'first', id: params.id })}
            disabled={params.row.orderDetail === 'F'}
          />,
          <GridActionsCellItem
            icon={<KeyboardArrowUp />}
            label={"위로"}
            onClick={() => handleSelectUpDown({ type: 'up', id: params.id })}
            disabled={params.row.orderDetail === 'F'}
          />,
          <GridActionsCellItem
            icon={<KeyboardArrowDown />}
            label={"아래로"}
            onClick={() => handleSelectUpDown({ type: 'down', id: params.id })}
            disabled={params.row.orderDetail === 'L'}
          />,
          <GridActionsCellItem
            icon={<KeyboardDoubleArrowDown />}
            label={"맨아래로"}
            onClick={() => handleSelectUpDown({ type: 'last', id: params.id })}
            disabled={params.row.orderDetail === 'L'}
          />
        ]
      );
    }

    return arrActions;
  }

  const columns = [
    {
      field: 'id',
      headerName: '아이디',
      width: 300,
      hide: true,
    },
    {
      field: 'name',
      headerName: '이름',
      width: 200,
      // editable: true,
    },
    {
      field: 'code',
      headerName: '코드',
      width: 200,
      // editable: true,
    },
    {
      field: 'gName',
      headerName: '분류',
      width: 150,
      // editable: true,
    },
    {
      field: 'gclient',
      headerName: '제조사',
      width: 150,
      // editable: true,
      // valueGetter: (params) => params.value?.name,
      // valueGetter: (params) => {
      //   if (params.value) {
      //     return params.value.map(val => val.gclient.name);
      //   }
      // },
      renderCell: (params) => {
        // const name = params.row?.name;
        // return (
        //   <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
        //     {params.row?.name}
        //     <StringAvatar
        //       name={name === "시공팀" ? name.substring(name.length-1) : name.substring(0, 1)}
        //       width={20}
        //       height={20}
        //       fontSize={"small"}
        //       color={params.row.color}
        //     />
        //   </Box>
        // )
        if (params.value && Array.isArray(params.value) && params.value.length > 0) {
          return params.value.length === 1
            ?
              (<Button variant="text" onClick={() => handleViewManufacturers([params.value[0].gclient])}>{params.value[0].gclient.name}</Button>)
            :
              (<Button variant="text" onClick={() => handleViewManufacturers(params.value.map(val => val.gclient))}>{"2+"}</Button>)
        };
      }
    },
    {
      field: 'g04docu',
      headerName: '규격',
      width: 300,
      // editable: true,
      // valueGetter: (params) => {
      //   if (params.row.g04docuName && params.row.gstandardNo) {
      //     return `${params.row.g04docuName} ( ${params.row.gstandardNo} )`
      //   }

      //   return "";
      // },
      renderCell: (params) => {
        if (params.row.g04docuName && params.row.gstandardNo) {
          return <>{params.row.g04docuName}<Chip label={params.row.gstandardNo} size="small" sx={{ ml: 1 }}></Chip></>;
        } else {
          return "";
        }
      }
    },
    {
      field: 'comments',
      headerName: '설명',
      width: 280,
      // editable: true,
    },
    {
      field: 'createdAt',
      headerName: '생성일시',
      width: 160,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => dateFormat(params.value),
    },
    {
      field: 'updatedAt',
      headerName: '수정일시',
      width: 160,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => dateFormat(params.value),
    },
    {
      field: 'actions',
      headerName: <Tooltip title={"상세/수정/복사하여 등록/삭제"} followCursor><Box>{"기능"}</Box></Tooltip>,
      width: checked ? 200 : 70,
      // description: "수정/삭제", // 불편해서 주석처리
      type: 'actions',
      getActions: (params) => generateActions(params),
    },
  ];
  
  const rows = useSelector((state) => state.gsubItem.gsubItems);
  const selectedRow = useSelector((state) => state.gsubItem.gsubItem);
  
  // 데이터 관리
  const dispatch = useDispatch();
  
  const selectAllByQuery = () => dispatch(gsubItemActions.selectAllByQuery())
  const select = (id) => dispatch(gsubItemActions.select(id))
  const initGSubItem = () => dispatch(gsubItemActions.init())
  const reorder = ({ type, id }) => gsubItemActions.reorder({ type, id })

  useEffect(
    async () => {
      await selectAllByQuery();

      await hideWatermark();
      // watermark 안보이면서 로딩바 보이도록 하기 위한 임시 코드
      setShow(true);
      setTimeout(() => setLoaded(true), 300);
    }, [dispatch]
  );

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    // console.log({ ...state, [anchor]: open });
    setDrawerOpen(open);
  };

  return (
    <ThemeProvider theme={theme}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container component="main" maxWidth="lg">
        <CssBaseline />
        <GSubItemDialog
          crudMode={crudMode}
          setCrudMode={setCrudMode}
          open={open}
          setOpen={setOpen}
          selectedRow={selectedRow}
          refresh={selectAllByQuery}
        />
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={10}>
            </Grid>
            <Grid item xs={2} display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                startIcon={<Add />}
                onClick={handleClickOpen}
              >
                {"등록하기"}
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={10}>
            </Grid>
            <Grid item xs={2} display="flex" justifyContent="flex-end" sx={{ mb: 2 }}>
              <FormControlLabel
                control={
                  <Switch 
                    checked={checked}
                    onChange={handleChangeOrder}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label={"순서"}
              />
            </Grid>
          </Grid>
          <div style={{ height: 800, width: '100%' }}>
            <Skeleton variant="rounded" height={show ? 0 : 800} />
            <DataGridPro
              localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
              columnHeaderHeight={38}
              rowHeight={34}
              sx={{ visibility: show ? 'visible' : 'hidden', cursor: 'pointer', fontSize: '0.85em' }}
              initialState={{ pinnedColumns: { /*left: ['id', 'name', 'code'], */right: ['actions'] } }}
              slots={{
                noRowsOverlay: CustomNoRowsOverlay,
                loadingOverlay: LinearProgress,
              }}
              loading={!loaded}
              rows={rows}
              columns={columns}
              // pageSize={5}
              // rowsPerPageOptions={[5]}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[10, 20, 50, 100]}
              pagination
              // checkboxSelection
              // disableSelectionOnClick
              // onRowSelectionModelChange={(ids) => {
              //   const selectedIDs = new Set(ids);
              //   const selectedRows = rows.filter((row) =>
              //     selectedIDs.has(row.id),
              //   );
      
              //   setSelectedRows(selectedRows);
              // }}
              onRowDoubleClick={(params) => handleSelect({ type: 'edit', params })}
            />
          </div>
        </Box>
        <ConfirmDialog
          removeId={removeId}
          title={"삭제"}
          open={confirmOpen}
          setOpen={setConfirmOpen}
          onConfirm={remove}
          onCancel={() => {}}
        >
          {`"${params && params.row && params.row.name || ""}(아이디 : ${params && params.id  || ""})"를 삭제하시겠습니까?`}
        </ConfirmDialog>
      </Container>
      <Drawer
        anchor={"bottom"} // TODO : 추후 사용자가 환경설정에서 위치 설정하면 전체 반영하는 방법 강구
        open={drawerOpen}
        PaperProps={{
          sx: { width: "100%" },
        }}
        onClose={toggleDrawer(false)}
      >
        <Grid display="flex" justifyContent={"center"} alignItems="center" sx={{ backgroundColor: "#f3f3f3" }}>
          <Tooltip title={"닫기"}>
            <IconButton aria-label="close drawer" size="small" component="span" onClick={toggleDrawer(false)}>
              <KeyboardArrowDown />
            </IconButton>
          </Tooltip>
        </Grid>
        {/* TODO : 아래 props를 하나로 넘겼을 때랑 아래처럼 따로 각각 넘겼을 때(setState를 각각 하고 있으므로) render 횟수 체크해 볼 것 */}
        <GClientManagement
          title={`거래선 정보`}
          from={
            {
              source: "GSubItemManagement",
              params: {
                selManufacturers,
              }
            }
          }
          // statusWorkOrder={statusWorkOrder}
          // originRefresh={() => selectAllByStatusReceptionByQuery(['PRE-RECEIVE', 'RECEIVE', 'RETURN'])}
        />
      </Drawer>
    </ThemeProvider>
  );
};

export default GSubItemManagement;
