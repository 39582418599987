import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DataGridPro, GridActionsCellItem, koKR } from '@mui/x-data-grid-pro';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  FormControlLabel,
  Grid,
  LinearProgress,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import  {
  Add,
  ContentPaste,
  Delete,
  Edit,
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowUp,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Login,
  Logout,
} from '@mui/icons-material';
import {
  ConfirmDialog,
} from "../dialog";
import {
  CustomNoRowsOverlay,
  // CustomLoadingOverlay,
} from "../datagrid";
import GGlassDialog from "./GGlassDialog";
import { dateFormat } from "../../utils";
import * as gtypeActions from "../../store/gtype";
import * as gtypeDetailActions from "../../store/gtypeDetail";
import * as gglassActions from "../../store/gglass";

const theme = createTheme();

const GGlassManagement = ({ title }) => {
  const [modify, setModify] = useState(false);
  const [paste, setPaste] = useState(false);
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [removeId, setRemoveId] = useState();
  const [params, setParams] = useState({});
  const [selectedGcomponentItems, setSelectedGcomponentItems] = useState([]);
  const [checked, setChecked] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [gtypeDetailsWithGComponentLength, setGtypeDetailsWithGComponentLength] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  
  const handleChangeOrder = () => {
    setChecked(!checked);
  }

  const handleSelect = async ({ type, params }) => {
    const { id } = params;
    
    setOpenBackdrop(true);

    if (type === "edit") {
      // 아래 호출순서에 따라 결과는 같지만 동작 모습이 다름
      setModify(true);
      setPaste(false);
      const data = await selectDirect(id);
      await selectGTypeDetailsWithGComponent(data.gtypeId); // 타입별 세부 구성
      setSelectedRow(data);
      setSelectedGcomponentItems(data.selectedGcomponentItems);
      setOpen(true);
    } else if (type === "paste") {
      setModify(false);
      setPaste(true);
      const data = await selectDirect(id);
      await selectGTypeDetailsWithGComponent(data.gtypeId); // 타입별 세부 구성
      setSelectedRow(data);
      setSelectedGcomponentItems(data.selectedGcomponentItems);
      setOpen(true);
    } else if (type === "delete") {
      setRemoveId(id);
      setParams(params);
      setConfirmOpen(true);
    }

    setOpenBackdrop(false);
  }

  const handleSelectUpDown = async ({ type, id }) => {
    setOpenBackdrop(true);

    await reorder({ type, id });
    setLoaded(false);
    await selectAllByQuery();
    setTimeout(() => setLoaded(true), 300);

    setOpenBackdrop(false);
  }

  const remove = (removeId) => {
    dispatch(gglassActions.remove(removeId))
    .then(res => selectAllByQuery())
    .catch(async (res) => {
      const data = await res.json();
      if (data && data.errors) setErrors(data.errors); // TODO : 삭제 에러 후 표시할 곳 마련하기
    });
  }

  // 저장/수정 다이얼로그
  const handleClickOpen = () => {
    setModify(false);
    setOpen(true);
  };

  const generateActions = (params) => {
    let arrActions = [
      <GridActionsCellItem 
        icon={<Edit />}
        label={"수정"}
        onClick={() => handleSelect({ type: 'edit', params })}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<ContentPaste />}
        label={"복사하여 등록"}
        onClick={() => handleSelect({ type: 'paste', params })}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<Delete />}
        label={"삭제"}
        onClick={() => handleSelect({ type: 'delete', params })}
        showInMenu
      />,
    ];

    if (checked) {
      arrActions = arrActions.concat([
        <GridActionsCellItem
          icon={<KeyboardDoubleArrowUp />}
          label={"맨위로"}
          onClick={() => handleSelectUpDown({ type: 'first', id: params.id })}
          disabled={params.row.orderDetail === 'F'}
        />,
        <GridActionsCellItem
          icon={<KeyboardArrowUp />}
          label={"위로"}
          onClick={() => handleSelectUpDown({ type: 'up', id: params.id })}
          disabled={params.row.orderDetail === 'F'}
        />,
        <GridActionsCellItem
          icon={<KeyboardArrowDown />}
          label={"아래로"}
          onClick={() => handleSelectUpDown({ type: 'down', id: params.id })}
          disabled={params.row.orderDetail === 'L'}
        />,
        <GridActionsCellItem
          icon={<KeyboardDoubleArrowDown />}
          label={"맨아래로"}
          onClick={() => handleSelectUpDown({ type: 'last', id: params.id })}
          disabled={params.row.orderDetail === 'L'}
        />
      ]);
    }

    return arrActions;
  }

  const columns = [
    // {
    //   field: 'id',
    //   headerName: '아이디',
    //   width: 100,
    // },
    {
      field: 'name',
      headerName: '이름',
      width: 250,
      // editable: true,
    },
    // {
    //   field: 'code',
    //   headerName: '코드',
    //   width: 100,
    //   // editable: true,
    // },
    {
      field: 'gtypeName',
      headerName: '템플릿 구조',
      width: 200,
      // editable: true,
    },
    {
      field: 'specification',
      headerName: '규격',
      width: 400,
      // editable: true,
      valueGetter: (params) => params.value.replaceAll("|", " ").replaceAll("+", " + ")
    },
    {
      field: 'otherSpecs',
      headerName: '비고',
      width: 400,
      // editable: true,
      valueGetter: (params) => params.value.replaceAll("|", " ").replaceAll("+", " + ")
    },
    {
      field: 'labelPositionView',
      headerName: '보는 방향(라벨)',
      width: 170,
      // valueGetter: (params) => {
      //   let labelPositionView = "";
      //   if (params.value === "inout") {
      //     labelPositionView = "실내에서 봄";
      //   } else if (params.value === "outin") {
      //     labelPositionView = "실외에서 봄";
      //   }
      //   return labelPositionView;
      // }
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        let labelPositionView = "";
        let icon;
        if (params.value === "inout") {
          labelPositionView = "실내에서 봄";
          icon = <Logout color="primary" />
        } else if (params.value === "outin") {
          labelPositionView = "실외에서 봄";
          icon = <Login color="primary" />
        }
        return (
          <>
            <Typography variant={"body2"} sx={{ mr: 2 }}>
              {labelPositionView}
            </Typography>
            {icon}
          </>
        )
      },
      // editable: true,
    },
    {
      field: 'labelPosition',
      headerName: '라벨위치',
      width: 120,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => {
        let labelPosition = "";
        if (params.value === "TopLeft") {
          labelPosition = "좌측상단";
        } else if (params.value === "TopRight") {
          labelPosition = "우측상단";
        } else if (params.value === "BottomLeft") {
          labelPosition = "좌측하단";
        } else if (params.value === "BottomRight") {
          labelPosition = "우측하단";
        }
        return labelPosition;
      }
      // editable: true,
    },
    {
      field: 'comments',
      headerName: '설명',
      width: 280,
      // editable: true,
    },
    {
      field: 'createdAt',
      headerName: '생성일시',
      width: 160,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => dateFormat(params.value),
    },
    {
      field: 'updatedAt',
      headerName: '수정일시',
      width: 160,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => dateFormat(params.value),
    },
    {
      field: 'actions',
      headerName: <Tooltip title={"수정/복사하여 등록/삭제"} followCursor><Box>{"기능"}</Box></Tooltip>,
      width: checked ? 200 : 70,
      // TODO : description 설정시 나타나는 툴팁 때문에 바로 기능버튼을 누르기 불편한 부분이 있어 여기서만 우선 주석처리. 추후 다른 목록들도 검토
      // description: "수정/삭제", // 불편해서 주석처리
      type: 'actions',
      getActions: (params) => generateActions(params),
    },
  ];

  const dispatch = useDispatch();

  const gtypes = useSelector((state) => state.gtype.gtypes);
  const rows = useSelector((state) => state.gglass.gglasses);
  const gtypeDetailsWithGComponent = useSelector((state) => state.gtypeDetail.gtypeDetailsWithGComponent);

  // 데이터 관리
  const selectAllByQuery = () => dispatch(gglassActions.selectAllByQuery())
  const selectDirect = (id) => gglassActions.selectDirect(id)
  const selectGTypeDetailsWithGComponent = (gtypeId) => dispatch(gtypeDetailActions.selectGTypeDetailsWithGComponent(gtypeId))
  const reorder = ({ type, id }) => gglassActions.reorder({ type, id })
  const selectAllGTypes = () => dispatch(gtypeActions.selectAll())

  useEffect(
    async () => {
      await selectAllByQuery();
      await selectAllGTypes();
      
      setLoaded(true);
    }, [dispatch]
  );

  useEffect(
    () => {
      // 선택 속성 데이터 구조 마련
      // if (gtypeDetailsWithGComponent.length > 0) {
      if (gtypeDetailsWithGComponent.length !== gtypeDetailsWithGComponentLength) {
        const selected = gtypeDetailsWithGComponent.map(gtypeDetails => {
          return gtypeDetails.map(gcomponent => {
            console.log(gcomponent)
            return {
              id: gcomponent.id,
              name: gcomponent.name,
              code: gcomponent.code,
              value: gcomponent?.multipleYN ? [] : {},
            }
          });
        });
        // console.log(selected)
        setSelectedGcomponentItems(selected);
        // console.log(gtypeDetailsWithGComponent)
        setGtypeDetailsWithGComponentLength(gtypeDetailsWithGComponent.length);
      }
    }, [gtypeDetailsWithGComponent]
  )

  return (
    <ThemeProvider theme={theme}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container component="main" maxWidth="lg">
        <CssBaseline />
        {
          // gtypeDetailsWithGComponent?.length > 0 && ( // TODO : 이런 식으로 유효성 체크를 하면 GGlassDialog의 rendering 횟수를 줄이게 됨. 검토해볼 것(지금도 많이 들어옴)
            <GGlassDialog
              modify={modify}
              paste={paste}
              open={open}
              setOpen={setOpen}
              selectedRow={selectedRow}
              setSelectedRow={setSelectedRow}
              gtypeDetailsWithGComponent={gtypeDetailsWithGComponent}
              gtypes={gtypes}
              refresh={selectAllByQuery}
              selectedGcomponentItems={selectedGcomponentItems}
              setSelectedGcomponentItems={setSelectedGcomponentItems}
              gtypeDetailsWithGComponentLength={gtypeDetailsWithGComponentLength}
            />
          // )
        }
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={10}>
              <Typography variant="h5">
                {title}
              </Typography>
            </Grid>
            <Grid item xs={2} display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                startIcon={<Add />}
                onClick={handleClickOpen}
              >
                {"등록하기"}
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={10}>
            </Grid>
            <Grid item xs={2} display="flex" justifyContent="flex-end" sx={{ mb: 2 }}>
              <FormControlLabel
                control={
                  <Switch 
                    checked={checked}
                    onChange={handleChangeOrder}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label={"순서"}
              />
            </Grid>
          </Grid>
          <div style={{ height: 800, width: '100%' }}>
            <DataGridPro
              localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
              sx={{ cursor: 'pointer', fontSize: '0.85em' }}
              initialState={{ pinnedColumns: { right: ['actions'] } }}
              slots={{
                noRowsOverlay: CustomNoRowsOverlay,
                loadingOverlay: LinearProgress,
              }}
              columnHeaderHeight={38}
              rowHeight={34}
              loading={!loaded}
              rows={rows}
              columns={columns}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[10, 20, 50, 100]}
              pagination
              onRowDoubleClick={(params) => handleSelect({ type: 'edit', params })}
            />
          </div>
        </Box>
        <ConfirmDialog
          removeId={removeId}
          title={"삭제"}
          open={confirmOpen}
          setOpen={setConfirmOpen}
          onConfirm={remove}
          onCancel={() => {}}
        >
          {`"${params && params.row && params.row.name || ""}(아이디 : ${params && params.id  || ""})"를 삭제하시겠습니까?`}
        </ConfirmDialog>
      </Container>
    </ThemeProvider>
  );
};

export default GGlassManagement;
