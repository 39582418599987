import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  Alert,
  Box,
  IconButton,
  LinearProgress,
  Snackbar,
  Stack,
  Typography,
} from '@mui/material';
import  {
  Close,
} from '@mui/icons-material';

import * as g04docuMaterialApprovalActions from "../../store/g04docuMaterialApproval";
import * as materialApprovalProgressActions from "../../store/materialApprovalProgress";

const theme = createTheme();

let timerId = null;

function LinearProgressWithLabel(props) {
  return (
    // <Box sx={{ display: 'flex', alignItems: 'center', width: '170px' }}>
    //   <Box sx={{ width: '100%', mr: 1 }}>
    //     <LinearProgress variant="determinate" {...props} />
    //   </Box>
    //   <Box sx={{ minWidth: 35 }}>
    //     <Typography variant="body2" sx={{ color: 'white' }}>{`${Math.round(
    //       props.value,
    //     )}%`}</Typography>
    //   </Box>
    // </Box>
    <Stack direction="row" sx={{ display: 'flex', alignItems: 'center' }}>
      {"진행률"}
      <LinearProgress variant="determinate" {...props} sx={{ width: 200, ml: 1, mr: 1 }} />
      <Typography variant="body2" sx={{ color: 'white' }}>{`${Math.round(props.value)}%`}</Typography>
    </Stack>
  );
}

const G04docuMaterialApprovalProgress = ({
}) => {
  const [pageSize, setPageSize] = useState(100);
  const [page, setPage] = useState(0);
  const [completed, setCompleted] = useState(false);

  const sessionUser = useSelector(state => state.session.sessionUser);
  const options = useSelector(state => state.materialApprovalProgress.options);
  const g04docuMaterialApprovalProgress = useSelector((state) => state.g04docuMaterialApproval.g04docuMaterialApprovalProgress);

  // 데이터 관리
  const dispatch = useDispatch();
  
  const selectProgressById = (id) => dispatch(g04docuMaterialApprovalActions.selectProgressById(id))
  const initProgress = () => dispatch(g04docuMaterialApprovalActions.initProgress())

  const selectAll = (queryOptions) => dispatch(g04docuMaterialApprovalActions.selectPaging(queryOptions))
  const selectByGClient = (gclientId) => dispatch(g04docuMaterialApprovalActions.selectByGClient(gclientId))
  const selectG04docuMaterialApprovalById = (id) => dispatch(g04docuMaterialApprovalActions.selectById(id))

  const location = useLocation();

  const queryPaging = useMemo(
    () => ({
      page,
      pageSize,
    }), [page]
  );

  useEffect(
    () => {
      if (options?.open) {
        setCompleted(false);
        // timerId = startInterval(5000, () => selectProgressById(options?.values.id));
        timerId = setTimeout(function select () {
          selectProgressById(options?.values.id);
          timerId = setTimeout(select, 5000)
        }, 5000);
      } else {
        initProgress();
    
        if (timerId) {
          clearTimeout(timerId);
        }

        setTimeout(() => setCompleted(false), 2000);
      }
    }, [options]
  )

  useEffect(
    () => {
      if (g04docuMaterialApprovalProgress && g04docuMaterialApprovalProgress.progressRate === 100 && timerId) {
        console.log(options)
        // options?.values?.func(options?.values?.funcParams.id);
        // alert(`clear progressItemId: ${timerId}`)
        clearTimeout(timerId);

        if (location.pathname === "/g04docuGenerateFile") {
          selectG04docuMaterialApprovalById(options?.values?.id);
        } else if (location.pathname === "/g04docuMaterialApproval") {
          const { id: gclientId, type } = sessionUser;
          let func = null;
          let search = null;
          if (type === 'ADMIN') {
            search = queryPaging;
            func = selectAll;
          } else if (type === 'GCLIENT') {
            search = gclientId;
            func = selectByGClient;
          }
    
          if (func) {
            func(search);
          }
        }

        setTimeout(() => setCompleted(true), 2000);

        // setTimeout(() => {
        //   dispatch(materialApprovalProgressActions.showMaterialApprovalProgress({ open: false }));
        // }, 3000);
      }
    }, [g04docuMaterialApprovalProgress]
  )

  const handleCloseProgressDialog = () => {
    dispatch(materialApprovalProgressActions.showMaterialApprovalProgress({ open: false }));
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Snackbar
          open={options?.open}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Alert
            // onClose={handleCloseProgressDialog}
            // severity={g04docuMaterialApprovalProgress?.results?.status === 'FAIL' ? "warning" : "info"} // success, info, warning, error
            severity={"info"} // success, info, warning, error
            variant="filled"
            sx={{ width: '380px' }}
            action={
              completed ? (
                <IconButton color="inherit" size="small" onClick={handleCloseProgressDialog}>
                  <Close fontSize="small" />
                </IconButton> 
              ) : (<></>)
            }
          >
            {
              !completed ? (
                <Stack direction="row">
                  <LinearProgressWithLabel value={g04docuMaterialApprovalProgress && g04docuMaterialApprovalProgress.progressRate ? g04docuMaterialApprovalProgress.progressRate : 0} />
                </Stack>
              ) : (
                <Stack direction="row" display="flex" alignItems="top">
                  {
                    g04docuMaterialApprovalProgress?.results?.status === 'SUCCESS' ? "자재승인서류가 작성되었습니다." : "자재승인서류 작성 중 오류가 발생했습니다."
                  }
                  {/* {"자재승인서류가 생성되었습니다."} */}
                  {/* {completed ? (
                  <IconButton color="inherit" size="small" onClick={handleCloseProgressDialog}>
                    <Close fontSize="small" />
                  </IconButton> ) : <></>
                  } */}
                </Stack>
                
              )
            }            
          </Alert>
        </Snackbar>
      </ThemeProvider>
    </>
  );
};

export default G04docuMaterialApprovalProgress;
