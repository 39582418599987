import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { DataGridPro, koKR } from '@mui/x-data-grid-pro';
import {
  Avatar,
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Container,
  CssBaseline,
  Grid,
  IconButton,
  Link,
  Skeleton,
  Stack,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  Paper,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import {
  red,
} from '@mui/material/colors';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Add,
  Block,
  Circle,
  ChromeReaderMode,
  Clear,
  Edit,
  FilePresent,
  KeyboardArrowDown,
  KeyboardArrowUp,
  LockOutlined,
  Remove,
  Warning,
  Settings,
  Email,
  Delete,
  Password,
  PersonAdd,
  Preview,
} from '@mui/icons-material';
import {
  createTheme,
  ThemeProvider,
} from '@mui/material/styles';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "../accordion";
import {
  AlertDialog,
  ConfirmDialog,
} from "../../components/dialog";
import { StyledTooltip } from "../tooltip";
import { dateFormat } from "../../utils";

// store actions
import * as g04docuFileActions from "../../store/g04docuFile";
import * as alertDialogActions from "../../store/components/alertDialog";
import * as gclientG04GeneralDocuMapActions from "../../store/gclientG04GeneralDocuMap";
import * as gclientG04docuEtcMapActions from "../../store/gclientG04docuEtcMap";

import G04docuFileDialogGeneral from "../G04docuFile/G04docuFileDialogGeneral";
import G04docuFileDialogEtc from "../G04docuFile/G04docuFileDialogEtc";

const tooltipTop = {
  "& .MuiTooltip-tooltip": {
    borderRadius: "0px",
  }
};

const G04GeneralDocu = ({
  handleClickFileDownload,
  handleClickViewEachDoc,
  selectedGClient,
}) => {
  const [expanded, setExpanded] = useState(true);
  const [mode, setMode] = useState('all');
  const [selectedRow, setSelectedRow] = useState([]);
  const [openGeneral, setOpenGeneral] = useState(false);
  const [removeFileObject, setRemoveFileObject] = useState({});
  const [confirmOpenRemoveFile, setConfirmOpenRemoveFile] = useState(false);
  const [selectedRowEtc, setSelectedRowEtc] = useState([]);
  const [etcType, setEtcType] = useState("");
  const [openEtc, setOpenEtc] = useState(false);

  const dispatch = useDispatch();

  const sessionUser = useSelector((state) => state.session.sessionUser);
  const g04GeneralDocus = useSelector((state) => state.gclientG04GeneralDocuMap.gclientG04GeneralDocuMaps);
  
  // const options = useSelector((state) => state.alertDialog.options);

  // const setOptions = (options) => dispatch(alertDialogActions.setOptions(options));
  
  const removeG04docuFile = (documentPath) => g04docuFileActions.removeDirect(documentPath)
  const selectMyG04GeneralDocus = (gclientId, status) => dispatch(gclientG04GeneralDocuMapActions.selectAllByGClientByStatus(gclientId, status))
  const removeGClientG04docuEtcMap = ({ gclientId, g04docuEtcId, documentPath }) => dispatch(gclientG04docuEtcMapActions.remove({ gclientId, g04docuEtcId, documentPath }))
  const removeGClientG04GeneralDocuMap = ({ gclientId, documentPath }) => dispatch(gclientG04GeneralDocuMapActions.remove({ gclientId, documentPath }))

  useEffect(
    () => {
      selectMyG04GeneralDocus(sessionUser.id, 'all');
    }, [dispatch]
  )

  const handleChangeMode = (e, newMode) => {
    e.stopPropagation();

    setMode(newMode);

    selectMyG04GeneralDocus(sessionUser.id, newMode);
  }

  const handleClickGeneralDoc = (e, g04GeneralDocu, mode) => {
    console.log(g04GeneralDocu)
    // console.log(g04GeneralDocu.g04docuEtcId)
    if (g04GeneralDocu.g04docuEtcId === undefined) { // undefined이면 일반문서, null이면 기타에서 일반문서로 첨부한 문서 // TODO : g04docuEtcId값으로 판단해도 될지...??
      const { id, code, name, gclientId, documentPath, validYN, startDate, endDate } = g04GeneralDocu;
      if (mode === 'attachDocument') {
        setSelectedRow({
          g04GeneralDocuId: id,
          documentType: code,
          title: name,
          documentPath,
          validYN,
          startDate,
          endDate,
        });
    
        setOpenGeneral(true);
      } else if (mode === 'deleteDocument') {
        if (documentPath) {
          setRemoveFileObject({ gclientId, documentPath, type: 'GENERAL' });
          setConfirmOpenRemoveFile(true);
        }
      }
      
    } else {
      const { id, code, name, gclientId, documentPath, validYN, startDate, endDate } = g04GeneralDocu;
      if (mode === 'attachDocument') {
        setSelectedRowEtc({
          g04docuEtcId: id,
          documentType: code,
          title: name,
          documentPath,
          validYN,
          startDate,
          endDate,
        });
    
        setEtcType('GENERAL');
        setOpenEtc(true);
      } else if (mode === 'deleteDocument') {
        if (documentPath) {
          setEtcType('GENERAL');
          setRemoveFileObject({ gclientId, g04docuEtcId: id, documentPath, type: 'ETC' });
          setConfirmOpenRemoveFile(true);
        }
      }
    }
  }

  const selectAllG04GeneralDocus = (gclientId) => {
    selectMyG04GeneralDocus(gclientId, mode);
  }

  const removeFile = async () => {
    const { gclientId, g04docuEtcId, division, documentPath, type } = removeFileObject;
    await removeG04docuFile(documentPath);

    if (type === 'GENERAL') {
      await removeGClientG04GeneralDocuMap({ gclientId, documentPath });
      selectAllG04GeneralDocus(gclientId);
    } else if (type === 'ETC') {
      await removeGClientG04docuEtcMap({ gclientId, g04docuEtcId, documentPath });
      if (etcType === 'GENERAL') {
        selectAllG04GeneralDocus(gclientId);
      }/* else {
        selectAllGComponentItems(gclientId, division);
      }*/
    }
  }

  return (
    <>
      <G04docuFileDialogGeneral
        openGeneral={openGeneral}
        setOpenGeneral={setOpenGeneral}
        selectedRow={selectedRow}
        selectedGClientId={sessionUser.id}
        selectedGClient={selectedGClient}
        refresh={selectAllG04GeneralDocus}
      />
      <G04docuFileDialogEtc
        open={openEtc}
        setOpen={setOpenEtc}
        selectedRow={selectedRowEtc}
        gclientId={sessionUser.id}
        gclient={selectedGClient}
        refresh={selectAllG04GeneralDocus}
      />
      <ConfirmDialog
        removeId={removeFileObject}
        title={"삭제"}
        open={confirmOpenRemoveFile}
        setOpen={setConfirmOpenRemoveFile}
        onConfirm={removeFile}
        onCancel={() => {}}
      >
        <div>
          <span style={{ color: "#1976d2" }}>{"첨부된 서류를 삭제하시겠습니까?"}</span>
          <br /><br />
          <Box sx={{ solid: 1, borderRadius: 1, bgcolor: '#eee', color: '#aaa', p: 1, fontSize: '0.8em' }}>
            { `파일명 : ${removeFileObject?.documentPath?.split("/")[removeFileObject?.documentPath?.split("/")?.length-1]}` }
          </Box>
        </div>
      </ConfirmDialog>
      <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
        <AccordionSummary aria-controls="basic-content" id="basic-header">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="h6" component="div">{"공통서류"}</Typography>
            </Grid>
            <Grid item xs={6} display="flex" justifyContent="flex-end" alignItems="center">
              <Typography variant="subtitle2" component="div" /*color="primary" */sx={{ mr: 1 }} onClick={(e) => e.stopPropagation()}>{"보기"}</Typography>
              <ToggleButtonGroup
                color="primary"
                value={mode}
                exclusive
                size="small"
                onChange={handleChangeMode}
              >
                <ToggleButton value="nofile">{"서류 없음"}</ToggleButton>
                <ToggleButton value="invalid">{"만료일 경과"}</ToggleButton>
                <ToggleButton value="all">{"전체"}</ToggleButton>
              </ToggleButtonGroup>  
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Grid container spacing={1} sx={{
                pb: 1,
                borderBottom: 1,
                // height: '100%',
                // borderRadius: 1,
                borderColor: '#DCDCDC'
              }}>
                <Grid item xs={4} display="flex" justifyContent="center" alignItems="center">
                  {"서류명"}
                </Grid>
                <Grid item xs={2} display="flex" justifyContent="center" alignItems="center">
                  {"서류"}
                </Grid>
                <Grid item xs={2} display="flex" justifyContent="center" alignItems="center">
                  {"만료일"}
                </Grid>
                <Grid item xs={2} display="flex" justifyContent="center" alignItems="center">
                  {"최종수정일시"}
                </Grid>
                <Grid item xs={2} display="flex" justifyContent="center" alignItems="center" sx={{ ml: -1.6 }}>
                  {"업무"}
                </Grid>
              </Grid>
            </Grid>
            {
              g04GeneralDocus?.map((g04GeneralDocu, index) => {
                const { id, name, code, documentPath, startDate, endDate, mapUpdatedAt, valid, validYN } = g04GeneralDocu;
                return (
                  <Grid item xs={12} key={id || `g04GeneralDocu-${index}`}>
                    <Grid container spacing={1} sx={{
                      // pb: 1,
                      borderBottom: 1,
                      // height: '100%',
                      // borderRadius: 1,
                      borderColor: '#DCDCDC'
                    }}>
                      {/* <Grid item xs={4}>
                        <Box display="flex" alignItems="center"
                          // endIcon={documentPath ? (<Tooltip title={"다운로드"}><FilePresent onClick={(e) => handleClickFileDownload(e, documentPath)} /></Tooltip>) : <></>}
                          // onClick={() => handleClickGeneral({ id, name, code, documentPath, startDate, endDate })}
                          // sx={{ ml: 1 }}
                          sx={{
                            border: 1,
                            height: '100%',
                            borderRadius: 1,
                            borderColor: '#C4C4C4'
                          }}
                        >
                          <Grid container>
                            <Grid item xs={9} display="flex" alignItems="center">
                              <Typography variant="subtitle1" sx={{ ml: 2 }}>{name}</Typography>
                            </Grid>
                            <Grid item xs={3} display="flex" justifyContent="flex-end" alignItems="center">
                              {
                                documentPath && (
                                  <IconButton color="primary" onClick={(e) => handleClickFileDownload(documentPath)}>
                                    <FilePresent
                                      // color="primary"
                                      // onClick={(e) => handleClickFileDownload(documentPath)}
                                    />
                                  </IconButton>
                                )
                              }
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid> */}
                      {/* <Grid item xs={4} display="flex" alignItems="center" sx={{ color: valid === false ? red[700] : "" }}> */}
                      <Grid item xs={4} display="flex" alignItems="center" sx={{ color: !documentPath ? red[700] : ((validYN || valid) ? "#000000" : "#ED6C02") }}>
                        {
                          documentPath && (validYN || valid) && <Grid display="flex" alignItems="center" sx={{ ml: 1, color: '#4caf50' }}><Circle sx={{ width: 14, height: 14}} /></Grid>
                        }
                        {/* {
                          documentPath && (!validYN && !valid) && <Grid display="flex" alignItems="center" sx={{ ml: 1, color: '#ED6C02' }}><Warning /></Grid>
                        } */}
                        {
                          documentPath && (!validYN && !valid) && <Grid display="flex" alignItems="center" sx={{ ml: 1, color: '#ED6C02' }}><Circle sx={{ width: 14, height: 14}} /></Grid>
                        }
                        {/* {
                          !documentPath && <Grid display="flex" alignItems="center" sx={{ ml: 1, color: '#ef5350' }}><Block /></Grid>
                        } */}
                        {
                          !documentPath && <Grid display="flex" alignItems="center" sx={{ ml: 1, color: '#ef5350' }}><Circle sx={{ width: 14, height: 14}} /></Grid>
                        }
                        <Typography variant="subtitle1" sx={{ ml: 2 }}>{name}</Typography>
                      </Grid>
                      <Grid item xs={2} display="flex" justifyContent="center" alignItems="center">
                        {
                          documentPath && (
                            // <>
                            //   <Tooltip title={"다운로드"}>
                            //     <IconButton color="primary" onClick={(e) => handleClickFileDownload(documentPath)}>
                            //       <FilePresent
                            //         // color={valid === false ? "error" : "primary"}
                            //         sx={{ color: (validYN || valid) ? "#1976D2" : "#ED6C02" }}
                            //       />
                            //     </IconButton>
                            //   </Tooltip>
                            //   <Tooltip title={"보기"}>
                            //     <IconButton
                            //       // color={data?.valid === false ? "error" : "primary"}
                            //       // color={"primary"}
                            //       aria-label="view"
                            //       edge="end"
                            //       onClick={() => handleClickViewEachDoc(documentPath)}
                            //       sx={{ color: (validYN || valid) ? "#1976D2" : "#ED6C02" }}
                            //     >
                            //       <ChromeReaderMode />
                            //     </IconButton>
                            //   </Tooltip>
                            // </>
                            <StyledTooltip
                              title={<Stack direction="row" display="flex" alignItems="center">
                                <Box sx={{ solid: 1, borderRadius: 1, bgcolor: '#eee', color: '#aaa', p: 1, fontSize: '0.8em', mr: 1 }}>
                                  {`미리보기`}
                                </Box>
                                <Typography variant="subtitle2">{documentPath?.split("/")[documentPath?.split("/")?.length-1]}</Typography>
                              </Stack>}
                              placement="right"
                              sx={tooltipTop}
                            >
                              <IconButton
                                color="primary"
                                aria-label="file"
                                onClick={() => handleClickViewEachDoc(documentPath)}
                                sx={{ color: (validYN || valid) ? "#1976D2" : "#ED6C02" }}
                              >
                                <Preview />
                              </IconButton>
                            </StyledTooltip>
                          )
                        }
                        {
                          !documentPath && (
                            <Tooltip title={"서류없음"} placement="right">
                              <IconButton
                                aria-label="download"
                                // edge="end"
                                sx={{ color: '#ef5350' }}
                              >
                                <Block />
                              </IconButton>
                            </Tooltip>
                          )
                        }
                      </Grid>
                      <Grid item xs={2} display="flex" justifyContent="center" alignItems="center">
                        {
                          endDate ? (
                            <Tooltip title={"만료일"} placement="right">
                              <Chip label={` ~ ${dateFormat(endDate, 'yyyy-MM-dd')}`} color={valid === false ? "warning" : "primary"} variant={valid === false ? "" : "outlined"}></Chip>
                            </Tooltip>
                          ) : ( validYN ? "만료일 없음" : "")
                        }
                      </Grid>
                      <Grid item xs={2} display="flex" justifyContent="center" alignItems="center">
                        {
                          mapUpdatedAt ? (
                            <Tooltip title={"최종수정일시"} placement="right">
                              <Chip label={dateFormat(mapUpdatedAt)}></Chip>
                            </Tooltip>
                          ) : ""
                        }
                      </Grid>
                      <Grid item xs={2} display="flex" justifyContent="center" alignItems="center" sx={{ ml: -1.6 }}>
                        <Button key={`save-${id || index}`} onClick={(e) => handleClickGeneralDoc(e, g04GeneralDocu, 'attachDocument')} sx={{ color: '#1976d2' }}>{"등록/수정"}</Button>
                        <Button
                          key={`delete-${id || index}`}
                          onClick={(e) => handleClickGeneralDoc(e, g04GeneralDocu, 'deleteDocument')}
                          disabled={!documentPath}
                          sx={{ color: '#1976d2' }}
                        >
                          {"삭제"}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                )
              })
            }
            </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  )
}

export default G04GeneralDocu;