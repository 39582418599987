import { csrfFetch } from "./csrf.js";
import { gcoreServerUrl } from '../config.js';

const SET_G04DOCU_GTESTCOMPARISONS = 'g04docuGTestComparison/setG04docuGTestComparisions';

const setG04docuGTestComparisions = (g04docuGTestComparisons) => ({
  type: SET_G04DOCU_GTESTCOMPARISONS,
  payload: g04docuGTestComparisons,
});

export const selectAllByG04docuGTests = (g04docuGTestId) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04docuGTestComparisons/g04docuGTests/${g04docuGTestId}`);
  const data = await response.json();
  dispatch(setG04docuGTestComparisions(data.g04docuGTestComparisons));
  return response;
}

export const createG04docuGTestComparisonsBulk = ({
  g04docuGTestId,
  g04docuGTestName,
  division,
  g04docuGTestComparisons,
  gclientId,
  gcomponentItemId,
  gcomponentItemName,
  g04docuGCertificationId,
  g04docuGCertificationName,
  gclient,
}) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04docuGTestComparisons/bulk`, {
    method: "POST",
    body: JSON.stringify({
      g04docuGTestId,
      g04docuGTestName,
      division,
      g04docuGTestComparisons,
      gclientId,
      gcomponentItemId,
      gcomponentItemName,
      g04docuGCertificationId,
      g04docuGCertificationName,
      gclient,
    }),
  });
  const data = await response.json();
  dispatch(setG04docuGTestComparisions(data.g04docuGTestComparisons));
  return response;
};

const initialState = {
  g04docuGTestComparisons: [],
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case SET_G04DOCU_GTESTCOMPARISONS:
      newState = Object.assign({}, state, { g04docuGTestComparisons: action.payload });
      return newState;
    default:
      return state;
  }
}

export default reducer;
