// import id from "date-fns/locale/id/index.js";
import { csrfFetch } from "./csrf.js";
import { gcoreServerUrl } from '../config';

const SET_GGLASS_PERFORMANCES = 'gglassPerformance/setGGlassPerformances';

const setGGlassPerformances = (gglassPerformances) => ({
  type: SET_GGLASS_PERFORMANCES,
  payload: gglassPerformances,
});

export const selectAll = () => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gglassPerformances`);
  const data = await response.json();
  dispatch(setGGlassPerformances(data.gglassPerformances));
  return response;
}

export const selectByCodeDirect = async (code) => {
  const response = await csrfFetch(null, `${gcoreServerUrl}/api/gglassPerformances/code/${code}`);
  const data = await response.json();
  return data.gglassPerformances;
}

export const selectByCode = (code) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gglassPerformances/code/${code}`);
  const data = await response.json();
  dispatch(setGGlassPerformances(data.gglassPerformances));
  return response;
}

const initialState = {
  gglassPerformances: [],
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case SET_GGLASS_PERFORMANCES:
      newState = Object.assign({}, state, { gglassPerformances: action.payload });
      return newState;
    default:
      return state;
  }
}

export default reducer;
