import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import { LicenseInfo } from '@mui/x-license-pro';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
} from '@mui/material';
import {
  DialogTitleClose,
  PaperComponent,
} from "./components/dialog";

import * as notificationActions from './store/notification';

const NotificationDialog = ({ ...props }) => {
  const notification = useSelector(state => state.notification.notification);

  const dispatch = useDispatch();

  const displayNotification = (show) => dispatch(notificationActions.displayNotification(show))

  const handleDialogClose = () => {
    displayNotification(false);
  }

  return (
    <Dialog
      open={notification}
      onClose={handleDialogClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      maxWidth="false"
    >
      <DialogTitleClose
        id="draggable-dialog-title"
        onClose={handleDialogClose}
        style={{ cursor: 'move' }}
      >
        {"알림"}
      </DialogTitleClose>
      <DialogContent dividers>
        {props.children}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose}>{"닫기"}</Button>
      </DialogActions>
    </Dialog>
  )
}

export default NotificationDialog;
