import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
} from '@mui/material';

// 자체 컴포넌트
import { ConfirmDialog } from "../dialog";

// store actions
import * as confirmDialogActions from "../../store/components/confirmDialog";

const GComponentDeleteConfirmDialog = ({
}) => {

  const dispatch = useDispatch();

  const options = useSelector((state) => state.confirmDialog.options);
  const selectedRows = useSelector((state) => state.datagrid.selectedRows);

  const setOptions = (options) => dispatch(confirmDialogActions.setOptions(options));

  return (
    <ConfirmDialog
      removeId={options?.params}
      title={"삭제"}
      open={options?.open || false}
      setOpen={() => setOptions({ ...options, open: false })}
      onConfirm={(params) => options?.action(params, selectedRows)}
      onCancel={() => {}}
    >
      {
        options && (
          <div>
            <span style={{ color: "#1976d2" }}>{`${options?.params && options?.params.row && options?.params.row.name || ""}`}</span>{`를 삭제하시겠습니까?`}
            <br /><br />
            <Box sx={{ solid: 1, borderRadius: 1, bgcolor: '#eee', color: '#aaa', p: 1, fontSize: '0.8em' }}>
            {`아이디 : ${options?.params && options?.params.id  || ""}`}
            </Box>
          </div>
        )
      }
    </ConfirmDialog>
  )
}

export default GComponentDeleteConfirmDialog;