/**
 * 외부에서 함수로 값을 설정하기 위해 FormInputDropdown을 일부 변형함.
 * TODO : 다른 곳에도 적용해보고 이상없으면 FormInputDropdown와 통합할 것 => 현재 밖에서 setValue하면 값이 설정되지 않음...
 * GOrderDialog에서 목록에서 띄운 경우(FormInputDropdown)와 띄운 상태에서 프로젝트 선택변경에 의한 값 설정용(FormInputDropdown1)으로 분리해서 사용
 */
import React from "react";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { Controller } from "react-hook-form";

const FormInputDropdown1 = React.forwardRef(({ name, control, label, setValue, getValues, options, onChangeSelectValue, ...props}, ref) => {

  // const [selectedValue, setSelectedValue] = React.useState(getValues && getValues(name));
  const [selectedValue, setSelectedValue] = React.useState("");

  // 부모 컴포넌트에서 사용할 함수를 선언
  React.useImperativeHandle(ref, () => ({
    setSelectedValue1,
    getOptions,
  }))

  const setSelectedValue1 = (value) => {
    setSelectedValue(value);
    setValue && setValue(name, value);
  }

  const getOptions = () => {
    return options;
  }

  const generateSingleOptions = () => {
    return options && options.map((option) => {
      return (
        <MenuItem key={option.value} value={option.value} sx={{ bgcolor: option?.bgcolor ? option.bgcolor : "", color: option?.color ? option.color : "" }}>
          {option.label}
        </MenuItem>
      );
    });
  };

  // const handleClick = () => {
  //   setSelectedValue("6")
  // }

  // TODO : defaultValue 설정하는 방법 강구할 것
  return (
    <FormControl size={"small"} fullWidth>
      <InputLabel>{label}</InputLabel>
      <Controller
        name={name}
        control={control}
        render={({
          field: { onChange, value },
          fieldState: { error },
          formState,
        }) => (
          <Select
            // onChange={onChange}
            onChange={
              (event) => {
                console.log(event)
                onChange(event);
                setValue && setValue(name, event.target.value)
                setSelectedValue(event.target.value)
                onChangeSelectValue && onChangeSelectValue(event, value); // oldValue임에 유의. event.target.value는 newValue
                // onChangeSelectValue && onChangeSelectValue(event, event.target.value); // newValue임에 유의
              }
            }
            // value={value}
            value={selectedValue}
            // label이 없으면 select의 외곽선과 InputLabel이 겹침
            label={label}
            {...props}
          >
            {generateSingleOptions()}
          </Select>
        )}
      />
      {/* <Button onClick={handleClick}>Click</Button> */}
    </FormControl>
  );
});

export default FormInputDropdown1;