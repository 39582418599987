import React from 'react';
import { Route } from 'react-router-dom';
import Intro from "../Intro";
import LoginFormPage from '../components/LoginFormPage';
import { GLogin, GLoginGClient, GLoginGUser } from '../components/GLoginFormPage';
import { SignupGClient, SignupGUser } from '../components/GSignup';

const IndexRoute = () => (
    <>
        <Route key="/" path="/" element={<Intro />} />,
        <Route key="/intro" path="/intro" element={<Intro />} />,
        <Route key="/signin" path="/signin" element={<LoginFormPage />} />,
        <Route key="/glass" path="/glass" element={<GLogin />} />,
        <Route key="/glass/signin" path="/glass/signin" element={<GLogin />} />,
        <Route key="/glass/signin/gclient" path="/glass/signin/gclient" element={<GLoginGClient />} />,
        <Route key="/glass/signin/guser" path="/glass/signin/guser" element={<GLoginGUser />} />,
        <Route key="/glass/signup/gclient" path="/glass/signup/gclient" element={<SignupGClient />} />,
        <Route key="/glass/signup/guser" path="/glass/signup/guser" element={<SignupGUser />} />
    </>
);

export default IndexRoute;