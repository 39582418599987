// TODO : core 서버의 위치 변경시 능동적으로 대체할 수 있도록 해야 함
const adminMenus = [
  {
    id: "dashboard",
    title: "대시보드",
    href:"/glass/dashboard"
  },
  // {
  //   id: "gproject",
  //   title: "프로젝트",
  //   href: "/projects",
  // },
  // {
  //   id: "gorder_general",
  //   title: "발주",
  //   href: "/gorders",
  // },
  // {
  //   id: "reception",
  //   title: "수주",
  //   href: "/greceptions",
  // },
  {
    href: "/profile",
    id: "profile",
    title: "내 정보",
  },
  {
    id: "material_approvement", // TODO : 아이디 설정 안되면 mouse over 안됨
    title: "자재승인",
    submenus: [
      {
        id: "g04docuFiles",
        title: "문서첨부",
        href: "/g04docuFiles",
      },
      {
        id: "g04docuAlarmSettings",
        title: "문서첨부 알람설정",
        href: "/g04docuAlarmSettings",
      },
      {
        id: "g04docuSearch",
        title: "문서검색",
        href: "/g04docuSearch",
      },
      // {
      //   id: "g04docuSearch1",
      //   title: "문서검색",
      //   href: "/g04docuSearch1",
      // },
      {
        id: "gsendG04docus",
        title: "전송문서 보관함",
        href: "/gsendG04docus",
      },
      {
        id: "g04docuGenerateFile",
        title: "자재승인서 발급",
        href: "/g04docuGenerateFile",
      },
      {
        id: "g04docuMaterialApproval",
        title: "자재승인서 보관함",
        href: "/g04docuMaterialApproval?userType=admin",
      },
      // {
      //   id: "g04docuGTests",
      //   title: "성적서 검색",
      //   href: "/g04docuGTests",
      // },
    ]
  },
  // {
  //   id: "process",
  //   title: "생산",
  //   submenus: [
  //     {
  //       id: "workorders_management",
  //       title: "작업의뢰",
  //       href: "/gworkOrders",
  //     },
  //     {
  //       id: "manufacture_schedule",
  //       title: "생산일정",
  //       href: "/gplans",
  //     },
  //     {
  //       id: "manufacture_status",
  //       title: "생산현황",
  //       submenus: [
  //         {
  //           id: "manufacture_status_total",
  //           title: "생산현황 - 작업의뢰별",
  //           href: "/gworkOrderStatus",
  //         },
  //         {
  //           id: "manufacture_status_by_gline",
  //           title: "생산현황 - 공정라인별",
  //           href: "/gworkOrderStatusByGLine",
  //         }
  //       ]
  //     },
  //   ],
  // },
  {
    id: "settings",
    title: "기준정보",
    submenus: [
      {
        id: "constructionWorkType",
        title: "공종",
        href: "/constructionWorkType",
      },
      {
        id: "materials",
        title: "원부자재",
        href: "/gcomponentItem?searchItemType=materials",
      },
      {
        id: "preferences",
        title: "속성",
        href: "/gcomponentItem?searchItemType=preferences",
      },
      {
        id: "products",
        title: "제품",
        submenus: [
          {
            id: "component",
            title: "구성단위",
            href: "/gcomponent",
          },
          {
            id: "gtemplate",
            title: "단면구조",
            href: "/gtype",
          },
          {
            id: "glasses_management",
            title: "품종",
            href: "/glasses",
          },
          {
            id: "gdomesticAuths",
            title: "국내산 인증정보",
            href: "/gdomesticAuths",
          },
        ],
      },
      {
        id: "g04docu",
        title: "자재승인",
        submenus: [
          {
            id: "gstandardTypes",
            title: "표준 구분",
            href: "/gstandardTypes",
          },
          {
            id: "gstandards",
            title: "표준", // 표준㉿
            href: "/gstandards",
          },
          // {
          //   id: "g04docus",
          //   title: "인증규격(old)",
          //   href: "/g04docus",
          // },
          {
            id: "g04docuGCertifications",
            title: "인증규격",
            href: "/g04docuGCertifications",
          },
          {
            id: "g04GeneralDocus",
            title: "일반서류 종류",
            href: "/g04GeneralDocus",
          },
          // {
          //   id: "g04docuGCertificationByGItemsOrGlasses",
          //   title: "인증부여 (종류·등급·호칭 또는 모델)",
          //   href: "/g04docuGCertificationByGItemsOrGlasses",
          // }
        ],
      },
      {
        id: "gclient",
        title: "거래선",
        submenus: [
          {
            id: "type",
            title: "구분",
            submenus: [
              {
                id: "gclientType",
                title: "대분류",
                href: "/gclientTypes",
              },
              {
                id: "gclientType04docu",
                title: "세분류 - 04 DOCU",
                href: "/gclientType04docus",
              },
            ]
          },
          {
            id: "gclients",
            title: "거래선",
            href: "/gclients",
          },
          {
            id: "securitiesByGClient",
            title: "거래선별 문서보안",
            href: "/securitiesByGClient",
          },
        ]
      },
      // {
      //   id: "gprocess",
      //   title: "공정",
      //   submenus: [
      //     {
      //       id: "gprocesses",
      //       title: "공정 단계",
      //       href: "/gprocesses",
      //     },
      //     {
      //       id: "glines",
      //       title: "공정 라인",
      //       href: "/glines",
      //     },
      //   ]
      // },
    ]
  },
];

const gclientMenus = [
  {
    id: "dashboard",
    title: "대시보드",
    href:"/glass/dashboard"
  },
  {
    href: "/profile",
    id: "profile",
    title: "내 정보",
  },
  {
    id: "g04docuFiles",
    title: "문서첨부",
    href: "/g04docuFiles",
  },
  {
    id: "g04docuAlarmSettings",
    title: "문서첨부 알람설정",
    href: "/g04docuAlarmSettings",
  },
  {
    id: "g04docuSearch",
    title: "문서검색",
    href: "/g04docuSearch",
  },
  {
    id: "gsendG04docus",
    title: "전송문서 보관함",
    href: "/gsendG04docus",
  },
  {
    id: "g04docuGenerateFile",
    title: "자재승인서 발급",
    href: "/g04docuGenerateFile",
  },
  {
    id: "g04docuMaterialApproval",
    title: "자재승인서 보관함",
    href: "/g04docuMaterialApproval",
  },
  // {
  //   id: "g04docuGenerateFile",
  //   title: "자재승인서 발급",
  //   href: "/g04docuGenerateFile",
  // },
  // {
  //   id: "g04docuMaterialApproval",
  //   title: "자재승인서 보관함",
  //   href: "/g04docuMaterialApproval",
  // },
];

const   userMenus = [
  {
    id: "dashboard",
    title: "대시보드",
    href:"/glass/dashboard"
  },
];

const menus = [
  {
    id: "dashboard",
    title: "대시보드",
    href:"/glass/dashboard"
  },
  {
    id: "form_demos",
    title: "폼 데모",
    submenus: [
      {
        id: "form_demos1",
        title: "폼 데모 1",
        href: "/reactHookform",
      },
      {
        id: "form_demos2",
        title: "폼 데모 2",
        href: "/reactHookform2",
      },
      {
        id: "react_test",
        title: "리액트 테스트",
        href: "/reactTest",
      },
      {
        id: "mui_test",
        title: "MUI 테스트",
        href: "/demoDrawer",
      },
      {
        id: "mui_select_test",
        title: "MUI selectbox 테스트",
        href: "/demoSelect",
      },
    ],
  },
  {
    id: "gproject",
    title: "프로젝트",
    href: "/projects",
  },
  {
    id: "gorder_general",
    title: "발주",
    href: "/gorders",
  },
  // {
  //   id: "projects",
  //   title: "시공",
  //   submenus: [
  //     {
  //       id: "gproject",
  //       title: "프로젝트",
  //       href: "/projects",
  //     },
  //     // {
  //     //   id: "gorder",
  //     //   title: "발주",
  //     //   submenus: [
  //     //     // {
  //     //     //   id: "gorder_excel",
  //     //     //   title: "발주(엑셀)",
  //     //     //   href: "/gordersfromexcel",
  //     //     // },
  //     //     {
  //     //       id: "gorder_excel_test",
  //     //       title: "발주(엑셀) 테스트",
  //     //       href: "/gordersfromexceltest",
  //     //     },
  //     //     {
  //     //       id: "gorder_general",
  //     //       title: "발주",
  //     //       href: "/gorders",
  //     //     }
  //     //   ]
  //     // }
  //     {
  //       id: "gorder_general",
  //       title: "발주",
  //       href: "/gorders",
  //     },
  //     // {
  //     //   id: "gorder",
  //     //   title: "발주(엑셀)",
  //     //   href: "/ordersfromexcel",
  //     // },
  //     // {
  //     //   id: "gorder",
  //     //   title: "발주(엑셀)테스트",
  //     //   href: "/ordersfromexceltest",
  //     // },
  //   ],
  // },
  {
    id: "reception",
    title: "수주",
    href: "/greceptions",
    // submenus: [
    //   {
    //     id: "reception_management",
    //     title: "수주내역",
    //     href: "/greceptions",
    //   }
    // ]
  },
  {
    id: "material_approvement", // TODO : 아이디 설정 안되면 mouse over 안됨
    title: "자재승인",
    submenus: [
      {
        id: "g04docuFiles",
        title: "관련 서류 첨부",
        href: "/g04docuFiles",
      },
      {
        id: "g04docuGenerateFile",
        title: "서류 발급",
        href: "/g04docuGenerateFile",
      },
      {
        id: "g04docuMaterialApproval",
        title: "서류 보관함",
        href: "/g04docuMaterialApproval",
      },
    ]
  },
  {
    id: "process",
    title: "생산",
    submenus: [
      {
        id: "workorders_management",
        title: "작업의뢰",
        href: "/gworkOrders",
      },
      // {
      //   id: "manufacture_status_management",
      //   title: "생산현황",
      //   href: "/gworkOrderDetailProcesses",
      //   // GWorkOrderDetailProcesses
      // },
      {
        id: "manufacture_schedule",
        title: "생산일정",
        href: "/gplans",
        // GWorkOrderDetailProcesses
      },
      {
        id: "manufacture_status",
        title: "생산현황",
        submenus: [
          {
            id: "manufacture_status_total",
            title: "생산현황 - 작업의뢰별",
            href: "/gworkOrderStatus",
          },
          {
            id: "manufacture_status_by_gline",
            title: "생산현황 - 공정라인별",
            href: "/gworkOrderStatusByGLine",
          }
        ]
      },
    ],
  },
  {
    id: "settings",
    title: "기준정보",
    submenus: [
      {
        id: "constructionWorkType",
        title: "공종",
        href: "/constructionWorkType",
      },
      {
        id: "materials",
        title: "원부자재",
        href: "/gcomponentItem?searchItemType=materials",
      },
      {
        id: "preferences",
        title: "속성",
        href: "/gcomponentItem?searchItemType=preferences",
      },
      {
        id: "products",
        title: "제품",
        submenus: [
          {
            id: "component",
            title: "구성단위",
            href: "/gcomponent",
          },
          {
            id: "gtemplate",
            title: "단면구조",
            href: "/gtype",
          },
          {
            id: "glasses_management",
            title: "품종",
            href: "/glasses",
          },
        ],
      },
      {
        id: "g04docu",
        title: "자재승인",
        submenus: [
          {
            id: "gstandardTypes",
            title: "표준 구분",
            href: "/gstandardTypes",
          },
          {
            id: "gstandards",
            title: "표준", // 표준㉿
            href: "/gstandards",
          },
          {
            id: "g04docus",
            title: "인증규격(old)",
            href: "/g04docus",
          },
          {
            id: "g04docuGCertifications",
            title: "인증규격",
            href: "/g04docuGCertifications",
          },
          {
            id: "g04GeneralDocus",
            title: "일반서류 종류",
            href: "/g04GeneralDocus",
          },
        ],
      },
      // {
      //   id: "materials",
      //   title: "자재",
      //   submenus: [
      //     {
      //       id: "glasses",
      //       title: "유리가공품",
      //       submenus: [
      //         {
      //           id: "gunit",
      //           title: "단위",
      //           href: "/gunit",
      //         },
      //         {
      //           id: "component",
      //           title: "구성요소",
      //           submenus: [
      //             {
      //               id: "gcomponent",
      //               title: "구성요소",
      //               href: "/gcomponent",
      //             },
      //             // {
      //             //   id: "gcomponentItem",
      //             //   title: "구성요소 제품",
      //             //   href: "/gcomponentItem",
      //             // },
      //           ],
      //         },
      //         {
      //           id: "gtemplate",
      //           title: "구조",
      //           href: "/gtype",
      //         },
      //         {
      //           id: "glasses_management",
      //           title: "유리가공품",
      //           href: "/glasses",
      //         },
      //       ],
      //     },
      //     {
      //       id: "sub",
      //       title: "시공부자재",
      //       submenus: [
      //         {
      //           id: "gsub",
      //           title: "구성요소",
      //           href: "/gsub",
      //         },
      //         {
      //           id: "gsubItem",
      //           title: "구성요소 제품",
      //           href: "/gsubItem",
      //         },
      //       ],
      //     },
      //     {
      //       id: "g04docu",
      //       title: "자재승인",
      //       submenus: [
      //         {
      //           id: "gstandards",
      //           title: "표준(KS)", // 표준㉿
      //           href: "/gstandards",
      //         },
      //         {
      //           id: "g04docus",
      //           title: "규격(인증서/성적서)",
      //           href: "/g04docus",
      //         },
      //         {
      //           id: "g04GeneralDocus",
      //           title: "규격(일반서류)",
      //           href: "/g04GeneralDocus",
      //         },
      //         // {
      //         //   id: "gprocess04docus",
      //         //   title: "공정",
      //         //   href: "/gprocess04docus",
      //         // },
      //         // {
      //         //   id: "gsubMaterialProcess04docu",
      //         //   title: "가공부자재",
      //         //   href: "/gsubMaterialProcess04docu",
      //         // },
      //         // {
      //         //   id: "gsubMaterialBuild04docu",
      //         //   title: "시공부자재",
      //         //   href: "/gsubMaterialBuild04docu",
      //         // },
      //       ]
      //     },
      //   ]
      // },
      {
        id: "gclient",
        title: "거래선",
        submenus: [
          {
            id: "type",
            title: "구분",
            submenus: [
              {
                id: "gclientType",
                title: "대분류",
                href: "/gclientTypes",
              },
              {
                id: "gclientType04docu",
                title: "세분류 - 04 DOCU",
                href: "/gclientType04docus",
              },
            ]
          },
          {
            id: "gclients",
            title: "거래선",
            href: "/gclients",
          },
        ]
      },
      {
        id: "gprocess",
        title: "공정",
        submenus: [
          {
            id: "gprocesses",
            title: "공정 단계",
            href: "/gprocesses",
          },
          {
            id: "glines",
            title: "공정 라인",
            href: "/glines",
          },
        ]
      },
    ]
  },
];

export {
  adminMenus,
  gclientMenus,
  userMenus,
}