import React, { useEffect, useState } from 'react';
import { Link as RouterLink, Navigate, useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  Link,
  ListItemIcon,
  Menu,
  MenuItem,
  styled,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  AccountCircle,
  Inbox,
  Login,
  Logout,
  ManageAccounts,
  Settings,
} from '@mui/icons-material';
// import NavigationGuard from './guard/NavigationGuard';
import * as sessionActions from '../../store/session';
import * as securityActions from '../../store/security';
import * as menuActions from '../../store/menu';
import CascadingHoverMenus from './CascadingHoverMenus';
import SendBasketDialog from './SendBasketDialog';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 5,
    top: 13,
    // border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    color: "white",
    backgroundColor: "#9e9e9e",
  },
}));

const Navigation = ({ isLoaded, menus }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const sessionUser = useSelector(state => state.session.sessionUser);
  const error = useSelector(state => state.error.error);
  const gsendBaskets = useSelector(state => state.gsendBasket.gsendBaskets);

  const [openBasket, setOpenBasket] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // const selectSecurityOpenGClientList = (service, gclientId) => dispatch(securityActions.selectSecurityOpenGClientList(service, gclientId))
  // const selectSecurityCloseGClientList = (service, gclientId) => dispatch(securityActions.selectSecurityCloseGClientList(service, gclientId))
  // const selectOpenedAboutMeGClientList = (service, gclientId) => dispatch(securityActions.selectOpenedAboutMeGClientList(service, gclientId))

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = (e) => {
    e.preventDefault();
    
    dispatch(sessionActions.logout(sessionUser));
    dispatch(menuActions.removeAllTabMenus());
  };
  
  const handleClickProfile = () => {
    const profileMenu = {
      menu: {
        href: "/profile",
        id: "profile",
        title: "내 정보",
      }
    }

    dispatch(menuActions.setSelectedMenu(profileMenu));
      
    dispatch(menuActions.addTabMenu(profileMenu.menu));

    navigate("/profile");
  }

  const handleClickInbox = () => {
    setOpenBasket(true);
  }

  // 전체 모듈에서 사용
  // useEffect(
  //   () => {
  //     const gclientId = sessionUser?.id;
  //     if (gclientId) {
  //       const service = '04docu';
  //       // selectSecurityOpenGClientList(service, gclientId);
  //       // selectSecurityCloseGClientList(service, gclientId);
  //       selectOpenedAboutMeGClientList(service, gclientId);
  //     }
  //   }, [sessionUser]
  // )

  return (
    <AppBar
      position="static"
      // TODO : style 적용하는 방법(customize) 여러가지가 있으므로 확인할 것
      sx={{
        minHeight: 68.5,
        bgcolor: '#F0F0F0',
        color: '#000',
      }}
    >
      {/* <Container maxWidth="xl"> */}
      <Container maxWidth="false">
        <Toolbar disableGutters>
          {/* mui Link의 href는 reloading 됨 */}
          {/* <Link href="/"> */}
          <RouterLink to="/" style={{ textDecoration: 'none', width: 300 }}>
            <Grid container sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              <Grid item sm={6} display="flex" alignItems="center">
                <img src="/kfgwa-logo.png" width="50%"/>
              </Grid>
              <Grid item sm={6} display="flex" alignItems="center" sx={{ ml: -8, color: '#212121', fontWeight: 'bold' }}>
                {/* <span style={{ color: '#29549E', fontSize: '1.2rem', fontWeight: 'bold' }}> */}
                  {"공사다큐"}
                {/* </span> */}
              </Grid>
              {/* <Grid item sm={6} display="flex" justifyContent="flex-start" alignItems="center">
                <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ mr: 2, color: '#212121', display: { xs: 'none', md: 'flex' } }}
                >
                  {"공사다큐"}
                </Typography>
              </Grid> */}
            </Grid>
          </RouterLink>
          {/* </Link> */}
          {/* xs: 'none'은 모바일 낮은 해상도에서 메뉴 숨김 */}
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
          {
            sessionUser && menus.map((menu, index) => {
              return (
                <CascadingHoverMenus key={index} menu={menu} />
              )
            })
          }
          </Box>
          {/* TODO : 해상도가 낮은 경우 Accordion 메뉴 형식으로 좌측 Drawer 형식으로 띄울 것*/}
          {/* <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
          {

          }
          </Box> */}
          {
            sessionUser && (<Box sx={{ flexGrow: 0 }}>
              <Tooltip title={"전송바구니"}>
                <StyledBadge badgeContent={gsendBaskets.length ? gsendBaskets.length : "0"/* 숫자형태의 0이면 나타나지 않음 */} onClick={handleClickInbox} sx={{ cursor: 'pointer' }}>
                  <IconButton onClick={handleClickInbox}>
                    <Inbox color="action" />
                  </IconButton>
                </StyledBadge>
              </Tooltip>
            </Box>)
          }
          <Box sx={{ flexGrow: 0 }}>
          {
            sessionUser && (
              <>
                {/* <Tooltip title="Account settings"> */}
                  <IconButton
                    onClick={handleClick}
                    // size="large"
                    sx={{ ml: 2 }}
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    // color="inherit"
                  >
                    <AccountCircle />
                  </IconButton>
                {/* </Tooltip> */}
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleClose}
                  onClick={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      mt: 1.5,
                      '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                  {/* <MenuItem component={Link} href={"/profile"}>
                    <Avatar /> {"내 정보"}
                  </MenuItem> */}
                  <MenuItem
                    component={Link}
                    onClick={handleClickProfile}>
                    <Avatar /> {"내 정보"}
                  </MenuItem>
                  <Divider />
                  {/* TODO : 사용자 계정 임시 주석 : 추후 기능구현해야 함 */}
                  {/* {
                    sessionUser && sessionUser.bizRegNumber && (
                      <MenuItem component={Link} href={"/userAccount"}>
                        <ListItemIcon>
                          <ManageAccounts fontSize="small" />
                        </ListItemIcon>
                        {"사용자 계정"}
                      </MenuItem>
                    )
                  } */}
                  {/* <MenuItem>
                    <ListItemIcon>
                      <Settings fontSize="small" />
                    </ListItemIcon>
                    {"Settings"}
                  </MenuItem> */}
                  {/**
                   * 서버에서 session이 없어진 상태는 error가 설정된 상태이므로(그리고 ErrorPage가 로딩된 상태) 그럴 경우 아래처럼 "/" 이동하면 App에서의 dispatch useEffect에서 restoreUser or restoreSessionUser를 통해 다시 조회하여 signin로 이동
                   * 그렇지 않은 경우 dispatch(sessionActions.logout());를 하면 App의 Route를 통해 signin으로 이동*/}
                  {
                    error ? (
                    <MenuItem component={Link} href={"/"}>
                      <ListItemIcon>
                        <Logout fontSize="small" />
                      </ListItemIcon>
                      {"로그아웃"}
                    </MenuItem>
                    ) : (
                      <MenuItem onClick={logout}>
                        <ListItemIcon>
                          <Logout fontSize="small" />
                        </ListItemIcon>
                        {"로그아웃"}
                      </MenuItem>
                    )
                  }
                </Menu>
              </>
            )
          }
          {/* 로그인 화면에서는 AppBar의 로그인 버튼이 보이지 않도록 함 */}
          {
            // TODO : 추후 sessionUser에 따라 signin 위치가 다름
            // sessionUser === undefined && location.pathname !== '/signin' && (
            //   <Button color="inherit" href={"/signin"} startIcon={<Login />}>{"로그인"}</Button>
            // )

            // 회원사 관리자 또는 회원사 사용자 로그인 화면에서 서로간 이동할 수 있는 링크가 있으므로 아래 생략
            // sessionUser === undefined && location.pathname !== '/glass/signin' && (
            //   <Button color="inherit" href={"/glass/signin"} startIcon={<Login />}>
            //     <Typography varaint="body2" sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            //       {"로그인 유형 선택"}
            //     </Typography>
            //   </Button>
            // )
          }
          </Box>
        </Toolbar>
        <SendBasketDialog
          open={openBasket}
          setOpen={setOpenBasket}
        />
      </Container>
    </AppBar>
  )  
}

// TODO : 문제 없는지 모니터링할 것
// export {
//   Navigation,
//   NavigationGuard,
// };
export default Navigation;