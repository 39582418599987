import { csrfFetch } from "./csrf.js";
import { gcoreServerUrl } from '../config';

const SET_GCOMPONENT = 'gcomponent/setGComponent';
const REMOVE_GCOMPONENT = "gcomponent/removeGComponent";
const SET_GCOMPONENTS = 'gcomponent/setGComponents';
const INIT_GCOMPONENTS_CHILD = 'gcomponent/initGComponentsChild';
const SET_GCOMPONENTS_CHILD = 'gcomponent/setGComponentsChild';
const SET_GCOMPONENTS_TREE = 'gcomponent/setGComponentsTree';
const SET_GCOMPONENTS_TREE_FIND = 'gcomponent/setGComponentsTreeFind';
const SET_GCOMPONENTS_TOP_LEVEL = 'gcomponent/setGComponentsTopLevel';
const SET_GCOMPONENTS_SELECT_COUNT = 'gcomponent/setGComponentsSelectCount';
const INIT_GCOMPONENTS_SELECT_COUNT = 'gcomponent/initGComponentsSelectCount';
const SET_GCOMPONENTS_ALL_SELECT_COUNT = 'gcomponent/setGComponentsAllSelectCount';
const SET_GCOMPONENTS_SELECTED_FOR_GGLASS = 'gcomponent/setGComponentsSelectedForGGlass';
const INIT_GCOMPONENTS_SELECTED_FOR_GGLASS = 'gcomponent/initGComponentsSelectedForGGlass';

const setGComponent = (gcomponent) => ({
  type: SET_GCOMPONENT,
  payload: gcomponent,
});

const removeGComponent = () => ({
  type: REMOVE_GCOMPONENT,
});

const setGComponents = (gcomponents) => ({
  type: SET_GCOMPONENTS,
  payload: gcomponents,
});

// setGComponentsChild에 빈 배열([])을 설정해도 되지만 조회 후 데이터가 없음이 아닌 초기화라고 명확하게 표현하는 게 좋을 것 같음
const initGComponentsChild = () => ({
  type: INIT_GCOMPONENTS_CHILD,
});

const setGComponentsChild = (componentsChild) => ({
  type: SET_GCOMPONENTS_CHILD,
  payload: componentsChild,
});

const setGComponentsTree = (gcomponentsTree) => ({
  type: SET_GCOMPONENTS_TREE,
  payload: gcomponentsTree,
});

const setGComponentsTreeFind = (gcomponentsTreeFind) => ({
  type: SET_GCOMPONENTS_TREE_FIND,
  payload: gcomponentsTreeFind,
});

const setGComponentsTopLevel = (gcomponentsTopLevel) => ({
  type: SET_GCOMPONENTS_TOP_LEVEL,
  payload: gcomponentsTopLevel,
});

const setGComponentsSelectCount = () => ({
  type: SET_GCOMPONENTS_SELECT_COUNT,
});

const initGComponentsSelectCount = () => ({
  type: INIT_GCOMPONENTS_SELECT_COUNT,
});

const setGComponentsAllSelectCount = (count) => ({
  type: SET_GCOMPONENTS_ALL_SELECT_COUNT,
  payload: count,
});

const setGComponentsSelectedForGGlass = (gcomponent) => ({
  type: SET_GCOMPONENTS_SELECTED_FOR_GGLASS,
  payload: gcomponent,
})

const initGComponentsSelectedForGGlass = () => ({
  type: INIT_GCOMPONENTS_SELECTED_FOR_GGLASS,
})

export const create = ({ id, name, code, /*gunitId, */comments, pid, type, multipleYN }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gcomponents`, {
    method: "POST",
    body: JSON.stringify({ id, name, code, /*gunitId, */comments, pid, type, multipleYN }),
  });
  const data = await response.json();
  dispatch(setGComponent(data.gcomponent));
  return response;
};

export const modify = ({ id, name, code, /*gunitId, */comments, pid, type, multipleYN }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gcomponents`, {
    method: "PUT",
    body: JSON.stringify({ id, name, code, /*gunitId, */comments, pid, type, multipleYN }),
  });
  const data = await response.json();
  dispatch(setGComponent(data.gcomponent));
  return response;
};

export const modifyType = ({ id, type }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gcomponents/type`, {
    method: "PUT",
    body: JSON.stringify({ id, type }),
  });
  const data = await response.json();
  dispatch(setGComponent(data.gcomponent));
  return response;
};

export const modifyMultipleYN = ({ id, multipleYN }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gcomponents/multipleYN`, {
    method: "PUT",
    body: JSON.stringify({ id, multipleYN }),
  });
  const data = await response.json();
  dispatch(setGComponent(data.gcomponent));
  return response;
};

export const selectAll = () => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gcomponents`);
  const data = await response.json();
  dispatch(setGComponents(data.gcomponents));
  return response;
}

export const selectByType = (type) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gcomponents/type/${type}`);
  const data = await response.json();
  dispatch(setGComponents(data.gcomponents));
}

export const selectAllByQuery = () => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gcomponents/query/selectAll`);
  const data = await response.json();
  dispatch(setGComponentsTree(data.gcomponentsTree));
  return response;
}


export const selectAllByQueryFind = (id) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gcomponents/query/selectAllFind/${id}`);
  const data = await response.json();
  dispatch(setGComponentsTreeFind(data.gcomponentsTreeFind));
  return response;
}

export const select = (id) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gcomponents/${id}`);
  const data = await response.json();
  dispatch(setGComponent(data.gcomponent));
  return response;
}

export const initializeGComponentsChild = () => async dispatch => {
  dispatch(initGComponentsChild());
}

export const selectByPid = (pid) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gcomponents/child/${pid}`);
  const data = await response.json();
  dispatch(setGComponentsChild(data.gcomponentsChild));
  return response;
}

export const selectByPidDirect = async (pid) => {
  const response = await csrfFetch(null, `${gcoreServerUrl}/api/gcomponents/child/${pid}`);
  const data = await response.json();
  return data.gcomponentsChild;
}

export const remove = (id) => async (dispatch) => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gcomponents/${id}`, {
    method: "DELETE",
  });
  dispatch(removeGComponent());
  return response;
};

export const selectAllTopLevel = () => async (dispatch) => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gcomponents/query/selectAllTopLevel`);
  const data = await response.json();
  dispatch(setGComponentsTopLevel(data.gcomponentsTopLevel));
  return response;
}

// TODO : 필요없어보여 주석 처리. 검토하고 삭제하면 서버쪽도 삭제할 것
// export const selectGcomponentsByGtypeByQuery = (gtypeDetails) => async dispatch => {
//   const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gcomponents/query/selectGcomponentsByGtypeByQuery`, {
//     method: "POST",
//     body: JSON.stringify(gtypeDetails),
//   });
//   const data = await response.json();
//   // dispatch(setGComponent(data.gcomponentsByGtype));
//   return response;
// }

export const increaseGComponentsSelectCount = () => async (dispatch) => {
  dispatch(setGComponentsSelectCount());
}

export const initializeGComponentsSelectCount = () => async (dispatch) => {
  dispatch(initGComponentsSelectCount());
}

export const countFindChildAllIncludeMeByQuery = (gtypeId) => async (dispatch) => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gcomponents/query/countFindChildAllIncludeMeByQuery/${gtypeId}`);
  const data = await response.json();
  dispatch(setGComponentsAllSelectCount(data.count));
  return response;
}

export const addGComponentsSelectedForGGlass = (gcomponent) => async (dispatch) => {
  dispatch(setGComponentsSelectedForGGlass(gcomponent));
}

export const initializeGComponentsSelectedForGGlass = () => async (dispatch) => {
  dispatch(initGComponentsSelectedForGGlass());
}

export const reorder = async ({ type, id }) => {
  const response = await csrfFetch(null, `${gcoreServerUrl}/api/gcomponents/reorder`, {
    method: "POST",
    body: JSON.stringify({ type, id }),
  });
  
  return response;
}

const initialState = {
  gcomponent: null,
  gcomponents: [],
  gcomponentsTree: [],
  gcomponentsTreeFind: [],
  gcomponentsChild: [],
  gcomponentsTopLevel: [],
  gcomponentsSelectCount: 0,
  gcomponentsAllSelectCount: 0,
  gcomponentsSelectedForGGlass: [],
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case SET_GCOMPONENT:
      newState = Object.assign({}, state, { gcomponent: action.payload });
      return newState;
    case REMOVE_GCOMPONENT:
      newState = Object.assign({}, state, { gcomponent: null });
      return newState;
    case SET_GCOMPONENTS:
      newState = Object.assign({}, state, { gcomponents: action.payload });
      return newState;
    case SET_GCOMPONENTS_TREE:
      newState = Object.assign({}, state, { gcomponentsTree: action.payload });
      return newState;
    case SET_GCOMPONENTS_TREE_FIND:
      newState = Object.assign({}, state, { gcomponentsTreeFind: action.payload });
      return newState;
    case INIT_GCOMPONENTS_CHILD:
      newState = Object.assign({}, state, { gcomponentsChild: [] });
      return newState;
    case SET_GCOMPONENTS_CHILD:
      newState = Object.assign({}, state, { gcomponentsChild: action.payload });
      return newState;
    case SET_GCOMPONENTS_TOP_LEVEL:
      newState = Object.assign({}, state, { gcomponentsTopLevel: action.payload });
      return newState;
    case SET_GCOMPONENTS_SELECT_COUNT:
      const count = state.gcomponentsSelectCount + 1;
      newState = Object.assign({}, state, { gcomponentsSelectCount: count });
      return newState;
    case INIT_GCOMPONENTS_SELECT_COUNT:
      newState = Object.assign({}, state, { gcomponentsSelectCount: 0 });
      return newState;
    case SET_GCOMPONENTS_ALL_SELECT_COUNT:
      newState = Object.assign({}, state, { gcomponentsAllSelectCount: action.payload });
      return newState;
    case SET_GCOMPONENTS_SELECTED_FOR_GGLASS:
      const { pGcomponentId, prev, next } = action.payload;
      
      // 같은 요소의 이전 선택한 값과 같은 것은 삭제
      const selectedComponents = state.gcomponentsSelectedForGGlass.filter(component => {
        if (component.pGcomponentId !== pGcomponentId ) {
          return true;
        } else if (component.pGcomponentId === pGcomponentId && component.next == prev) {
          return false;
        } 
        
        return true;
      });

      // 없을 경우만 추가
      const duplicated = selectedComponents.filter(component => component.pGcomponentId === pGcomponentId && component.next == next);
      if (duplicated.length === 0) {
        selectedComponents.push({pGcomponentId, prev, next });
      }
      
      newState = Object.assign({}, state, { gcomponentsSelectedForGGlass: selectedComponents });
      return newState;
    case INIT_GCOMPONENTS_SELECTED_FOR_GGLASS:
      newState = Object.assign({}, state, { gcomponentsSelectedForGGlass: [] });
      return newState;
    default:
      return state;
  }
}

export default reducer;
