import React from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { Controller } from "react-hook-form";

const FormInputDropdown = ({ name, control, label, options, onChangeSelectValue, ...props}) => {
  const generateSingleOptions = () => {
    return options && options.map((option) => {
      return (
        <MenuItem key={option.value} value={option.value} sx={{ bgcolor: option?.bgcolor ? option.bgcolor : "", color: option?.color ? option.color : "" }}>
          {option.label}
        </MenuItem>
      );
    });
  };

  // TODO : defaultValue 설정하는 방법 강구할 것
  return (
    <FormControl size={"small"} fullWidth>
      <InputLabel>{label}</InputLabel>
      <Controller
        name={name}
        control={control}
        render={({
          field: { onChange, value },
          fieldState: { error },
          formState,
        }) => (
          <Select
            // onChange={onChange}
            onChange={
              (event) => {
                console.log(event)
                onChange(event);
                onChangeSelectValue && onChangeSelectValue(event, value); // oldValue임에 유의. event.target.value는 newValue
              }
            }
            value={value}
            // label이 없으면 select의 외곽선과 InputLabel이 겹침
            label={label}
            {...props}
          >
            {generateSingleOptions()}
          </Select>
        )}
      />
    </FormControl>
  );
}

export default FormInputDropdown;