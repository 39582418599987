import React, { useEffect, useState } from 'react';

import './index.css';

import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from 'react-router-dom';
import { DragDropContext } from 'react-beautiful-dnd';

import { ModalProvider } from './context/Modal';
import App from './App';

import configureStore from './store';
import { restoreCSRF, csrfFetch } from "./store/csrf";
import * as sessionActions from './store/session';

// import ReactDataGrid from 'react-data-grid';

const { store, persistor } = configureStore();

if (process.env.NODE_ENV !== "production") {
  restoreCSRF();

  window.csrfFetch = csrfFetch;
  window.store = store;
  window.sessionActions = sessionActions;
}

function Root() {
  const [items, setItems] = useState([]);

  useEffect(() => setItems(getItems(50)), [])

  const getItems = count =>
    Array.from({ length: count }, (v, k) => k).map(k => ({
      id: `item-${k}`,
      content: `item ${k}`
    }));

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  
  const onDragEnd = ({ destination, source }) => {
    // // dropped outside the list
    // if (!destination) {
    //   return;
    // }

    // const items = reorder(
    //   items,
    //   source.index,
    //   destination.index
    // );

    // setItems(items);
  };

  return (
    <ModalProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <DragDropContext onDragEnd={onDragEnd}>
            <App />
          </DragDropContext>
        </BrowserRouter>
        </PersistGate>
      </Provider>
    </ModalProvider>
  );
}

// 환경 변수 출력
console.log('============ 환경 변수 ===========');
console.log('현재 환경 :', process.env.NODE_ENV);
Object.keys(process.env)
  .filter(key => key.startsWith('REACT_APP_'))
  .forEach(key => {
    console.log(`${key}: ${process.env[key]}`);
  });
console.log('===================================');

ReactDOM.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
  document.getElementById('root')
);
