import Cookies from "js-cookie";
import * as errorActions from "./error";
import * as sessionActions from "./session";
import * as menuActions from "./menu";

import { mode } from '../config';

export async function csrfFetch(dispatch, url, options = {}) {
  options.method = options.method || "GET"; // set options.method to 'GET' if there is no method
  options.headers = options.headers || {}; // set options.headers to an empty object if there is no headers

  // if the options.method is not 'GET', then set the "Content-Type" header to "application/json", and set the "CSRF-TOKEN" header to the value of the "XSRF-TOKEN" cookie
  if (options.method.toUpperCase() !== "GET") {
    options.headers["Content-Type"] = options.headers["Content-Type"] || "application/json";
    options.headers["XSRF-Token"] = Cookies.get("XSRF-TOKEN");
  }

  const accessToken = sessionStorage.getItem('accessToken');
  if (accessToken) {
    options.headers['Authorization'] = `Bearer ${accessToken}`;
  }
  
  // console.log(options)
  let res = await window.fetch(url, options); // call the default window's fetch with the url and the options passed in

  /**
   * 아래 API 오류 처리 방식
   * 1. status 400이상인 경우만 오류처리
   * 2. 토큰 만료 등 토큰 관련 오류일 경우 다시 API 호출
   * 3. 로그인이나 가입시 중복 오류일 경우 각 화면에서 오류처리
   * 4. 그 외 errorActions.occurError 호출하여 에러페이지(ErrorPage) 처리
   */
  if (res.status >= 400) { // 오류 처리 방식 1
    // 아래의 serverResponse(await res.json())는 core 서버의 Error formatter(app.use에서 처리)에서 res.json으로 보내는 내용임
    // res.json({
    //   title: err.title || 'Server Error',
    //   message: err.message,
    //   errors: err.errors,
    //   stack: isProduction ? null : err.stack,
    //   details: err.details || null,
    // });
    const serverResponse = await res.json(); // await 안해서 Promise를 리턴받고 고생했었음

    if (mode.indexOf('java')) {
      /**
       * 인증관련: 로그인 및 access token.
       * REQUIRE_LOGIN(HttpStatus.UNAUTHORIZED, 11001, "require login"),
       * EXPIRED_TOKEN(HttpStatus.UNAUTHORIZED, 11002, "Token이 만료되었습니다."),
       * INVALID_TOKEN(HttpStatus.UNAUTHORIZED, 11003, "유효하지 않은 토큰입니다."),
       * NOT_EXIST_TOKEN_USER(HttpStatus.UNAUTHORIZED, 11004, "사용자 정보를 조회할 수 없습니다."),
       * FAIL_INIT_PASSWORD(HttpStatus.UNAUTHORIZED, 11005, "암호 초기화 처리를 하지 못했습니다." ,4 , "informational"),
       * FAIL_CHANGE_PASSWORD(HttpStatus.UNAUTHORIZED, 11006, "암호를 변경하지 못했습니다." ,4 , "informational"),
       * LOGIN_FAIL(HttpStatus.UNAUTHORIZED, 20024, "로그인 정보가 올바르지 않습니다.", 3, "informational"),
       */
      console.log(serverResponse)
      const { code } = serverResponse;
      if (code === 11002) { // 토큰 만료
        await dispatch(sessionActions.refreshAccessToken());
      } else if (code === 11003) {
      } else if (code === 11001) {
        // 로그아웃할 것 처리
        dispatch(sessionActions.removeSessionUser());
        dispatch(menuActions.removeAllTabMenus());
      } else if (
        code === 10010 || // 이메일 발송 오류(이메일 SMTP 계정이 맞지않아 이메일 발송을 실패했습니다.)
        code === 20024 || // 계정 불일치
        code === 20026 || // 탈퇴후3개월이내
        code === 42001 // {"title":"Add GSendBasket Error","message":"이미 추가된 문서입니다."}
      ) {
        throw serverResponse;
      } else { // code === 99999
        console.log({ url })
        
        // 10002 MISSING_REQUIRED_PARAMETER : Array 메시지 처리
        // details[0] "field": "password", "error": "비밀번호는 최소 8자 이상 최대 20자 이하로 설정할 수 있습니다."
        if (serverResponse?.details != null && serverResponse?.details.length > 0) { 
          serverResponse.message = serverResponse?.details[0].error;
          //console.log(serverResponse)
        } 

        // 서버 500 오류 처리
        if (res.status===500 && dispatch) { // ~Direct의 경우 dispatch가 null이므로 아래 함수가 호출되지 않아(ErrorPage 노출하지 않고) 오류를 Snackbar형태로 처리하려면 화면에서 처리(아래를 직접 호출)
          await dispatch(errorActions.occurError({ response: res, serverResponse })); // 오류 처리 방식 4
        }
        
        throw ({ response: res, serverResponse });
      }
    } else {
      // TODO : accessToken 만료이면 토큰 refresh 요청. 아래 함수화할 것
      if (serverResponse?.details?.name === "TokenExpiredError" && serverResponse?.details?.type === "AccessToken") { // 오류 처리 방식 2
        await dispatch(sessionActions.refreshAccessToken());
        
        const accessToken = sessionStorage.getItem('accessToken');
        if (accessToken) {
          options.headers['Authorization'] = `Bearer ${accessToken}`;
          res = await window.fetch(url, options);
        }
      } else {
        if (serverResponse?.details?.name === "LoginError" || serverResponse?.details?.name === "AddUserError") { // 오류 처리 방식 3
          throw serverResponse;
        }/* else if (serverResponse?.details?.name === "PasswordChangeError") { // TODO : 주석처리한 코드 체크 필요
          throw serverResponse;
        } */ else {
          if (dispatch) { // ~Direct의 경우 dispatch가 null이므로 아래 함수가 호출되지 않아(ErrorPage 노출하지 않고) 오류를 Snackbar형태로 처리하려면 화면에서 처리(아래를 직접 호출)
            await dispatch(errorActions.occurError({ response: res, serverResponse })); // 오류 처리 방식 4
          }
          throw ({ response: res, serverResponse });
        }
      }
    }
    
  }
  
  return res; // if the response status code is under 400, then return the response to the next promise chain
}

export function restoreCSRF() {
  return csrfFetch(null, "/api/csrf/restore");
  // return csrfFetch(null, `${authServerUrl}/api/csrf/restore`);
}
