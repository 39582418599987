import { csrfFetch } from "./csrf.js";
import { gcoreServerUrl } from '../config';

const SET_GUNIT = 'gunit/setGUnit';
const REMOVE_GUNIT = "gunit/removeGUnit";
const SET_GUNITS = 'gunit/setGUnits';

const setGUnit = (gunit) => ({
  type: SET_GUNIT,
  payload: gunit,
});

const removeGUnit = () => ({
  type: REMOVE_GUNIT,
});

const setGUnits = (data) => ({
  type: SET_GUNITS,
  payload: data,
});

export const create = ({ name, code, type, comments }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gunits`, {
    method: "POST",
    body: JSON.stringify({ name, code, type, comments }),
  });
  const data = await response.json();
  dispatch(setGUnit(data.gunit));
  return response;
};

export const modify = ({ id, name, code, type, comments }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gunits`, {
    method: "PUT",
    body: JSON.stringify({ id, name, code, type, comments }),
  });
  const data = await response.json();
  dispatch(setGUnit(data.gunit));
  return response;
};

export const selectAll = () => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gunits`);
  const data = await response.json();
  dispatch(setGUnits(data)); // data 임에 유의
  return response;
}

export const selectPaging = (queryPaging) => async dispatch => {
  const { page, pageSize } = queryPaging;
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gunits/offset/${page}/limit/${pageSize}`);
  const data = await response.json();
  dispatch(setGUnits(data));
  return response;
}

export const selectAllByType = (type) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gunits/type/${type}`);
  const data = await response.json();
  dispatch(setGUnits(data));
  return response;
}

export const selectAllByTypeDirect = async (type) => {
  const response = await csrfFetch(null, `${gcoreServerUrl}/api/gunits/type/${type}`);
  const data = await response.json();
  return data.gunits;
}

export const select = (id) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gunits/${id}`);
  const data = await response.json();
  dispatch(setGUnit(data.gunit));
  return response;
}

export const initGUnit = () => async dispatch => {
  dispatch(setGUnit(null));
}

export const remove = (id) => async (dispatch) => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gunits/${id}`, {
    method: "DELETE",
  });
  dispatch(removeGUnit());
  return response;
};

export const reorder = async ({ type, id }) => {
  const response = await csrfFetch(null, `${gcoreServerUrl}/api/gunits/reorder`, {
    method: "POST",
    body: JSON.stringify({ type, id }),
  });
  
  return response;
}

const initialState = {
  gunit: null,
  gunits: [],
  count: 0,
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case SET_GUNIT:
      newState = Object.assign({}, state, { gunit: action.payload });
      return newState;
    case REMOVE_GUNIT:
      newState = Object.assign({}, state, { gunit: null });
      return newState;
    case SET_GUNITS:
      newState = Object.assign({}, state, { gunits: action.payload.gunits, count: action.payload.count });
      return newState;
    default:
      return state;
  }
}

export default reducer;
