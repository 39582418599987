import React from 'react';
import { useDispatch, useSelector } from "react-redux";

// 자체 컴포넌트
import { AlertDialog } from "../dialog";

// store actions
import * as alertDialogActions from "../../store/components/alertDialog";

const GDomesticAuthAlertDialog = ({ }) => {
  const dispatch = useDispatch();

  const options = useSelector((state) => state.alertDialog.options);

  const setOptions = (options) => dispatch(alertDialogActions.setOptions(options));
  
  return (
    <AlertDialog
      alertInfo={options?.alertInfo}
      setAlertInfo={() => setOptions({ alertInfo : { ...options?.alertInfo, open: false } })}
    />
  )
}

export default GDomesticAuthAlertDialog;