import React from 'react';
import {
  Box,
  Container,
  Grid,
  Typography,
} from '@mui/material';

const CopyrightKFGWA = (props) => {
  return (
    <Container component="main" maxWidth="false" sx={{ mt: 10, bgcolor: "#F0F0F0" }}>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Grid container maxWidth="lg">
          <Grid item xs={12} display="flex" justifyContent={"center"}>
            <Grid container spacing={4} sx={{ p: 4 }}>
              <Grid item xs={12} sm={2} display="flex" justifyContent={"center"} alignItems="center">
                <a href="http://www.flatglass.or.kr" target="_blank" rel="noreferrer">
                  <img src="/kfgwa_footer_logo.png"/>
                </a>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2" sx={{ color: '#BBBBBB' }}>
                  {"(사)한국판유리창호협회, (T) 02-3453-7991, (F) 02-3453-0550"}
                </Typography>
                <Typography variant="subtitle2" sx={{ color: '#BBBBBB' }}>
                  {"본사 : 서울시 마포구 서강로 136, 아이비타워 8층 (노고산동)"}
                </Typography>
                <Typography variant="subtitle2" sx={{ color: '#A2A2A2' }}>
                  {"copyright© "}{new Date().getFullYear()}{" KFGWA, All RIGHT RESERVED"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4} display="flex" justifyContent={"center"} alignItems="center">
                <Grid container>
                  <Grid item xs={12} sm={3} display="flex" justifyContent={"center"} alignItems="center">
                    <img src="/kfgwa_footer_call.png" />
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <span>{"대표전화"}&nbsp;</span><span style={{ fontSize: '0.8rem', color: '#BBBBBB' }}>{" | CUSTOMER CENTER"}</span>
                    <Typography variant="h5" sx={{ mt: 1, borderTop: 1, pt: 1 }}>
                      {"02-3453-7991"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}

export default CopyrightKFGWA;