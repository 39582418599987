import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Fab,
  FormControlLabel,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import {
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { v4 as uuidv4 } from 'uuid';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "../accordion";
import {
  FormInputText,
} from "../form";
import {
  AlertDialog,
  ConfirmDialog,
} from "../dialog";
import * as gprojectActions from "../../store/gproject";
import * as glineActions from "../../store/gline";
import * as sessionActions from "../../store/session";
import * as userActions from "../../store/user";
import { hideWatermark } from "../../utils";
import TextMaskCustom from "../../components/form/inputProps/TextMaskCustom"

const theme = createTheme();

const defaultValues = {

};

const User = () => {

  const sessionUser = useSelector(state => state.session.sessionUser);
  const glines = useSelector((state) => state.gline.glines);
  const gprojects = useSelector((state) => state.gproject.gprojects);

  const { control } = useForm({ defaultValues });
  
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [users, setUsers] = useState([]);
  const [usersByGClient, setUsersByGClient] = useState([]);
  const [alertInfo, setAlertInfo] = useState({});
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [removeObject, setRemoveObject] = useState({});
  const [params, setParams] = useState({});

  // 데이터 관리
  const dispatch = useDispatch();
  
  const selectGLinesByQuery = () => dispatch(glineActions.selectAllByQuery())
  const selectGProjectsByQuery = (ownerId) => dispatch(gprojectActions.selectAllByOwnerByQuery(ownerId))
  const signupUserDirect = ({ id, userId, password, gclientId, belongs }) => sessionActions.signupUserDirect({ id, userId, password, gclientId, belongs })
  const selectAllByGClientDirect = (gclientId) => userActions.selectAllByGClientDirect(gclientId)
  const deleteUserByQueryDirect = ({ userId, gclientId, belongs }) => userActions.removeByQueryDirect({ userId, gclientId, belongs })

  useEffect(
    async () => {
      setOpenBackdrop(true);

      const resUsersByGClient = await selectAllByGClientDirect(sessionUser.id);
      setUsersByGClient(resUsersByGClient)
      // 가공업체는 공정라인별 계정 필요
      await selectGLinesByQuery();
      // 시공팀은 진행중인 프로젝트별 계정 필요. sessionUser가 GClient인 경우만 이 화면에 들어오므로 sessionUser.gclient.id인 경우는 없음
      await selectGProjectsByQuery(sessionUser.id);

      setOpenBackdrop(false);
    }, [dispatch]
  )

  useEffect(
    async () => {
      const newUsers = glines.map((gline, i) => {
        // gclientId로 Users에서 검색해 온 후 users에 조건에 맞는 user 정보 설정
        const user = usersByGClient.find(user => user.belongs.type === 'GLINE' && user.belongs.glineId === gline.id);
        
        return {
          belongs: {
            type: 'GLINE',
            glineId: gline.id,
          },
          userId: user && user.userId || "",
          userPassword: user && user.password || "",
          userConfirmPassword: "",
          showUserPassword: false,
          showUserConfirmPassword: false,
          userExist: user && user.userId ? true : false,
          loadingSave: false,
          loadingDelete: false,
        }
      });

      console.log(newUsers);

      setUsers(users.concat(newUsers));
    }, [glines]
  )

  useEffect(
    () => {
      const myProjects = [];
      gprojects.forEach(gproject => {
        const { id, site, gclientDetails } = gproject;
        if (gclientDetails) {
          const buildTeams = gclientDetails.find(gclient => gclient.code === 'BUILD_TEAM');
          myProjects.push({
            id,
            site,
            buildTeams: buildTeams?.gclients?.map(gclient => gclient.value),
          });
        }
      });

      const newUsers = [];
      myProjects.forEach((myProject, i) => {
        myProject.buildTeams?.forEach((buildTeam, j) => {
        // gclientId로 Users에서 검색해 온 후 users에 조건에 맞는 user 정보 설정
        const user = usersByGClient.find(user => user.belongs.type === 'GPROJECT' && user.belongs.buildTeamId === buildTeam.id);

        newUsers.push({
            belongs: {
              type: 'GPROJECT',
              buildTeamId: buildTeam.id,
              gprojectId: myProject.id, 
            },
            userId: user && user.userId || "",
            userPassword: user && user.password || "",
            userConfirmPassword: "",
            showUserPassword: false,
            showUserConfirmPassword: false,
            userExist: user && user.userId ? true : false,
            loadingSave: false,
            loadingDelete: false,
          });
        });
      });

      console.log(newUsers);

      setUsers(users.concat(newUsers));
    }, [gprojects]
  )

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  
  const handleChangeUserAccount = (e, id, i, col, type) => {
    // console.log({ e, id, i, col, type});
    let iId;
    if (type === 'GLINE') {
      iId = "glineId";
    } else if (type === 'GPROJECT') {
      iId = "buildTeamId";
    }

    const newUsers = users.map((user, idx) => {
      if (user.belongs.type === type && user.belongs[iId] && user.belongs[iId] === id) {
        if (col === 'userId') {
          return {
            ...user,
            userId: e.target.value,
          }
        } else if (col === 'userPassword') {
          return {
            ...user,
            userPassword: e.target.value,
          }
        } else if (col === 'userConfirmPassword') {
          return {
            ...user,
            userConfirmPassword: e.target.value,
          }
        } else {
          return user;
        }
      }

      return user;
    });

    console.log(newUsers);
    setUsers(newUsers);
  }

  const handleClickSaveUser = async (e, id, i, type) => {
    let iId;
    if (type === 'GLINE') {
      iId = "glineId";
    } else if (type === 'GPROJECT') {
      iId = "buildTeamId";
    }

    const selectedUser = users.find(user => user.belongs.type === type && user.belongs[iId] === id);
    if (selectedUser) {
      if (selectedUser.userPassword !== selectedUser.userConfirmPassword) {
        setAlertInfo({
          titleAlert: "안내",
          messageAlert: "입력하신 비밀번호가 일치하지 않습니다.",
          open: true,
        });
        return;
      }

      // 버튼 로딩중
      const update = users.map((user, idx) => {
        if (user.belongs.type === type && user.belongs[iId] && user.belongs[iId] === id) {
          return {
            ...user,
            loadingSave: true,
          }
        }

        return user;
      });

      setUsers(update);
    
      const { userId, userPassword, belongs } = selectedUser;
      await signupUserDirect({
        id: uuidv4(),
        userId,
        password: userPassword,
        gclientId: sessionUser.id,
        belongs,
      });
    }

    // 해당 행의 정보 업데이트 (로딩 완료와 비밀번호 변경 모드)
    const newUsers = users.map((user, idx) => {
      if (user.belongs.type === type && user.belongs[iId] && user.belongs[iId] === id) {
        return {
          ...user,
          userExist: true,
          loadingSave: false,
        }
      }

      return user;
    });

    setTimeout(() => setUsers(newUsers), 1000);
  }

  const deleteUser = async ({ id, i, type, iId, selectedUser }) => {
    // 버튼 로딩중
    const update = users.map((user, idx) => {
      if (user.belongs.type === type && user.belongs[iId] && user.belongs[iId] === id) {
        return {
          ...user,
          loadingDelete: true,
        }
      }

      return user;
    });

    setUsers(update);

    const { userId, belongs } = selectedUser;
    await deleteUserByQueryDirect({
      userId,
      gclientId: sessionUser.id,
      belongs,
    });
    

    // 해당 행의 정보 초기화
    const newUsers = users.map((user, idx) => {
      if (user.belongs.type === type && user.belongs[iId] && user.belongs[iId] === id) {
        return {
          ...user,
          userId: "",
          userPassword: "",
          userConfirmPassword: "",
          userExist: false,
          loadingDelete: false,
        }
      }

      return user;
    });

    setTimeout(() => setUsers(newUsers), 1000);
  }
  
  const handleClickDeleteUser = (e, id, i, type) => {
    let iId;
    if (type === 'GLINE') {
      iId = "glineId";
    } else if (type === 'GPROJECT') {
      iId = "buildTeamId";
    }

    const selectedUser = users.find(user => user.belongs.type === type && user.belongs[iId] === id);

    setRemoveObject({ id, i, type, iId, selectedUser });
    setParams(selectedUser);
    setConfirmOpen(true);
  }

  const handleClickShowPassword = (id, col, type) => {
    // setShowPassword((show) => !show);
    let iId;
    if (type === 'GLINE') {
      iId = "glineId";
    } else if (type === 'GPROJECT') {
      iId = "buildTeamId";
    }

    const newUsers = users.map((user, idx) => {
      if (user.belongs.type === type && user.belongs[iId] && user.belongs[iId] === id) {
        if (col === 'userPassword') {
          return {
            ...user,
            showUserPassword: !user.showUserPassword,
          }
        } else if (col === 'userConfirmPassword') {
          return {
            ...user,
            showUserConfirmPassword: !user.showUserConfirmPassword,
          }
        } else {
          return user;
        }
      }

      return user;
    });

    console.log(newUsers);
    setUsers(newUsers);
  }

  const generateAccountsForGLines = () => {
    return (
      <Card sx={{ p: 2 }}>
        <Grid container spacing={2.5}>
          {
            glines.map((gline, i) => {
              const { id, name } = gline;
              
              // 라인 당 한 명의 유저만 등록
              return (
                <>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={2} display="flex" justifyContent="flex-end" alignItems="center" sx={{ ml: -3, mr: 1 }}>
                        <Typography variant="body" component="div">{name}</Typography>
                      </Grid>
                      {/* <Divider orientation="vertical" flexItem /> */}
                      <Grid item xs={4}>
                        <FormInputText
                          size="large"
                          name={`userId_${id}`}
                          control={control}
                          label={"사용자아이디(전화번호)"}
                          onChange={(e) => handleChangeUserAccount(e, id, i, 'userId', 'GLINE')}
                          disabled={users.find(user => user.belongs.glineId === id)?.userExist}
                          value={users.find(user => user.belongs.glineId === id)?.userId || ""}
                          InputProps={{
                            inputComponent: TextMaskCustom,  // TODO : 이렇게 바깥에서 설정할지 FormInputText 안으로 넣을지 고려할 것
                            inputProps: {
                              style: { // TODO : 넘기는 방법을 정확히 몰라 style에 실어 넘김
                                mask: "000-0000-0000",
                                definitions: {
                                  '#': /[1-9]/,
                                },
                              },
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <FormControl variant="outlined" sx={{ width: '100%' }}>
                          <InputLabel htmlFor="outlined-adornment-password">{"비밀번호"}</InputLabel>
                          <OutlinedInput
                            fullWidth
                            // sx={{
                            //   "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                            //     borderColor: "green"
                            //   },
                            //   "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                            //     borderColor: "red"
                            //   },                    
                            //   "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            //     borderColor: "purple"
                            //   },
                            // }}
                            // size="small"
                            id={`password_${id}`}
                            type={users.find(user => user.belongs.glineId === id)?.showUserPassword ? 'text' : 'password' }
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={(e) => handleClickShowPassword(id, 'userPassword', 'GLINE')}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {users.find(user => user.belongs.glineId === id)?.showUserPassword ? <VisibilityOff /> : <Visibility /> }
                                </IconButton>
                              </InputAdornment>
                            }
                            label="Password"
                            onChange={(e) => handleChangeUserAccount(e, id, i, 'userPassword', 'GLINE')}
                            value={users.find(user => user.belongs.glineId === id)?.userPassword || ""}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={2}>
                        <FormControl variant="outlined" sx={{ width: '100%' }}>
                          <InputLabel htmlFor="outlined-adornment-password" /*sx={{ mt: -1 }}*/>{"비밀번호 확인"}</InputLabel>
                          <OutlinedInput
                            fullWidth
                            // size="small"
                            id={`confirmPassword_${id}`}
                            type={users.find(user => user.belongs.glineId === id)?.showUserConfirmPassword ? 'text' : 'password'}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={(e) => handleClickShowPassword(id, 'userConfirmPassword', 'GLINE')}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {users.find(user => user.belongs.glineId === id)?.showUserConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            }
                            label="Password"
                            onChange={(e) => handleChangeUserAccount(e, id, i, 'userConfirmPassword', 'GLINE')}
                            value={users.find(user => user.belongs.glineId === id)?.userConfirmPassword || ""}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={2} display="flex">
                        <LoadingButton
                          loading={users.find(user => user.belongs.glineId === id)?.loadingSave}
                          onClick={(e) => handleClickSaveUser(e, id, i, 'GLINE')}
                        >
                          {users.find(user => user.belongs.glineId === id)?.userExist ? "비밀번호 변경" : "등록"}
                        </LoadingButton>
                        <LoadingButton
                          loading={users.find(user => user.belongs.glineId === id)?.loadingDelete}
                          onClick={(e) => handleClickDeleteUser(e, id, i, 'GLINE')}>
                          {"삭제"}
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </Grid>
                  { i !== glines.length-1 && <Grid item xs={12}><Divider variant="middle" /></Grid> }
                </>
              )
            })
          }
        </Grid>
      </Card>
    )
  }

  const generateAccountsForGProjects = () => {
    const myProjects = [];
    gprojects.forEach(gproject => {
      const { id, site, gclientDetails } = gproject;
      if (gclientDetails) {
        const buildTeams = gclientDetails.find(gclient => gclient.code === 'BUILD_TEAM');
        myProjects.push({
          projectId: id,
          site,
          buildTeams: buildTeams.gclients.map(gclient => gclient.value),
        });
      }
    });
      
    console.log(myProjects)
    return myProjects.map((myProject, i) => {
      const { site, buildTeams } = myProject;
      return (
        <Card sx={{ mb: i !== myProjects.length-1 ? 2 : 0 }}>
          <CardHeader
            titleTypographyProps={{ variant: 'subtitle1' }}
            title={site}
            sx={{ bgcolor: "#eaeaea" }}
          />
          <CardContent>
            <Grid container spacing={2.5} sx={{ mt: -0.4 }}>
            {
              buildTeams.map((buildTeam, j) => {
                const { id, name } = buildTeam;
                
                // 거래선 당 한 명의 유저만 등록
                return (
                  <>
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={2} display="flex" justifyContent="flex-end" alignItems="center" sx={{ ml: -3, mr: 1 }}>
                          <Typography variant="body" component="div">{name}</Typography>
                        </Grid>
                        {/* <Divider orientation="vertical" flexItem /> */}
                        <Grid item xs={4}>
                          <FormInputText
                            size="large"
                            name={`userId_${id}`}
                            control={control}
                            label={"사용자아이디(전화번호)"}
                            onChange={(e) => handleChangeUserAccount(e, id, i, 'userId', 'GPROJECT')}
                            disabled={users.find(user => user.belongs.buildTeamId === id)?.userExist}
                            value={users.find(user => user.belongs.buildTeamId === id)?.userId || ""}
                            InputProps={{
                              inputComponent: TextMaskCustom,  // TODO : 이렇게 바깥에서 설정할지 FormInputText 안으로 넣을지 고려할 것
                              inputProps: {
                                style: { // TODO : 넘기는 방법을 정확히 몰라 style에 실어 넘김
                                  mask: "000-0000-0000",
                                  definitions: {
                                    '#': /[1-9]/,
                                  },
                                },
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <FormControl variant="outlined" sx={{ width: '100%' }}>
                            <InputLabel htmlFor="outlined-adornment-password">{"비밀번호"}</InputLabel>
                            <OutlinedInput
                              fullWidth
                              id={`password_${id}`}
                              type={users.find(user => user.belongs.buildTeamId === id)?.showUserPassword ? 'text' : 'password'}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={(e) => handleClickShowPassword(id, 'userPassword', 'GPROJECT')}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {users.find(user => user.belongs.buildTeamId === id)?.showUserPassword ? <VisibilityOff /> : <Visibility />}
                                  </IconButton>
                                </InputAdornment>
                              }
                              label="Password"
                              onChange={(e) => handleChangeUserAccount(e, id, i, 'userPassword', 'GPROJECT')}
                              value={users.find(user => user.belongs.buildTeamId === id)?.userPassword || ""}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                          <FormControl variant="outlined" sx={{ width: '100%' }}>
                            <InputLabel htmlFor="outlined-adornment-password">{"비밀번호 확인"}</InputLabel>
                            <OutlinedInput
                              fullWidth
                              id={`confirmPassword_${id}`}
                              type={users.find(user => user.belongs.buildTeamId === id)?.showUserConfirmPassword ? 'text' : 'password'}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={(e) => handleClickShowPassword(id, 'userConfirmPassword', 'GPROJECT')}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {users.find(user => user.belongs.buildTeamId === id)?.showUserConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                  </IconButton>
                                </InputAdornment>
                              }
                              label="Password"
                              onChange={(e) => handleChangeUserAccount(e, id, i, 'userConfirmPassword', 'GPROJECT')}
                              value={users.find(user => user.belongs.buildTeamId === id)?.userConfirmPassword || ""}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={2} display="flex">
                          <LoadingButton
                            loading={users.find(user => user.belongs.buildTeamId === id)?.loadingSave}
                            onClick={(e) => handleClickSaveUser(e, id, i, 'GPROJECT')}
                          >
                            {users.find(user => user.belongs.buildTeamId === id)?.userExist ? "비밀번호 변경" : "등록"}
                          </LoadingButton>
                          <LoadingButton
                            loading={users.find(user => user.belongs.buildTeamId === id)?.loadingDelete}
                            onClick={(e) => handleClickDeleteUser(e, id, i, 'GPROJECT')}
                          >
                            {"삭제"}
                          </LoadingButton>
                        </Grid>
                      </Grid>
                      
                    </Grid>
                    { j !== buildTeams.length-1 && <Grid item xs={12}><Divider variant="middle" /></Grid> }
                  </>
                )
              })
            }
            </Grid>
          </CardContent>
        </Card>
      )
    })
  }

  return (
    <ThemeProvider theme={theme}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container component="main" maxWidth="lg">
        <CssBaseline />
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            {
              sessionUser && sessionUser.gclientTypes?.filter(gclientType => gclientType.code === 'FABRICATOR' || gclientType.code === 'BUILD_TEAM').map((gclientType, i) => {
                const { id } = gclientType;
                return (
                  <Grid item xs={12}>
                    <Accordion expanded={true}/*expanded={basicExpanded} onChange={() => setBasicExpanded(!basicExpanded)}*/>
                      <AccordionSummary aria-controls="basic-content" id="basic-header">
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Typography variant="h6" component="div">{gclientType.name}</Typography>
                          </Grid>
                        </Grid>
                      </AccordionSummary>
                      <AccordionDetails>
                        {/* 가공업체는 공정라인별 계정 필요 */}
                        {
                          gclientType.code === 'FABRICATOR' && generateAccountsForGLines()
                        }
                        {/* 시공팀은 진행중인 프로젝트별 계정 필요 */}
                        {
                          gclientType.code === 'BUILD_TEAM' && generateAccountsForGProjects()
                        }
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                )
              })
            }
          </Grid>
        </Box>
        <AlertDialog
          alertInfo={alertInfo}
          setAlertInfo={setAlertInfo}
        />
        <ConfirmDialog
          removeId={removeObject}
          title={"삭제"}
          open={confirmOpen}
          setOpen={setConfirmOpen}
          onConfirm={deleteUser}
          onCancel={() => {}}
        >
          {`사용자(아이디 : ${params && params.userId  || ""})"를 삭제하시겠습니까?`}
        </ConfirmDialog>
      </Container>
    </ThemeProvider>
  )
}

export default User;