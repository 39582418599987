const SET_ERROR = 'error/setError';

const setError = (error) => ({
  type: SET_ERROR,
  payload: error,
});

export const occurError = (error) => async dispatch => {
  console.log("!!!!!!!!!!!!!")
  console.log(error)
  dispatch(setError(error));
};

const initialState = { error: null };

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case SET_ERROR:
      newState = Object.assign({}, state, { error: action.payload });
      return newState;
    default:
      return state;
  }
}

export default reducer;
