// TODO : id/gcomponentItemId, type/itemType => frontend, backend(api), db 혼용하여 혼동됨. 정리 필요
import { csrfFetch } from "./csrf.js";
import { authServerUrl, gcoreServerUrl } from '../config.js';

const SET_GCLIENT_GCOMPONENT_ITEM_MAP = "gclientGComponentItemMaps/setGClientGComponentItemMap";
const SET_GCLIENT_GCOMPONENT_ITEM_MAPS = "gclientGComponentItemMaps/setGClientGComponentItemMaps";
const REMOVE_GCLIENT_GCOMPONENT_ITEM_MAP = "gclientGComponentItemMaps/removeGClientGComponentItemMap";
const INIT_GCOMPONENT_ITEMS = 'gclientGComponentItemMaps/initGComponentItems';
const SET_GCOMPONENT_ITEMS = 'gclientGComponentItemMaps/setGComponentItems';
const SET_GCOMPONENT_ITEMS_RAW_MATEIRAL = 'gclientGComponentItemMaps/setGComponentItemsRawMaterial';
const SET_GCOMPONENT_ITEMS_SUB_MATEIRAL_PROCESS = 'gclientGComponentItemMaps/setGComponentItemsSubMaterialProcess';
const SET_GCOMPONENT_ITEMS_SUB_MATEIRAL_BUILD = 'gclientGComponentItemMaps/setGComponentItemsSubMaterialBuild';

const setGClientGComponentItemMap = (gclientGComponentItemMap) => ({
  type: SET_GCLIENT_GCOMPONENT_ITEM_MAP,
  payload: gclientGComponentItemMap,
});

const setGClientGComponentItemMaps = (gclientGComponentItemMaps) => ({
  type: SET_GCLIENT_GCOMPONENT_ITEM_MAPS,
  payload: gclientGComponentItemMaps,
});

const removeGClientGComponentItemMap = () => ({
  type: REMOVE_GCLIENT_GCOMPONENT_ITEM_MAP,
});

const initGComponentItems = () => ({
  type: INIT_GCOMPONENT_ITEMS,
})

const setGComponentItems = (gcomponentItems) => ({
  type: SET_GCOMPONENT_ITEMS,
  payload: gcomponentItems,
});

const setGComponentItemsRawMaterial = (gcomponentItemsRawMaterial) => ({
  type: SET_GCOMPONENT_ITEMS_RAW_MATEIRAL,
  payload: gcomponentItemsRawMaterial,
});

const setGComponentItemsSubMaterialProcess = (gcomponentItemsSubMaterialProcess) => ({
  type: SET_GCOMPONENT_ITEMS_SUB_MATEIRAL_PROCESS,
  payload: gcomponentItemsSubMaterialProcess,
});

const setGComponentItemsSubMaterialBuild = (gcomponentItemsSubMaterialBuild) => ({
  type: SET_GCOMPONENT_ITEMS_SUB_MATEIRAL_BUILD,
  payload: gcomponentItemsSubMaterialBuild,
});

export const createMaps = (gclientGComponentItemMaps) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gclientGComponentItemMaps/bulk`, {
    method: "POST",
    body: JSON.stringify(gclientGComponentItemMaps),
  });
  const data = await response.json();
  dispatch(setGClientGComponentItemMaps(data.gclientGComponentItemMaps));
  return response;
};

export const createMapsDirect = async (gclientGComponentItemMaps) => {
  const response = await csrfFetch(null, `${gcoreServerUrl}/api/gclientGComponentItemMaps/bulk`, {
    method: "POST",
    body: JSON.stringify(gclientGComponentItemMaps),
  });
  const data = await response.json();
  return data.gclientGComponentItemMaps;
};

export const modifyProductName = ({ gcomponentItemId, gcomponentItemType, gclientId, productName }) => async dispatch => { 
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gclientGComponentItemMaps/productName`, {
    method: "PUT",
    body: JSON.stringify({ gcomponentItemId, gcomponentItemType, gclientId, productName }),
  });
  const data = await response.json();
  dispatch(setGClientGComponentItemMap(data.gclientGComponentItemMap));
  return response;
};

export const selectAllByGClientByItemTypeByQuery = (gclientId, itemType) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gclientGComponentItemMaps/query/selectAll/gclient/${gclientId}/itemType/${itemType}`);
  const data = await response.json();
  dispatch(setGClientGComponentItemMaps(data.gclientGComponentItemMaps));
  return response;
}

export const select = (gcomponentItemId, gclientId, itemType) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gclientGComponentItemMaps/gcomponentItemId/${gcomponentItemId}/gclient/${gclientId}/itemType/${itemType}`);
  const data = await response.json();
  dispatch(setGClientGComponentItemMap(data.gclientGComponentItemMap));
  return response;
}

export const remove = (gcomponentItemId, gclientId, itemType) => async (dispatch) => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gclientGComponentItemMaps/gcomponentItemId/${gcomponentItemId}/gclient/${gclientId}/itemType/${itemType}`, {
    method: "DELETE",
  });
  dispatch(removeGClientGComponentItemMap());
  return response;
};

export const initializeMyGComponentItems = () => async dispatch => {
  dispatch(initGComponentItems());
  return;
}

export const selectAllMyGComponentItems = (gclientId) => async dispatch => {
  // 거래선 세부타입에 해당하는 규격도 추가로 조회함
  const res = await csrfFetch(null, `${authServerUrl}/api/gclients/query/selectG04docuGCertificationIds/gclient/${gclientId}`);
  const dat = await res.json();
  const g04docuGCertificationIds = dat.g04docuGCertificationIds;
  console.log(g04docuGCertificationIds);

  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gclientGComponentItemMaps/myGComponentItems/gclient/${gclientId}`, {
    method: "POST",
    body: JSON.stringify({ g04docuGCertificationIds }),
  });
  const data = await response.json();
  console.log(data.gcomponentItems)
  dispatch(setGComponentItems(data.gcomponentItems));
  return response;
};

export const selectAllMyGComponentItemsForTree = (gclientId) => async dispatch => {
  // 거래선 세부타입에 해당하는 규격도 추가로 조회함
  const res = await csrfFetch(null, `${authServerUrl}/api/gclients/query/selectG04docuGCertificationIds/gclient/${gclientId}`);
  const dat = await res.json();
  const g04docuGCertificationIds = dat.g04docuGCertificationIds;
  console.log(g04docuGCertificationIds);

  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gclientGComponentItemMaps/myGComponentItems/tree/gclient/${gclientId}`, {
    method: "POST",
    body: JSON.stringify({ g04docuGCertificationIds }),
  });
  const data = await response.json();
  dispatch(setGComponentItems(data.gcomponentItems));
  return response;
};

export const selectAllGComponentItemsByDivisionForTree = (gclientId, division) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gclientGComponentItemMaps/myGComponentItems/tree/gclient/${gclientId}/division/${division}`, );
  const data = await response.json();
  if (division === 'RAW_MATERIAL') {
    dispatch(setGComponentItemsRawMaterial(data.gcomponentItems));  
  } else if (division === 'SUB_MATERIAL_PROCESS') {
    dispatch(setGComponentItemsSubMaterialProcess(data.gcomponentItems));
  } else if (division === 'SUB_MATERIAL_BUILD') {
    dispatch(setGComponentItemsSubMaterialBuild(data.gcomponentItems));
  }

  return response;
};

export const selectAllGComponentItemsByDivisionByStatusForTree = (gclientId, division, status) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gclientGComponentItemMaps/myGComponentItems/tree/gclient/${gclientId}/division/${division}/status/${status}`, );
  const data = await response.json();
  if (division === 'RAW_MATERIAL') {
    dispatch(setGComponentItemsRawMaterial(data.gcomponentItems));  
  } else if (division === 'SUB_MATERIAL_PROCESS') {
    dispatch(setGComponentItemsSubMaterialProcess(data.gcomponentItems));
  } else if (division === 'SUB_MATERIAL_BUILD') {
    dispatch(setGComponentItemsSubMaterialBuild(data.gcomponentItems));
  }

  return response;
};

const initialState = {
  gclientGComponentItemMap: null,
  gclientGComponentItemMaps: [],
  gcomponentItems: [],
  gcomponentItemsRawMaterial: [],
  gcomponentItemsSubMaterialProcess: [],
  gcomponentItemsSubMaterialBuild: [],
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case SET_GCLIENT_GCOMPONENT_ITEM_MAP:
      newState = Object.assign({}, state, { gclientGComponentItemMap: action.payload });
      return newState;
    case SET_GCLIENT_GCOMPONENT_ITEM_MAPS:
      newState = Object.assign({}, state, { gclientGComponentItemMaps: action.payload });
      return newState;
    case INIT_GCOMPONENT_ITEMS:
      newState = Object.assign({}, state, { gcomponentItems: initialState.gcomponentItems });
      return newState;
    case SET_GCOMPONENT_ITEMS:
      newState = Object.assign({}, state, { gcomponentItems: action.payload });
      return newState;
    case SET_GCOMPONENT_ITEMS_RAW_MATEIRAL:
      newState = Object.assign({}, state, { gcomponentItemsRawMaterial: action.payload });
      return newState;
    case SET_GCOMPONENT_ITEMS_SUB_MATEIRAL_PROCESS:
      newState = Object.assign({}, state, { gcomponentItemsSubMaterialProcess: action.payload });
      return newState;
    case SET_GCOMPONENT_ITEMS_SUB_MATEIRAL_BUILD:
      newState = Object.assign({}, state, { gcomponentItemsSubMaterialBuild: action.payload });
      return newState;
    default:
      return state;
  }
}

export default reducer;
