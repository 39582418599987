// import id from "date-fns/locale/id/index.js";
import { csrfFetch } from "./csrf.js";

const SET_GPROCESS = 'gprocess/setGProcess';
const REMOVE_GPROCESS = "gprocess/removeGProcess";
const SET_GPROCESSES = 'gprocess/setGProcesss';

const setGProcess = (gprocess) => ({
  type: SET_GPROCESS,
  payload: gprocess,
});

const removeGProcess = () => ({
  type: REMOVE_GPROCESS,
});

const setGProcesss = (gprocesses) => ({
  type: SET_GPROCESSES,
  payload: gprocesses,
});

export const create = ({ id, name, code, steps, color, comments, defects }) => async dispatch => {
  const response = await csrfFetch(dispatch, "/api/gprocesses", {
    method: "POST",
    body: JSON.stringify({ id, name, code, steps, color, comments, defects }),
  });
  const data = await response.json();
  dispatch(setGProcess(data.gprocess));
  return response;
};

export const modify = ({ id, name, code, steps, color, comments, defects }) => async dispatch => {
  const response = await csrfFetch(dispatch, "/api/gprocesses", {
    method: "PUT",
    body: JSON.stringify({ id, name, code, steps, color, comments, defects }),
  });
  const data = await response.json();
  dispatch(setGProcess(data.gprocess));
  return response;
};

export const selectAll = () => async dispatch => {
  const response = await csrfFetch(dispatch, "/api/gprocesses");
  const data = await response.json();
  dispatch(setGProcesss(data.gprocesses));
  return response;
}

export const selectAllDirect = async () => {
  const response = await csrfFetch(null, "/api/gprocesses");
  const data = await response.json();
  return data.gprocesses;
}

export const select = (id) => async dispatch => {
  const response = await csrfFetch(dispatch, `/api/gprocesses/${id}`);
  const data = await response.json();
  dispatch(setGProcess(data.gprocess));
  return response;
}

export const initGProcess = () => async dispatch => {
  dispatch(setGProcess(null));
}

export const initGProcessWithInitialValue = (initialValue) => async dispatch => {
  dispatch(setGProcess(initialValue));
}

export const remove = (id) => async (dispatch) => {
  const response = await csrfFetch(dispatch, `/api/gprocesses/${id}`, {
    method: "DELETE",
  });
  dispatch(removeGProcess());
  return response;
};

export const reorder = async ({ type, id }) => {
  const response = await csrfFetch(null, "/api/gprocesses/reorder", {
    method: "POST",
    body: JSON.stringify({ type, id }),
  });
  
  return response;
}

const initialState = {
  gprocess: null,
  gprocesses: [],
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case SET_GPROCESS:
      newState = Object.assign({}, state, { gprocess: action.payload });
      return newState;
    case REMOVE_GPROCESS:
      newState = Object.assign({}, state, { gprocess: null });
      return newState;
    case SET_GPROCESSES:
      newState = Object.assign({}, state, { gprocesses: action.payload });
      return newState;
    default:
      return state;
  }
}

export default reducer;
