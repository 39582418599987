// import id from "date-fns/locale/id/index.js";
import { csrfFetch } from "./csrf.js";

const SET_CAPACITY_BY_GPROCESS = 'gcapacity/setCapacityByGProcess';
const SET_CAPACITY_BY_GLINE = "gcapacity/setCapacityByGLine";

export const setCapacityByGProcess = (gcapacities) => ({
  type: SET_CAPACITY_BY_GPROCESS,
  payload: gcapacities,
});

export const setCapacityByGLine = (gcapacities) => ({
  type: SET_CAPACITY_BY_GLINE,
  payload: gcapacities,
});

export const selectAllByGProcessByQuery = (start, end) => async dispatch => {
  const response = await csrfFetch(dispatch, `/api/glineCapacities/query/selectAllByGProcess/start/${start}/end/${end}`);
  const data = await response.json();
  dispatch(setCapacityByGProcess(data.gcapacities));
  return response;
};

export const selectAllByGProcessDirectByQuery = async (start, end) => {
  const response = await csrfFetch(null, `/api/glineCapacities/query/selectAllByGProcess/start/${start}/end/${end}`);
  const data = await response.json();
  return data.gcapacities;
};

export const selectAllByGLineByQuery = (start, end) => async dispatch => {
  const response = await csrfFetch(dispatch, `/api/glineCapacities/query/selectAllByGLine/start/${start}/end/${end}`);
  const data = await response.json();
  dispatch(setCapacityByGLine(data.gcapacities));
  return response;
};

export const selectAllByGLineDirectByQuery = async (gprocessCode, start, end) => {
  const response = await csrfFetch(null, `/api/glineCapacities/query/selectAllByGLine/gprocessCode/${gprocessCode}/start/${start}/end/${end}`);
  const data = await response.json();
  return data.gcapacities;
};

export const selectAllByGLineWeeklyDirectByQuery = async (gprocessCode, start, end) => {
  const response = await csrfFetch(null, `/api/glineCapacities/query/selectAllByGLine/gprocessCode/${gprocessCode}/start/${start}/end/${end}/week`);
  const data = await response.json();
  return data.gcapacities;
};

const initialState = {
  gcapacitiesByGProcess: [],
  gcapacitiesByGLine: [],
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case SET_CAPACITY_BY_GPROCESS:
      newState = Object.assign({}, state, { gcapacitiesByGProcess: action.payload });
      return newState;
    case SET_CAPACITY_BY_GLINE:
      newState = Object.assign({}, state, { gcapacitiesByGLine: action.payload });
      return newState;
    default:
      return state;
  }
}

export default reducer;
