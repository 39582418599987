import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import {
  FormInputCheckbox,
  FormInputDropdown,
  FormInputText,
} from "../form";
import {
  DialogTitleClose,
  PaperComponent,
} from "../dialog";
import * as gstandardActions from "../../store/gstandard";
import * as gclientG04docuGCertificationDetailMapActions from "../../store/gclientG04docuGCertificationDetailMap";

const today = new Date();
let endDate = new Date();
// endDate.setFullYear(today.getFullYear() + 2);

// 아래 form components의 name과 연계
const defaultValues = {
  id: "",
  name: "",
  gstandardNo: "",
  gstandardName: "",
  division: "",
};

// TODO : 이렇게 객체로 쓰면 간편한데??? 추후 검토
const cellStyle = {
  borderColor: '#dcdcdc',
  borderBottomWidth: 0, borderBottomWidth: 1, borderBottomStyle: 'solid',
  borderRightWidth: 0, borderRightWidth: 1, borderRightStyle: 'solid',
};

const G04docuDialogGCertification = ({
  open,
  setOpen,
  crudMode,
  setCrudMode,
  selectedGClientId,
  selectedRow,
  refresh,
}) => {
  const [errors, setErrors] = useState([]);
  const [currentSelectedClassifications, setCurrentSelectedClassifications] = useState([]);

  const handleDialogClose = () => {
    setOpen(false);
    setCrudMode('');

    initDialog();
  };

  const initDialog = () => {
    for (const [item, value] of Object.entries(defaultValues)) {
      setValue(item, value);
    }
    
    // 그외 초기화할 것들은 여기서 초기화
    
  }

  /**
   * userForm에 인자 { defaultValues: defaultValues }를 넘기지 않고 useForm() 형태로 사용하면 아래 에러 발생
   * Warning: A component is changing an uncontrolled input to be controlled. This is likely caused by
   * the value changing from undefined to a defined value, which should not happen. Decide between using
   * a controlled or uncontrolled input element for the lifetime of the component.
   */
  const { handleSubmit, reset, control, setValue, getValues } = useForm({ defaultValues: defaultValues });

  const gstandard = useSelector((state) => state.gstandard.gstandard);

  // 데이터 관리
  const dispatch = useDispatch();

  const createG04docuGCertificationSelectedClassifications = ({ gclientId, g04docuGCertificationId, selectedClassifications }) => dispatch(gclientG04docuGCertificationDetailMapActions.create({ gclientId, g04docuGCertificationId, selectedClassifications }))
  const selectGStandardById = (id) => dispatch(gstandardActions.select(id))

  const onSubmit = ({ id }) => {
    setErrors([]);

    console.log(selectedRow);

    let func = createG04docuGCertificationSelectedClassifications;
    
    // console.log({ gclientId: selectedGClientId, g04docuGCertificationId: id, selectedClassifications: currentSelectedClassifications });
    // return;
    
    func({ gclientId: selectedGClientId, g04docuGCertificationId: id, selectedClassifications: currentSelectedClassifications })
      .then (async (res) => {
        handleDialogClose();

        refresh(selectedGClientId);
      })
      .catch (async (res) => {
        console.log(res)
        const data = await res.json();
        if (data && data.errors) setErrors(data.errors);
      });
  }

  useEffect(
    () => {
      if (selectedRow) {
        for (const [item, value] of Object.entries(defaultValues)) {
          setValue(item, selectedRow[item] || value);
        }

        // 그외 초기화
        console.log(selectedRow.selectedClassifications)
        if (selectedRow.selectedClassifications && selectedRow.selectedClassifications.length > 0) {
          setCurrentSelectedClassifications(selectedRow.selectedClassifications); // GClientG04docuGCertificationDetailMaps의 selectedClassifications
        } else {
          setCurrentSelectedClassifications(selectedRow.classifications); // G04docuGCertifications의 classifications
        }

        selectedRow["gstandardId"] && selectGStandardById(selectedRow["gstandardId"]);
      }
    }, [selectedRow]
  );

  const handleChangeCheckValue = (e, checked, name) => {
    const { classifications, selectedClassifications } = selectedRow;
    console.log(classifications)
    console.log(selectedClassifications)
    console.log(currentSelectedClassifications)
    console.log(e)
    console.log(checked)
    console.log(name)

    const arr = [].concat(currentSelectedClassifications.length > 0 ? currentSelectedClassifications : ( selectedClassifications && selectedClassifications.length > 0 ? selectedClassifications : classifications));

    const newClassificationStandards = arr.map(classificationStandard => {
      if (classificationStandard.name === name) {
        return {
          ...classificationStandard,
          checked,
        }
      }

      return classificationStandard;
    })

    console.log(newClassificationStandards);
    setCurrentSelectedClassifications(newClassificationStandards);
  }

  return (
    <Dialog
      PaperProps={{style: {overflowY: 'visible'}}}
      open={open}
      onClose={handleDialogClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      scroll={"body"}
    >
      <DialogTitleClose
        id="draggable-dialog-title"
        onClose={handleDialogClose}
        style={{ cursor: 'move' }}
      >
        {/* { crudMode === 'C' && "인증서 종류·등급·호칭 또는 모델 등록" }
        { crudMode === 'R' && "인증서 종류·등급·호칭 또는 모델 상세" } */}
        { crudMode === 'U' && "인증서 종류·등급·호칭 또는 모델 수정" }
      </DialogTitleClose>
      <DialogContent
        style={{ overflowY: 'visible' }}
        dividers
      >
        <ul>
          {errors.map((error, idx) => <li key={idx}>{error}</li>)}
          {/* {
            JSON.stringify(selectedRow, null, 2)
          } */}
        </ul>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ display: 'none' }}>
            <FormInputText
              name={"id"}
              control={control}
              label={"아이디"}
              inputProps={
                { readOnly : true }
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormInputText
              name={"name"}
              control={control}
              label={"이름"}
              inputProps={
                { readOnly : true }
              }
            />
          </Grid>
          {/* <Grid item xs={12}>
            <FormInputText
              name={"code"}
              control={control}
              label={"코드"}
              inputProps={
                { readOnly: crudMode === 'R' }
              }
            />
          </Grid> */}
          <Grid item xs={12} sx={{ mb: 1 }}>
            <Card>
              <CardHeader
                titleTypographyProps={{ variant: 'subtitle1' }}
                // title={"종류등〮급호〮칭 또는 모델"}
                title={"종류·등급·호칭 또는 모델"}
                sx={{ bgcolor: "#eaeaea" }}
              />
              <CardContent>
                {/* <Grid container spacing={1}>
                {
                  currentSelectedClassifications?.map((item, i) => {
                    return (
                      <Grid item xs={6} display="flex" alignItems="center">
                        <FormInputCheckbox
                          name={`${i}_certificationStandard`}
                          control={control}
                          // label={item}
                          setValue={setValue}
                          onChangeCheckValue={handleChangeCheckValue}
                          checked={item.checked}
                        />
                        <Typography>{item.type}</Typography>
                      </Grid>
                    )
                  })
                }
                </Grid> */}
                <Grid conatiner>
                  <Grid item xs={12}>
                    <Stack direction="row">
                      {/* {
                        detailsForTable?.map((col1, i) => {
                          let length = col1.length;
                          let count = 0;
                          // console.log(detailsForTable)
                          // console.log(col1)
                          return (
                            <Grid container>
                              {
                                col1.map((col2, j) => {
                                  if (i === 0) {
                                    return (
                                      <Grid item xs={12} display="flex" sx={cellStyle}>
                                        <Typography sx={{ p: 1 }}>{col2[`type${i}`]}</Typography>
                                      </Grid>
                                    )
                                  } else {
                                    for(let k=i-1; k>=0; k--) {
                                      return detailsForTable[k].map((val, index) => {
                                        if (count >= length) {
                                          count = 0;
                                        }
                                        return (
                                          <Grid item xs={12} display="flex" sx={cellStyle}>
                                            <Typography sx={{ p: 1 }}>{col1[count++][`type${i}`]}</Typography>
                                          </Grid>
                                        )
                                      })
                                    }
                                  }
                                })
                              }
                            </Grid>
                          )
                        })
                      } */}
                      {
                        gstandard?.classificationComponents?.map((types, i) => {
                          let prevArrayLength = 1;
                          if (i > 0) {
                            for(let k=i-1; k>=0; k--) prevArrayLength = gstandard?.classificationComponents[k].length*prevArrayLength; // 현재 배열의 앞 배열들의 곱집합 크기
                          }
                          return (
                            <Grid container>
                              {
                                Array.from(' '.repeat(prevArrayLength)).map(() => ( // Array.from의 첫번째 인자에 '' 빈 문자열은 안됨에 유의
                                  types.map(type => (<Grid item xs={12} display="flex" sx={cellStyle}>
                                    <Typography sx={{ p: 1 }}>{type[`type${i}`]}</Typography>
                                  </Grid>))
                                ))
                              }
                            </Grid>
                          )
                        })
                      }
                      <Grid container sx={{ width: '42px' }}>
                        {
                          currentSelectedClassifications?.map((item, i) => {
                            return (
                              <Grid item xs={12} display="flex" alignItems="center" sx={{ ...cellStyle }}>
                                <FormInputCheckbox
                                  name={`${i}_classificationStandard`}
                                  control={control}
                                  // label={item}
                                  setValue={setValue}
                                  onChangeCheckValue={handleChangeCheckValue}
                                  // item.check가 초기에 undefined이면 checked가 초기값이 설정되지 않으므로 state 변경시 rendering에 문제
                                  // TODO : controlled / uncontrolled 학습할 것 => 다른 form input 들도 내부에서 강제 rendering 했는데 이 문제일 수 있음
                                  checked={item.checked ? item.checked : false}
                                />
                                {/* <Typography>{item.type}</Typography> */}
                              </Grid>
                            )
                          })
                        }
                      </Grid>
                    </Stack>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <FormInputText
              name={"comments"}
              control={control}
              label={"설명"}
              multiline
              maxRows={5}
              inputProps={
                { readOnly: true }
              }
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {
          crudMode === 'C' && (
            <>
              <Button onClick={handleSubmit(onSubmit)}>{"저장"}</Button>
              <Button onClick={() => reset()}>{"초기화"}</Button>
            </>
          )
        }
        { crudMode === 'U' && <Button onClick={handleSubmit(onSubmit)}>{"수정"}</Button> }
        <Button onClick={handleDialogClose}>{"닫기"}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default G04docuDialogGCertification;
