import { csrfFetch } from "./csrf.js";
import { gcoreServerUrl } from '../config';

// export const create = ({ gclientId, docuFileType }) => async dispatch => {
//   const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04docuFiles/uploadFile`, {
//     method: "POST",
//     body: JSON.stringify({ id, division, name, code, type, details, divider, comments, majority, pid, gstandardId }),
//   });
//   const data = await response.json();
//   dispatch(setG04docu(data.g04docu));
//   return response;
// };

export const downloadDirect = async (documentPath) => {
  const response = await csrfFetch(null, `${gcoreServerUrl}/api/g04docuFiles/downloadFile`, {
    method: "POST",
    body: JSON.stringify({ file: documentPath }),
  });
  
  // TODO : 추후 data 아래에 지정 필요할 수 있음
  // const data = await response.json();
  // return data;
  return response;
};

export const downloadAllDirect = async (id, documentPaths) => {
  const response = await csrfFetch(null, `${gcoreServerUrl}/api/g04docuFiles/downloadFiles`, {
    method: "POST",
    body: JSON.stringify({ id, files: documentPaths }),
  });
  
  // TODO : 추후 data 아래에 지정 필요할 수 있음
  // const data = await response.json();
  // return data;
  return response;
};

export const removeDirect = async (documentPath) => {
  const response = await csrfFetch(null, `${gcoreServerUrl}/api/g04docuFiles/deleteFile`, {
    method: "DELETE",
    body: JSON.stringify({ file: documentPath }),
  });
  
  // TODO : 추후 data 아래에 지정 필요할 수 있음
  const data = await response.json();
  return data;
};

export const generateDirect = async (treeData, gprojectId) => {
  const response = await csrfFetch(null, `${gcoreServerUrl}/api/g04docuFiles/generate`, {
    method: "POST",
    body: JSON.stringify({ data: treeData, gprojectId }),
  });
  
  const data = await response.json();
  return data.generatedTreeData;
};

// gcoreMAServer로 이동
// export const makeMaterialApproval = async (contents) => {
//   const response = await csrfFetch(null, `${gcoreServerUrl}/api/g04docuFiles/makeMaterialApproval`, {
//     method: "POST",
//     body: JSON.stringify({ contents }),
//   });
  
//   const data = await response.json();
//   return data.generatedTreeData;
// };

const initialState = {
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    default:
      return state;
  }
}

export default reducer;
