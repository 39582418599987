const SET_SHOW_NOTIFICATION = 'error/setShowNotification';

const setShowNotification = (show) => ({
  type: SET_SHOW_NOTIFICATION,
  payload: show,
});

export const displayNotification = (show) => async dispatch => {
  dispatch(setShowNotification(show));
};

const initialState = {
  notification: false,
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case SET_SHOW_NOTIFICATION:
      newState = Object.assign({}, state, { notification: action.payload });
      return newState;
    default:
      return state;
  }
}

export default reducer;
