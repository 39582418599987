// import id from "date-fns/locale/id/index.js";
import { csrfFetch } from "./csrf.js";
import { gcoreServerUrl } from '../config';

const SET_GTYPE_DETAIL = 'gtypeDetail/setGTypeDetail';
const REMOVE_GTYPE_DETAIL = "gtypeDetail/removeGTypeDetail";
const SET_GTYPE_DETAILS = 'gtypeDetail/setGTypeDetails';
const SET_GTYPE_DETAILS_WITH_GCOMPONENT = 'gtypeDetail/setGTypeDetailsWithGComponent';

const setGTypeDetail = (gtypeDetail) => ({
  type: SET_GTYPE_DETAIL,
  payload: gtypeDetail,
});

const removeGTypeDetail = () => ({
  type: REMOVE_GTYPE_DETAIL,
});

const setGTypeDetails = (gtypeDetails) => ({
  type: SET_GTYPE_DETAILS,
  payload: gtypeDetails,
});

const setGTypeDetailsWithGComponent = (gtypeDetailsWithGComponent) => ({
  type: SET_GTYPE_DETAILS_WITH_GCOMPONENT,
  payload: gtypeDetailsWithGComponent,
})

export const initDetails = () => dispatch => {
  dispatch(setGTypeDetails([]));
}

export const initWithComponent = () => dispatch => {
  dispatch(setGTypeDetailsWithGComponent([]));
}

export const setGTypeDetailsWithGComponentEx = (gtypeDetailsWithGComponent) => dispatch => {
  dispatch(setGTypeDetailsWithGComponent(gtypeDetailsWithGComponent));
}

export const create = ({ gtypeId, gcomponentId }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gtypeDetails`, {
    method: "POST",
    body: JSON.stringify({ gtypeId, gcomponentId }),
  });
  const data = await response.json();
  dispatch(setGTypeDetail(data.gtypeDetail));
  return response;
};

export const modify = ({ gtypeId, gcomponents }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gtypeDetails`, {
    method: "PUT",
    body: JSON.stringify({ gtypeId, gcomponents }),
  });
  const data = await response.json();
  dispatch(setGTypeDetail(data.gtypeDetail));
  return response;
};

export const selectByQueryGTypeId = (gtypeId) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gtypeDetails/query/selectByQueryGTypeId/${gtypeId}`);
  const data = await response.json();
  dispatch(setGTypeDetails(data.gtypeDetails));
  return response;
}

export const selectGTypeDetailsWithGComponent = (gtypeId) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gtypeDetails/query/selectGTypeDetailsWithGComponent/${gtypeId}`);
  const data = await response.json();
  dispatch(setGTypeDetailsWithGComponent(data.gtypeDetailsWithGComponent));
  return response;
}

export const selectGTypeDetailsWithGComponentDirect = async (gtypeId) => {
  const response = await csrfFetch(null, `${gcoreServerUrl}/api/gtypeDetails/query/selectGTypeDetailsWithGComponent/${gtypeId}`);
  const data = await response.json();
  return data.gtypeDetailsWithGComponent;
}

export const remove = (id) => async (dispatch) => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gtypeDetails/${id}`, {
    method: "DELETE",
  });
  dispatch(removeGTypeDetail());
  return response;
};

const initialState = {
  gtypeDetail: null,
  gtypeDetails: [],
  gtypeDetailsWithGComponent: [],
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case SET_GTYPE_DETAIL:
      newState = Object.assign({}, state, { gtypeDetail: action.payload });
      return newState;
    case REMOVE_GTYPE_DETAIL:
      newState = Object.assign({}, state, { gtypeDetail: null });
      return newState;
    case SET_GTYPE_DETAILS:
      newState = Object.assign({}, state, { gtypeDetails: action.payload });
      return newState;
    case SET_GTYPE_DETAILS_WITH_GCOMPONENT:
      newState = Object.assign({}, state, { gtypeDetailsWithGComponent: action.payload });
      return newState;
    default:
      return state;
  }
}

export default reducer;
