// TODO : 찾기 다이얼로그, 등록/수정 다이얼로그가 서로 연계되어 있어 관리/다이얼로그 분리는 추후에 검토
import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DataGridPro, GridActionsCellItem, koKR } from '@mui/x-data-grid-pro';
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  Fab,
  FormControlLabel,
  Grid,
  LinearProgress,
  Skeleton,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  Add,
  ContentPaste,
  Delete,
  CheckCircleOutline,
  Edit,
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowUp,
  KeyboardArrowDown,
  KeyboardArrowUp,
  OpenInNew,
  Search,
  Filter1,
  Filter2,
} from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';
import usePrevious from "../hook/usePrevious";
import {
  FormInputCheckbox,
  FormInputDropdown,
  FormInputText,
  FormInputToggleButton,
} from "../form";
import {
  DialogTitleClose,
  PaperComponent,
  ConfirmDialog,
} from "../dialog";
import {
  CustomNoRowsOverlay,
  // CustomLoadingOverlay,
} from "../datagrid";
import {
  dateFormat,
  hideWatermark,
} from "../../utils";
import * as gstandardActions from "../../store/gstandard";
import * as g04docuActions from "../../store/g04docu";

// 아래 form components의 name과 연계
// "id", "gstandardId", "division", "name", "code", "type", "comments", "majority", "pid", "details", "divider"
const defaultValues = {
  id: "",
  gstandardId: null,
  division: "RAW_MATERIAL", // 규격구분 :  RAW_MATERIAL / SUB_MATERIAL_PROCESS / SUB_MATERIAL_BUILD / PROCESS
  name: "",
  code: "",
  type: "CATEGORY", // 구분 : CATEGORY / STANDARD / CERTIFICATION / TEST / /*ETC*/
  // details: [],
  comments: "",
  majority: false,
  pid: "", // null이면 레이블과 값이 겹쳐져 나옴
  pname: "",
  divider: " ",
  
};

const theme = createTheme();

const G04docuManagement = () => {
  const [crudMode, setCrudMode] = useState('');
  const [open, setOpen] = useState(false);
  const [openFindDialog, setOpenFindDialog] = useState(false);
  const [errors, setErrors] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowFind, setSelectedRowsFind] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [loadedChild, setLoadedChild] = useState(false);
  const [loadedFind, setLoadedFind] = useState(false);
  const [show, setShow] = useState(false);
  const [showFind, setShowFind] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmOpenChild, setConfirmOpenChild] = useState(false);
  const [removeId, setRemoveId] = useState();
  const [params, setParams] = useState({});
  const [checked, setChecked] = useState(false);
  const [checkedChild, setCheckedChild] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [checkedMultipleYN, setCheckedMultipleYN] = useState(false);
  const [pageSizeFind, setPageSizeFind] = useState(100);
  const [pageSize, setPageSize] = useState(100);
  const [pageSizeChild, setPageSizeChild] = useState(100);
  // const [showSpecification, setShowSpecification] = useState(false);
  const [g04docu, setG04docu] = useState('STANDARD');
  const [details, setDetails] = useState([]);
  const [g04docuDivision, setG04docuDivision] = useState('RAW_MATERIAL');
  // const [certificationStandards, setCertificationStandards] = useState([]);

  // 상위/하위 구성요소 공통
  
  // 상위 구성요소
  const handleSelect = async ({ type, params }) => {
    
    const { id } = params;

    setOpenBackdrop(true);

    if (type === "detail") {
      await select(id);
      setCrudMode('R');
      setOpen(true);
    } else if (type === "edit") {
      // TODO : 아래 내용 재검토
      // 조회 후 다이얼로그 열면 데이터는 적용된 후 다이얼로그가 나타나 화면에서 데이터가 바뀌는게 보이는 것은 없어지나
      // 다소 다이얼로그가 늦게 나타나고,
      // 반대의 경우 다이얼로그는 빨리 나타나나 나타난 후 데이터가 바뀌는 것이 보임. 바뀌는 것이 보이는 것이 동적으로 변화하여
      // (마치 의도된 로딩처럼) 보기 좋을 수 있으나 화면 구성에 따라 보기 안좋은 경우도 있음.
      await select(id);
      setCrudMode('U');
      setOpen(true);
    } else if (type === "paste") {
      await select(id);
      // setValue("id", `${id}_copy`);
      // setValue("id", uuidv4());
      setCrudMode('C');
      setOpen(true);
    } else if (type === "delete") {
      setRemoveId(id);
      setParams(params);
      setConfirmOpen(true);
    } else if (type === "addChild") {
      setValue("pid", id);
      console.log(params)
      setValue("pname", params.row.name);
      setCrudMode('C');
      setOpen(true);
    }

    setOpenBackdrop(false);
  }
  
  const handleSelectUpDown = async ({ type, id }) => {
    setOpenBackdrop(true);

    await reorder({ type, id });
    setLoaded(false);
    // await selectAllByQuery();
    if (g04docuDivision === "ALL") {
      await selectAllByQuery();
    } else {
      await selectAllByDivisionByQuery(g04docuDivision);
    }
    setTimeout(() => setLoaded(true), 300);

    // 선택한 요소의 하위 요소를 다시 검색
    if (selectedRows.length === 1) {
      setLoadedChild(false);
      selectByPid(selectedRows[0].id);
      setTimeout(() => setLoadedChild(true), 300);
    }

    setOpenBackdrop(false);
  }

  const remove = (removeId) => {
    dispatch(g04docuActions.remove(removeId))
      .then(async res => {
        if (g04docuDivision === "ALL") {
          await selectAllByQuery();
        } else {
          await selectAllByDivisionByQuery(g04docuDivision);
        }
        // 구성요소를 삭제할 때 삭제하는 구성요소가 선택되어 자식 목록에 자식 구성요소가 조회되어 있는 경우 자식 목록 지움
        if (selectedRows.length === 1 && selectedRows[0].id === removeId) {
          initializeG04docusChild();
        }
      })
      .catch(async (res) => {
        const data = await res.json();
        if (data && data.errors) setErrors(data.errors);
      });
  }

  const handleChangeOrder = () => {
    setChecked(!checked);
  }

  const handleChangeToggleDivision = async ({ division, params, e }) => {
    e.stopPropagation();

    setOpenBackdrop(true);

    await modifyG04docuDivision({ id: params.id, division })

    setLoaded(false);
    // await selectAllByQuery();
    if (g04docuDivision === "ALL") {
      await selectAllByQuery();
    } else {
      await selectAllByDivisionByQuery(g04docuDivision);
    }
    setTimeout(() => setLoaded(true), 300);

    // 선택한 요소의 하위 요소를 다시 검색
    if (selectedRows.length === 1) {
      setLoadedChild(false);
      selectByPid(selectedRows[0].id);
      setTimeout(() => setLoadedChild(true), 300);
    }

    setOpenBackdrop(false);
  }

  const handleChangeToggle = async ({ type, params, e }) => {
    e.stopPropagation();

    setOpenBackdrop(true);

    await modifyG04docuType({ id: params.id, type })

    setLoaded(false);
    // await selectAllByQuery();
    if (g04docuDivision === "ALL") {
      await selectAllByQuery();
    } else {
      await selectAllByDivisionByQuery(g04docuDivision);
    }
    setTimeout(() => setLoaded(true), 300);

    // 선택한 요소의 하위 요소를 다시 검색
    if (selectedRows.length === 1) {
      setLoadedChild(false);
      selectByPid(selectedRows[0].id);
      setTimeout(() => setLoadedChild(true), 300);
    }

    setOpenBackdrop(false);
  }

  const handleChangeType = async (e) => {
    const { value } = e.target;
    // if (value === "STANDARD") {
    //   setShowSpecification(true);
    // } else {
    //   setShowSpecification(false);
    // }

    setG04docu(value);
  }

  // 하위 구성요소
  const handleSelectChild = async ({ type, id }) => {
    setOpenBackdrop(true);

    if (type === "detail") {
      await select(id);
      setCrudMode('R');
      setOpen(true);
    } else if (type === "edit") {
      await select(id);
      setCrudMode('U');
      setOpen(true);
    } else if (type === "paste") {
      await select(id);
      setCrudMode('C');
      setOpen(true);
    } else if (type === "delete") {
      setRemoveId(id);
      setParams(params);
      setConfirmOpenChild(true);
    }

    setOpenBackdrop(false);
  }

  const removeChild = (removeId) => {
    dispatch(g04docuActions.remove(removeId))
      .then(async (res) => {
        // selectAllByQuery();
        if (g04docuDivision === "ALL") {
          await selectAllByQuery();
        } else {
          await selectAllByDivisionByQuery(g04docuDivision);
        }

        // 선택한 요소의 하위 요소를 다시 검색
        if (selectedRows.length === 1) {
          selectByPid(selectedRows[0].id);
        }
      })
      .catch(async (res) => {
        const data = await res.json();
        if (data && data.errors) setErrors(data.errors);
      });
  }

  const handleChangeOrderChild = () => {
    setCheckedChild(!checkedChild);
  }

  const initDialog = () => {
    // ["id", "gstandardId", "division", "name", "code", "type", "comments", "majority", "pid", "details", "divider"].forEach(item => {
    //   if (!open && item === "id") {
    //     setValue(item, uuidv4());
    //   } else if (item === "division") {
    //     setValue(item, defaultValues.division);
    //   } else if (item === "type") {
    //     setG04docu('STANDARD');
    //     initializeGStandard();
    //     setValue(item, defaultValues.type);
    //   } else if (item === "majority") {
    //     setValue("majority", defaultValues.majority);
    //   } else if (item === "details") {
    //     setDetails([]);
    //   } else if (item === "divider") {
    //     setValue("divider", defaultValues.divider);
    //   } else {
    //     setValue(item, "")
    //   }
    // });
    for (const [item, value] of Object.entries(defaultValues)) {
      setValue(item, value);
    }

    // 그외 초기화할 것들은 여기서 초기화
    setG04docu('STANDARD');
    setDetails([]);
    
    initializeGStandard();
  }

  // 등록 다이얼로그
  const handleClickOpen = () => {
    setCrudMode('C');
    setOpen(true);
  }

  const handleDialogClose = () => {
    setOpen(false);
    setCrudMode('');

    initDialog();
  }

  // 부모아이디 찾기 다이얼로그
  const handleClickFind = async () => {
    const id = getValues("id");
    
    await selectAllByQueryFind(id);
    
    // setModify(true);

    setOpenFindDialog(true);
    
    // 임시
    setTimeout(async () => {
      await hideWatermark();
      setShowFind(true);
      setTimeout(() => setLoadedFind(true), 300);
    }, 300);
  }

  const handleFindDialogClose = () => {
    setLoadedFind(false);
    setOpenFindDialog(false);
  }

  const handleSelectFind = () => {
    console.log(selectedRowFind);
    setValue("pid", selectedRowFind.id);
    setValue("pname", selectedRowFind.row.name);
    setOpenFindDialog(false);
  }

  const generateActions = (params) => {
    let arrActions = [
      <GridActionsCellItem
        icon={<OpenInNew />}
        label={"상세"}
        onClick={() => handleSelect({ type: 'detail', params })}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<Edit />}
        label={"수정"}
        onClick={() => handleSelect({ type: 'edit', params })}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<ContentPaste />}
        label={"복사하여 등록"}
        onClick={() => handleSelect({ type: 'paste', params })}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<Delete />}
        label={"삭제"}
        onClick={() => handleSelect({ type: 'delete', params })}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<Add />}
        label={"하위등록"}
        onClick={() => handleSelect({ type: 'addChild', params })}
        showInMenu
      />,
    ];

    if (checked) {
      arrActions = arrActions.concat([
          <GridActionsCellItem
            icon={<KeyboardDoubleArrowUp />}
            label={"맨위로"}
            onClick={() => handleSelectUpDown({ type: 'first', id: params.id })}
            disabled={params.row.orderDetail === 'F'}
          />,
          <GridActionsCellItem
            icon={<KeyboardArrowUp />}
            label={"위로"}
            onClick={() => handleSelectUpDown({ type: 'up', id: params.id })}
            disabled={params.row.orderDetail === 'F'}
          />,
          <GridActionsCellItem
            icon={<KeyboardArrowDown />}
            label={"아래로"}
            onClick={() => handleSelectUpDown({ type: 'down', id: params.id })}
            disabled={params.row.orderDetail === 'L'}
          />,
          <GridActionsCellItem
            icon={<KeyboardDoubleArrowDown />}
            label={"맨아래로"}
            onClick={() => handleSelectUpDown({ type: 'last', id: params.id })}
            disabled={params.row.orderDetail === 'L'}
          />
        ]
      );
    }

    return arrActions;
  }

  const columns = useMemo(() => [
      {
        // RAW_MATERIAL / SUB_MATERIAL_PROCESS / SUB_MATERIAL_BUILD / PROCESS
        field: 'division',
        headerName: '규격구분',
        width: 280,
        hide: true,
        // editable: true,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => {
          let division = params.row.division;
          if (params.row.division === "") {
            division = 'RAW_MATERIAL';
          }
          return (
            <ToggleButtonGroup
              color="primary"
              value={division}
              exclusive
              size={"small"}
            >
              <ToggleButton value="RAW_MATERIAL" sx={{ fontWeight: 'bold' }} onClick={(e) => handleChangeToggleDivision({ division: 'RAW_MATERIAL', params, e })}>{"원자재"}</ToggleButton>
              <ToggleButton value="SUB_MATERIAL_PROCESS" sx={{ fontWeight: 'bold' }} onClick={(e) => handleChangeToggleDivision({ division: 'SUB_MATERIAL_PROCESS', params, e })}>{"가공부자재"}</ToggleButton>
              <ToggleButton value="SUB_MATERIAL_BUILD" sx={{ fontWeight: 'bold' }} onClick={(e) => handleChangeToggleDivision({ division: 'SUB_MATERIAL_BUILD', params, e })}>{"시공부자재"}</ToggleButton>
              <ToggleButton value="PROCESS" sx={{ fontWeight: 'bold' }} onClick={(e) => handleChangeToggleDivision({ division: 'PROCESS', params, e })}>{"공정"}</ToggleButton>
            </ToggleButtonGroup>
          )
        },
      },
      {
        field: 'type',
        headerName: '구분',
        width: 180,
        // hide: true,
        // editable: true,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => {
          let type = params.row.type;
          if (params.row.type === "") {
            type = 'CATEGORY';
          }
          return (
            <ToggleButtonGroup
              color="primary"
              value={type}
              exclusive
              size={"small"}
            >
              <ToggleButton value="CATEGORY" sx={{ fontWeight: 'bold' }} onClick={(e) => handleChangeToggle({ type: 'CATEGORY', params, e })}>{"구분"}</ToggleButton>
              <ToggleButton value="STANDARD" sx={{ fontWeight: 'bold' }} onClick={(e) => handleChangeToggle({ type: 'STANDARD', params, e })}>{"규격"}</ToggleButton>
              <ToggleButton value="CERTIFICATION" sx={{ fontWeight: 'bold' }} onClick={(e) => handleChangeToggle({ type: 'CERTIFICATION', params, e })}>{"인증"}</ToggleButton>
              {/* <ToggleButton value="TEST" sx={{ fontWeight: 'bold' }} onClick={(e) => handleChangeToggle({ type: 'TEST', params, e })}>{"성적서"}</ToggleButton> */}
              {/* <ToggleButton value="ETC" sx={{ fontWeight: 'bold' }} onClick={(e) => handleChangeToggle({ type: 'ETC', params, e })}>{"기타"}</ToggleButton> */}
            </ToggleButtonGroup>
          )
        },
      },
      {
        field: 'code',
        headerName: '코드',
        width: 200,
        // editable: true,
      },
      {
        field: 'gstandardNo',
        headerName: '표준번호',
        width: 200,
        // editable: true,
      },
      {
        field: 'gstandardName',
        headerName: '표준명(한글)',
        width: 200,
        // editable: true,
      },
      {
        field: 'comments',
        headerName: '설명',
        width: 280,
        // editable: true,
      },
      {
        field: 'pid',
        headerName: '부모아이디',
        width: 100,
        hide: true,
        // editable: true,
      },
      {
        field: 'createdAt',
        headerName: '생성일시',
        width: 160,
        headerAlign: 'center',
        align: 'center',
        valueGetter: (params) => dateFormat(params.value),
      },
      {
        field: 'updatedAt',
        headerName: '수정일시',
        width: 160,
        headerAlign: 'center',
        align: 'center',
        valueGetter: (params) => dateFormat(params.value),
      },
      {
        field: 'actions',
        headerName: <Tooltip title={"상세/수정/복사하여 등록/삭제/하위등록"} followCursor><Box>{"기능"}</Box></Tooltip>,
        width: checked ? 200 : 70,
        // description: "상세/수정/복사하여 등록/삭제/하위등록",
        // headerClassName: 'super-app-theme--header',
        // cellClassName: 'super-app-theme--cell',
        type: 'actions',
        getActions: (params) => generateActions(params),
      },
    ], [handleSelect]
  );

  const generateActionsChild = (params) => {
    let arrActions = [
      <GridActionsCellItem
        icon={<OpenInNew />}
        label={"상세"}
        onClick={() => handleSelectChild({ type: 'detail', id: params.id })}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<Edit />}
        label={"수정"}
        onClick={() => handleSelectChild({ type: 'edit', id: params.id })}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<ContentPaste />}
        label={"복사하여 등록"}
        onClick={() => handleSelectChild({ type: 'paste', id: params.id })}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<Delete />}
        label={"삭제"}
        onClick={() => handleSelectChild({ type: 'delete', id: params.id })}
        showInMenu
      />,
    ];

    if (checkedChild) {
      arrActions = arrActions.concat([
          <GridActionsCellItem
            icon={<KeyboardDoubleArrowUp />}
            label={"맨위로"}
            onClick={() => handleSelectUpDown({ type: 'first', id: params.id })}
            disabled={params.row.orderDetail === 'F'}
          />,
          <GridActionsCellItem
            icon={<KeyboardArrowUp />}
            label={"위로"}
            onClick={() => handleSelectUpDown({ type: 'up', id: params.id })}
            disabled={params.row.orderDetail === 'F'}
          />,
          <GridActionsCellItem
            icon={<KeyboardArrowDown />}
            label={"아래로"}
            onClick={() => handleSelectUpDown({ type: 'down', id: params.id })}
            disabled={params.row.orderDetail === 'L'}
          />,
          <GridActionsCellItem
            icon={<KeyboardDoubleArrowDown />}
            label={"맨아래로"}
            onClick={() => handleSelectUpDown({ type: 'last', id: params.id })}
            disabled={params.row.orderDetail === 'L'}
          />
        ]
      );
    }

    return arrActions;
  }

  const columnsChild = useMemo(() => [
      {
        field: 'name',
        headerName: '이름',
        width: 300,
        // editable: true,
      },
      {
        // RAW_MATERIAL / SUB_MATERIAL_PROCESS / SUB_MATERIAL_BUILD / PROCESS
        field: 'division',
        headerName: '규격구분',
        width: 280,
        hide: true,
        // editable: true,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => {
          let division = params.row.division;
          if (params.row.division === "") {
            division = 'RAW_MATERIAL';
          }
          return (
            <ToggleButtonGroup
              color="primary"
              value={division}
              exclusive
              size={"small"}
            >
              <ToggleButton value="RAW_MATERIAL" sx={{ fontWeight: 'bold' }} onClick={(e) => handleChangeToggleDivision({ division: 'RAW_MATERIAL', params, e })}>{"원자재"}</ToggleButton>
              <ToggleButton value="SUB_MATERIAL_PROCESS" sx={{ fontWeight: 'bold' }} onClick={(e) => handleChangeToggleDivision({ division: 'SUB_MATERIAL_PROCESS', params, e })}>{"가공부자재"}</ToggleButton>
              <ToggleButton value="SUB_MATERIAL_BUILD" sx={{ fontWeight: 'bold' }} onClick={(e) => handleChangeToggleDivision({ division: 'SUB_MATERIAL_BUILD', params, e })}>{"시공부자재"}</ToggleButton>
              <ToggleButton value="PROCESS" sx={{ fontWeight: 'bold' }} onClick={(e) => handleChangeToggleDivision({ division: 'PROCESS', params, e })}>{"공정"}</ToggleButton>
            </ToggleButtonGroup>
          )
        },
      },
      {
        field: 'type',
        headerName: '구분',
        width: 180,
        // hide: true,
        // editable: true,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => {
          let type = params.row.type;
          if (params.row.type === "") {
            type = 'CATEGORY';
          }
          return (
            <ToggleButtonGroup
              color="primary"
              value={type}
              exclusive
              size={"small"}
            >
              <ToggleButton value="CATEGORY" sx={{ fontWeight: 'bold' }} onClick={(e) => handleChangeToggle({ type: 'CATEGORY', params, e })}>{"구분"}</ToggleButton>
              <ToggleButton value="STANDARD" sx={{ fontWeight: 'bold' }} onClick={(e) => handleChangeToggle({ type: 'STANDARD', params, e })}>{"규격"}</ToggleButton>
              <ToggleButton value="CERTIFICATION" sx={{ fontWeight: 'bold' }} onClick={(e) => handleChangeToggle({ type: 'CERTIFICATION', params, e })}>{"인증"}</ToggleButton>
              {/* <ToggleButton value="TEST" sx={{ fontWeight: 'bold' }} onClick={(e) => handleChangeToggle({ type: 'TEST', params, e })}>{"성적서"}</ToggleButton> */}
              {/* <ToggleButton value="ETC" sx={{ fontWeight: 'bold' }} onClick={(e) => handleChangeToggle({ type: 'ETC', params, e })}>{"기타"}</ToggleButton> */}
            </ToggleButtonGroup>
          )
        },
      },
      {
        field: 'code',
        headerName: '코드',
        width: 200,
        // editable: true,
      },
      {
        field: 'gstandardNo',
        headerName: '표준번호',
        width: 200,
        // editable: true,
      },
      {
        field: 'gstandardName',
        headerName: '표준명(한글)',
        width: 200,
        // editable: true,
      },
      {
        field: 'comments',
        headerName: '설명',
        width: 280,
        // editable: true,
      },
      {
        field: 'pid',
        headerName: '부모아이디',
        width: 100,
        hide: true,
        // editable: true,
      },
      {
        field: 'createdAt',
        headerName: '생성일시',
        width: 160,
        headerAlign: 'center',
        align: 'center',
        valueGetter: (params) => dateFormat(params.value),
      },
      {
        field: 'updatedAt',
        headerName: '수정일시',
        width: 160,
        headerAlign: 'center',
        align: 'center',
        valueGetter: (params) => dateFormat(params.value),
      },
      {
        field: 'actions',
        headerName: <Tooltip title={"상세/수정/복사하여 등록/삭제"} followCursor><Box>{"기능"}</Box></Tooltip>,
        width: checkedChild ? 200 : 70,
        type: 'actions',
        getActions: (params) => generateActionsChild(params),
      },
    ], [handleSelectChild]
  );

  const columnsFind = useMemo(() => [
    // {
    //   field: 'name',
    //   headerName: '이름',
    //   width: 180,
    //   // editable: true,
    // },
    {
      field: 'code',
      headerName: '코드',
      width: 120,
      // editable: true,
    },
    {
      field: 'gstandardNo',
      headerName: '표준번호',
      width: 120,
      // editable: true,
    },
    {
      field: 'gstandardName',
      headerName: '표준명(한글)',
      width: 200,
      // editable: true,
    },
    {
      field: 'comments',
      headerName: '설명',
      width: 280,
      // editable: true,
    },
    {
      field: 'pid',
      headerName: '부모아이디',
      width: 100,
      hide: true,
      // editable: true,
    },
    // {
    //   field: 'createdAt',
    //   headerName: '생성일시',
    //   width: 200,
    // },
    // {
    //   field: 'updatedAt',
    //   headerName: '수정일시',
    //   width: 200,
    // },
    // {
    //   field: 'functions',
    //   headerName: "기능",
    //   width: 60,
    //   type: 'actions',
    //   hide: true,
    //   getActions: (params) => [
    //     <GridActionsCellItem
    //       icon={<Edit />}
    //       label={"수정"}
    //       onClick={() => handleSelect({ type: 'edit', id: params.id })}
    //       showInMenu
    //     />,
    //     <GridActionsCellItem
    //       icon={<Delete />}
    //       label={"삭제"}
    //       onClick={() => handleSelect({ type: 'delete', id: params.id })}
    //       showInMenu
    //     />,
    //     <GridActionsCellItem
    //       icon={<Add />}
    //       label={"하위등록"}
    //       onClick={() => handleSelect({ type: 'addChild', id: params.id })}
    //       showInMenu
    //     />,
    //   ],
    // },
    ], [handleSelect]
  );

  /**
   * userForm에 인자 { defaultValues: defaultValues }를 넘기지 않고 useForm() 형태로 사용하면 아래 에러 발생
   * Warning: A component is changing an uncontrolled input to be controlled. This is likely caused by
   * the value changing from undefined to a defined value, which should not happen. Decide between using
   * a controlled or uncontrolled input element for the lifetime of the component.
   */
  const { handleSubmit, reset, control, setValue, getValues } = useForm({ defaultValues: defaultValues });

  const dispatch = useDispatch();

  const gstandard = useSelector((state) => state.gstandard.gstandard);
  const gstandards = useSelector((state) => state.gstandard.gstandards);
  const rows = useSelector((state) => state.g04docu.g04docusTree);
  const selectedRow = useSelector((state) => state.g04docu.g04docu);
  const rowsChild = useSelector((state) => state.g04docu.g04docusChild);
  const rowsFind = useSelector((state) => state.g04docu.g04docusTreeFind);
  
  // 데이터 관리
  const selectAllGStandard = () => dispatch(gstandardActions.selectAll())
  const initializeGStandard = () => dispatch(gstandardActions.initGStandard())
  const selectGStandardById = (id) => dispatch(gstandardActions.select(id))
  const selectAllByQuery = () => dispatch(g04docuActions.selectAllByQuery())
  const selectAllByDivisionByQuery = (division) => dispatch(g04docuActions.selectByDivisionByQuery(division))
  const selectAllByQueryFind = (id) => dispatch(g04docuActions.selectAllByQueryFind(id))
  const select = (id) => dispatch(g04docuActions.select(id))
  const selectByPid = (pid) => dispatch(g04docuActions.selectByPid(pid))
  const selectByPidDirect = (pid) => g04docuActions.selectByPidDirect(pid)
  const addG04docu = ({ id, division, name, code, type, details, divider, comments, majority, pid, gstandardId }) => dispatch(g04docuActions.create({ id, division, name, code, type, details, divider, comments, majority, pid, gstandardId }))
  const modifyG04docu = ({ id, division, name, code, type, details, divider, comments, majority, pid, gstandardId }) => dispatch(g04docuActions.modify({ id, division, name, code, type, details, divider, comments, majority, pid, gstandardId }))
  const modifyG04docuDivision = ({ id, division }) => dispatch(g04docuActions.modifyDivision({ id, division }))
  const modifyG04docuType = ({ id, type }) => dispatch(g04docuActions.modifyType({ id, type }))
  const reorder = ({ type, id }) => g04docuActions.reorder({ type, id })
  const initializeG04docusChild = () => dispatch(g04docuActions.initializeG04docusChild())
  // const initializeG04docu = () => dispatch(g04docuActions.initializeG04docu())
  // const modifyG04docuMultipleYN = ({ id, multipleYN }) => dispatch(g04docuActions.modifyMultipleYN({ id, multipleYN }))

  // // 아래는 구매전 테스트를 위한 임시 코드임
  // const hideWatermark = () => {
  //   var divTags = document.getElementsByTagName("div");
  //   // console.log(divTags)
  //   var searchText = "MUI X: Missing license key";
  //   var found;

  //   for (var i = 0; i < divTags.length; i++) {
  //     if (divTags[i].textContent == searchText) {
  //       found = divTags[i];
  //       break;
  //     }
  //   }

  //   if (found) {
  //     found.remove();
  //   }
  // }

  const prevGStandard = usePrevious(gstandard);

  // TODO : 추후 아이디 "중복확인" 추가할 것
  const onSubmit = ({ id, division, name, code, type, divider, comments, majority, pid, gstandardId }) => {
    setErrors([]);

    let func;
    if (crudMode === 'C') {
      func = addG04docu;
    } else if (crudMode === 'U') {
      // TODO : 자기 자신을 상위(또는 하위)로 가질 수 없으므로 id, pid 같이 않도록 유효성 체크 필요
      func = modifyG04docu;
    }    
    // console.log({ id, division, name, code, type, details, divider, comments, majority, pid, gstandardId });
    // return;
    // dispatch(func({ id, division, name, code, gunitId, comments, pid }))
    func({ id, division, name, code, type, details, divider, comments, majority, pid, gstandardId: gstandardId ? gstandardId : null })
      .then (async (res) => {
        handleDialogClose();
        
        if (g04docuDivision === "ALL") {
          await selectAllByQuery();
        } else {
          await selectAllByDivisionByQuery(g04docuDivision);
        }

        if (selectedRows.length === 1) {
          selectByPid(selectedRows[0].id);
        }
      })
      .catch (async (res) => {
        const data = await res.json();
        if (data && data.errors) setErrors(data.errors);
      });
  }

  
  setTimeout(() => hideWatermark(), 300);

  useEffect(
    async () => {
      // 다른 목록과 달리 로딩시간이 길어 Backdrop 사용
      // setOpenBackdrop(true);
      // await dispatch(g04docuActions.selectAllByQuery());
      // await selectAllByQuery();
      selectAllGStandard();

      // watermark 안보이면서 로딩바 보이도록 하기 위한 임시 코드
      // setOpenBackdrop(false);
      // setShow(true);
      // setTimeout(() => setLoaded(true), 300);
    }, [dispatch]
  );

  // TODO : 아래는 목록에서도 들어온다. 검토해볼 것
  useEffect(
    () => {
      // TODO : 전체적으로 selectedRow가 값이 있을 경우만 본 함수 호출되어야 할 것으로 보임. 또한 수정시(onSubmit)에서도 호출됨
      // ["id", "gstandardId", "division", "name", "code", "type", "comments", "majority", "pid", "details", "divider"].forEach(item => {
      //   if (item === "id") {
      //     if (paste) {
      //       setValue(item, uuidv4());
      //     } else {
      //       setValue(item, selectedRow && selectedRow[item] || "");
      //     }
      //   } else if (item === "division") {
      //     setValue(item, selectedRow && selectedRow[item] || defaultValues.division);
      //   } else if (item === "type") {
      //     // selectedRow && selectedRow[item] === "STANDARD" ? setShowSpecification(true) : setShowSpecification(false);
      //     setG04docu(selectedRow && selectedRow[item] || "");
      //     setValue(item, selectedRow && selectedRow[item] || defaultValues.type);
      //   } else if (item === "gstandardId") {
      //     selectedRow && selectedRow[item] && selectGStandardById(selectedRow[item]);
      //     setValue(item, selectedRow && selectedRow[item] || "");
      //   } else if (item === "details") {
      //     setDetails(selectedRow && selectedRow[item]);
      //   } else if (item === "divider") {
      //     setValue(item, selectedRow && selectedRow[item]|| "none");
      //   } else if (item === "majority") {
      //     setValue(item, selectedRow && selectedRow[item] || defaultValues.majority);
      //   } else {
      //     setValue(item, selectedRow && selectedRow[item] || "");
      //   }
      // });
      if (selectedRow) {
        for (const [item, value] of Object.entries(defaultValues)) {
          setValue(item, selectedRow[item] || value);
        }

        // 그외 설정할 것들은 여기서 한다.
        setG04docu(selectedRow["type"] || defaultValues.type);
        console.log(selectedRow["details"]);
        setDetails(selectedRow["details"]);

        selectedRow["gstandardId"] && selectGStandardById(selectedRow["gstandardId"]);
      }
    }, [selectedRow]
  );

  // useEffect(
  //   () => {
  //     if (paste) {
  //       setValue("id", uuidv4());
  //     }
  //   }, [paste]
  // );

  useEffect(
    () => {
      if (crudMode === 'C') {
        setValue("id", uuidv4());
      }
    }, [crudMode]
  );

  useEffect(
    async () => {
      setTimeout(() => setLoadedChild(true), 300);
    }, [selectedRows]
  )

  useEffect(
    async () => {
      // TODO 아래의 두 if 조건문 검토 필요
      if (prevGStandard?.id === gstandard?.id) {
        return;
      }

      if (prevGStandard !== null || crudMode === 'C') {
        if (g04docu === 'CERTIFICATION') {
          if (gstandard) {
            const arrCertification = [];
            gstandard.certifications?.forEach((certification, i) => {
              arrCertification.push(certification);
            })
    
            const arrCartesian = arrCertification.reduce(
              (accumulator, currentValue) => accumulator.cross(currentValue),
            );
    
            const arrCertificationStandards = [];
    
            let i = 0;
            // arrCartesian은 2차원 배열
            arrCartesian.forEach(arr1 => {
              let certificationStandard = "";
              if (Array.isArray(arr1)) {
                arr1.forEach(arr2 => {
                  for (const [item, value] of Object.entries(arr2)) {
                    certificationStandard += value + getValues("divider");
                  }
                })
    
                arrCertificationStandards.push({ type: certificationStandard.substring(0, certificationStandard.length-1), checked: false, name: `${i}_certificationStandard`, divider: getValues('divider') });
                i++;
              } else {
                for (const [item, value] of Object.entries(arr1)) {
                  certificationStandard += value + getValues("divider");
                }
                arrCertificationStandards.push({ type: certificationStandard.substring(0, certificationStandard.length-1), checked: false, name: `${i}_certificationStandard`, divider: getValues('divider') });
                i++;
              }
            })
            
            console.log(arrCertificationStandards.map(certificationStandard => {
              const match = details.find(detail => detail.name === certificationStandard.name);
              if (match) {
                return {
                  ...certificationStandard,
                  checked: match.checked,
                }
              }

              return certificationStandard;
            }))
            console.log(details)
            console.log(arrCertificationStandards)
            // setDetails(arrCertificationStandards.map(certificationStandard => {
            //   const match = details.find(detail => detail.name === certificationStandard.name);
            //   if (match) {
            //     return {
            //       ...certificationStandard,
            //       checked: match.checked,
            //     }
            //   }

            //   return certificationStandard;
            // }));
            setDetails(arrCertificationStandards);
          } else {
            setDetails([]);
          }
        }
      }
    }, [gstandard]
  )

  useEffect(
    async () => {
      console.log(details)
      const divider = getValues("divider");
      // g04docu === 'TEST' && details?.length > 0 && setValue("name", details.join(divider === "none" ? "" : divider))
      g04docu === 'TEST' && details?.length > 0 && setValue("name", details.join(divider))
    }, [details]
  )

  // const handleChangeMultipleYN = (e) => {
  //   // TODO : form control 안에서 제어 가능한지 연구 필요
  //   setValue("multipleYN", !checkedMultipleYN)
  //   setCheckedMultipleYN(!checkedMultipleYN);
  // }

  useEffect(
    async () => {
      // 다른 목록과 달리 로딩시간이 길어 Backdrop 사용
      setOpenBackdrop(true);
      // await dispatch(g04docuActions.selectAllByQuery());
      
      if (g04docuDivision === "ALL") {
        await selectAllByQuery();
      } else {
        await selectAllByDivisionByQuery(g04docuDivision);
      }

      // watermark 안보이면서 로딩바 보이도록 하기 위한 임시 코드
      setOpenBackdrop(false);
      setShow(true);
      setTimeout(() => setLoaded(true), 300);
    }, [g04docuDivision]
  )

  const handleChangeGStandard = (e) => {
    console.log(e)
    selectGStandardById(e.target.value);
  }

  const handleChangeDetails = (e, i) => {
    // console.log(e)
    // console.log(i)
    const newDetails = [].concat(details);
    newDetails.splice(i, 1, e.target.value);
    console.log(newDetails);
    setDetails(newDetails);
  }

  const handleChangeCheckValue = (e, checked, name) => {
    console.log(details)
    console.log(e)
    console.log(checked)
    console.log(name)

    const arr = [].concat(details);

    const newCertificationStandards = arr.map(certificationStandard => {
      if (certificationStandard.name === name) {
        return {
          ...certificationStandard,
          checked,
        }
      }

      return certificationStandard;
    })

    console.log(newCertificationStandards);
    setDetails(newCertificationStandards);
  }

  const handleChangeDivider = (e) => {
    
    // const oldValue = getValues('divider');
    const { value } = e.target;

    setValue("divider", value);
    
    if (g04docu === 'CERTIFICATION') {

      setDetails(details.map(detail => {
        let { type, divider } = detail;
        const arrType = type.split(divider);
        return {
          ...detail,
          type: arrType.join(value),
          divider: value,
        }  
      }));
    } else if (g04docu === 'TEST') {
      details?.length > 0 && setValue("name", details.join(value === "none" ? "" : value));
    }
  }

  const handleChangeG04docuDivision = (e) => {
    setG04docuDivision(e.target.value);
  }

  // const generateCertifications = () => {
  //   const arrCertification = [];
  //   gstandard.certifications?.forEach((certification, i) => {
  //     arrCertification.push(certification);
  //   })

  //   const arrCartesian = arrCertification.reduce(
  //     (accumulator, currentValue) => accumulator.cross(currentValue),
  //   );

  //   const certificationStandards = [];

  //   // arrCartesian은 2차원 배열
  //   arrCartesian.forEach(arr1 => {
  //     let certificationStandard = "";
  //     if (Array.isArray(arr1)) {
  //       arr1.forEach(arr2 => {
  //         for (const [item, value] of Object.entries(arr2)) {
  //           certificationStandard += value + getValues("divider");
  //         }
  //       })

  //       certificationStandards.push(certificationStandard.substring(0, certificationStandard.length-1));
  //     } else {
  //       for (const [item, value] of Object.entries(arr1)) {
  //         certificationStandard += value;
  //       }
  //       certificationStandards.push(certificationStandard);
  //     }
  //   })

  //   // return JSON.stringify(certificationStandards);
  //   return certificationStandards.map((item, i) => {
  //     return (
  //       <Grid item xs={6} display="flex" alignItems="center">
  //         {/* <FormInputText
  //           control={control}
  //           label={"인증서 규격"}
  //           inputProps={
  //             { readOnly: crudMode === 'R' }
  //           }
  //           value={item}
  //         /> */}
  //         <FormInputCheckbox
  //           name={`${i}_certificationStandard`}
  //           control={control}
  //           // label={item}
  //           setValue={setValue}
  //           onChangeCheckValue={handleChangeCheckValue}
  //         />
  //         <Typography>{item}</Typography>
  //       </Grid>
  //     )
  //   })
  // }

  return (
    <ThemeProvider theme={theme}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container component="main" maxWidth="lg">
        <CssBaseline />
        <Dialog
          open={openFindDialog}
          onClose={handleFindDialogClose}
          fullWidth={true}
          maxWidth={"md"}
          // PaperComponent={PaperComponent}
          // 팝업의 팝업은 drag and drop이 안됨.
          // aria-labelledby="draggable-dialog-title-find"
        >
          <DialogTitleClose
            // id="draggable-dialog-title-find"
            onClose={handleFindDialogClose}
            // style={{ cursor: 'move' }}
          >
            {"상위요소 선택"}
          </DialogTitleClose>
          <DialogContent dividers>
            <Grid container>
              <div style={{ height: 400, width: '100%' }}>
                <DataGridPro
                  localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
                  columnHeaderHeight={38}
                  rowHeight={34}
                  sx={{ visibility: showFind ? 'visible' : 'hidden', cursor: 'pointer', fontSize: '0.85em' }}
                  treeData
                  groupingColDef={{ headerName : "아이디 (하위요소 개수)" }}
                  getTreeDataPath={(row) => row.path}
                  // initialState={{ pinnedColumns: { left: ['id', 'name', 'code'] } }}
                  slots={{
                    noRowsOverlay: CustomNoRowsOverlay,
                    loadingOverlay: LinearProgress,
                  }}
                  loading={!loadedFind}
                  rows={rowsFind}
                  columns={columnsFind}
                  pageSize={pageSizeFind}
                  onPageSizeChange={(newPageSize) => setPageSizeFind(newPageSize)}
                  rowsPerPageOptions={[10, 20, 50, 100]}
                  pagination
                  onRowClick={(params) => {
                    setSelectedRowsFind(params);
                  }}
                  // 더블클릭시 포커스가 가거나 하는 동작 때문에 잘 선택되지 않는 경우가 발생하므로
                  // 이를 해결하기 전까지는 더블클릭 이벤트 사용하지 않음
                  // onRowDoubleClick={(params) => {
                  //   setValue("pid", params.id);
                  //   handleFindDialogClose();
                  // }}
                />
              </div>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSelectFind}>{"선택"}</Button>
            <Button onClick={handleFindDialogClose}>{"닫기"}</Button>
          </DialogActions>
        </Dialog>
        {/* TODO : G04docuDialog로 분리할 것 */}
        <Dialog
          PaperProps={{style: {overflowY: 'visible'}}}
          open={open}
          onClose={handleDialogClose}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitleClose
            id="draggable-dialog-title"
            onClose={handleDialogClose}
            style={{ cursor: 'move' }}
          >
            { crudMode === 'C' && "구성요소 등록" }
            { crudMode === 'R' && "구성요소 상세" }
            { crudMode === 'U' && "구성요소 수정" }
          </DialogTitleClose>
          <DialogContent
            style={{ overflowY: 'visible' }}
            dividers
          >
            <ul>
              {errors.map((error, idx) => <li key={idx}>{error}</li>)}
            </ul>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {/* TODO : 읽기전용 기능 추가 필요 */}
                <FormInputToggleButton
                  name="division"
                  control={control}
                  label="규격구분"
                  exclusive
                  // RAW_MATERIAL / SUB_MATERIAL_PROCESS / SUB_MATERIAL_BUILD / PROCESS
                  options={[
                    {
                      label: "원자재",
                      value: "RAW_MATERIAL",
                    },
                    {
                      label: "가공부자재",
                      value: "SUB_MATERIAL_PROCESS",
                    },
                    {
                      label: "시공부자재",
                      value: "SUB_MATERIAL_BUILD"
                    },
                    {
                      label: "공정",
                      value: "PROCESS"
                    },
                  ]}
                  // onClick={handleChangeDivision}
                />
              </Grid>
              <Grid item xs={6}>
                {/* TODO : 읽기전용 기능 추가 필요 */}
                <FormInputToggleButton
                  name="type"
                  control={control}
                  label="구분"
                  exclusive
                  options={[
                    {
                      label: "구분",
                      value: "CATEGORY",
                    },
                    {
                      label: "규격",
                      value: "STANDARD",
                    },
                    {
                      label: "인증",
                      value: "CERTIFICATION"
                    },
                    // {
                    //   label: "성적서",
                    //   value: "TEST"
                    // },
                    // {
                    //   label: "기타",
                    //   value: "ETC"
                    // },
                  ]}
                  onClick={handleChangeType}
                />
              </Grid>
              <Grid item xs={6} display="flex" alignItems="end">
                <FormInputDropdown
                  name={"gstandardId"}
                  control={control}
                  label={"표준명(한글)"}
                  onChangeSelectValue={handleChangeGStandard}
                  options={
                    [{ label: '없음', value: "" }].concat(gstandards.map(gstandard => {
                      return {
                        label: gstandard.no,
                        value: gstandard.id,
                        name: gstandard.name,
                      }
                    }))
                  }
                  inputProps={
                    { readOnly: crudMode === 'R' }
                  }
                />
              </Grid>
              <Grid item xs={12} sx={{ display: 'none' }}>
                <FormInputText
                  name={"id"}
                  control={control}
                  label={"아이디"}
                  inputProps={
                    { readOnly : true }
                  }
                />
              </Grid>
              {
                g04docu === "CERTIFICATION" && gstandard && (
                  <Grid item xs={12} sx={{ mb: 1 }}>
                    {/* <Grid container spacing={2}> */}
                      <Card>
                        <CardHeader
                          titleTypographyProps={{ variant: 'subtitle1' }}
                          // title={"종류등〮급호〮칭 또는 모델"}
                          title={"종류·등급·호칭 또는 모델"}
                          sx={{ bgcolor: "#eaeaea" }}
                        />
                        <CardContent>
                          <Grid container spacing={1}>
                          {
                            // generateCertifications()
                            details.map((item, i) => {
                              return (
                                <Grid item xs={6} display="flex" alignItems="center">
                                  {/* <FormInputText
                                    control={control}
                                    label={"인증서 규격"}
                                    inputProps={
                                      { readOnly: crudMode === 'R' }
                                    }
                                    value={item}
                                  /> */}
                                  <FormInputCheckbox
                                    name={`${i}_certificationStandard`}
                                    control={control}
                                    // label={item}
                                    setValue={setValue}
                                    onChangeCheckValue={handleChangeCheckValue}
                                    checked={item.checked}
                                  />
                                  <Typography>{item.type}</Typography>
                                </Grid>
                              )
                            })
                          }
                          </Grid>
                        </CardContent>
                      </Card>
                      {/* {
                        // TODO : 조합하여 목록으로 보여줄 것
                        gstandard.certifications?.map((certification, i) => (
                          <Grid item xs={12/gstandard.certifications?.length}>
                            <FormInputText
                              select
                              // name={`selectCertification_${i}`}
                              control={control}
                              label={"인증서 규격"}
                              inputProps={
                                { readOnly: crudMode === 'R' }
                              }
                              options={
                                certification.map(item => ({
                                  label: item[`type${i}`],
                                  value: item[`type${i}`],
                                }))
                              }
                              onChange={(e) => handleChangeDetails(e, i)}
                              value={details && details.length > i && details[i]}
                            />
                          </Grid>
                        ))
                      } */}
                    {/* </Grid> */}
                  </Grid>
                )
              }
              {/* {
                g04docu === "TEST" && (
                  <FormInputText
                    control={control}
                    label={"성적서 임시"}
                    inputProps={
                      { readOnly: crudMode === 'R' }
                    }
                    multiline
                    maxRows={100}
                    value={JSON.stringify(gstandard.certifications, null, 2)}
                  />
                )
              } */}
              {
                g04docu === "TEST" && gstandard && (
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      {
                        gstandard.testCertifications?.map((testCertification, i) =>  (
                          <Grid item xs={12/gstandard.testCertifications?.length}>
                            <FormInputText
                              select
                              control={control}
                              label={"성적서 규격"}
                              inputProps={
                                { readOnly: crudMode === 'R' }
                              }
                              options={
                                testCertification.map(item => ({
                                  label: item[`type${i}`],
                                  value: item[`type${i}`],
                                }))
                              }
                              onChange={(e) => handleChangeDetails(e, i)}
                              value={details && details.length > i && details[i]}
                            />
                          </Grid>
                        ))
                      }
                    </Grid>
                  </Grid>
                )
              }
              {/* {
                g04docu === "TEST" && (
                  <FormInputText
                    control={control}
                    label={"인증서 임시"}
                    inputProps={
                      { readOnly: crudMode === 'R' }
                    }
                    multiline
                    maxRows={100}
                    value={JSON.stringify(gstandard.testCertifications, null, 2)}
                  />
                )
              } */}
              {
                (g04docu === "CERTIFICATION" || g04docu === "TEST") && gstandard && (
                  <Grid item xs={12}>
                    <FormInputText
                      select
                      name={"divider"}
                      control={control}
                      label={"구분자"}
                      inputProps={
                        { readOnly: crudMode === 'R' }
                      }
                      options={[
                        // { label: "없음", value: "none" },
                        { label: "공백", value: " " },
                        { label: "+", value: "+" },
                        { label: "-", value: "-" },
                        { label: "*", value: "*" },
                        { label: "/", value: "/" },
                        { label: ",", value: "," },
                      ]}
                      onChange={handleChangeDivider}
                    />
                  </Grid>
                )
              } 
              <Grid item xs={12}>
                <FormInputText
                  name={"name"}
                  control={control}
                  label={"이름"}
                  inputProps={
                    // { readOnly: crudMode === 'R' }
                    { readOnly: (/*g04docu === "CERTIFICATION" || */g04docu === "TEST" || crudMode === 'R') ? true : false }
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <FormInputText
                  name={"code"}
                  control={control}
                  label={"코드"}
                  inputProps={
                    { readOnly: crudMode === 'R' }
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <FormInputText
                  name={"comments"}
                  control={control}
                  label={"설명"}
                  multiline
                  maxRows={5}
                  inputProps={
                    { readOnly: crudMode === 'R' }
                  }
                />
              </Grid>
              <Grid item xs={10} sx={{ display: 'none' }}>
                <FormInputText
                  name={"pid"}
                  control={control}
                  label={"상위 구성요아이디"}
                  disabled
                />
              </Grid>
              <Grid item xs={10}>
                <FormInputText
                  name={"pname"}
                  control={control}
                  label={"상위 구성요소명"}
                  disabled
                />
              </Grid>
              <Grid item xs={2} display="flex" justifyContent="flex-end" alignItems="center">
                <Fab
                  color="primary"
                  size="small"
                  variant="extended"
                  onClick={handleClickFind}
                  // 찾기 버튼을 비활성해야 하는 경우
                  // 1. 상세보기일 경우
                  // 2. 하위등록일 경우 (crudMode가 'C'이고 pid가 설정된 경우)
                  disabled={crudMode === 'R' || (crudMode === 'C' && getValues('pid') !== undefined && getValues('pid') !== null && getValues('pid') !== "")}
                >
                  <Search />
                  {"찾기"}&nbsp;
                </Fab>
              </Grid>
              <Grid item xs={12} display="flex" alignItems="center">
                <FormInputCheckbox
                  name="majority"
                  control={control}
                  // label={"주로 사용"}
                  setValue={setValue}
                  // onChangeCheckValue={handleChangeStatusCheckButton}
                  inputProps={
                    { readOnly: crudMode === 'R' }
                  }
                />
                <Typography>{"주로 사용"}</Typography>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            {
              crudMode === 'C' && (
                <>
                  <Button onClick={handleSubmit(onSubmit)}>{"저장"}</Button>
                  <Button onClick={() => reset()}>{"초기화"}</Button>
                </>
              )
            }
            { crudMode === 'U' && <Button onClick={handleSubmit(onSubmit)}>{"수정"}</Button> }
            <Button onClick={handleDialogClose}>{"닫기"}</Button>
          </DialogActions>
        </Dialog>
        <Box
          sx={{
            mt: 3,
            '& .super-app-theme--header': {
              backgroundColor: 'rgba(255, 7, 0, 0.55)',
            },
            '& .super-app-theme--cell' : {
              backgroundColor: 'rgba(255, 7, 0, 0.55)',
            },
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={10} display="flex" alignItems="center">
              <Filter1 />
              {/* <Typography variant="h6" sx={{ mt: 3, mb: 2 }}>{"▶ 상위 구성요소"}</Typography> */}
              <Typography variant="h6" sx={{ mt: 2, mb: 2, ml: 2 }}>{"상위 구성요소"}</Typography>
            </Grid>
            <Grid item xs={2} display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                startIcon={<Add />}
                onClick={handleClickOpen}
              >
                {"등록하기"}
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={10}>
              <ToggleButtonGroup
                color="primary"
                value={g04docuDivision}
                exclusive
                onChange={handleChangeG04docuDivision}
                aria-label="Platform"
                size="small"
              >
                {/* 규격구분 :  RAW_MATERIAL / SUB_MATERIAL_PROCESS / SUB_MATERIAL_BUILD / PROCESS */}
                <ToggleButton value={"ALL"} sx={{ fontWeight: 'bold' }}>{"전체"}</ToggleButton>
                <ToggleButton value={"RAW_MATERIAL"} sx={{ fontWeight: 'bold' }}>{"원자재"}</ToggleButton>
                <ToggleButton value={"SUB_MATERIAL_PROCESS"} sx={{ fontWeight: 'bold' }}>{"가공부자재"}</ToggleButton>
                <ToggleButton value={"SUB_MATERIAL_BUILD"} sx={{ fontWeight: 'bold' }}>{"시공부자재"}</ToggleButton>
                <ToggleButton value={"PROCESS"} sx={{ fontWeight: 'bold' }}>{"공정"}</ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid item xs={2} display="flex" justifyContent="flex-end" sx={{ mb: 2 }}>
              <FormControlLabel
                control={
                  <Switch 
                    checked={checked}
                    onChange={handleChangeOrder}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label={"순서"}
              />
            </Grid>
          </Grid>
          <div style={{ height: 400, width: '100%' }}>
            <DataGridPro
              localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
              columnHeaderHeight={38}
              rowHeight={34}
              sx={{ visibility: show ? 'visible' : 'hidden', cursor: 'pointer', fontSize: '0.85em' }}
              treeData
              // groupingColDef={{ headerName : "아이디 (하위요소 개수)" }}
              groupingColDef={{ headerName : "이름 (하위요소 개수)", width: 300 }}
              getTreeDataPath={(row) => row.path}
              initialState={{ pinnedColumns: { /*left: ['id', 'name', 'code'], */right: ['actions'] } }}
              slots={{
                noRowsOverlay: CustomNoRowsOverlay,
                loadingOverlay: LinearProgress,
              }}
              loading={!loaded}
              rows={rows}
              columns={columns}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[10, 20, 50, 100]}
              pagination
              // CheckCircleOutlineSelection
              // disableSelectionOnClick
              onRowSelectionModelChange={(ids) => {
                const selectedIDs = new Set(ids);
                const selectedRows = rows.filter((row) =>
                  selectedIDs.has(row.id),
                );
      
                setSelectedRows(selectedRows);

                // 현재는 단일 선택 기준임
                if (selectedRows.length === 1) {
                  setLoadedChild(false);
                  selectByPid(selectedRows[0].id);
                }
              }}
              onRowDoubleClick={(params) => handleSelect({ type: 'edit', params })}
              // onRowClick={(params, e) => e.detail === 2 && handleSelect({ type: 'edit', params })} // double click only
            />
          </div>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={10} display="flex" alignItems="center">
              <Filter2 />
              <Typography variant="h6" sx={{ mt: 2, mb: 2, ml: 2 }}>{"하위 구성요소"}</Typography>
            </Grid>
            <Grid item xs={2} display="flex" justifyContent="flex-end" sx={{ mb: 2 }}>
              <FormControlLabel
                control={
                  <Switch 
                    checked={checkedChild}
                    onChange={handleChangeOrderChild}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label={"순서"}
              />
            </Grid>
          </Grid>
          <div style={{ height: 400, width: '100%' }}>
            <Skeleton variant="rounded" height={show ? 0 : 800} />
            <DataGridPro
              localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
              columnHeaderHeight={38}
              rowHeight={34}
              sx={{ visibility: show ? 'visible' : 'hidden', cursor: 'pointer', fontSize: '0.85em' }}
              loading={!loadedChild}
              initialState={{ pinnedColumns: { /*left: ['id', 'name', 'code'], */right: ['actions'] } }}
              slots={{
                noRowsOverlay: CustomNoRowsOverlay,
                loadingOverlay: LinearProgress,
              }}
              rows={rowsChild}
              columns={columnsChild}
              pageSize={pageSizeChild}
              onPageSizeChange={(newPageSize) => setPageSizeChild(newPageSize)}
              rowsPerPageOptions={[10, 20, 50, 100]}
              pagination
              // onRowSelectionModelChange={(ids) => {
              //   const selectedIDs = new Set(ids);
              //   const selectedRows = rows.filter((row) =>
              //     selectedIDs.has(row.id),
              //   );
      
              //   setSelectedRows(selectedRows);
              // }}
              onRowDoubleClick={(params) => handleSelectChild({ type: 'edit', id: params.id })}
            />
          </div>
        </Box>
        {/* TODO : 하나의 ConfirmDialog 쓰기 위해 useState로 onConfirm 함수 설정 시도했으나 동작하지 않아 아래와 같이 두개 사용. 추후 재검토 필요 */}
        <ConfirmDialog
          removeId={removeId}
          title={"삭제"}
          open={confirmOpen}
          setOpen={setConfirmOpen}
          onConfirm={remove}
          onCancel={() => {}}
        >
          {`"${params && params.row && params.row.name || ""}(아이디 : ${params && params.id  || ""})"를 삭제하시겠습니까?`}
        </ConfirmDialog>
        <ConfirmDialog
          removeId={removeId}
          title={"삭제"}
          open={confirmOpenChild}
          setOpen={setConfirmOpenChild}
          onConfirm={removeChild}
          onCancel={() => {}}
        >
          {`"${params && params.row && params.row.name || ""}(아이디 : ${params && params.id  || ""})"를 삭제하시겠습니까?`}
        </ConfirmDialog>
      </Container>
    </ThemeProvider>
  );
};

export default G04docuManagement;
