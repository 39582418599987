const SET_OPTIONS = 'alertDialog/setOptions';

export const setOptions = (options) => ({
  type: SET_OPTIONS,
  payload: options,
});

const initialState = {
  options: null,
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case SET_OPTIONS:
      newState = Object.assign({}, state, { options: action.payload });
      return newState;
    default:
      return state;
  }
}

export default reducer;
