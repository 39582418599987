import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box } from '@mui/material';

// 자체 컴포넌트
import { ConfirmDialog } from "../dialog";

// store actions
import * as confirmDialogActions from "../../store/components/confirmDialog";

const GTestComparisonlDeleteConfirmDialog = ({
}) => {

  const dispatch = useDispatch();

  const options = useSelector((state) => state.confirmDialog.options);

  const setOptions = (options) => dispatch(confirmDialogActions.setOptions(options));

  return (
    <ConfirmDialog
      removeId={options?.params}
      title={"삭제"}
      open={options?.open || false}
      setOpen={() => setOptions({ ...options, open: false })}
      onConfirm={(params) => options?.action(params)}
      onCancel={() => {}}
    >
      {
        options && (<div>
          {"선택한 시험성과 항목 "}<span style={{ color: "#1976d2" }}>{`${options?.params?.row.item || ""}`}</span>{` 를 삭제하시겠습니까?`}
          <br /><br />
          <Box sx={{ solid: 1, borderRadius: 1, bgcolor: '#eee', color: '#aaa', p: 1, fontSize: '0.8em' }}>
          { `구분 : ${options?.params?.row?.item}` }<br />
          { `단위 : ${options?.params?.row?.unit}` }<br />
          { `기준 : ${options?.params?.row?.criterion}` }
          </Box>
        </div>)
      }
    </ConfirmDialog>
  )
}

export default GTestComparisonlDeleteConfirmDialog;