import { csrfFetch } from "./csrf.js";
import { authServerUrl } from '../config';

const SET_SECURITY_REQUEST = 'gclient/setSecurityRequest';
const SET_SECURITY_REQUESTS = 'gclient/setSecurityRequests';
const SET_SECURITY_TO_REQUESTS = 'gclient/setSecurityToRequests';
const SET_SECURITY_TO_APPROVALS = 'gclient/setSecurityToApprovals';

const setSecurityRequest = (securityRequest) => ({
  type: SET_SECURITY_REQUEST,
  payload: securityRequest,
});

const setSecurityRequests = (securityRequests) => ({
  type: SET_SECURITY_REQUESTS,
  payload: securityRequests,
});

const setSecurityToRequests = (securityToRequests) => ({
  type: SET_SECURITY_TO_REQUESTS,
  payload: securityToRequests,
});

const setSecurityToApprovals = (securityToApprovals) => ({
  type: SET_SECURITY_TO_APPROVALS,
  payload: securityToApprovals,
});

export const create = ({ id, senderId, receiverId, service, status }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/securityRequests`, {
    method: "POST",
    body: JSON.stringify({ id, senderId, receiverId, service, status }),
  });
  const data = await response.json();
  dispatch(setSecurityRequest(data.securityRequest));
  return response;
};

export const modify = ({ id, senderId, receiverId, service, status }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/securityRequests`, {
    method: "PUT",
    body: JSON.stringify({ id, senderId, receiverId, service, status }),
  });
  const data = await response.json();
  dispatch(setSecurityRequest(data.securityRequest));
  return response;
};

export const modifyStatus = ({ id, status }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/securityRequests/${id}/status`, {
    method: "PUT",
    body: JSON.stringify({ status }),
  });
  const data = await response.json();
  dispatch(setSecurityRequest(data.securityRequest));
  return response;
};

export const modifyStatusByIds = ({ ids, status }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/securityRequests/status/ids`, {
    method: "PUT",
    body: JSON.stringify({ ids, status }),
  });
  const data = await response.json();
  dispatch(setSecurityRequests(data.securityRequests));
  return response;
};

export const selectAll = () => async dispatch => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/securityRequests`);
  const data = await response.json();
  dispatch(setSecurityRequests(data.securityRequests));
  return response;
}


export const select = (id) => async dispatch => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/securityRequests/${id}`);
  const data = await response.json();
  dispatch(setSecurityRequests(data.securityRequests));
  return response;
}

export const selectGClientsBySecurityRequestStatusByQueryDirect = async ({ senderId, receiverIds }) => {
  const response = await csrfFetch(null, `${authServerUrl}/api/securityRequests/query/selectGClientsByStatus`, {
    method: "POST",
    body: JSON.stringify({ senderId, receiverIds }),
  });
  const data = await response.json();
  return data.gclientsWithSecurityRequestStatus;
}

export const selecteSecurityToRequestsByQuery = (senderId) => async dispatch => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/securityRequests/query/toRequest/${senderId}`);
  const data = await response.json();
  dispatch(setSecurityToRequests(data.securityToRequests));
  return response;
}

export const selecteSecurityToApprovalsByQuery = (receiverId) => async dispatch => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/securityRequests/query/toApproval/${receiverId}`);
  const data = await response.json();
  dispatch(setSecurityToApprovals(data.securityToApprovals));
  return response;
}

export const init = () => dispatch => {
  dispatch(setSecurityRequest(null));
}

const initialState = {
  securityRequest: null,
  securityRequests: [],
  securityToRequests: [],
  securityToApprovals: [],
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case SET_SECURITY_REQUEST:
      newState = Object.assign({}, state, { securityRequest: action.payload });
      return newState;
    case SET_SECURITY_REQUESTS:
      newState = Object.assign({}, state, { securityRequests: Array.isArray(action.payload) ? action.payload : [] });
      return newState;
    case SET_SECURITY_TO_REQUESTS:
      newState = Object.assign({}, state, { securityToRequests: Array.isArray(action.payload) ? action.payload : [] });
      return newState;
    case SET_SECURITY_TO_APPROVALS:
      newState = Object.assign({}, state, { securityToApprovals: Array.isArray(action.payload) ? action.payload : [] });
      return newState;
    default:
      return state;
  }
}

export default reducer;
