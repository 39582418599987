import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DataGridPro, GridActionsCellItem, koKR } from '@mui/x-data-grid-pro';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  FormControlLabel,
  Grid,
  LinearProgress,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import  {
  Add,
  Delete,
  Edit,
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowUp,
  KeyboardArrowDown,
  KeyboardArrowUp,
  OpenInNew,
} from '@mui/icons-material';
import {
  ConfirmDialog,
} from "../dialog";
import {
  CustomNoRowsOverlay,
  // CustomLoadingOverlay,
} from "../datagrid";
import {
  StringAvatar,
} from "../avatar";
import { dateFormat } from "../../utils";
import * as gclientActions from "../../store/gclient";
import GClientDialog from "./GClientDialog";

const theme = createTheme();

const GClientManagement = ({ title, from }) => {
  // from structure (example)
  // from={
  //   {
  //     source: "GSubItemManagement",
  //     params: {
  //       selManufacturers,
  //     }
  //   }
  // }
  const [crudMode, setCrudMode] = useState('');
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [removeId, setRemoveId] = useState(); // TODO : 파라미터 없으면 undefined?
  const [params, setParams] = useState({});
  const [checked, setChecked] = useState(false);
  const [pageSize, setPageSize] = useState(100);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  
  const handleSelect = async ({ type, params }) => {
    const { id } = params;

    setOpenBackdrop(true);

    if (type === "detail") {
      await select(id);
      setCrudMode('R');
      setOpen(true);
    } else if (type === "edit") {
      await select(id);
      setCrudMode('U');
      setOpen(true);
    } else if (type === "delete") {
      setRemoveId(id);
      setParams(params);
      setConfirmOpen(true);
    }

    setOpenBackdrop(false);
  }

  const handleSelectUpDown = async ({ type, id }) => {
    setOpenBackdrop(true);

    await reorder({ type, id });
    setLoaded(false);
    await selectAll();
    setTimeout(() => setLoaded(true), 300)

    setOpenBackdrop(false);
  }

  const remove = (removeId) => {
    dispatch(gclientActions.remove(removeId))
    .then(res => selectAll())
    .catch(async (res) => {
      const data = await res.json();
      if (data && data.errors) setErrors(data.errors);
    });
  }

  // 저장/수정 다이얼로그
  const handleClickOpen = () => {
    initGClient();
    setOpen(true);
    setCrudMode('C');
  };

  const handleChangeOrder = () => {
    setChecked(!checked);
  }

  const generateActions = (params) => {
    let arrActions = [
      <GridActionsCellItem
        icon={<OpenInNew />}
        label={"상세"}
        onClick={() => handleSelect({ type: 'detail', params })}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<Edit />}
        label={"수정"}
        onClick={() => handleSelect({ type: 'edit', params })}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<Delete />}
        label={"삭제"}
        onClick={() => handleSelect({ type: 'delete', params })}
        showInMenu
      />,
    ];

    if (checked) {
      arrActions = arrActions.concat([
          <GridActionsCellItem
            icon={<KeyboardDoubleArrowUp />}
            label={"맨위로"}
            onClick={() => handleSelectUpDown({ type: 'first', id: params.id })}
            disabled={params.row.orderDetail === 'F'}
          />,
          <GridActionsCellItem
            icon={<KeyboardArrowUp />}
            label={"위로"}
            onClick={() => handleSelectUpDown({ type: 'up', id: params.id })}
            disabled={params.row.orderDetail === 'F'}
          />,
          <GridActionsCellItem
            icon={<KeyboardArrowDown />}
            label={"아래로"}
            onClick={() => handleSelectUpDown({ type: 'down', id: params.id })}
            disabled={params.row.orderDetail === 'L'}
          />,
          <GridActionsCellItem
            icon={<KeyboardDoubleArrowDown />}
            label={"맨아래로"}
            onClick={() => handleSelectUpDown({ type: 'last', id: params.id })}
            disabled={params.row.orderDetail === 'L'}
          />
        ]
      );
    }

    return arrActions;
  }

  const handleChangeToggleRejectAlarmEmailYN = async ({ params, e }) => {
    e.stopPropagation();
    
    await modifyRejectAlarmEmailYN({ id: params.id, rejectAlarmEmailYN: e.target.checked ? 'Y' : 'N' });

    setLoaded(false);
    
    await selectAll();

    setTimeout(() => setLoaded(true), 300);
  }

  const columnsBasic = [
    // {
    //   field: 'id',
    //   headerName: '아이디',
    //   width: 300,
    //   hide: true,
    // },
    // {
    //   field: 'gclientTypes',
    //   headerName: '구분',
    //   width: 118,
    //   headerAlign: 'center',
    //   // align: 'center',
    //   renderCell: (params) => {
    //     return (
    //       <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.2 }}>
    //         {params.row?.gclientTypes?.map((gclientType, idx) => {
    //           if (idx < 3) {
    //             // return <Chip key={gclientType.id} color="primary" variant="outlined" label={gclientType.name.substring(0, 2)} />
    //             return (
    //               // TODO : 추후 색상 정하도록... 글자기반 자동 계산
    //               <StringAvatar
    //                 name={gclientType.name === "시공팀" ? gclientType.name.substring(gclientType.name.length-1) : gclientType.name.substring(0, 1)}
    //                 width={24}
    //                 height={24}
    //                 fontSize={"small"}
    //               />
    //             );
    //           } else {
    //             return ".";
    //           }
    //         })}
    //       </Box>
    //     )
    //   }
    // },
    {
      field: 'name',
      headerName: '이름',
      width: 240,
      // renderCell: (params) => {
      //   return (
      //     <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.4 }}>
      //       {params.row?.name}
      //       {params.row?.gclientTypes?.map((gclientType, idx) => {
      //         // if (idx < 3) {
      //           // return <Chip key={gclientType.id} color="primary" variant="outlined" label={gclientType.name.substring(0, 2)} />
      //           // let stringAvatar = "";
      //           // const { name } = gclientType;
      //           // if (name === "시공팀") {
      //           //   stringAvatar = gclientType.name.substring(gclientType.name.length-1);
      //           // } else if (name === "시공업체") {
      //           //   stringAvatar = gclientType.name.substring(1, 2);
      //           // } else {
      //           //   stringAvatar = gclientType.name.substring(0, 1);
      //           // }

      //           return (
      //             // TODO : 추후 색상 정하도록... 글자기반 자동 계산
      //             <StringAvatar
      //               // 시공팀, 시공업체 모두 "시"이므로 시공팀은 "팀", 시공업체는 "시"
      //               // name={gclientType.name === "시공팀" ? gclientType.name.substring(gclientType.name.length-1) : gclientType.name.substring(0, 1)}
      //               // name={stringAvatar}
      //               name={gclientType.displayType ? gclientType.displayType : ""}
      //               width={20}
      //               height={20}
      //               fontSize={"small"}
      //               color={gclientType.color}
      //             />
      //           );
      //         // } else {
      //         //   return ".";
      //         // }
      //       })}
      //     </Box>
      //   )
      // }
    },
    // TODO : JAVA
    {
      field: 'gclientTypes',
      headerName: '대분류',
      width: 200,
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.4 }}>
            {params.row?.gclientTypes?.map((gclientType, idx) => {
              return (
                <StringAvatar
                  name={gclientType.displayType ? gclientType.displayType : ""}
                  width={20}
                  height={20}
                  fontSize={"small"}
                  color={gclientType.color}
                />
              );
            })}
          </Box>
        )
      }
    },
    // TODO : JAVA
    {
      field: 'gclientType04docus',
      headerName: '세분류',
      width: 200,
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.4 }}>
            {params.row?.gclientType04docus?.map((gclientType04docu, idx) => {
              return (
                <StringAvatar
                  name={gclientType04docu?.displayType ? gclientType04docu.displayType : ""}
                  width={20}
                  height={20}
                  fontSize={"small"}
                  color={gclientType04docu?.color}
                />
              );
            })}
          </Box>
        )
      }
    },
    {
      field: 'bizRegNumber',
      headerName: '사업자등록번호',
      width: 160,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'code',
      headerName: '코드',
      width: 200,
      hide: true,
    },
    {
      field: 'phone',
      headerName: '연락처',
      width: 140,
    },
    {
      field: 'address',
      headerName: '주소',
      width: 450,
    },
    {
      field: 'email',
      headerName: '회사대표메일',
      width: 220,
    },
    // {
    //   field: 'inChargeName',
    //   headerName: '담당자명',
    //   width: 120,
    //   valueGetter: (params) => {
    //     const { inCharges } = params.row;
    //     if (inCharges && Array.isArray(inCharges) && inCharges.length > 0) {
    //       const additional = inCharges.length > 1 ? ` 외 ${inCharges.length-1}인` : ""
    //       return `${inCharges[0].inChargeName}${additional}`;
    //     }

    //     return "";
    //   },
    // },
    // {
    //   field: 'inChargePhone',
    //   headerName: '담당자연락처',
    //   width: 150,
    //   valueGetter: (params) => {
    //     const { inCharges } = params.row;
    //     if (inCharges && Array.isArray(inCharges) && inCharges.length > 0) {
    //       return inCharges[0].inChargePhone;
    //     }

    //     return "";
    //   },
    // },
    // {
    //   field: 'inChargeEmail',
    //   headerName: '담당자메일',
    //   width: 220,
    //   valueGetter: (params) => {
    //     const { inCharges } = params.row;
    //     if (inCharges && Array.isArray(inCharges) && inCharges.length > 0) {
    //       return inCharges[0].inChargeEmail;
    //     }

    //     return "";
    //   },
    // },
    {
      field: 'rejectAlarmEmailYN',
      headerName: '만료안내메일 수신거부',
      headerAlign: 'center',
      align: 'center',
      width: 200,
      renderCell: (params) => {
        let rejectAlarmEmailYN = params.value;
        return (
          <Switch
            checked={rejectAlarmEmailYN === 'Y' ? true : false}
            onChange={(e) => handleChangeToggleRejectAlarmEmailYN({ params, e })}
          />
        )
      },
    },
    {
      field: 'systemUrl',
      headerName: '시스템 URL',
      width: 280,
    },
    {
      field: 'comments',
      headerName: '설명',
      width: 280,
    },
    {
      field: 'createdAt',
      headerName: '생성일시',
      width: 160,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => dateFormat(params.value),
    },
    {
      field: 'updatedAt',
      headerName: '수정일시',
      width: 160,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => dateFormat(params.value),
    },
  ];

  const columns = (from !== undefined && from !== null) ? columnsBasic : columnsBasic.concat({
    field: 'actions',
    headerName: <Tooltip title={"상세/수정/삭제"} followCursor><Box>{"기능"}</Box></Tooltip>,
    width: checked ? 200 : 70,
    // description: "수정/삭제", // 불편해서 주석처리
    type: 'actions',
    getActions: (params) => generateActions(params),
  });

  const rows = useSelector((state) => state.gclient.gclients);
  const selectedRow = useSelector((state) => state.gclient.gclient);
  const gclientTypes = useSelector((state) => state.gclientType.gclientTypes);
  
  // 데이터 관리
  const dispatch = useDispatch();

  const selectAll = () => dispatch(gclientActions.selectAll())
  const selectAllByIds = (ids) => dispatch(gclientActions.selectAllByIds(ids))
  const select = (id) => dispatch(gclientActions.select(id))
  const initGClient = () => dispatch(gclientActions.init())
  const reorder = ({ type, id }) => gclientActions.reorder({ type, id })
  const modifyRejectAlarmEmailYN = ({ id, rejectAlarmEmailYN }) => dispatch(gclientActions.modifyRejectAlarmEmailYN({ id, rejectAlarmEmailYN }))

  useEffect(
    async () => {
      if (from?.params?.selManufacturers) {
        const ids = from.params.selManufacturers.map(manufacturer => ({ id: manufacturer.id }));
        console.log(ids)
        await selectAllByIds(ids);
      } else {
        await selectAll();
      }
      
      setLoaded(true);
    }, [dispatch]
  );

  return (
    <ThemeProvider theme={theme}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container component="main" maxWidth="false">
        <CssBaseline />
        <GClientDialog
          crudMode={crudMode}
          setCrudMode={setCrudMode}
          open={open}
          setOpen={setOpen}
          selectedRow={selectedRow}
          refresh={selectAll}
        />
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={10}>
              <Typography variant="h5">
                {title}
              </Typography>
            </Grid>
            {
              (from === undefined || from === null) && (
                <Grid item xs={12} sm={2} display="flex" justifyContent="flex-end">
                <Button
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  startIcon={<Add />}
                  onClick={handleClickOpen}
                >
                  {"등록하기"}
                </Button>
              </Grid>
              )
            }
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={10}>
            </Grid>
            <Grid item xs={12} sm={2} display="flex" justifyContent="flex-end" sx={{ mb: 2 }}>
              {
                (from === undefined || from === null) && (
                  <FormControlLabel
                  control={
                    <Switch 
                      checked={checked}
                      onChange={handleChangeOrder}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label={"순서"}
                />
                )
              }
            </Grid>
          </Grid>
          <div style={{ height: 800, width: '100%' }}>
            <DataGridPro
              localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
              sx={{ cursor: 'pointer', fontSize: '0.85em' }}
              initialState={{ pinnedColumns: { right: ['actions'] } }}
              slots={{
                noRowsOverlay: CustomNoRowsOverlay,
                loadingOverlay: LinearProgress,
              }}
              columnHeaderHeight={38}
              rowHeight={34}
              loading={!loaded}
              rows={rows}
              columns={columns}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[10, 20, 50, 100]}
              pagination
              onRowDoubleClick={(params) => handleSelect({ type: 'edit', params })}
            />
          </div>
        </Box>
        <ConfirmDialog
          removeId={removeId}
          title={"삭제"}
          open={confirmOpen}
          setOpen={setConfirmOpen}
          onConfirm={remove}
          onCancel={() => {}}
        >
          {`"${params && params.row && params.row.name || ""}(아이디 : ${params && params.id  || ""})"를 삭제하시겠습니까?`}
        </ConfirmDialog>
      </Container>
    </ThemeProvider>
  );
};

export default GClientManagement;
