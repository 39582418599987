// 기본 레이아웃
const BASE_LAYOUT = {
    HEADER_HEIGHT: 165,
    FOOTER_HEIGHT: 30,
  };
  
  // 화면별 레이아웃
  export const LAYOUT = {
    DEFAULT: {
      ...BASE_LAYOUT,
    },
    ONE_LINE_LAYER: {
      ...BASE_LAYOUT,
      HEADER_HEIGHT: BASE_LAYOUT.HEADER_HEIGHT + 85, // 검색창 1단 레이어
    },
    TWO_LINE_BUTTON_LAYER: {
        ...BASE_LAYOUT,
        HEADER_HEIGHT: BASE_LAYOUT.HEADER_HEIGHT + 120, // 검색창 1단 레이어 + 버튼 레이어
      },
    TWO_LINE_LAYER: {
      ...BASE_LAYOUT,
      FOOTER_HEIGHT: BASE_LAYOUT.HEADER_HEIGHT + 170, // 검색창 2단 레이어
    },
  };
  
  // 화면별 컨텐츠 높이 계산 함수
  export const getGridHeight = (screenType, extraSpace = 0) => {
    const { HEADER_HEIGHT, FOOTER_HEIGHT } = LAYOUT[screenType] || LAYOUT.DEFAULT;
    return {
      FULL: `calc(100vh - ${HEADER_HEIGHT + FOOTER_HEIGHT}px)`,
      REDUCED: `calc(100vh - ${HEADER_HEIGHT + FOOTER_HEIGHT + extraSpace}px)`,
    };
  };