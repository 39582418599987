import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Box,
  Container,
  CssBaseline,
  Grid,
  Button,
} from '@mui/material';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import ReactPlayer from 'react-player';

import * as constructionWorkTypeActions from './store/constructionWorkType';

const theme = createTheme();

const StyledButton = styled((props) => (
  <Button {...props}/>
))(
    ({ theme, ...props }) => {
      const { sx } = { ...props };
      return {
        '&:hover': {
          backgroundColor: sx.bgcolor,
          filter: 'brightness(120%)',
        },
    }}
);

const Intro = () => {  
  const constructionWorkTypes = useSelector(state => state.constructionWorkType.constructionWorkTypes);

  const dispatch = useDispatch();

  const selectConstructionTypes = () => dispatch(constructionWorkTypeActions.selectAll())

  useEffect(
    async () => {
      await selectConstructionTypes();
    }, [dispatch]
  )

  

  const Types = ({ name, comments, color, url, col }) => {
    return (
      <Grid item xs={12} sm={col} display="flex" justifyContent={"center"} alignItems="center">
        <Box sx={{ width: '100%', height: '130px' }} >
          <StyledButton component={Link} to={url || ""} variant="contained" sx={{ width: '100%', height: '100%', fontSize: '1.06rem', fontWeight: 'bold', bgcolor: color || '#aaa' }}>
            <Grid container spacing={2}>
              <Grid item xs={12} display="flex" justifyContent={"center"} alignItems="center">
                {name}
              </Grid>
              <Grid item xs={12} display="flex" justifyContent={"center"} alignItems="center" sx={{ fontSize: '0.7em' }}>
                {comments || '준비중 입니다...'}
              </Grid>
            </Grid>
          </StyledButton>
        </Box>
      </Grid>
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="lg">
        <CssBaseline />
        <Grid container spacing={3} sx={{ mt: 5 }}>
          <Grid item xs={12} sm={8}>
            <ReactPlayer
              width={'100%'}
              height='100%'
              url={`/intro.mp4`} // 캐싱으로 인해 이전 서비스 영상이 계속 보이는 문제 해결
              playing={true}
              muted={true}
              controls={true}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid container spacing={3}>
              {
                constructionWorkTypes &&
                constructionWorkTypes.length > 0 &&
                constructionWorkTypes
                  .filter((type, i) => i >=0 && i < 6)
                  .map((type) => <Types key={type.id || type.index} name={type.name} comments={type.comments} color={type.color} url={type.url} col={6} />)
              }
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{ mt: 1 }}>
          {
            constructionWorkTypes &&
            constructionWorkTypes.length > 0 &&
            constructionWorkTypes
              .filter((type, i) => i >=6)
              .map((type) => <Types key={type.id || type.index} name={type.name} comments={type.comments} color={type.color} url={type.url} col={2} />)
          }
        </Grid>
      </Container>
    </ThemeProvider>
  );
}

export default Intro;