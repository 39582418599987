// import id from "date-fns/locale/id/index.js";
import { csrfFetch } from "./csrf.js";
import { authServerUrl, mode } from '../config.js';

export const sendSystemMailDrect = async ({ to, type, from }) => {
  const response = await csrfFetch(null, `${authServerUrl}/api/mails`, {
    method: "POST",
    body: JSON.stringify({ to, type, from }),
  });
  
  const data = await response.json();

  return data;
};

export const sendSystemMailNoAuthDrect = async ({ to, type, from }) => {
  const response = await csrfFetch(null, `${authServerUrl}/api/mails/noAuth`, {
    method: "POST",
    body: JSON.stringify({ to, type, from }),
  });
  
  const data = await response.json();
  
  return data;
};

export const sendMailTestDrect = async ({ mailSettings }) => {
  const response = await csrfFetch(null, `${authServerUrl}/api/mails/test`, {
    method: "POST",
    body: mode.indexOf('java') > 0 ? JSON.stringify(mailSettings) : JSON.stringify({ mailSettings }),
  });
  
  const data = await response.json();
  return data;
};

const initialState = {
  mail: null,
  mails: [],
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
  //   case SET_USER:
  //     newState = Object.assign({}, state, { user: action.payload });
  //     return newState;
  //   case REMOVE_USER:
  //     newState = Object.assign({}, state, { user: null });
  //     return newState;
  //   case SET_USERS:
  //     newState = Object.assign({}, state, { users: action.payload });
  //     return newState;
    default:
      return state;
  }
}

export default reducer;
