import React, { useState, useEffect } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
} from '@mui/material';
import { Controller } from "react-hook-form";

// const options = [
//   {
//     label: "Checkbox Option 1",
//     value: "1",
//   },
//   {
//     label: "Checkbox Option 2",
//     value: "2",
//   },
// ];

const FormInputCheckbox = ({ name, control, label, setValue, onChangeCheckValue, ...props }) => {

  /**
   * 아래 코드에서 warning 발생
   * React Hook useEffect has missing dependencies: 'name' and 'setValue'. Either include them or
   * remove the dependency array. If 'setValue' changes too often, find the parent component that
   * defines it and wrap that definition in useCallback
   */
  /**
   * we are setting form value manually here
   * Details
   * in this component we are controlling the value and onChange both manually here. That’s why inside
   * the render function we are not using the field prop anymore. To set the value we are taking another
   * new prop named setValue here. This function is a special function of react-hook-form for setting the
   * value manually. You can ask then why are we doing this if we are manually handling the inputs? The
   * answer is when you are using react-hook-form you want all your inputs in one place. So we are giving
   * this MultiSelectCheckbox component a special treatment here so that it works with other components easily.
   */
  // useEffect(() => {
  //   setValue(name, selectedItems);
  // }, [selectedItems]);
  // TODO : 추후 label 추가 검토할 것. 현재 label을 FormControlLabel을 사용하여 출력하면 label없이 가운데 정렬할 경우가 되지 않아 보류함
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{label}</FormLabel>
      <Controller
        name={name}
        render={({
            field: { onChange, onBlur, value },
            fieldState: { error },
            formState,
        }) => (
          <>
            <Checkbox
              checked={value}
              // onChange={onChange}
              onChange={
                (event) => {
                  console.log(event)
                  onChange(event);
                  onChangeCheckValue && onChangeCheckValue(event, event.target.checked, name);
                }
              }
              {...props}
            />
          </>
        )}
        control={control}
      />
    </FormControl>
  );
}

export default FormInputCheckbox;
