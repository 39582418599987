import React from 'react';
import { Route, useLocation } from 'react-router-dom';
import { Snackbar, Alert } from '@mui/material';
import { Slide } from '@mui/material';
import { Box, Grid, Typography } from '@mui/material';

import Intro from "../Intro";
import LoginFormPage from '../components/LoginFormPage';
import { GLogin, GLoginGClient, GLoginGUser } from '../components/GLoginFormPage';
import { SignupGClient, SignupGUser } from '../components/GSignup';
import { Navigation } from "../components/Navigation";
import Header from '../components/layout/Header';
import AppRoutes from './AppRoutes';

import Copyright from "../components/layout/Copyright";
import CopyrightKFGWA from "../components/layout/CopyrightKFGWA";
import { ErrorPage } from "../components/error";
import ConsentDialog from "../ConsentDialog";
import NotificationDialog from "../NotificationDialog";
import { adminMenus, gclientMenus, userMenus } from '../menus'
import G04docuMaterialApprovalProgress from "../components/G04docuMaterialApproval/G04docuMaterialApprovalProgress";




const cellStyleTop = {
    borderColor: '#dcdcdc',
    borderWidth: 1, borderStyle: 'solid',
    bgcolor: "#F2F2F2",
  };
  
  const cellStyleTop2 = {
    borderColor: '#dcdcdc',
    borderWidth: 1, borderStyle: 'solid',
    borderLeftWidth: 0,
  };
  
  const cellStyle = {
    borderColor: '#dcdcdc',
    borderWidth: 1, borderStyle: 'solid',
    borderTopWidth: 0,
    bgcolor: '#F7F7F7',
  };
  
  const cellStyle2 = {
    borderColor: '#dcdcdc',
    borderWidth: 1, borderStyle: 'solid',
    borderTopWidth: 0,
    borderLeftWidth: 0,
  };
  

const MainRoute = ({ isLoaded, sessionUser, error, handleClose, open, setOpen }) => {
    const { pathname } = useLocation();
    const footer = true; // 필요에 따라 조정

    const  SlideTransition = (props) => <Slide {...props} direction="up" />


    return (
        <>
        {/* useLocation().pathname !== "/gsendG04docus/download" 조건은 메일로 전송한 다운로드 경로인 경우 화면에 메뉴와 탭을 보이지 않도록 하기 위해서임 */}
        {/* 메뉴 : Navigation */}
        {/* { (useLocation().pathname !== "/gsendG04docus/download") && <Navigation isLoaded={isLoaded} menus={sessionUser?.type === 'ADMIN' ? adminMenus : ( sessionUser?.type === 'GCLIENT' ? gclientMenus : userMenus )} /> } */}
        { (pathname !== "/gsendG04docus/download" && pathname !== "/intro") && <Navigation isLoaded={isLoaded} menus={sessionUser?.type === 'ADMIN' ? adminMenus : ( sessionUser?.type === 'GCLIENT' ? gclientMenus : userMenus )} /> }
        {/* 탭 : Header */}
        {/* { (useLocation().pathname !== "/gsendG04docus/download") && <Header menus={sessionUser?.type === 'ADMIN' ? adminMenus : ( sessionUser?.type === 'GCLIENT' ? gclientMenus : userMenus )} /> } */}
        { (pathname !== "/gsendG04docus/download" && pathname !== "/intro") && <Header menus={sessionUser?.type === 'ADMIN' ? adminMenus : ( sessionUser?.type === 'GCLIENT' ? gclientMenus : userMenus )} /> }
        {
            isLoaded && <AppRoutes sessionUser={sessionUser} isLoaded={isLoaded} />
        }
        {/* <StickyFooter /> */}
        { footer ? (pathname !== "/" && pathname !== "/intro" ? <CopyrightKFGWA /> : <Copyright />) : <></> }
        {
            error?.serverResponse?.details?.severityLevel >= 3 ? (
            <Snackbar
                open={error || false}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                TransitionComponent={SlideTransition}
                autoHideDuration={5000}
                onClose={handleClose}
                // message={error?.serverResponse?.details.message}
                // action={<IconButton
                //   size="small"
                //   aria-label="close"
                //   color="inherit"
                //   onClick={handleClose}
                // >
                //   <Close fontSize="small" />
                // </IconButton>}
            >
                <Alert
                onClose={handleClose}
                severity="warning" // success, info, warning, error
                variant="filled"
                sx={{ width: '100%' }}
                >
                {error?.serverResponse?.details.message}
                </Alert>
            </Snackbar>
            ) : (<></>)
        }
        <NotificationDialog>
            <Box sx={{ p: 1 }}>
            {"KFGWA 공사다큐 서비스를 사용하시는 고객님께 최초 1회 문서 업로드 서비스를 제공해 드립니다."}<br /><br />
            {"가지고 계신 서류를 아래 메일로 보내 주십시요."}<br /><br />
            {"보내실 메일 주소 "}<span style={{ color: "#1976d2", marginLeft: '10px' }}>{"wan@warpcore.co.kr"}</span>
            <Grid container sx={{ mt: 4 }}>
                <Grid item xs={12} sx={cellStyleTop}>
                <Typography sx={{ p: 1, fontWeight: 'bold' }}>{"서류목록"}</Typography>
                </Grid>
                <Grid item xs={12} sm={3} display="flex" sx={cellStyle}>
                <Typography sx={{ p: 1 }}>{"일반서류"}</Typography>
                </Grid>
                <Grid item xs={12} sm={9} display="flex" sx={cellStyle2}>
                <Grid container sx={{ p: 1 }}>
                    <Grid item xs={12} sm={6}>
                    <Typography>{"- 사업자등록증"}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <Typography>{"- 국세 납세증명서"}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <Typography>{"- 지방세 납세증명서"}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <Typography>{"- 회사소개자료"}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <Typography>{"- 납품실적"}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <Typography>{"- 공장등록증"}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <Typography>{"- 기타"}</Typography>
                    </Grid>
                </Grid>
                </Grid>
                <Grid item xs={12} sm={3} display="flex" sx={cellStyle}>
                <Typography sx={{ p: 1 }}>{"그 외 서류"}</Typography>
                </Grid>
                <Grid item xs={12} sm={9} display="flex" sx={cellStyle2}>
                <Grid container sx={{ p: 1 }}>
                    <Grid item xs={12} sm={6}>
                    <Typography>{"- 인증서"}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <Typography>{"- 성적서"}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <Typography>{"- 기타"}</Typography>
                    </Grid>
                </Grid>
                </Grid>
            </Grid>
            </Box>
        </NotificationDialog>
        <ConsentDialog open={open} setOpen={setOpen} />
        <G04docuMaterialApprovalProgress />
    </>
  );

};

export default MainRoute;