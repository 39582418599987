import React from 'react';
import { Route } from 'react-router-dom';
import RequireAuth from './RequireAuth'; 
import Profile from '../components/Profile';
import { User } from '../components/User';

// 내 정보
const ProfileRoute = ({ sessionUser }) => (
    <>
    <Route 
      path="/profile" 
      element={
        <RequireAuth sessionUser={sessionUser}>
          <Profile />
        </RequireAuth>
      }
    />
    <Route path="/userAccount" element={
        <RequireAuth sessionUser={sessionUser}>
          <User />
        </RequireAuth>
      } />
    </>
);

export default ProfileRoute;