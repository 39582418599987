import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Switch,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import {
  FormInputText,
  FormInputSwitch,
} from "../form";
import {
  DialogTitleClose,
  PaperComponent,
} from "../dialog";
import * as g04docuEtcActions from "../../store/g04docuEtc";
import * as gclientGComponentItemMapActions from "../../store/gclientGComponentItemMap";

// 아래 form components의 name과 연계
const defaultValues = {
  id: "",
  name: "",
  code: "",
  type: "",
  itemOrProcess: "",
  comments: "",
  supplierOnlyYN: 'N',
};

const G04docuDialogEtc = ({
  open,
  setOpen,
  crudMode,
  setCrudMode,
  selectedRow,
  gclientId,
  refresh,
}) => {
  const [errors, setErrors] = useState([]);
  const [showItemOrProcess, setShowItemOrProcess] = useState(false);
  const [showSupplierOnly, setShowSupplierOnly] = useState(false);
  const [checkedSupplierOnly, setCheckedSupplierOnly] = useState(false);
  const [gcomponentItemId, setGComponentItemId] = useState(null); // ""이면 fk ""이 undefined이면 필드에서 빠지므로 null로 함. (gcomponentId, g04docuGCertificationId 둘중 하나만 값이 있음)
  const [g04docuGCertificationId, setG04docuGCertificationId] = useState(null);
  const [division, setDivision] = useState("");

  const handleDialogClose = () => {
    setOpen(false);
    setCrudMode('');

    initDialog();
  };

  const initDialog = () => {
    for (const [item, value] of Object.entries(defaultValues)) {
      setValue(item, value);
    }
    
    // 그외 초기화할 것들은 여기서 초기화
    setShowItemOrProcess(false);
    setShowSupplierOnly(false);
    setCheckedSupplierOnly(false);
  }
  
  const { handleSubmit, reset, control, setValue, getValues } = useForm({ defaultValues: defaultValues });

  const rowsGComponentItem = useSelector((state) => state.gclientGComponentItemMap.gcomponentItems);

  // 데이터 관리
  const dispatch = useDispatch();
  
  const addG04docuEtc = ({ id, name, code, type, division, comments, gclientId, gcomponentItemId, g04docuGCertificationId, supplierOnlyYN }) => dispatch(g04docuEtcActions.create({ id, name, code, type, division, comments, gclientId, gcomponentItemId, g04docuGCertificationId, supplierOnlyYN }))
  const modifyG04docuEtc = ({ id, name, code, type, division, comments, gclientId, gcomponentItemId, g04docuGCertificationId, supplierOnlyYN }) => dispatch(g04docuEtcActions.modify({ id, name, code, type, division, comments, gclientId, gcomponentItemId, g04docuGCertificationId, supplierOnlyYN }))
  const selectAllMines = (gclientId) => dispatch(gclientGComponentItemMapActions.selectAllMyGComponentItems(gclientId))

  const onSubmit = ({ id, name, code, type, comments, supplierOnlyYN }) => {
    setErrors([]);

    let func;
    if (crudMode === 'C') {
      func = addG04docuEtc;
    } else if (crudMode === 'U') {
      func = modifyG04docuEtc;
    }
    
    // console.log({ id, name, code, type, comments, gclientId, supplierOnlyYN });
    // return;
    
    func({ id, name, code, type, division, comments, gclientId, gcomponentItemId, g04docuGCertificationId, supplierOnlyYN })
      .then (async (res) => {
        handleDialogClose();

        refresh(gclientId);
      })
      .catch (async (res) => {
        console.log(res)
        const data = await res.json();
        if (data && data.errors) setErrors(data.errors);
      });
  }

  useEffect(
    () => {
      if (crudMode === 'C') {
        setValue("id", uuidv4());
      }
      
      if (crudMode !== "") {
        selectAllMines(gclientId);
      }
    }, [crudMode]
  );

  useEffect(
    () => {
      if (selectedRow) {
        for (const [item, value] of Object.entries(defaultValues)) {
          if (item === 'supplierOnlyYN' && selectedRow[item] === 'Y') {
             setCheckedSupplierOnly(true);
          }

          setValue(item, selectedRow[item] || value);
        }

        // 그외 초기화
        if (selectedRow["type"] === 'TEST') {
          setShowItemOrProcess(true);
        }

        if (selectedRow["type"] === 'GENERAL') {
          setShowSupplierOnly(true);
        }

        selectedRow["gcomponentItemId"] && setValue("itemOrProcess", selectedRow["gcomponentItemId"]);
        selectedRow["g04docuGCertificationId"] && setValue("itemOrProcess", selectedRow["g04docuGCertificationId"]);
      }
    }, [selectedRow]
  );

  const handleChangeType = (e) => {
    const { value } = e.target;
    setValue("type", value); // onChange 이벤트가 추가되면 반드시 setValue 해줘야 한다.

    if (value === 'TEST') {
      setShowItemOrProcess(true);
    } else {
      setShowItemOrProcess(false);

      if (value === 'GENERAL') {
        setShowSupplierOnly(true);
      } else {
        setShowSupplierOnly(false);
      }
    }
  }

  const handleChangeItemOrProcess = (e, items) => {
    const { value } = e.target;
    console.log(value)
    const selectedItems = items.filter(i => i.id === value);
    if (selectedItems.length > 0) {
      const gcomponentItemType = selectedItems[0].gcomponentItemType;
      if (gcomponentItemType === 'PROCESS') {
        setGComponentItemId(null);
        setG04docuGCertificationId(value);
      } else {
        setGComponentItemId(value);
        setG04docuGCertificationId(null);
      }

      setDivision(gcomponentItemType);
    }

    setValue("itemOrProcess", value);
  }

  const handleChangeSupplierOnly = (e) => {
    // TODO : form control 안에서 제어 가능한지 연구 필요 => FormInputDropdown1과 FormInputDate1은 set~함수를 만들고 외부에서 호출하도록 구현(검토 필요)
    let supplierOnlyYN = "";
    if (checkedSupplierOnly) { // 반대임에 유의
      supplierOnlyYN = 'N';
    } else {
      supplierOnlyYN = 'Y';
    }
    setValue("supplierOnlyYN", supplierOnlyYN);
    setCheckedSupplierOnly(!checkedSupplierOnly);
  }

  return (
    <Dialog
      PaperProps={{style: {overflowY: 'visible'}}}
      open={open}
      onClose={handleDialogClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitleClose
        id="draggable-dialog-title"
        onClose={handleDialogClose}
        style={{ cursor: 'move' }}
      >
        { crudMode === 'C' && "기타 서류 등록" }
        { crudMode === 'R' && "기타 서류 상세" }
        { crudMode === 'U' && "기타 서류 수정" }
      </DialogTitleClose>
      <DialogContent
        style={{ overflowY: 'visible' }}
        dividers
      >
        <ul>
          {errors.map((error, idx) => <li key={idx}>{error}</li>)}
          {/* {
            JSON.stringify(selectedRow, null, 2)
          } */}
        </ul>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ display: 'none' }}>
            <FormInputText
              name={"id"}
              control={control}
              label={"아이디"}
              inputProps={
                { readOnly : true }
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormInputText
              name={"name"}
              control={control}
              label={"이름"}
              inputProps={
                { readOnly: crudMode === 'R' }
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormInputText
              name={"code"}
              control={control}
              label={"코드"}
              inputProps={
                { readOnly: crudMode === 'R' }
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormInputText
              select
              name="type"
              control={control}
              label={"구분"}
              // sx={{ width: 300 }}
              options={
                [
                  { label: '일반서류', value: 'GENERAL' },
                  { label: '인증서', value: 'CERTIFICATION' },
                  { label: '성적서', value: 'TEST' },
                  { label: '기타', value: 'ETC' },
                ]
              }
              onChange={handleChangeType}
              inputProps={
                { readOnly: crudMode === 'R' }
              }
            />
          </Grid>
          {
            showSupplierOnly && (<Grid item xs={12}><Box sx={{ solid: 1, borderRadius: 1, bgcolor: '#eee', color: '#666666' }}>
              <Switch
                checked={checkedSupplierOnly}
                onChange={handleChangeSupplierOnly}
              />
              {"공급업체 전용"}
            </Box></Grid>)
          }
          {
            showItemOrProcess && (
              <Grid item xs={12}>
                <FormInputText
                  select
                  name="itemOrProcess"
                  control={control}
                  label={"제품/가공품"}
                  options={rowsGComponentItem.map(i => {
                    const { id, name, gcomponentItemType } = i;
                    let itemType = "";
                    if (gcomponentItemType === 'RAW_MATERIAL') {
                      itemType = "원자재";
                    } else if (gcomponentItemType === 'SUB_MATERIAL_PROCESS') {
                      itemType = "가공부자재";
                    } else if (gcomponentItemType === 'SUB_MATERIAL_BUILD') {
                      itemType = "시공부자재";
                    } else if (gcomponentItemType === 'PROCESS') {
                      itemType = "공정";
                    }

                    return {
                      label: <><Chip label={itemType} size="small" sx={{ mr: 1 }}></Chip>{name}</>,
                      value: id,
                      data: i,
                    }
                  })}
                  onChange={(e) => handleChangeItemOrProcess(e, rowsGComponentItem)}
                  inputProps={
                    { readOnly: crudMode === 'R' }
                  }
                />
              </Grid>
            )
          }
          <Grid item xs={12}>
            <FormInputText
              name={"comments"}
              control={control}
              label={"설명"}
              multiline
              maxRows={5}
              inputProps={
                { readOnly: crudMode === 'R' }
              }
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {
          crudMode === 'C' && (
            <>
              <Button onClick={handleSubmit(onSubmit)}>{"저장"}</Button>
              <Button onClick={() => reset()}>{"초기화"}</Button>
            </>
          )
        }
        { crudMode === 'U' && <Button onClick={handleSubmit(onSubmit)}>{"수정"}</Button> }
        <Button onClick={handleDialogClose}>{"닫기"}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default G04docuDialogEtc;
