import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
} from '@mui/material';
import {
  FormInputDropdown,
  FormInputText,
} from "../form";
import {
  DialogTitleClose,
  PaperComponent,
} from "../dialog";
import * as gunitActions from "../../store/gunit";

// 아래 form components의 name과 연계
const defaultValues = {
  id: "",
  name: "",
  code: "",
  type: "",
  comments: "",
};

const G04docuSearchDialog = ({
  crudMode,
  open,
  setOpen,
  selectedRow,
  queryPaging,
  refresh,
}) => {
  const [errors, setErrors] = useState([]);

  const handleDialogClose = () => {
    setOpen(false);
  };

  /**
   * userForm에 인자 { defaultValues: defaultValues }를 넘기지 않고 useForm() 형태로 사용하면 아래 에러 발생
   * Warning: A component is changing an uncontrolled input to be controlled. This is likely caused by
   * the value changing from undefined to a defined value, which should not happen. Decide between using
   * a controlled or uncontrolled input element for the lifetime of the component.
   */
  const { handleSubmit, reset, control, setValue } = useForm({ defaultValues: defaultValues });
  
  // 데이터 관리
  const dispatch = useDispatch();

  const addGUnit = ({ name, code, type, comments }) => dispatch(gunitActions.create({ name, code, type, comments }))
  const modifyGUnit = ({ id, name, code, type, comments }) => dispatch(gunitActions.modify({ id, name, code, type, comments }))

  const onSubmit = ({ id, name, code, type, comments }) => {
    setErrors([]);
    
    // console.log({ id, name, code, type, comments });
    // return;
    let func;
    if (crudMode === 'U') {
      func = modifyGUnit;
    } else if ((crudMode === 'C')) {
      func = addGUnit
    }
    func({ id, name, code, type, comments })
      .then (res => {
        handleDialogClose();
        refresh(queryPaging);
      })
      .catch (async (res) => {
        const data = await res.json();
        if (data && data.errors) setErrors(data.errors);
      });
  }

  useEffect(
    () => {
      console.log(selectedRow);
      ["id", "name", "code", "type", "comments"].forEach(item => setValue(item, selectedRow && selectedRow[item] || ""))
    }
    , [selectedRow]
  );

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitleClose
        id="draggable-dialog-title"
        onClose={handleDialogClose}
        style={{ cursor: 'move' }}
      >
        { crudMode === 'C' && "단위 등록"}
        { crudMode === 'R' && "단위 상세"}
        { crudMode === 'U' && "단위 수정"}
      </DialogTitleClose>
      <DialogContent dividers>
        <ul>
          {errors.map((error, idx) => <li key={idx}>{error}</li>)}
        </ul>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormInputText
              name={"id"}
              control={control}
              label={"아이디"}
              inputProps={
                { readOnly: true }
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormInputText
              name={"name"}
              control={control}
              label={"이름"}
              inputProps={
                { readOnly: crudMode === 'R' }
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormInputText
              name={"code"}
              control={control}
              label={"코드"}
              inputProps={
                { readOnly: crudMode === 'R' }
              }
            />
          </Grid>
          <Grid item xs={12}>
            {/* TODO : 읽기전용 기능 추가 필요 */}
            <FormInputDropdown
              name={"type"}
              control={control}
              label={"타입"}
              options={
                [
                  {
                    label: "길이",
                    value: "length",
                  },
                  {
                    label: "면적",
                    value: "area",
                  },
                ]
              }
              // defaultValue="area" // TODO : defaultValues 설정하는 적절한 방법 찾을 것
            />
          </Grid>
          <Grid item xs={12}>
            <FormInputText
              name={"comments"}
              control={control}
              label={"설명"}
              multiline
              maxRows={5}
              inputProps={
                { readOnly: crudMode === 'R' }
              }
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        { crudMode === 'C' && <Button onClick={handleSubmit(onSubmit)}>{"저장"}</Button> }
        { crudMode === 'U' && <Button onClick={handleSubmit(onSubmit)}>{"수정"}</Button> }
        {/* TODO : 아래 코드 안되는 이유? */}
        {/* <Button onClick={reset}>{"초기화"}</Button> */}
        { crudMode === 'C' && <Button onClick={() => reset()}>{"초기화"}</Button> }
        <Button onClick={handleDialogClose}>{"닫기"}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default G04docuSearchDialog;
