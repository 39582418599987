import { csrfFetch } from "./csrf.js";
import { gcoreServerUrl } from '../config';

const SET_G04_GENERAL_DOCU = 'g04GeneralDocu/setG04GeneralDocu';
const REMOVE_G04_GENERAL_DOCU = "g04GeneralDocu/removeG04GeneralDocu";
const SET_G04_GENERAL_DOCUS = 'g04GeneralDocu/setG04GeneralDocus';

const setG04GeneralDocu = (g04GeneralDocu) => ({
  type: SET_G04_GENERAL_DOCU,
  payload: g04GeneralDocu,
});

const removeG04GeneralDocu = () => ({
  type: REMOVE_G04_GENERAL_DOCU,
});

const setG04GeneralDocus = (data) => ({
  type: SET_G04_GENERAL_DOCUS,
  payload: data,
});

export const create = ({ id, name, code, comments }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04GeneralDocus/`, {
    method: "POST",
    body: JSON.stringify({ id, name, code, comments }),
  });
  const data = await response.json();
  dispatch(setG04GeneralDocu(data.g04GeneralDocu));
  return response;
};

export const modify = ({ id, name, code, comments }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04GeneralDocus/`, {
    method: "PUT",
    body: JSON.stringify({ id, name, code, comments }),
  });
  const data = await response.json();
  dispatch(setG04GeneralDocu(data.g04GeneralDocu));
  return response;
};

export const selectAll = () => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04GeneralDocus/`);
  const data = await response.json();
  dispatch(setG04GeneralDocus(data)); // data 임에 유의
  return response;
}

export const selectPaging = (queryPaging) => async dispatch => {
  const { page, pageSize } = queryPaging;
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04GeneralDocus/offset/${page}/limit/${pageSize}`);
  const data = await response.json();
  dispatch(setG04GeneralDocus(data));
  return response;
}

export const select = (id) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04GeneralDocus/${id}`);
  const data = await response.json();
  dispatch(setG04GeneralDocu(data.g04GeneralDocu));
  return response;
}

export const initG04GeneralDocu = () => async dispatch => {
  dispatch(setG04GeneralDocu(null));
}

export const remove = (id) => async (dispatch) => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04GeneralDocus/${id}`, {
    method: "DELETE",
  });
  dispatch(removeG04GeneralDocu());
  return response;
};

export const reorder = async ({ type, id }) => {
  const response = await csrfFetch(null, `${gcoreServerUrl}/api/g04GeneralDocus/reorder`, {
    method: "POST",
    body: JSON.stringify({ type, id }),
  });
  
  return response;
}

const initialState = {
  g04GeneralDocu: null,
  g04GeneralDocus: [],
  count: 0,
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case SET_G04_GENERAL_DOCU:
      newState = Object.assign({}, state, { g04GeneralDocu: action.payload });
      return newState;
    case REMOVE_G04_GENERAL_DOCU:
      newState = Object.assign({}, state, { g04GeneralDocu: null });
      return newState;
    case SET_G04_GENERAL_DOCUS:
      newState = Object.assign({}, state, { g04GeneralDocus: action.payload.g04GeneralDocus, count: action.payload.count });
      return newState;
    default:
      return state;
  }
}

export default reducer;
