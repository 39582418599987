import { createTheme } from '@mui/material/styles';

const pretendardFont = [
  'Pretendard',
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  'Roboto',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join(',');

const theme = createTheme({
  typography: {
    fontFamily: pretendardFont,
    h1: { fontFamily: pretendardFont },
    h2: { fontFamily: pretendardFont },
    h3: { fontFamily: pretendardFont },
    h4: { fontFamily: pretendardFont },
    h5: { fontFamily: pretendardFont },
    h6: { fontFamily: pretendardFont },
    subtitle1: { fontFamily: pretendardFont },
    subtitle2: { fontFamily: pretendardFont },
    body1: { fontFamily: pretendardFont },
    body2: { fontFamily: pretendardFont },
    button: { fontFamily: pretendardFont },
    caption: { fontFamily: pretendardFont },
    overline: { fontFamily: pretendardFont },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Pretendard';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: url('./assets/css/font.css');
        }
        body {
          font-family: ${pretendardFont};
        }
      `,
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: pretendardFont,
        },
      },
    },
  },
});

export default theme;