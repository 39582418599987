// import id from "date-fns/locale/id/index.js";
import { csrfFetch } from "./csrf.js";
import { authServerUrl } from '../config';

const SET_GCLIENT_TYPE_04_DOCU = 'gclientType04docu/setGClientType04docu';
const REMOVE_GCLIENT_TYPE_04_DOCU = "gclientType04docu/removeGClientType";
const SET_GCLIENT_TYPE_04_DOCUS = 'gclientType04docu/setGClientType04docus';

const setGClientType04docu = (gclientType04docu) => ({
  type: SET_GCLIENT_TYPE_04_DOCU,
  payload: gclientType04docu,
});

const removeGClientType = () => ({
  type: REMOVE_GCLIENT_TYPE_04_DOCU,
});

const setGClientType04docus = (gclientType04docus) => ({
  type: SET_GCLIENT_TYPE_04_DOCUS,
  payload: gclientType04docus,
});

export const create = ({ id, name, code, gclientTypeId, g04docuGCertificationId, g04docuGCertification, displayType, comments, color }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/gclientType04docus`, {
    method: "POST",
    body: JSON.stringify({ id, name, code, gclientTypeId, g04docuGCertificationId, g04docuGCertification, displayType, comments, color }),
  });
  const data = await response.json();
  dispatch(setGClientType04docu(data.gclientType04docu));
  return response;
};

export const modify = ({ id, name, code, gclientTypeId, g04docuGCertificationId, g04docuGCertification, displayType, comments, color }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/gclientType04docus`, {
    method: "PUT",
    body: JSON.stringify({ id, name, code, gclientTypeId, g04docuGCertificationId, g04docuGCertification, displayType, comments, color }),
  });
  const data = await response.json();
  dispatch(setGClientType04docu(data.gclientType04docu));
  return response;
};

export const selectAll = () => async dispatch => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/gclientType04docus`);
  const data = await response.json();
  dispatch(setGClientType04docus(data.gclientType04docus));
  return response;
}

export const selectAllDirect = async () => {
  const response = await csrfFetch(null, `${authServerUrl}/api/gclientType04docus`);
  const data = await response.json();
  return data.gclientType04docus;
}

export const selectAllByQuery = () => async dispatch => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/gclientType04docus/query/selectAll`);
  const data = await response.json();
  dispatch(setGClientType04docus(data.gclientType04docus));
  return response;
}

export const selectAllByGClientTypesByQuery = (gclientTypeIds) => async dispatch => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/gclientType04docus/query/selectAll/gclientTypes`, {
    method: "POST",
    body: JSON.stringify({ gclientTypeIds }),
  });

  const data = await response.json();
  dispatch(setGClientType04docus(data.gclientType04docus));
  return response;
}

export const select = (id) => async dispatch => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/gclientType04docus/${id}`);
  const data = await response.json();
  dispatch(setGClientType04docu(data.gclientType04docu));
  return response;
}

export const initGClientType04docu = () => dispatch => {
  dispatch(setGClientType04docu(null));
}

export const remove = (id) => async dispatch => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/gclientType04docus/${id}`, {
    method: "DELETE",
  });
  dispatch(removeGClientType());
  return response;
};

export const reorder = async ({ type, id }) => {
  const response = await csrfFetch(null, `${authServerUrl}/api/gclientType04docus/reorder`, {
    method: "POST",
    body: JSON.stringify({ type, id }),
  });
  
  return response;
}

const initialState = {
  gclientType04docu: null,
  gclientType04docus: [],
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case SET_GCLIENT_TYPE_04_DOCU:
      newState = Object.assign({}, state, { gclientType04docu: action.payload });
      return newState;
    case REMOVE_GCLIENT_TYPE_04_DOCU:
      newState = Object.assign({}, state, { gclientType04docu: null });
      return newState;
    case SET_GCLIENT_TYPE_04_DOCUS:
      newState = Object.assign({}, state, { gclientType04docus: action.payload });
      return newState;
    default:
      return state;
  }
}

export default reducer;
