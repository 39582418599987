// TODO : id/gcomponentItemId, type/itemType => frontend, backend(api), db 혼용하여 혼동됨. 정리 필요
import { csrfFetch } from "./csrf.js";
import { gcoreServerUrl, mode } from '../config';

const INIT_GCLIENT_G04_GENERAL_DOCU_MAPS = "gclientG04GeneralDocuMaps/initGClientG04GeneralDocuMaps";
const SET_GCLIENT_G04_GENERAL_DOCU_MAP = "gclientG04GeneralDocuMaps/setGClientG04GeneralDocuMap";
const SET_GCLIENT_G04_GENERAL_DOCU_MAPS = "gclientG04GeneralDocuMaps/setGClientG04GeneralDocuMaps";
const REMOVE_GCLIENT_G04_GENERAL_DOCU_MAP = "gclientG04GeneralDocuMaps/removeGClientG04GeneralDocuMap";

const initGClientG04GeneralDocuMaps = () => ({
  type: INIT_GCLIENT_G04_GENERAL_DOCU_MAPS,
});

const setGClientG04GeneralDocuMap = (gclientG04GeneralDocuMap) => ({
  type: SET_GCLIENT_G04_GENERAL_DOCU_MAP,
  payload: gclientG04GeneralDocuMap,
});

const setGClientG04GeneralDocuMaps = (gclientG04GeneralDocuMaps) => ({
  type: SET_GCLIENT_G04_GENERAL_DOCU_MAPS,
  payload: gclientG04GeneralDocuMaps,
});

const removeGClientG04GeneralDocuMap = () => ({
  type: REMOVE_GCLIENT_G04_GENERAL_DOCU_MAP,
});

export const create = ({ gclientId, g04GeneralDocuId, gclient, documentPath, documentType, validYN, startDate, endDate }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gclientG04GeneralDocuMaps/`, {
    method: "POST",
    body: JSON.stringify({ gclientId, g04GeneralDocuId, gclient, documentPath, documentType, validYN, startDate, endDate }),
  });
  const data = await response.json();
  dispatch(setGClientG04GeneralDocuMap(data.gclientG04GeneralDocuMap));
  return response;
};

export const modifyDate = ({ gclientId, g04GeneralDocuId, documentPath, validYN, startDate, endDate }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gclientG04GeneralDocuMaps/modifyDate`, {
    method: "PUT",
    body: JSON.stringify({ gclientId, g04GeneralDocuId, documentPath, validYN, startDate, endDate }),
  });
  const data = await response.json();
  dispatch(setGClientG04GeneralDocuMap(data.gclientG04GeneralDocuMap));
  return response;
};

export const initializeGClientG04GeneralDocuMaps = () => async dispatch => {
  dispatch(initGClientG04GeneralDocuMaps());
}

export const selectAllByGClient = (gclientId) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gclientG04GeneralDocuMaps/query/selectAll/gclient/${gclientId}`);
  const data = await response.json();
  dispatch(setGClientG04GeneralDocuMaps(data.gclientG04GeneralDocuMaps));
  return response;
}

export const selectAllByGClientDirect = async (gclientId) => {
  const response = await csrfFetch(null, `${gcoreServerUrl}/api/gclientG04GeneralDocuMaps/query/selectAll/gclient/${gclientId}`);
  const data = await response.json();
  return data.gclientG04GeneralDocuMaps;
}

export const selectAllByGClientByStatus = (gclientId, status) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gclientG04GeneralDocuMaps/query/selectAll/gclient/${gclientId}/status/${status}`);
  const data = await response.json();
  dispatch(setGClientG04GeneralDocuMaps(data.gclientG04GeneralDocuMaps));
  return response;
}

export const countByGClientDirect = async (gclientId) => {
  const response = await csrfFetch(null, `${gcoreServerUrl}/api/gclientG04GeneralDocuMaps/query/count/gclient/${gclientId}`);
  const data = await response.json();
  return data.count;
}

export const remove = ({ gclientId, documentPath }) => async (dispatch) => {
  let url = "";
  let body = null;
  if (mode.indexOf('java') > 0) {
    url = `${gcoreServerUrl}/api/gclientG04GeneralDocuMaps/gclient/${gclientId}`;
    body = JSON.stringify({ documentPath });
  } else {
    url = `${gcoreServerUrl}/api/gclientG04GeneralDocuMaps/gclient/${gclientId}/g04GeneralDocuPath/${encodeURIComponent(documentPath)}`;
  }
  const response = await csrfFetch(dispatch, url, {
    method: "DELETE",
    body,
  });
  dispatch(removeGClientG04GeneralDocuMap());
  return response;
};

const initialState = {
  gclientG04GeneralDocuMap: null,
  gclientG04GeneralDocuMaps: [],
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case INIT_GCLIENT_G04_GENERAL_DOCU_MAPS:
      newState = Object.assign({}, state, { gclientG04GeneralDocuMaps: initialState.gclientG04GeneralDocuMaps });
      return newState;
    case SET_GCLIENT_G04_GENERAL_DOCU_MAP:
      newState = Object.assign({}, state, { gclientG04GeneralDocuMap: action.payload });
      return newState;
    case SET_GCLIENT_G04_GENERAL_DOCU_MAPS:
      newState = Object.assign({}, state, { gclientG04GeneralDocuMaps: action.payload });
      return newState;
    default:
      return state;
  }
}

export default reducer;
