import React from 'react';
import { Route } from 'react-router-dom';
import RequireAuth from './RequireAuth';
import { G04docuFileManagement } from "../components/G04docuFile";
import { G04docuMaterialApprovalManagement } from "../components/G04docuMaterialApproval";
import { G04docuAlarmSettingManagement } from "../components/G04docuAlarmSetting";
import { G04docuSearchManagement } from "../components/G04docuSearch";
import { GProjectManagement } from "../components/GProject";
import { GSendG04docuManagement, GSendG04docuDownloadPage } from '../components/GSendG04docu';

// 자재승인서 관리
const MaterialApproveRoute = ({sessionUser}) => {

    return (
        <>
            {/* 문서첨부 */}
            <Route path="/g04docuFiles" element={
                <RequireAuth sessionUser={sessionUser}>
                    <G04docuFileManagement />
                </RequireAuth>
            } />
            {/* 문서첨부 알람설정 */}
            <Route path="/g04docuAlarmSettings" element={
                <RequireAuth sessionUser={sessionUser}>
                    <G04docuAlarmSettingManagement />
                </RequireAuth>
            } />
            {/* 문서검색 */}
            <Route path="/g04docuSearch" element={
                <RequireAuth sessionUser={sessionUser}>
                    <G04docuSearchManagement />
                </RequireAuth>
            } />
            {/* 전송문서 보관함 */}
            <Route path="/gsendG04docus" element={
                <RequireAuth sessionUser={sessionUser}>
                    <GSendG04docuManagement />
                </RequireAuth>
            } />
            <Route path="/gsendG04docus/download" element={
                // <RequireAuth sessionUser={sessionUser}>
                <GSendG04docuDownloadPage />
                // </RequireAuth>
            } />    
            {/* 자재승인서 발급 */}
            <Route path="/g04docuGenerateFile" element={
                <RequireAuth sessionUser={sessionUser}>
                    <GProjectManagement />
                </RequireAuth>
            } />    
            {/* 자재승인서 보관함 */}
            <Route path="/g04docuMaterialApproval" element={
                <RequireAuth sessionUser={sessionUser}>
                    <G04docuMaterialApprovalManagement />
                </RequireAuth>
            } />
            {/* 성적서 검색
            <Route path="/g04docuGTests" element={
                <RequireAuth sessionUser={sessionUser}>
                    <G04docuGTestManagement />
                </RequireAuth>
            } /> */}
        </>
    );
};

export default MaterialApproveRoute;

