import React, { useState, useRef, useEffect } from 'react';
import { useForm } from "react-hook-form";
import * as sessionActions from "../../store/session";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useSearchParams } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  CardMedia,
  Checkbox,
  Container,
  CssBaseline,
  Fade,
  FormControlLabel,
  Grid,
  Link,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  LockOutlined,
  Login,
  PowerSettingsNew,
} from '@mui/icons-material';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import {
  // FormInputDate,
  // FormInputDropdown,
  // FormInputMultipleCheckbox,
  // FormInputRadio,
  // FormInputSlider,
  FormInputCheckbox,
  FormInputText,
} from "../../components/form";
import PasswordChangeDialog from "../Password/PasswordChangeDialog";
import TextMaskCustom from "../../components/form/inputProps/TextMaskCustom";
import * as mailActions from "../../store/mail";
import CopyrightKFGWA from "../layout/CopyrightKFGWA";

let passwordInputRef = null;

// 아래 form components의 name과 연계
const defaultValues = {
  credential: "",
  password: "",
  loginType: true,
};

const theme = createTheme();

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }}>
    {props.children}
  </Tooltip>
))(({ theme }) => ({
  zIndex: theme.zIndex.tooltip + 1,
  //margin: 4,
  [`& .MuiTooltip-tooltip`]: {
    fontSize: '1rem',
    maxWidth: 500,
    // height: 100,
    backgroundColor: "rgb(255,255,255)",
    margin: 4,
    padding: 8,
    whiteSpace: "pre-line",
    border: "solid #777 1px",
    borderRadius: "10px",
    color: '#777',
  }
}));

const tooltipTop = {
  "& .MuiTooltip-tooltip": {
    border: "solid #777 1px",
    borderRadius: "0px",
    color: '#777',
  }
};

const SignIn = () => {
  const [searchParams] = useSearchParams();
  const queryStrings = Object.fromEntries([...searchParams]);
  const searchCredential = queryStrings["c"];
  const searchPassword = queryStrings["p"];
  const searchType = queryStrings["t"];
  const searchStatus = queryStrings["s"];
  /**
   * userForm에 인자 { defaultValues: defaultValues }를 넘기지 않고 useForm() 형태로 사용하면 아래 에러 발생
   * Warning: A component is changing an uncontrolled input to be controlled. This is likely caused by
   * the value changing from undefined to a defined value, which should not happen. Decide between using
   * a controlled or uncontrolled input element for the lifetime of the component.
   */
   const { handleSubmit, getValues, setValue, control } = useForm({ defaultValues: defaultValues });

  const dispatch = useDispatch();
  const location = useLocation();
  // console.log("location");
  // console.log(location.state.from);
  const sessionUser = useSelector((state) => state.session.sessionUser);

  // const sendMailDrect = ({ aaa }) => mailActions.sendMailDrect({ aaa })
  // const initPasswordDirect = ({ credential, type }) => sessionActions.initPasswordDirect({ credential, type })

  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState("");
  const [error, setError] = useState(null);
  const [logging, setLogging] = useState(false);
  const [saveId, setSaveId] = useState(false);
  const [fadeIn, setFadeIn] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const signinButtonRef = useRef(null);

  const setPasswordInputRef = element => {
    passwordInputRef = element;
  };

  useEffect(
    () => {
      if (searchCredential || searchPassword) {
        setValue("credential", searchCredential);
        setValue("password", searchPassword);

        if (searchCredential && searchPassword) {
          setDisabled(false);
        }
      } else {
        const type = localStorage.getItem("type");
        if (type === 'USER') {
          const isSave = localStorage.getItem("saveId");
          isSave && setSaveId(true);

          const id = localStorage.getItem("credential");
          id && setValue("credential", id);

          if (isSave && id) {
            passwordInputRef.focus();
          }
        }
      }
    }, [dispatch]
  )

  // 로그인되어 있다면 이 페이지가 뜨지 않고 root로 이동시킴
  if (sessionUser) {
    // console.log("LoginFormPage/index sessionUser exists.");
    // console.log(location);
    // console.log(sessionUser.user?.initPassword)
    if (sessionUser.user?.initPassword) {
      return <Navigate to={"/profile"} />; // TODO : state 필요없는지 확인 필요  
    }
    return <Navigate to={location.state && location.state.from ? `${location.state.from}` : "/glass/dashboard"} />; // TODO : state 필요없는지 확인 필요
  } else {
    console.log("LoginFormPage/index no sessionUser exists.");
  }
  
  // React Hook "useEffect" is called conditionally. React Hooks must be called in the exact same order in every component render  react-hooks/rules-of-hooks
  // 오류로 위로 올림
  // useEffect(
  //   () => {
  //     const id = localStorage.getItem("C");
  //     const isSave = localStorage.getItem("saveId");
  //     if (isSave && id) {
  //       setSaveId(true);
  //       setValue("credential", id);
  //     }
  //   }, [dispatch]
  // )

  const onSubmit = ({ credential, password }) => {
    setError(null);

    setLogging(true);

    // console.log({ credential, password, type });
    // return;

    // const status = searchStatus ? "등록완료" : ""
    return dispatch(sessionActions.login({ credential, password, type: "USER"/*, status*/, searchStatus }))
      .then(res => {
        setLogging(false);

        if (saveId) {
          localStorage.setItem("saveId", true);
          localStorage.setItem("credential", credential);
          localStorage.setItem("type", "USER");
        }
      })
      .catch(async (e) => {
        setLogging(false);
        
        // const data = await res.json();
        // if (data && data.errors) setErrors(data.errors);
        // if (res && res.errors) {
        //   setErrors(res.errors);
        // }
        console.log(e)
        setError(e);
        setFadeIn(true);
        setTimeout(() => setFadeIn(false), 2000);
      });
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      signinButtonRef.current.click();
    }
  }

  const handleClickSaveId = () => {
    setSaveId(!saveId);

    localStorage.removeItem("saveId");
    localStorage.removeItem("credential");
    localStorage.removeItem("type");
  }

  const handleClickPasswordChange = () => {
    setOpen(true);
    setMode('I');
  }

  const handleChange = () => {
    setFadeIn(false);
    if (getValues("credential") && getValues("password")) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {/* <img src="/kfgwa-logo.jpg"/> */}
          <Typography component="h1" variant="h4" sx={{ color: '#777' }}>{"로그인"}</Typography>
          <Grid display="flex" alignItems="end" sx={{ height: 50 }}>
            <Fade in={fadeIn}>
              <Typography sx={{ color: "#ff5722" }}>
                { error?.message }
              </Typography>
            </Fade>
          </Grid>
          <Box sx={{ mt: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box sx={{ border: "1px solid #C4C4C4", p: 2 }}>
                  <Typography variant="h6" sx={{ mb: 2 }}>{"회원사 사용자"}</Typography>
                  <FormInputText
                    margin="normal"
                    autoComplete="userId"
                    name={"credential"}
                    control={control}
                    required
                    fullWidth
                    label={"이메일 아이디"}
                    // autoFocus
                    size={"medium"}
                    // InputProps={{
                    //   inputComponent: TextMaskCustom,  // TODO : 이렇게 바깥에서 설정할지 FormInputText 안으로 넣을지 고려할 것
                    //   inputProps: {
                    //     style: { // TODO : 넘기는 방법을 정확히 몰라 style에 실어 넘김
                    //       mask: "000-0000-0000",
                    //       definitions: {
                    //         '#': /[1-9]/,
                    //       },
                    //     },
                    //   },
                    // }}
                    onCustomChange={handleChange}
                  />
                  <FormInputText
                    margin="normal"
                    required
                    fullWidth
                    name={"password"}
                    control={control}
                    label={"비밀번호"}
                    size={"medium"}
                    type="password"
                    autoComplete="current-password"
                    onKeyPress={handleKeyPress}
                    inputProps={{ // InputProps와는 다름
                      ref: setPasswordInputRef,
                    }}
                    onCustomChange={handleChange}
                  />
                  <LoadingButton
                    onClick={handleSubmit(onSubmit)} // TODO : 현재 type을 USER로 사용하고 있어 GUSER로 바꾸지 않음
                    fullWidth
                    variant={"contained"}
                    sx={{ mt: 2, mb: 2, height: '60px', bgcolor: '#0C529D' }}
                    ref={signinButtonRef}
                    loading={logging}
                    loadingPosition="start"
                    disabled={disabled}
                    startIcon={<PowerSettingsNew />}
                  >
                    {"로그인"}
                  </LoadingButton>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <FormControlLabel
                        control={<Checkbox control={control} checked={saveId} color="primary" onClick={handleClickSaveId} />}
                        label="아이디 저장"
                      />
                    </Grid>
                    <Grid item xs={12} sx={{ fontSize: "0.9rem", color: "#ff5722" }}>
                      {"비밀번호를 잃어버리셨나요? 아래 비밀번호 초기화를 클릭해 주세요."}
                    </Grid>
                    <Grid item xs={12} sm={6} display="flex" justifyContent={"flex-start"}>
                      <Link href="#" variant="body1" underline="none" onClick={handleClickPasswordChange}>
                        <StyledTooltip
                          title={"비밀번호를 분실하셨으면 비밀번호 초기화를 누르십시요. 등록된 메일로 임시비밀번호를 발급해 드립니다."}
                          followCursor
                          sx={tooltipTop}
                        >
                          {"비밀번호 초기화"}
                        </StyledTooltip>
                      </Link>
                    </Grid>
                    <Grid item xs={12} sm={6} display="flex" justifyContent={"flex-end"}>
                      <Link href={"/glass/signup/guser"} underline="none" variant="body1">{"회원사 사용자 가입"}</Link>
                    </Grid>
                    <Grid item xs={12} display="flex" justifyContent={"flex-end"}>
                      {/* <Link href={"/glass/signin/gclient"} underline="none" variant="body2">{"회원사 관리자 로그인으로 이동"}</Link> */}
                      <Link href={"/glass/signin"} underline="none" variant="body2">{"로그인 유형 선택"}</Link>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
        <PasswordChangeDialog
          open={open}
          setOpen={setOpen}
          mode={mode}
          setMode={setMode}
          setCheckValue={setValue}
          type={"USER"}
          // setType={setType}
          credential={getValues("credential")}
        />
      </Container>
      {/* <CopyrightKFGWA /> */}
    </ThemeProvider>
  );
}

export default SignIn;