import * as React from 'react';
import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, name, ...other } = props;
  // console.log(other)
  return (
    <IMaskInput
      {...other}
      // mask="000-00-00000"
      mask={other.style.mask}
      // definitions={{
      //   '#': /[1-9]/,
      // }}
      definitions={other.style.definitions}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: name, value } })}
      onChange={(e) => onChange(e)} 
      overwrite
    />
  );
});

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default TextMaskCustom;