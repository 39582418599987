import { Route } from "react-router-dom";
import RequireAuth from "./RequireAuth";
import { Dashboard } from "../components/Dashboard";

const DashboardRoute = ({sessionUser}) => (
    <>
       sessionUser?.type === 'ADMIN' ? (
        <Route path="/glass/dashboard" element={
          <RequireAuth sessionUser={sessionUser}>
            <Dashboard />
          </RequireAuth>
        } />
      ) : (
        <Route path="/glass/dashboard" element={
          <RequireAuth sessionUser={sessionUser}>
            <Dashboard />
          </RequireAuth>
        } />
      ) 
    </>
);

export default DashboardRoute;

