import React, { useState, useEffect } from "react";
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DataGridPro, GridActionsCellItem, koKR } from '@mui/x-data-grid-pro';
import {
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  LinearProgress,
  Skeleton,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import  {
  Add,
  Delete,
  Done,
  Edit,
  KeyboardReturn,
  Undo,
  OpenInNew,
  Send,
  Redo,
  WarningAmber,
} from '@mui/icons-material';

import { FormInputText } from "../form";
import {
  DialogTitleClose,
  PaperComponent,
  ConfirmDialog,
} from "../dialog";
import {
  CustomNoRowsOverlay,
  // CustomLoadingOverlay,
} from "../datagrid";
import {
  dateFormat,
  hideWatermark,
  objectEmptyCheck,
} from "../../utils";
import * as gorderActions from "../../store/gorder";
import GOrderDialog from "./GOrderDialog";
import GOrderProcessDialog from "./GOrderProcessDialog";

const theme = createTheme();

// const ITEM_HEIGHT = 48;

const GOrderManagement = ({
  title,
  // gprojectId,
  from,
}) => {
  // const [anchorEl, setAnchorEl] = useState(null);
  const [crudMode, setCrudMode] = useState(''); // C: create, R: read, U: update, D: x
  // const [detail, setDetail] = useState(false);
  // const [modify, setModify] = useState(false);
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [show, setShow] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [sendDialogOpen, setSendDialogOpen] = useState(false);
  const [gorderId, setGorderId] = useState();
  const [removeId, setRemoveId] = useState();
  const [params, setParams] = useState({});
  const [openBackdrop, setOpenBackdrop] = useState(false);
  // const [gproject, setGProject] = useState({});
  const [mode, setMode] = useState("");
  const [pageSize, setPageSize] = useState(100);

  const [searchParams] = useSearchParams();
  
  // const [selectedRows, setSelectedRows] = useState([]);  

  // const openFunctionMenu = Boolean(anchorEl);
  
  // 기능버튼 메뉴
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };
  
  const handleRowDoubleClick = async ({ params }) => {
    const { id, status } = params.row;

    setOpenBackdrop(true);

    // 발주 상태에 따라 crudMode가 다름
    if (status === 'SEND' || status === 'RECEIVE') {
      setCrudMode('R');
    } else {
      setCrudMode('U');
    }
    
    await select(id);
    setOpen(true);

    setOpenBackdrop(false);
  }

  const handleSelect = async ({ type, params }) => {
    // setAnchorEl(null);
    console.log(params)
    setOpenBackdrop(true);
    // const id = selectedRows[0].id;
    const { id } = params;
    
    if (type === "detail") {
      // setModify(false);
      // setDetail(true);
      setCrudMode('R');
      await select(id);
      setOpen(true);
    } else if (type === "edit") {
      // setModify(true);
      // setDetail(false);
      setCrudMode('U');
      await select(id);
      setOpen(true);
    } else if (type === "send") {
      setGorderId(id);
      setParams(params); // TODO : 필요한가?
      setSendDialogOpen(true);
    } else if (type === "delete") {
      setRemoveId(id);
      setParams(params);
      setConfirmOpen(true);
    }

    setOpenBackdrop(false);
  }

  const remove = (removeId) => {
    dispatch(gorderActions.remove(removeId))
    .then(res => refreshManagementList())
    .catch(async (res) => {
      const data = await res.json();
      if (data && data.errors) setErrors(data.errors);
    });
  }
  
  // const openOrderDialog = () => {
  //   setOpen(true);
  //   setModify(false);
  //   initGOrder(); // 다이얼로그 초기화
  // }

  // 등록 다이얼로그
  const handleClickOpen = () => {
    // openOrderDialog();
    setCrudMode('C');
    setOpen(true);
    // setModify(false);
    // setDetail(false);
    // initGOrder(); // 다이얼로그 초기화
    initWithInitialValue({});
  };

  const generateActions = (params) => {
    const { status } = params.row;

    const detailAction = <GridActionsCellItem icon={<OpenInNew />} label={"상세"} onClick={() => handleSelect({ type: 'detail', params })} showInMenu />;
    const editAction = <GridActionsCellItem icon={<Edit />} label={"수정"} onClick={() => handleSelect({ type: 'edit', params })} showInMenu />;
    const sendAction = <GridActionsCellItem icon={<Redo />} label={"전송"} onClick={() => handleSelect({ type: 'send', params })} showInMenu />;
    const deleteAction = <GridActionsCellItem icon={<Delete />} label={"삭제"} onClick={() => handleSelect({ type: 'delete', params })} showInMenu />;

    let arrActions = [];

    switch(status) {
      case 'WRITING': 
        arrActions = [].concat([detailAction, editAction, sendAction, deleteAction]);
        break;
      case 'SEND':
        arrActions = [].concat([detailAction]);
        break;
      case 'RECEIVE':
        arrActions = [].concat([detailAction]);
        break;
      case 'RETURN':
        arrActions = [].concat([editAction, sendAction, deleteAction]);
        break;
      default:
        arrActions = [].concat([detailAction]);
    }

    return arrActions;
  }

  const columns = [
    {
      field: 'id',
      headerName: '아이디',
      width: 300,
    },
    {
      field: 'site',
      headerName: '현장명',
      width: 180,
      // editable: true,
    },
    {
      field: 'orderSerialNo',
      headerName: '발주일련번호',
      width: 120,
      // editable: true,
    },
    {
      field: 'orderNo',
      headerName: '발주번호',
      width: 120,
      // editable: true,
    },
    {
      field: 'status',
      headerName: '상태',
      width: 120,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const { status } = params.row;
        let result;
        if (status === 'WRITING') {
          result = <Chip icon={<Edit />} label={"작성"} variant="outlined" color="info" size="small" sx={{ width: '80px' }} />
        } else if (status === 'SEND') {
          result = <Chip icon={<Redo />} label={"전송"} variant="outlined" color="warning" size="small" sx={{ width: '80px' }} />
        } else if (status === 'RECEIVE') {
          result = <Chip icon={<Done />} label={"접수"} variant="outlined" color="success" size="small" sx={{ width: '80px' }} />
        } else if (status === 'RETURN') {
          result = <Chip icon={<Undo />/* <KeyboardReturn /> <WarningAmber /> */} label={"반송"} variant="outlined" color="error" size="small" sx={{ width: '80px' }} />
        }

        return (
          <div>
            {result}
          </div>
        );
      },
    },
    {
      field: 'comments',
      headerName: '설명',
      width: 280,
      // editable: true,
    },
    {
      field: 'receiver',
      headerName: '가공업체',
      width: 200,
      // editable: true,
      valueGetter: (params) => params.value?.name,
    },
    {
      field: 'inChargeNameGclient',
      headerName: '현장소장명',
      width: 100,
      // editable: true,
    },
    {
      field: 'inChargePhoneGclient',
      headerName: '현장소장연락처',
      width: 140,
      // editable: true,
    },
    // {
    //   field: 'inChargeEmailGclient',
    //   headerName: '담당자이메일',
    //   width: 120,
    //   // editable: true,
    // },
    {
      field: 'deliveryAddress',
      headerName: '착지주소',
      width: 280,
      // editable: true,
    },
    {
      field: 'orderDate',
      headerName: '발주일',
      width: 120,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => dateFormat(params.value, 'yyyy-MM-dd'),
    },
    {
      field: 'deliveryDate',
      headerName: '납기일',
      width: 120,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => dateFormat(params.value, 'yyyy-MM-dd'),
    },
    // {
    //   field: 'owner',
    //   headerName: '발주자',
    //   width: 120,
    //   // editable: true,
    // },
    {
      field: 'orderer',
      headerName: '시공업체',
      width: 200,
      valueGetter: (params) => params.value?.name,
    },
    {
      field: 'ordererName',
      headerName: '발주자명',
      width: 120,
      // editable: true,
    },
    {
      field: 'ordererPhone',
      headerName: '발주자연락처',
      width: 120,
      // editable: true,
    },
    {
      field: 'ordererEmail',
      headerName: '발주자이메일',
      width: 200,
      // editable: true,
    },
    {
      field: 'sumAmount',
      headerName: '수량',
      width: 120,
      type: 'number',
      valueGetter: (params) => Number(params.value).toFixed(0).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
    },
    {
      field: 'sumAreaMeter',
      headerName: '면적 (m2)',
      width: 120,
      type: 'number',
      valueGetter: (params) => Number(params.value).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
    },
    {
      field: 'sumAreaJa',
      headerName: '면적 (평)',
      width: 120,
      type: 'number',
      valueGetter: (params) => Number(params.value).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
    },
    {
      field: 'sumAreaFeet',
      headerName: '면적 (ft2)',
      width: 120,
      type: 'number',
      valueGetter: (params) => Number(params.value).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
    },
    {
      field: 'sumContractPriceTotal',
      headerName: '수주금액',
      width: 120,
      type: 'number',
      valueGetter: (params) => Number(params.value).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
    },
    {
      field: 'sumOrderPriceTotal',
      headerName: '발주금액',
      width: 120,
      type: 'number',
      valueGetter: (params) => Number(params.value).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
    },
    {
      field: 'createdAt',
      headerName: '생성일시',
      width: 160,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => dateFormat(params.value),
    },
    {
      field: 'updatedAt',
      headerName: '수정일시',
      width: 160,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => dateFormat(params.value),
    },
    {
      field: 'actions',
      headerName: <Tooltip title={"상세/수정/전송/삭제"} followCursor><Box>{"기능"}</Box></Tooltip>,
      width: 70,
      // description: "수정/삭제", // 불편해서 주석처리
      type: 'actions',
      getActions: (params) => generateActions(params),
    },
  ];

  const rows = useSelector((state) => state.gorder.gorders);
  const selectedRow = useSelector((state) => state.gorder.gorder);
  
  const defaultValues = {
    comments: "",
  }

  const { handleSubmit, control, setValue } = useForm({ defaultValues: defaultValues }); // 전송시 상태정보를 바꾸기 위한 form

  // 데이터 관리
  const dispatch = useDispatch();

  const selectAllByQuery = () => dispatch(gorderActions.selectAllByQuery())
  const selectAllByProjectByQuery= (gprojectId) => dispatch(gorderActions.selectAllByProjectByQuery(gprojectId))
  const select = (id) => dispatch(gorderActions.select(id))
  // const initGOrder = () => dispatch(gorderActions.initGOrder())
  const initWithInitialValue = (initialValue) => dispatch(gorderActions.initGOrderWithInitialValue(initialValue));
  // const reorder = ({ type, id }) => gorderActions.reorder({ type, id })
  // const sendOrderDirect = ({ id, status, comments }) => gorderActions.sendDirect({ id, status, comments })

  // const onSubmit = async ({ comments }) => {
  //   setErrors([]);
  //   // return;
  //   handleSendDialogClose();

  //   setOpenBackdrop(true);
    
  //   /*const result = */await sendOrderDirect({ id: sendId, status: 'SEND', comments });
    
  //   // setValue("comments", "");
  //   // setSendDialogOpen(false);

  //   // 발주 다이얼로그에서 전송 요청한 경우
  //   if (open) {
  //     setOpen(false);
  //   }

  //   if (from?.params?.gprojectId) { // TODO : 추후 Drawer 방식으로 변경할 것
  //     await selectAllByProjectByQuery(from.params.gprojectId);
  //   } else {
  //     await selectAllByQuery();
  //   }

  //   setOpenBackdrop(false);
  // }

  const handleSendDialogClose = () => {
    setValue("comments", "");
    setSendDialogOpen(false);
  }

  useEffect(
    async () => {
      // console.log(searchParams);
      // const gprojectId = searchParams.get('gprojectId');
      // const mode = searchParams.get('mode');
      // if (gprojectId && mode) {
      //   openOrderDialog();
      // }

      if (from?.params?.gprojectId) {
        await selectAllByProjectByQuery(from.params.gprojectId);
      } else {
        await selectAllByQuery();
      }

      await hideWatermark();
      // watermark 안보이면서 로딩바 보이도록 하기 위한 임시 코드
      setShow(true);
      setTimeout(() => setLoaded(true), 300);

      if (from?.params?.gprojectId) {
        // setCrudMode('C');
        // setOpen(true);
        // initWithInitialValue({});
        handleClickOpen();
      }

    }, [dispatch]
  );

  const refreshManagementList = async () => {
    setLoaded(false);

    if (from?.params?.gprojectId) {
      await selectAllByProjectByQuery(from.params.gprojectId);
    } else {
      await selectAllByQuery();
    }

    setTimeout(() => setLoaded(true), 300);
  }

  // const refresh = async () => {
  //   if (from?.params?.gprojectId) {
  //     await selectAllByProjectByQuery(from.params.gprojectId);
  //   } else {
  //     await selectAllByQuery();
  //   }
  // }

  return (
    <ThemeProvider theme={theme}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container component="main" maxWidth="lg">
        <CssBaseline />
        <GOrderDialog
          // modify={modify}
          crudMode={crudMode}
          open={open}
          setOpen={setOpen}
          selectedRow={selectedRow}
          refresh={refreshManagementList}
          // gprojectId={from?.params?.gprojectId}
          from={from}
          // 목록에 있는 GReceptionProcessDialog를 다이얼로그 안에서 사용하기 위해...
          setSendDialogOpen={setSendDialogOpen}
          // greceptionId={greceptionId}
          setGorderId={setGorderId}
          setParentOpen={setOpen}
        />
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={10}>
              <Typography variant="h5">
                {title}
              </Typography>
            </Grid>
            <Grid item xs={2} display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                startIcon={<Add />}
                onClick={handleClickOpen}
              >
                {"등록하기"}
              </Button>
            </Grid>
          </Grid>
          <div style={{ height: 800, width: '100%' }}>
            <Skeleton variant="rounded" height={show ? 0 : 800} />
            <DataGridPro
              localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
              columnHeaderHeight={38}
              rowHeight={34}
              sx={{ visibility: show ? 'visible' : 'hidden', cursor: 'pointer', fontSize: '0.85em' }}
              initialState={{ pinnedColumns: { right: ['actions'] } }}
              slots={{
                noRowsOverlay: CustomNoRowsOverlay,
                loadingOverlay: LinearProgress,
              }}
              loading={!loaded}
              rows={rows}
              columns={columns}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[10, 20, 50, 100]}
              pagination
              onRowDoubleClick={(params) => handleRowDoubleClick({ params })}
            />
          </div>
        </Box>
        <ConfirmDialog
          removeId={removeId}
          title={"삭제"}
          open={confirmOpen}
          setOpen={setConfirmOpen}
          onConfirm={remove}
          onCancel={() => {}}
        >
          {`"${params && params.row && params.row.name || ""}(아이디 : ${params && params.id  || ""})"를 삭제하시겠습니까?`}
        </ConfirmDialog>
        {/* TODO : GReceptionProcessDialog처럼 분리할 것 */}
        {/* <Dialog
          open={sendDialogOpen}
          onClose={handleSendDialogClose}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          fullWidth={true}
          maxWidth="sm"
          // onConfirm={sendOrder}
        >
          <DialogTitleClose
            id="draggable-dialog-title"
            onClose={handleSendDialogClose}
            style={{ cursor: 'move' }}
          >
            {"발주 전송"}
          </DialogTitleClose>
          <DialogContent dividers>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormInputText
                  name={"comments"}
                  control={control}
                  label={"노트"}
                  multiline
                  rows={3}
                  maxRows={5}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSubmit(onSubmit)}>{"전송"}</Button>
            <Button onClick={handleSendDialogClose}>{"닫기"}</Button>
          </DialogActions>
        </Dialog> */}
        <GOrderProcessDialog
          open={sendDialogOpen}
          setOpen={setSendDialogOpen}
          id={gorderId}
          setParentOpen={setOpen}
          // refresh={() => selectAllByStatusReceptionByQuery(['PRE-RECEIVE', 'RECEIVE', 'RETURN'])}
          refresh={refreshManagementList}
        />
      </Container>
    </ThemeProvider>
  );
};

export default GOrderManagement;
