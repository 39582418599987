// TODO : gplan store와 통합할 것
import { csrfFetch } from "./csrf.js";

const SET_GWORKORDERS = "gplanPlanning/setGWorkOrders";
const REMOVE_GWORKORDER = "gplanPlanning/removeGWorkOrder";
const ADD_GWORKORDER = "gplanPlanning/addGWorkOrder";
const RECEIVE_PLANS = "gplanPlanning/receivePlans";
const CREATE_PLAN = 'gplanPlanning/createPlan';
const UPDATE_PLAN = 'gplanPlanning/updatePlan';
const REMOVE_PLAN = 'gplanPlanning/removePlan';

const setGWorkOrders = (gworkOrdersPlanning) => ({
  type: SET_GWORKORDERS,
  payload: gworkOrdersPlanning,
});

const removeGWorkOrder = (gworkOrderId) => ({
  type: REMOVE_GWORKORDER,
  payload: gworkOrderId,
});

const addGWorkOrder = (gworkOrder) => ({
  type: ADD_GWORKORDER,
  payload: gworkOrder,
})

const receivePlans = (gplans) => ({
  type: RECEIVE_PLANS,
  payload: gplans,
});

const createPlan = (gplan) => ({
  type: CREATE_PLAN,
  payload: gplan,
});

const updatePlan = (gplan) => ({
  type: UPDATE_PLAN,
  payload: gplan,
});

const removePlan = () => ({
  type: REMOVE_PLAN,
});

const getCircularReplacer = () => {
  const seen = new WeakSet();
  return (key, value) => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  };
};

export const establishSechdule = async ({ gplansPlanning, gworkOrdersPlanning }) => {
  // console.log("gplansPlanning");
  console.log(gplansPlanning);
  // console.log(JSON.stringify({ gplansPlanning, gworkOrdersPlanning }, getCircularReplacer()));
  const response = await csrfFetch(null, "/api/gplans/establish", {
    method: "POST",
    // body: JSON.stringify({ gplansPlanning, gworkOrdersPlanning }, getCircularReplacer()), // TypeError: Converting circular structure to JSON 해결하기 위해 getCircularReplacer 함수 사용
    body: JSON.stringify({ gplansPlanning, gworkOrdersPlanning }), // TypeError: Converting circular structure to JSON 해결하기 위해 getCircularReplacer 함수 사용
  });
  const data = await response.json();
  return data.gplans;
};

export const create = ({ name, start, end, comments }) => async dispatch => {
  const response = await csrfFetch(dispatch, "/api/gplans", {
    method: "POST",
    body: JSON.stringify({ name, start, end, comments }),
  });
  const data = await response.json();
  dispatch(createPlan(data.gplan));
  return response;
};

export const modify = ({ id, name, start, end, comments }) => async dispatch => {
  const response = await csrfFetch(dispatch, "/api/gplans", {
    method: "PUT",
    body: JSON.stringify({ id, name, start, end, comments }),
  });
  const data = await response.json();
  dispatch(updatePlan(data.gplan));
  return response;
};

export const selectAll = () => async dispatch => {
  const response = await csrfFetch(dispatch, "/api/gplans");
  const data = await response.json();
  console.log(data)
  dispatch(receivePlans(data.gplans));
  return response;
}

// 작업의뢰 목록을 데이터베이스에서 조회
export const selectGWorkOrdersByStatusAndGProcess = ({ status, gprocess }) => async dispatch => {
  const response = await csrfFetch(dispatch, `/api/gplans/gworkOrders/status/${status}/gprocess/${gprocess}`);
  const data = await response.json();
  console.log(data);
  dispatch(setGWorkOrders(data.gworkOrders));
  return response;
}

// 일정에 추가하면 목록에서는 지워지되 아직 확정이 아니므로 데이터베이스의 작업의뢰에는 아무 영향을 끼치지 않고 화면상의 redux에서만 적용
export const assignGWorkOrderToPlanning = (gworkOrderId) => async (dispatch) => {
  dispatch(removeGWorkOrder(gworkOrderId));
  return;
};

export const cancelGWorkOrderToPlanning = (event) => async (dispatch) => {
  dispatch(addGWorkOrder(event));
  return;
};

export const remove = (id) => async (dispatch) => {
  const response = await csrfFetch(dispatch, `/api/gplans/${id}`, {
    method: "DELETE",
  });
  dispatch(removePlan());
  return response;
};

export const setGPlansPlanning = (gplans) => async (dispatch) => {
  dispatch(receivePlans(gplans));
  return;
}

const initialState = {
  gworkOrdersPlanning: [],
  gplanPlanning: null,
  gplansPlanning: [],
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case SET_GWORKORDERS:
      // const gworkOrdersPlanning = action.payload.map(i => {
      //   return {
      //     ...i,
      //     belong: 'left',
      //   }
      // })
      newState = Object.assign({}, state, { gworkOrdersPlanning: action.payload });
      return newState;
    case REMOVE_GWORKORDER:
      console.log(state.gworkOrdersPlanning)
      console.log(action.payload)
      newState = Object.assign({}, state, {
        // TODO : id가 아닌 workOrderNo로 할지 재검토 필요
        // gworkOrdersPlanning: state.gworkOrdersPlanning.filter(i => i.id.toString() !== action.payload), // type에 주의 (db에는 integer, 화면에 dom id는 string)
        gworkOrdersPlanning: state.gworkOrdersPlanning.map(i => {
          if (i.id === action.payload) {
            return {
              ...i,
              status: 'ASSIGN',
            }
          }

          return i;
        }),
      });
      return newState;
    case ADD_GWORKORDER:
      console.log("ADD_GWORKORDER");
      console.log(action.payload);  
      console.log(state.gworkOrdersPlanning);

      let gworkOrdersPlanning = null;
      // const { id } = action.payload; // TODO : action.payload.id는 오류, const { id } = action.payload는 정상 ???
      const { id } = action.payload.extendedProps;

      const result = state.gworkOrdersPlanning.filter(plan => {
        // return plan.id.toString() === id;
        return plan.id === id;
      });
      if (result.length === 0) {
        // alert("추가")
        // console.log(action.payload.extendedProps)
        // 새로운 데이터의 extendedProps에는 status가 PRE이므로 배열에만 넣으면 되고 따로 status를 변경할 필요는 없음
        gworkOrdersPlanning = [].concat(state.gworkOrdersPlanning);

        let nPos = -1;
        gworkOrdersPlanning.some((i, idx) => {
          // if (i.id.toString() > id) {
          if (i.id > id) {
            nPos = idx;
            return true;
          }
        })
        
        nPos === -1 ? gworkOrdersPlanning.push(action.payload.extendedProps) : gworkOrdersPlanning.splice(nPos, 0, action.payload.extendedProps);
      } else {
        // alert("수정")
        gworkOrdersPlanning = [].concat(
          state.gworkOrdersPlanning.map(i => {
            // TODO : id가 아닌 workOrderNo로 할지 재검토 필요
            // if (i.id.toString() === id) { // type에 주의 (db에는 integer, 화면에 dom id는 string)
            if (i.id === id) {
              return {
                ...i,
                status: 'PRE',
              }
            }
  
            return i;
          })
        )
      }

      newState = Object.assign({}, state, { gworkOrdersPlanning });
      return newState;
    case CREATE_PLAN:
      // alert("gplanPlanning");
      newState = Object.assign({}, state, {
        gplan: action.payload,
        gplans: state.gplans.push(action.payload),
      });
      return newState;
    case UPDATE_PLAN:
      newState = Object.assign({}, state, {
        gplan: action.payload,
        gplans: state.gplans.map(i => {
          if (i === action.payload.id) {
            return action.payload;
          } else {
            return i;
          }
        })
      });

      return newState;
    case REMOVE_PLAN:
      newState = Object.assign({}, state, {
        gplan: null,
        gplans: state.gplans.filter(i => i.id !== action.payload.id),
      });
      
      return newState;
    case RECEIVE_PLANS:
      // alert("gplanPlanning")
      newState = Object.assign({}, state, { gplansPlanning: action.payload });
      return newState;
    default:
      return state;
  }
}

export default reducer;
