import { useLocation, Navigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as sessionActions from '../store/session';
import * as menuActions from '../store/menu';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';

const RequireAuth = ({ sessionUser, children }) => {
    const location = useLocation();
    const dispatch = useDispatch();
    
    console.log("RequireAuth");
    console.log(location);
    const token = Cookies.get('token');
    console.log(sessionUser);
    console.log(token);
    // document.cookie
    console.log(token && jwtDecode(token));
    
    if (sessionUser) {
      return (<>{children}</>);
    } else {
      dispatch(sessionActions.removeSessionUser());
      dispatch(menuActions.removeAllTabMenus());
      
      // 로그아웃하면 이전 로그인한 사용자에 따라 signin 위치가 다름
      const userType = localStorage.getItem("type");
      if (userType === "GCLIENT") {
        return (<Navigate to="/glass/signin/gclient" replace state={{ from: `${location.pathname}${location.search}` }}/>); // search에 ? 붙어 있음
      } else if (userType === "USER") {
        return (<Navigate to="/glass/signin/guser" replace state={{ from: `${location.pathname}${location.search}` }}/>);
      } else {
        // // 아래 설정한 state를 signin에서 useLocation를 통해 접근 가능
        return (<Navigate to="/glass/signin" replace state={{ from: `${location.pathname}${location.search}` }}/>);
        // return (<Navigate to="/intro" replace state={{ from: `${location.pathname}${location.search}` }}/>);
      }
    }   
}

 export default RequireAuth;
