import { csrfFetch } from "./csrf.js";

const SET_GORDER_DETAILS = 'gorderDetail/setGOrderDetailss';

const setGOrderDetails = (gorderDetails) => ({
  type: SET_GORDER_DETAILS,
  payload: gorderDetails,
});

export const selectAllByGorderId = (gorderId) => async dispatch => {
  const response = await csrfFetch(dispatch, `/api/gorderDetails/gorderId/${gorderId}`);
  const data = await response.json();
  dispatch(setGOrderDetails(data.gorderDetails));
  return response;
}

export const selectAllByGorderIdDirect = async (gorderId) => {
  const response = await csrfFetch(null, `/api/gorderDetails/gorderId/${gorderId}`);
  const data = await response.json();
  return data.gorderDetails;
}

export const selectAllByGorderIdByQueryDirect = async (gorderId) => {
  const response = await csrfFetch(null, `/api/gorderDetails/query/selectAll/gorderId/${gorderId}`);
  const data = await response.json();
  return data.gorderDetails;
}

export const selectNoWorkOrder = async (gorderId) => {
  const response = await csrfFetch(null, `/api/gorderDetails/query/selectNoWorkOrder/gorderId/${gorderId}`);
  const data = await response.json();
  return data.gorderDetails;
}

const initialState = {
  gorderDetails: [],
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case SET_GORDER_DETAILS:
      newState = Object.assign({}, state, { gorderDetails: action.payload });
      return newState;
    default:
      return state;
  }
}

export default reducer;