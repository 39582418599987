import { csrfFetch } from "./csrf.js";
import { authServerUrl, mode } from '../config';

const SET_SESSION_USER = 'session/setSessionUser';
const REMOVE_SESSION_USER = 'session/removeSessionUser';

const setSessionUser = (sessionUser) => ({
  type: SET_SESSION_USER,
  payload: sessionUser,
});

export const removeSessionUser = () => ({
  type: REMOVE_SESSION_USER,
});

export const initPasswordDirect = async ({ type, bizRegNumber, email, userId }) => {
  // TODO : ~Direct는 dispatch를 null로 실행했는데 이럴 경우 오류 발생시 
  const response = await csrfFetch(null, `${authServerUrl}/api/session/initPassword`, {
    method: "POST",
    body: JSON.stringify({ type, bizRegNumber, email, userId }),
  });

  const data = await response.json();
  return data;
}

export const changePasswordDirect = async ({ credential, type, password, passwordNew, passwordNewConfirm }) => {
  const response = await csrfFetch(null, `${authServerUrl}/api/session/changePassword`, {
    method: "POST",
    body: JSON.stringify({ credential, type, password, passwordNew, passwordNewConfirm }),
  });
  
  const data = await response.json();
  return data;
}

export const login = ({ credential, password, type/*, status*/, searchStatus }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/session/`, {
    method: "POST",
    body: JSON.stringify({ credential, password, type/*, status*/, searchStatus }),
  });
  
  const data = await response.json();
  
  const { sessionUser, accessToken, refreshToken } = data;
  
  // 중요: redux setGClient 전에 해야함
  sessionStorage.setItem("accessToken", accessToken);
  sessionStorage.setItem("refreshToken", refreshToken);

  dispatch(setSessionUser(sessionUser));
  
  // sessionStorage.setItem("accessToken", accessToken);
  // sessionStorage.setItem("refreshToken", refreshToken);

  return response;
};

// TODO : 추후 type (GLIENT, ADMIN, USER...) 대응 필요
export const setEmailDirect = async ({ type, credential, mailSettings }) => {
  const response = await csrfFetch(null, `${authServerUrl}/api/session/email`, {
    method: "PUT",
    body: JSON.stringify({ type, credential, mailSettings }),
  });
  
  const data = await response.json();
  return data;
}

export const restoreSessionUser = () => async dispatch => {
  try {
    const response = await csrfFetch(dispatch, `${authServerUrl}/api/session/`);
    const data = await response.json();
    // 여러 이유로 세션이 만료되었다고 판단되는 경우 서버에서 {}를 리턴하여 (res.json({} 코드실행) data는 {}이 되고 data.user는 undefined가 됨
    console.log(data.sessionUser)

    // const data = await response.json();
    
    const { sessionUser, accessToken, refreshToken } = data;
    
    if (mode.indexOf('java')) {
    } else {
      // 중요: redux setGClient 전에 해야함
      sessionStorage.setItem("accessToken", accessToken);
      sessionStorage.setItem("refreshToken", refreshToken);
    }

    dispatch(setSessionUser(sessionUser));
    return response;
  } catch (e) {
    console.log("~~~~~~~~~~~~~")
    console.log(e)
  }
};

export const updateSessionUser = (gclient) => dispatch => {
  dispatch(setSessionUser(gclient));
  return;
};

// sessionUser에 정보를 저장해야 하므로 gclient store를 사용하지 않고 session store를 사용함
export const signupGClient = ({ id, name, bizRegNumber, phone, address, email, password, initPassword, loginCount, /* 판유리협회 회원사 정보 중 추가 컬럼들 */ceo, fax, joinDate, products, zipCode, phone2, otherPhones, address2, otherAddresses, membership, consented }) => async (dispatch) => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/gclients/signup`, {
    method: "POST",
    body: JSON.stringify({ id, name, bizRegNumber, phone, address, email, password, initPassword, loginCount, /* 판유리협회 회원사 정보 중 추가 컬럼들 */ceo, fax, joinDate, products, zipCode, phone2, otherPhones, address2, otherAddresses, membership, consented }),
  });
  
  const data = await response.json();
  const { sessionUser, accessToken, refreshToken } = data;
  
  sessionStorage.setItem("accessToken", accessToken);
  sessionStorage.setItem("refreshToken", refreshToken);

  dispatch(setSessionUser(sessionUser));

  return response;
};

// sessionUser에 정보를 저장해야 하므로 gclient store를 사용하지 않고 session store를 사용함
export const signupUser = ({ id, userId, name, mobile, password, gclientId, consented }) => async (dispatch) => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/users/signup`, {
    method: "POST",
    body: JSON.stringify({ id, userId, name, mobile, password, gclientId, consented }),
  });
  
  const data = await response.json();
  const { sessionUser, accessToken, refreshToken } = data;
  
  sessionStorage.setItem("accessToken", accessToken);
  sessionStorage.setItem("refreshToken", refreshToken);

  dispatch(setSessionUser(sessionUser));

  return response;
};

export const signupUserDirect = async ({
  id,
  userId,
  name,
  mobile,
  password,
  gclientId,
  consented,
  // belongs,
}) => {
  const response = await csrfFetch(null, `${authServerUrl}/api/users/signup`, {
    method: "POST",
    body: JSON.stringify({
      id,
      userId,
      name,
      mobile,
      password,
      gclientId,
      consented,
      // belongs,
    }),
  });

  return response;
};

export const refreshAccessToken = () => async (dispatch) => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/session/refreshAccessToken`, {
    method: "POST",
    // TODO : JAVA에서 빼야 할 수도 있음
    body: JSON.stringify({
      refreshToken: sessionStorage.getItem("refreshToken"),
    }),
  });
  const data = await response.json();
  const { accessToken } = data;

  sessionStorage.setItem("accessToken", accessToken);

  // dispatch(setSessionUser(data.sessionUser));
  return response;
}

export const logout = (session) => async (dispatch) => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/session/`, {
    method: "DELETE",
  });
  
  dispatch(removeSessionUser());

  delete sessionStorage.accessToken;
  delete sessionStorage.refreshToken;

  return response;
};

const initialState = {
  sessionUser: null,
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case SET_SESSION_USER:
      newState = Object.assign({}, state, { sessionUser: action.payload });
      return newState;
    case REMOVE_SESSION_USER:
      newState = Object.assign({}, state, { sessionUser: /*null*/undefined });
      return newState;
    default:
      return state;
  }
}

export default reducer;
