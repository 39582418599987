import PropTypes from 'prop-types';
import {
  DialogTitle,
  IconButton,
} from '@mui/material';
import {
  Close,
  ZoomInMap,
  ZoomOutMap,
} from '@mui/icons-material';

const DialogTitleClose = (props) => {
  const { children, onClose, onMinMax, fullScreen, buttonColor, hideCloseButton, ...other } = props;
  
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose && !hideCloseButton ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => fullScreen ? "white" : (buttonColor ? buttonColor : theme.palette.grey[500]),
          }}
        >
          <Close />
        </IconButton>
      ): null}
      {onMinMax && (
        <IconButton
          aria-label="close"
          onClick={onMinMax}
          sx={{
            position: 'absolute',
            right: 48,
            top: 8,
            color: (theme) => fullScreen ? "white" : (buttonColor ? buttonColor : theme.palette.grey[500]),
          }}
        >
          {
            fullScreen ? <ZoomInMap /> : <ZoomOutMap />
          }
        </IconButton>
      )}
    </DialogTitle>
  );
};

DialogTitleClose.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  hideCloseButton: PropTypes.bool,
};

export default DialogTitleClose;