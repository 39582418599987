const SET_SHOW_FOOTER = 'error/setShowFooter';

const setShowFooter = (show) => ({
  type: SET_SHOW_FOOTER,
  payload: show,
});

export const displayFooter = (show) => async dispatch => {
  dispatch(setShowFooter(show));
};

const initialState = {
  footer: false,
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case SET_SHOW_FOOTER:
      newState = Object.assign({}, state, { footer: action.payload });
      return newState;
    default:
      return state;
  }
}

export default reducer;
