const SET_SELECTED_ROWS = 'datagrid/setSelectedRows';

export const setSelectedRows = (rows) => ({
  type: SET_SELECTED_ROWS,
  payload: rows,
});

const initialState = {
  selectedRows: [],
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case SET_SELECTED_ROWS:
      newState = Object.assign({}, state, { selectedRows: action.payload });
      return newState;
    default:
      return state;
  }
}

export default reducer;
