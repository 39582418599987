import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  // Accordion,
  // AccordionSummary,
  // AccordionDetails,
  Typography,
} from '@mui/material';
import  {
  PictureAsPdf,
  Image,
} from '@mui/icons-material';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "../accordion";
import {
  FormInputDropdown,
  FormInputText,
} from "../form";
import {
  DialogTitleClose,
  PaperComponent,
} from "../dialog";
import * as gunitActions from "../../store/gunit";

// 아래 form components의 name과 연계
const defaultValues = {
  id: "",
  fromGClient: "",
  toGClient: "",
  subject: "",
  html: "",
};

const GSendG04docuDialog = ({
  crudMode,
  open,
  setOpen,
  selectedRow,
  handleClickDownloadAttachFile,
}) => {
  // TODO : extend가 의미가 맞는 거 같다.
  const [expanded, setExpanded] = useState(false);

  const { handleSubmit, reset, control, setValue } = useForm({ defaultValues: defaultValues });

  const handleDialogClose = () => {
    setOpen(false);
  };

  useEffect(
    () => {
      console.log(selectedRow);
      if (selectedRow) {
        for (const [item, value] of Object.entries(defaultValues)) {
          setValue(item, selectedRow[item] || value);
        }
      }

      if (selectedRow.attachments && selectedRow.attachments.length <= 5) {
        setExpanded(true);
      }
    }
    , [selectedRow]
  );

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitleClose
        id="draggable-dialog-title"
        onClose={handleDialogClose}
        style={{ cursor: 'move' }}
      >
        { crudMode === 'R' && "메일 상세"}
      </DialogTitleClose>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ display: 'none'}}>
            <FormInputText
              name={"id"}
              control={control}
              label={"아이디"}
              inputProps={
                { readOnly: true }
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormInputText
              name={"fromGClient"}
              control={control}
              label={"보낸 거래선"}
              inputProps={
                { readOnly: crudMode === 'R' }
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormInputText
              name={"toGClient"}
              control={control}
              label={"받은 거래선"}
              inputProps={
                { readOnly: crudMode === 'R' }
              }
              // InputProps={{
              //   inputComponent: NumberFormatCustom,
              //   inputProps: {
              //     style: { textAlign: "right" },
              //   }
              // }}
            />
          </Grid>
          <Grid item xs={12}>
           {
              selectedRow.mailTo && selectedRow.mailTo.length > 0 ? (<FormInputText
                name={"mailTo"}
                control={control}
                label={"받은 사람"}
                inputProps={{ readOnly: crudMode === 'R' }}
                InputProps={{ startAdornment: selectedRow.mailTo.map(to => <Chip label={to.name} sx={{ mt: 1, mb: 1, mr: 1 }} />) }}
              />) : (<FormInputText
                name={"mailTo"}
                control={control}
                label={"받은 사람"}
                inputProps={{ readOnly: crudMode === 'R' }}
              />)
            }
          </Grid>
          <Grid item xs={12}>
            {
              selectedRow.mailCc && selectedRow.mailCc.length > 0 ? (<FormInputText
                name={"mailCc"}
                control={control}
                label={"참조"}
                inputProps={{ readOnly: crudMode === 'R' }}
                InputProps={{ startAdornment: selectedRow.mailCc.map(cc => <Chip label={cc.name} sx={{ mt: 1, mb: 1, mr: 1 }} />) }}
              />) : (<FormInputText
                name={"mailCc"}
                control={control}
                label={"참조"}
                inputProps={{ readOnly: crudMode === 'R' }}
              />)
            }
          </Grid>
          <Grid item xs={12}>
            {
              selectedRow.mailCcInternal && selectedRow.mailCcInternal.length > 0 ? (<FormInputText
                name={"mailCcInternal"}
                control={control}
                label={"내부참조"}
                inputProps={{ readOnly: crudMode === 'R' }}
                InputProps={{ startAdornment: selectedRow.mailCcInternal.map(ccInternal => <Chip label={ccInternal.name} sx={{ mr: 1 }} />) }}
              />) : (<FormInputText
                name={"mailCcInternal"}
                control={control}
                label={"내부참조"}
                inputProps={{ readOnly: crudMode === 'R' }}
              />)
            }
          </Grid>
          <Grid item xs={12}>
            <FormInputText
              name={"subject"}
              control={control}
              label={"제목"}
              inputProps={
                { readOnly: crudMode === 'R' }
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormInputText
              name={"html"}
              control={control}
              label={"메시지"}
              inputProps={
                { readOnly: crudMode === 'R' }
              }
              multiline
              maxRows={5}
            />
          </Grid>
          <Grid item xs={12}>
            <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
              <AccordionSummary aria-controls="basic-content" id="basic-header">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="body" component="div">{`첨부파일 (${selectedRow.attachments && selectedRow.attachments.length ? selectedRow.attachments.length : 0})`}</Typography>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                {
                  selectedRow.attachments && selectedRow.attachments.length > 0 && selectedRow.attachments.map(file => {
                    const { filename, path } = file;
                    const pos = filename.lastIndexOf(".");
                    let ext = "";
                    if (pos > -1) {
                      ext = filename.substring(pos+1);
                    }

                    return (
                      <ListItem disablePadding>
                        <Tooltip title={"다운로드"}>
                        <ListItemButton onClick={(e) => handleClickDownloadAttachFile(e, path)}>
                          <ListItemIcon>
                            { ext.toLowerCase() === 'pdf' ? <PictureAsPdf /> : <Image /> }
                          </ListItemIcon>
                          <ListItemText primary={filename} />
                        </ListItemButton>
                        </Tooltip>
                      </ListItem>
                    )
                  })
                }     
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose}>{"닫기"}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default GSendG04docuDialog;
