import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Stack,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import {
  FormInputDropdown,
  FormInputText,
} from "../form";
import {
  DialogTitleClose,
  PaperComponent,
} from "../dialog";
import * as g04docuAlarmEmailActions from "../../store/g04docuAlarmEmail";
import * as dialogActions from "../../store/components/dialog";

// 아래 form components의 name과 연계
const defaultValues = {};

const spinnerStyles = {
  "-webkit-appearance": "none",
  background:
    "#fff url('spinner.png') no-repeat center center",
  width: "2em",
  opacity: 1,
  position: "absolute",
  top: 0,
  right: 0,
  bottom: 0,
  overflow: "hidden",
  borderTopRightRadius: "0.25rem",
  borderBottomRightRadius: "0.25rem"
};

const G04docuAlarmSettingDialog = ({ }) => {
  
  const [g04docuAlarmEmails, setG04docuAlarmEmails] = useState([
    { period: 0, type: 'D' },
    { period: 0, type: 'D' },
    { period: 0, type: 'D' },
  ]);

  const initDialog = () => {

    setTimeout(() => {
      setG04docuAlarmEmails([
        { period: 0, type: 'D' },
        { period: 0, type: 'D' },
        { period: 0, type: 'D' },
      ]);
    }, 500);
  }

  const handleDialogClose = () => {
    dispatch(dialogActions.setOptions({ open: false, name: "G04docuAlarmSettingDialog", crudMode: '' }));

    initDialog();
  }
  
  const { handleSubmit, reset, control, setValue } = useForm({ defaultValues: defaultValues });
  
  // 데이터 관리
  const dispatch = useDispatch();

  const options = useSelector((state) => state.dialog.options);

  const createG04docuAlarmEmails = ({ gclientId, g04docuId, g04docuAlarmEmails }) => g04docuAlarmEmailActions.bulkCreateDirect({ gclientId, g04docuId, g04docuAlarmEmails })

  const onSubmit = async ({ }) => {
    const gclientId = options?.values?.gclientId;
    const g04docuId = options?.values?.g04docuId;

    try {
      if (gclientId && g04docuId) {
        const res = await createG04docuAlarmEmails({ gclientId, g04docuId, g04docuAlarmEmails: g04docuAlarmEmails.filter(alarm => alarm.period !== 0) });
        options?.action && options?.action({ gclientId, types: options?.values.types, searchName: options?.values.searchName });
        
        // TODO : 오류발생시 처리 필요
        handleDialogClose();
      }
    } catch (e) {
      // TODO : 오류발생시 처리 필요
    }
  }

  useEffect(
    () => {
      if (options) {
        if (options.values && options.values.alarms && options.values.alarms.length > 0) {
          const initLength = 3 - options.values.alarms.length;

          const newG04docuAlarmEmails = options.values.alarms.map(alarm => ({ period: alarm.period, type: alarm.type }));
          if (initLength > 0) {
            for(let i=0; i<initLength; i++) {
              newG04docuAlarmEmails.push({ period: 0, type: 'D' });
            }
          }
          setG04docuAlarmEmails(newG04docuAlarmEmails);
        }
      }
    }
    , [options]
  );

  const handleChangePeriod = (e, i) => {
    const { value } = e.target;
    const newValue = value > 0 ? value : 0;
    
    const newG04docuAlarmEmails = g04docuAlarmEmails.map((g04docuAlarmEmail, idx) => {
      if (idx === i) {
        return {
          ...g04docuAlarmEmail,
          period: newValue,
        }
      } else {
        return g04docuAlarmEmail;
      }
    });

    setG04docuAlarmEmails(newG04docuAlarmEmails);
  }

  return (
    <Dialog
      open={options?.open && options?.name === "G04docuAlarmSettingDialog" || false}
      onClose={handleDialogClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      maxWidth="xs"
    >
      <DialogTitleClose
        id="draggable-dialog-title"
        onClose={handleDialogClose}
        style={{ cursor: 'move' }}
      >
        {"알람설정"}
      </DialogTitleClose>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ color: "#1976D2" }}>
            {"0을 설정하시면 알람이 꺼집니다."}
          </Grid>
          {
            g04docuAlarmEmails.map((g04docuAlarmEmail, i) => {
              const { id, period, type } = g04docuAlarmEmail;
              return (
                <Grid item xs={12}>
                  <Stack direction="row" display="flex" alignItems="center">
                    <FormInputText
                      control={control}
                      value={period}
                      type="number"
                      sx={{
                        "input[type=number]::-webkit-outer-spin-button": {
                          ...spinnerStyles
                        },
                        "input[type=number]::-webkit-inner-spin-button": {
                          ...spinnerStyles
                        },
                        width: '200px',
                        mr: 1,
                      }}
                      onChange={(e) => handleChangePeriod(e, i)}
                      InputProps={{
                        inputProps: {
                            style: { textAlign: "center", marginLeft: '100px' },
                        }
                      }}
                    />
                    {
                      type === 'D' && "일 전"
                    }
                  </Stack>
                </Grid>
              )
            })
          }
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit(onSubmit)}>{"설정"}</Button>
        <Button onClick={handleDialogClose}>{"닫기"}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default G04docuAlarmSettingDialog;
