/**
 * Config.js 파일
 * 
 * 환경파일 패키지 설치 : npm install --save-dev env-cmd cross-env
 * 로컬 => 개발자의 개발 frontend
 * 로컬 서버 => 개발자의 개발 서버(core + db)
 * 테스트 서버 => 운영 서버 배포전 동일한 형태의 테스트 서버 node env === 'development' (core + db)
 * 운영 서버 => www.04docu.co.kr 서버 node env === 'production' (core + db)
 * 
 * [ mode 설명 ]
 * 
 * # 개발용
 * dev-java-api                 => 로컬 - 자바API 테스트서버(https://121.78.79.54)
 * dev-local                    => 로컬 - 로컬 서버
 * dev-development              => 로컬 -> 시연 서버
 * dev-development-external     => 로컬 -> 시연 서버 (외부IP)
 * dev-development2              => 로컬 -> 배포 테스트 서버
 * dev-development2-external     => 로컬 ->배포 테스트 서버 (외부IP)
 * dev-production               => 로컬 -> 운영 서버 => 사용안함
 * dev-production-external      => 로컬 -> 운영 서버 (외부IP) => 사용안함
 * 
 * # 배포용 (~/frontend에서 npm run build)
 * dist-development   => 시연 서버 빌드
 * dist-development2   => 배포 테스트 서버 빌드
 * dist-production    => 운영 서버 빌드 => 사용안함
 * dist-production-idc    => 운영 서버 빌드
 * 
 * dev-development(-external)와 dev-production(-external)은 개발자의 frontend에서 직접 서버로 접속하여 테스트해 볼 용도로 사용 (frontend 개발시 서버에 배포전 바로 확인할 수 있는 장점이 있음)
 * 
 * [사용법] 
 * 해당 용도에 맞게 하나의 모드만 켜고 주석처리하여 사용한다.
 * 참고로 아래 명령어 수행시
 * react-scripts start => 자등으로 NODE_ENV === "development"
 * react-scripts build => 자등으로 NODE_ENV === "production"
 */

//set REACT_APP_MODE=dev-java-api && npm start


export const mode = process.env.REACT_APP_MODE|| "dev-java-api";

export const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || '';
export const authServerUrl = `${apiBaseUrl}${process.env.REACT_APP_AUTH_SERVER_URL}`;
export const projectServerUrl = `${apiBaseUrl}${process.env.REACT_APP_PROJECT_SERVER_URL}`;
export const gcoreServerUrl = `${apiBaseUrl}${process.env.REACT_APP_GCORE_SERVER_URL}`;
export const gcoreMAServerUrl = `${apiBaseUrl}${process.env.REACT_APP_GCORE_MA_SERVER_URL}`;
export const fileServerUrl = `${apiBaseUrl}${process.env.REACT_APP_FILE_SERVER_URL}`;
export const filePreviewServerUrl = `${apiBaseUrl}${process.env.REACT_APP_FILE_PREVIEW_SERVER_URL}`;
// 서버에서의 파일 저장 경로로 DB에 저장되어 있다. frontend에서 문서보기할때 uploadFilePath를 fileServerUrl로 replace하여 사용
export const uploadFilePath = process.env.REACT_APP_UPLOAD_FILE_PATH;
// 현재 App.js에서만 사용하고 있음 => mode에 따라 console.log 동작 중지하는 용도로 사용

const config = {
  authServerUrl,
  projectServerUrl,
  gcoreServerUrl,
  gcoreMAServerUrl,
  fileServerUrl,
  uploadFilePath,
  filePreviewServerUrl,
  mode,
};

export default config;