import { csrfFetch } from "./csrf.js";
import { authServerUrl } from '../config';

const SET_CONSENT = 'consent/setConsent';

const setConsent = (consent) => ({
  type: SET_CONSENT,
  payload: consent,
});

export const createOrModify = (credential) => async dispatch => {
  const response = await csrfFetch(dispatch, `${authServerUrl}/api/consents`, {
    method: "POST",
    body: JSON.stringify({ credential }),
  });
  const data = await response.json();
  dispatch(setConsent(data.consent));
  return response;
};

const initialState = {
  consent: null,
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case SET_CONSENT:
      newState = Object.assign({}, state, { consent: action.payload });
      return newState;
    default:
      return state;
  }
}

export default reducer;
