import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import { red } from '@mui/material/colors';
import {
  Block,
  Circle,
  ChromeReaderMode,
  FilePresent,
  Preview,
} from '@mui/icons-material';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "../accordion";
import { ConfirmDialog } from "../../components/dialog";
import { dateFormat } from "../../utils";
import { StyledTooltip } from "../tooltip";

// store actions
import * as g04docuFileActions from "../../store/g04docuFile";
import * as gclientG04docuEtcMapActions from "../../store/gclientG04docuEtcMap";

import G04docuFileDialogEtc from "../G04docuFile/G04docuFileDialogEtc";

const tooltipTop = {
  "& .MuiTooltip-tooltip": {
    borderRadius: "0px",
  }
};

const G04docuEtc = ({
  handleClickFileDownload,
  handleClickViewEachDoc,
  selectedGClient,
}) => {
  const [expanded, setExpanded] = useState(true);
  const [mode, setMode] = useState('all');

  const sessionUser = useSelector((state) => state.session.sessionUser);
  const etcs = useSelector((state) => state.gclientG04docuEtcMap.gclientG04docuEtcMaps);
  const [selectedRowEtc, setSelectedRowEtc] = useState([]);
  const [etcType, setEtcType] = useState("");
  const [open, setOpen] = useState(false);
  const [openEtc, setOpenEtc] = useState(false);
  const [removeFileObject, setRemoveFileObject] = useState({});
  const [confirmOpenRemoveFile, setConfirmOpenRemoveFile] = useState(false);

  const dispatch = useDispatch();

  const removeG04docuFile = (documentPath) => g04docuFileActions.removeDirect(documentPath)
  const removeGClientG04docuEtcMap = ({ gclientId, g04docuEtcId, documentPath }) => dispatch(gclientG04docuEtcMapActions.remove({ gclientId, g04docuEtcId, documentPath }))
  const selectMyG04docuEtcs = (gclientId, type, status) => dispatch(gclientG04docuEtcMapActions.selectByGClientByTypeByStatus(gclientId, type, status)) // 기타 문서 중 type=ETC인 문서(기타 문서 중 공통서류, 인증서, 성적서가 아닌 문서)

  useEffect(
    () => {
      selectMyG04docuEtcs(sessionUser.id, 'ETC', 'all')
    }, [dispatch]
  )

  const handleChangeMode = (e, newMode) => {
    e.stopPropagation();

    setMode(newMode);

    selectMyG04docuEtcs(sessionUser.id, 'ETC', newMode);
  }

  const handleClickEtc = (e, etc, mode) => {
    const { id, code, gclientId, name, documentPath, validYN, startDate, endDate } = etc;
    if (mode === 'attachDocument') {
      setSelectedRowEtc({
        g04docuEtcId: id,
        documentType: code,
        title: name,
        documentPath,
        validYN,
        startDate,
        endDate,
      });

      setEtcType('ETC');
      setOpenEtc(true);
    } else if (mode === 'deleteDocument') {
      if (documentPath) {
        setEtcType('ETC');
        setRemoveFileObject({ gclientId, g04docuEtcId: id, documentPath, type: 'ETC' });
        setConfirmOpenRemoveFile(true);
      }
    }
  }

  const selectAllG04docuEtcs = (gclientId) => {
    selectMyG04docuEtcs(gclientId, 'ETC', mode);
  }

  const removeFile = async () => {
    const { gclientId, g04docuEtcId, documentPath } = removeFileObject;
    await removeG04docuFile(documentPath);
    await removeGClientG04docuEtcMap({ gclientId, g04docuEtcId, documentPath });
    selectAllG04docuEtcs(gclientId);
  }

  return (
    <>
      {
        // || modeG04docuGCertification === 'nofile' || modeG04docuGCertification === 'invalid' 조건이 없다면 인증서 필터링할 때 조회결과가 없으면 사라짐
        (etcs && etcs.length > 0 || mode === 'nofile' || mode === 'invalid') && (
          <Grid item xs={12}>
            <G04docuFileDialogEtc
              open={openEtc}
              setOpen={setOpenEtc}
              selectedRow={selectedRowEtc}
              gclientId={sessionUser.id}
              gclient={selectedGClient}
              refresh={selectAllG04docuEtcs}
            />
            <ConfirmDialog
              removeId={removeFileObject}
              title={"삭제"}
              open={confirmOpenRemoveFile}
              setOpen={setConfirmOpenRemoveFile}
              onConfirm={removeFile}
              onCancel={() => {}}
            >
              <div>
                <span style={{ color: "#1976d2" }}>{"첨부된 서류를 삭제하시겠습니까?"}</span>
                <br /><br />
                <Box sx={{ solid: 1, borderRadius: 1, bgcolor: '#eee', color: '#aaa', p: 1, fontSize: '0.8em' }}>
                  { `파일명 : ${removeFileObject?.documentPath?.split("/")[removeFileObject?.documentPath?.split("/")?.length-1]}` }
                </Box>
              </div>
            </ConfirmDialog>
            <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
              <AccordionSummary aria-controls="basic-content" id="basic-header">
                <Grid container spacing={2}>
                  <Grid item xs={6} display="flex" justifyContent="flex-start" alignItems="center">
                    <Typography variant="h6" component="div">
                      {"기타"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} display="flex" justifyContent="flex-end" alignItems="center">
                    <Typography variant="subtitle2" component="div" /*color="primary" */sx={{ mr: 1 }} onClick={(e) => e.stopPropagation()}>{"보기"}</Typography>
                    <ToggleButtonGroup
                      color="primary"
                      value={mode}
                      exclusive
                      size="small"
                      onChange={handleChangeMode}
                    >
                      <ToggleButton value="nofile">{"문서 없음"}</ToggleButton>
                      <ToggleButton value="invalid">{"만료일 경과"}</ToggleButton>
                      <ToggleButton value="all">{"전체"}</ToggleButton>
                    </ToggleButtonGroup>  
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <Grid container spacing={1} sx={{
                      pb: 1,
                      borderBottom: 1,
                      // height: '100%',
                      // borderRadius: 1,
                      borderColor: '#DCDCDC'
                    }}>
                      <Grid item xs={4} display="flex" justifyContent="center" alignItems="center">
                        {"서류명"}
                      </Grid>
                      <Grid item xs={2} display="flex" justifyContent="center" alignItems="center">
                        {"서류"}
                      </Grid>
                      <Grid item xs={2} display="flex" justifyContent="center" alignItems="center">
                        {"만료일"}
                      </Grid>
                      <Grid item xs={2} display="flex" justifyContent="center" alignItems="center">
                        {"최종수정일시"}
                      </Grid>
                      <Grid item xs={2} display="flex" justifyContent="center" alignItems="center" sx={{ ml: -1.6 }}>
                        {"업무"}
                      </Grid>
                    </Grid>
                  </Grid>
                  {
                    etcs?.map(etc => {
                      const {
                        code,
                        comments,
                        createdAt,
                        details,
                        divider,
                        division,
                        documentPath,
                        documentType,
                        endDate,
                        gclient,
                        gclientId,
                        gcomponentItemId,
                        gstandardId,
                        gstandardName,
                        gstandardNo,
                        id,
                        majority,
                        name,
                        order,
                        orderDetail,
                        selectedDetails,
                        startDate,
                        updatedAt,
                        mapCreatedAt,
                        mapUpdatedAt,
                        valid,
                        validYN,
                      } = etc;
                      // console.log(etc)
                      return (
                        <Grid item xs={12}>
                          <Grid container spacing={1} sx={{
                            // pb: 1,
                            borderBottom: 1,
                            // height: '100%',
                            // borderRadius: 1,
                            borderColor: '#DCDCDC'
                          }}>
                            <Grid item xs={4} display="flex" alignItems="center" sx={{ color: !documentPath ? red[700] : ((validYN || valid) ? "#000000" : "#ED6C02") }}>
                              {/* <Grid item xs={4} display="flex" alignItems="center" sx={{ color: valid === false ? red[700] : "" }}> */}
                              {
                                // documentPath && (validYN || valid) && <Grid display="flex" alignItems="center" sx={{ ml: 1, color: '#4caf50' }}><Circle /></Grid>
                                documentPath && (validYN || valid) && <Grid display="flex" alignItems="center" sx={{ ml: 1, color: '#4caf50' }}><Circle sx={{ width: 14, height: 14 }} /></Grid>
                              }
                              {/* {
                                documentPath && (!validYN && !valid) && <Grid display="flex" alignItems="center" sx={{ ml: 1, color: '#ED6C02' }}><Warning /></Grid>
                              } */}
                              {
                                documentPath && (!validYN && !valid) && <Grid display="flex" alignItems="center" sx={{ ml: 1, color: '#ED6C02' }}><Circle sx={{ width: 14, height: 14 }} /></Grid>
                              }
                              {/* {
                                !documentPath && <Grid display="flex" alignItems="center" sx={{ ml: 1, color: '#ef5350' }}><Block /></Grid>
                              } */}
                              {
                                !documentPath && <Grid display="flex" alignItems="center" sx={{ ml: 1, color: '#ef5350' }}><Circle sx={{ width: 14, height: 14 }} /></Grid>
                              }
                              <Typography variant="subtitle1" sx={{ ml: 2 }}>{name}</Typography>
                            </Grid>
                            <Grid item xs={2} display="flex" justifyContent="center" alignItems="center">
                              {
                                documentPath && (
                                  <StyledTooltip
                                    title={<Stack direction="row" display="flex" alignItems="center">
                                      <Box sx={{ solid: 1, borderRadius: 1, bgcolor: '#eee', color: '#aaa', p: 1, fontSize: '0.8em', mr: 1 }}>
                                        {`미리보기`}
                                      </Box>
                                      <Typography variant="subtitle2">{documentPath?.split("/")[documentPath?.split("/")?.length-1]}</Typography>
                                    </Stack>}
                                    placement="right"
                                    sx={tooltipTop}
                                  >
                                    <IconButton
                                      color="primary"
                                      aria-label="file"
                                      onClick={() => handleClickViewEachDoc(documentPath)}
                                      sx={{ color: (validYN || valid) ? "#1976D2" : "#ED6C02" }}
                                    >
                                      <Preview />
                                    </IconButton>
                                  </StyledTooltip>
                                )
                              }
                              {
                                !documentPath && (
                                  <Tooltip title={"서류없음"} placement="right">
                                    <IconButton
                                      aria-label="download"
                                      // edge="end"
                                      sx={{ color: '#ef5350' }}
                                    >
                                      <Block />
                                    </IconButton>
                                  </Tooltip>
                                )
                              }
                            </Grid>
                            <Grid item xs={2} display="flex" justifyContent="center" alignItems="center">
                              {
                                endDate ? (
                                  <Tooltip title={"만료일"} placement="right">
                                    <Chip label={` ~ ${dateFormat(endDate, 'yyyy-MM-dd')}`} color={valid === false ? "warning" : "primary"} variant={valid === false ? "" : "outlined"}></Chip>
                                  </Tooltip>
                                ) : ( validYN ? "만료일 없음" : "")
                              }
                            </Grid>
                            <Grid item xs={2} display="flex" justifyContent="center" alignItems="center">
                              {
                                mapUpdatedAt ? (
                                  <Tooltip title={"최종수정일시"} placement="right">
                                    <Chip label={dateFormat(mapUpdatedAt)}></Chip>
                                  </Tooltip>
                                ) : ""
                              }
                            </Grid>
                            <Grid item xs={2} display="flex" justifyContent="center" alignItems="center" sx={{ ml: -1.6 }}>
                              <>
                                <Button onClick={(e) => handleClickEtc(e, etc, 'attachDocument')} sx={{ color: '#1976d2' }}>{"등록/수정"}</Button>
                                <Button
                                  onClick={(e) => handleClickEtc(e, etc, 'deleteDocument')}
                                  disabled={!documentPath}
                                  sx={{ color: '#1976d2' }}
                                >
                                  {"삭제"}
                                </Button>
                              </>
                            </Grid>
                          </Grid>
                        </Grid>
                      )
                    })
                  }
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        )
      }
    </>
  )
}

export default G04docuEtc;