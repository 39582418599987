import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useForm, /*useFieldArray*/ } from "react-hook-form";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  Avatar,
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  IconButton,
  Grid,
  LinearProgress,
  Paper,
  Stack,
  Step,
  Stepper,
  StepButton,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  Add,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Remove,
  ChevronLeft,
  ChevronRight,
  DoneAll,
} from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';
import {
  objectEmptyCheck,
  hideWatermark,
  changeArrayOrder,
} from "../../utils";
import { GlassUtils } from "../../utils";
import {
  FormInputCheckbox,
  FormInputText,
  FormInputMultipleSelect,
  FormInputDropdown1,
} from "../form";
import {
  AlertDialog,
} from "../dialog";
import { NavigationGuard } from "../Navigation";
import * as gclientActions from "../../store/gclient";
import * as gtypeDetailActions from "../../store/gtypeDetail";
import * as gperfDataActions from "../../store/gperfData";

const steps = ["유리선택", "원판", "가공", "가공부자재", "시공부자재"];

const theme = createTheme();

const today = new Date();
let endDate = new Date();
endDate.setFullYear(today.getFullYear() + 2);

const GProjectG04Step = ({
  crudMode,
  setCrudMode,
  modify,
  setModify,
  open,
  setOpen,
  selectedRow,
  setSelectedRow,
  setValue,
  getValues,
  control,
  // gtypeDetailsWithGComponent,
  gtypes,
  refresh,
  selectedGlasses,
  setSelectedGlasses,
  selectedSubMaterialProcessItems,
  setSelectedSubMaterialProcessItems,
  selectedSubMaterialBuildItems,
  setSelectedSubMaterialBuildItems,
  modifyGlass,
  setModifyGlass,
  // setStepCompleted,
}) => {
  const dependentGcomponentMap = new Map();

  const gclientDropDown = React.useRef(); // 자식 함수 호출하기 위함(gclient FormInputDropDown의 set 함수 호출)
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const [renderRawMaterials, setRenderRawMaterials] = useState(true);
  const [renderProcesses, setRenderProcesses] = useState(true);
  const [baseSubMaterialProcess, setBaseSubMaterialProcess] = useState([]);
  const [renderSubMaterialProcessItems, setRenderSubMaterialProcessItems] = useState(true);
  const [baseSubMaterialBuild, setBaseSubMaterialBuild] = useState([]);
  const [renderSubMaterialBuildItems, setRenderSubMaterialBuildItems] = useState(true);
  const [alertInfo, setAlertInfo] = useState({});
  const [modifiedGlassesIndexArray, setModifiedGlassesIndexArray] = useState([]);
  const [selectedGClientIdForRawMaterial, setSelectedGClientIdForRawMaterial] = useState(undefined);
  const [selectedGClientsForProcess, setSelectedGClientsForProcess] = useState(undefined);
  
  // 제조사 또는 해당 가공업체들 모음
  const [manufacturersRawMaterial, setManufacturersRawMaterial] = useState([]);
  const [heatGClients, setHeatGClients] = useState([]);
  const [temperedGClients, setTemperedGClients] = useState([]);
  const [temperedHeatSoakGClients, setTemperedHeatSoakGClients] = useState([]);
  const [laminationGClients, setLaminationGClients] = useState([]);
  const [insulationGClients, setInsulationGClients] = useState([]);
  const [insulationGlazingGClients, setInsulationGlazingGClients] = useState([]);
  
  const [openBackdrop, setOpenBackdrop] = useState(false);
  // const [gclientsProcess, setGClientsProcess] = useState([]);

  const dispatch = useDispatch();

  const gclients = useSelector((state) => state.gclient.gclients);
  const gglasses = useSelector((state) => state.gglass.gglasses);
  const g04docuGCertificationsProcess = useSelector((state) => state.g04docuGCertification.g04docuGCertificationsProcess);
  const g04docuGCertificationsSubMaterialProcess = useSelector((state) => state.g04docuGCertification.g04docuGCertificationsSubMaterialProcess);
  const g04docuGCertificationsSubMaterialBuild = useSelector((state) => state.g04docuGCertification.g04docuGCertificationsSubMaterialBuild);
  // TODO : 성능확인 데이터 미리 가져오기 (데이터 사이즈가 클 수 있으므로 추후 검토. 아래에서 즉석에서 api 호출하는 방식??? 현재 async 문제로 미리 가져오는 방식으로 구현)
  const gglassPerformances = useSelector((state) => state.gglassPerformance.gglassPerformances);
  const gtrans = useSelector((state) => state.gperfData.gtrans);

  const selectGTypeDetailsWithGComponentDirect = (gtypeId) => gtypeDetailActions.selectGTypeDetailsWithGComponentDirect(gtypeId)
  const selectGClientsByType04docus = (type04docus) => gclientActions.selectByType04docusByQueryDirect(type04docus)
  const selectGPerfData1 = ({ glass }) => gperfDataActions.selectGPerfData1({ glass });
  const selectGPerfData2 = ({ ex_glass, gas_thickness, gas_material, in_glass }) => gperfDataActions.selectGPerfData2({ ex_glass, gas_thickness, gas_material, in_glass });
  const selectGPerfData3 = ({ ex_glass, gas_thickness1, gas_material1, mid_glass, build_aspect, gas_thickness2, gas_material2, in_glass }) => gperfDataActions.selectGPerfData3({ ex_glass, gas_thickness1, gas_material1, mid_glass, build_aspect, gas_thickness2, gas_material2, in_glass });
  
  useEffect(
    () => {
      const manufacturers = [];
      const heatGClients = [];
      const laminationGClients = [];
      const insulationGClients = [];
      
      gclients.forEach(gclient => {
        const { gclientType04docus } = gclient;
        if (gclientType04docus && Array.isArray(gclientType04docus) && gclientType04docus.length > 0) {
          const type04docus = gclientType04docus.map(gclientType04docu => gclientType04docu.code);
          // type04docus(gclientType04docu.code)와 인증규격 코드(G04docuGCertifications.code)를 혼동하지 말 것
          if (type04docus.includes('MANUFACTURER_RAW_MATERIAL')) {
            manufacturers.push(gclient);
          }
          
          if (type04docus.includes('HEAT_STRENGTHENED')) {
            heatGClients.push(gclient);
          }

          if (type04docus.includes('TEMPERED')) {
            temperedGClients.push(gclient);
          }

          if (type04docus.includes('Heat_Soak_Test')) {
            temperedHeatSoakGClients.push(gclient);
          }

          if (type04docus.includes('LAMINATED')) {
            laminationGClients.push(gclient);
          }

          if (type04docus.includes('INSULATED_GLASS_UNIT')) {
            insulationGClients.push(gclient);
          }

          if (type04docus.includes('ARGON_GAS')) {
            insulationGlazingGClients.push(gclient);
          }
        }
      });

      console.log({ manufacturers, heatGClients, temperedGClients, temperedHeatSoakGClients, laminationGClients, insulationGClients });

      setManufacturersRawMaterial(manufacturers);
      setHeatGClients(heatGClients);
      setTemperedGClients(temperedGClients);
      setTemperedHeatSoakGClients(temperedHeatSoakGClients);
      setLaminationGClients(laminationGClients);
      setInsulationGClients(insulationGClients);
      setInsulationGlazingGClients(insulationGlazingGClients);
    }, [gclients]
  );

  const updateLaminatedFilmInfo = (newSelectedGlasses) => {
    const laminatedGlasses = newSelectedGlasses.filter(glass => {
      return glass.selectedGcomponentItems.some(layer => 
        layer.some(item => item.code === 'FILM TYPE' || item.code === 'FILM THICKNESS')
      );
    });
  
    console.log(laminatedGlasses)
    const laminatedFilms = laminatedGlasses.reduce((acc, glass) => {
      const filmInfo = {};
      glass.selectedGcomponentItems.forEach(layer => {
        layer.forEach(item => {
          if (item.code === 'FILM TYPE') {
            filmInfo.code = item.value.code;
            filmInfo.selectedItem = item.value;
          }
          if (item.code === 'FILM THICKNESS') {
            const { code, gcomponentId, id, itemType, name, order } = item.value;
            filmInfo.gcomponentItemsSpecific = { code, gcomponentId, id, itemType, name, order };
          }
        });
      });
    
      const existingFilm = acc.find(film => film.code === filmInfo.code);
      if (existingFilm) {
        if (!existingFilm.gcomponentItemsSpecific.some(t => t.code === filmInfo.gcomponentItemsSpecific.code)) {
          existingFilm.gcomponentItemsSpecific.push(filmInfo.gcomponentItemsSpecific);
        }
      } else {
        acc.push({
          code: filmInfo.code,
          selectedGClientId: filmInfo.selectedItem.gclients && 
                              Array.isArray(filmInfo.selectedItem.gclients) && 
                              filmInfo.selectedItem.gclients.length === 1 && 
                              filmInfo.selectedItem.gclients[0].id || "",
          gcomponentItemsSpecific: [filmInfo.gcomponentItemsSpecific],
        });
      }
      return acc;
    }, []);
    
    console.log("접합유리 정보:", laminatedFilms);
    
    const laminatedFilmTemplate = baseSubMaterialProcess.find(item => item.code === 'LAMINATED_FILM');
    const originalIndex = baseSubMaterialProcess.findIndex(item => item.code === 'LAMINATED_FILM');
  
    const newBaseSubMaterialProcess = baseSubMaterialProcess.filter(item => item.code !== 'LAMINATED_FILM');
    const newSelectedSubMaterialProcessItems = selectedSubMaterialProcessItems.filter(item => item.code !== 'LAMINATED_FILM');
  
    laminatedFilms.forEach((film, index) => {
      const newBaseItem = { ...laminatedFilmTemplate };
      const insertIndex = originalIndex + index;
      newBaseSubMaterialProcess.splice(insertIndex, 0, newBaseItem);
  
      const matchingItem = laminatedFilmTemplate.gcomponentItems.find(item => item.code === film.code);
      const { id, name, code } = newBaseItem;
      const newSelectedSubMaterialProcessItem = {
        id,
        name,
        code,
        usable: true,
        value: {
          ...matchingItem,
          g04docuGCertificationCode: laminatedFilmTemplate.code,
          selectedGClientId: film.selectedGClientId,
          gcomponentItemsSpecific: film.gcomponentItemsSpecific
        }
      }
      newSelectedSubMaterialProcessItems.splice(insertIndex, 0, newSelectedSubMaterialProcessItem);
    });
  
    if (!newBaseSubMaterialProcess.find(item => item.code === 'LAMINATED_FILM')) {
      newBaseSubMaterialProcess.splice(originalIndex, 0, laminatedFilmTemplate);
      const { id, name, code } = laminatedFilmTemplate;
      newSelectedSubMaterialProcessItems.splice(originalIndex, 0, { id, name, code, usable: true, value: {} });
    }
  
    newSelectedSubMaterialProcessItems.forEach((item, index) => {
      item.index = index;
    });
  
    setBaseSubMaterialProcess(newBaseSubMaterialProcess);
    setSelectedSubMaterialProcessItems(newSelectedSubMaterialProcessItems);

    console.log({ newBaseSubMaterialProcess, newSelectedSubMaterialProcessItems });
  };

  useEffect(
    async () => {
      switch(activeStep) {
        case 0:
          break;

        case 1:
          // TODO : 자재승인서류 제출 업무에서 원자재 제조사가 아니어도 제조사로 선택할 수 있으므로 원자재 제조사를 전부 조회하여 제조사에 추가한다. 단, 해당 원자재의 제조사가 아닌 경우 비활성화 한다.
          // const manufacturers = await selectGClientsByType04docus(['MANUFACTURER_RAW_MATERIAL']);
          // console.log(manufacturers);
          // setManufacturersRawMaterial(manufacturers);

          ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          // 원판 제조사 자동 설정 => 해당 원판의 제조사는 한군데라는 전제
          // const newSelectedGlasses = JSON.parse(JSON.stringify(selectedGlasses));
          // newSelectedGlasses.forEach(glass => {
          //   const { selectedGcomponentItems } = glass;
          //   selectedGcomponentItems.forEach((gtypeDetails, i) => {
          //     gtypeDetails.forEach((item, j) => {
          //       // <<<주의 : 코드 하드코딩 사용>>>
          //       if (item.code === 'GLASS PRODUCT NAME') {
          //         item.value.selectedGClientId = item.value.gclients && Array.isArray(item.value.gclients) && item.value.gclients.length === 1 && item.value.gclients[0].id || "";
          //       }
          //     })
          //   })
          // });
          // setSelectedGlasses(newSelectedGlasses);
          ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          break;

        case 2:
          // const fabricator = await selectGClientsByType04docus(['HEAT_STRENGTHENED', 'TEMPERED', 'LAMINATED', 'INSULATED_GLASS_UNIT']);
          // console.log(JSON.stringify(fabricator, null, 2));
          // setGClientsProcess(fabricator);
          let first = false;
          console.log(selectedGlasses)
          
          selectedGlasses.forEach(glass => {
            const { selectedProcessGClients } = glass;
            if (selectedProcessGClients && Array.isArray(selectedProcessGClients) && selectedProcessGClients.length <=0) {
              first = true;
              return false;
            }
          }); // ;이 없으면 오류. 없다면 아래처럼 if 사용 필요

          // console.log({ modifyGlass, first });
          // (modifyGlass || first) && separateProcessFromGlass();
          setOpenBackdrop(true);
          setProgress(".");
          showProgress();
          await separateProcessFromGlass();
          setOpenBackdrop(false);
          // if (modifyGlass || first) {
          //   separateProcessFromGlass();
          // }

          break;

        case 3:
          setRenderSubMaterialProcessItems(false);
          setTimeout(() => setRenderSubMaterialProcessItems(true), 500);
          break;
          
        default:
      }
      // if (activeStep === 2) {
      //   modifyGlass && separateProcessFromGlass();
      // }
    }, [activeStep]
  )

  // const totalSteps = () => {
  //   return steps.length;
  // };

  // const completedSteps = () => {
  //   return Object.keys(completed).length;
  // };

  // const isLastStep = () => {
  //   return activeStep === totalSteps() - 1;
  // };

  // const allStepsCompleted = () => {
  //   const completed = completedSteps() === totalSteps();
  //   completed && setStepCompleted(completed);
  //   return completed;
  // };

  // const handleNext = () => {
  //   const newActiveStep =
  //     isLastStep() && !allStepsCompleted()
  //       ? // It's the last step, but not all steps have been completed,
  //         // find the first step that has been completed
  //         steps.findIndex((step, i) => !(i in completed))
  //       : activeStep + 1;
  //   setActiveStep(newActiveStep);
  // };

  // const handleBack = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep - 1);
  // };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  // const handleComplete = () => {
  //   const newCompleted = completed;
  //   newCompleted[activeStep] = true;
  //   setCompleted(newCompleted);
  //   handleNext();
  // };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  useEffect(
    async () => {
      if (selectedRow) {
        // console.log(selectedRow)
        if (selectedRow.selectedGlasses && Array.isArray(selectedRow.selectedGlasses) && selectedRow.selectedGlasses.length > 0) {
          let i = 0;
          for await (let glass of selectedRow.selectedGlasses) {
            const gtypeDetailsWithGComponent = await selectGTypeDetailsWithGComponentDirect(glass.gtypeId);
            selectedRow.selectedGlasses[i] = { ...selectedRow.selectedGlasses[i], gtypeDetailsWithGComponent }; // TODO : 이렇게 직접 덮어써도 되나???
            i = i + 1;
          }
          setSelectedGlasses(selectedRow.selectedGlasses);
          setModifiedGlassesIndexArray(selectedRow.selectedGlasses.map(glass => false));
        } else {
          setSelectedGlasses([{
            id: uuidv4(),
            gprojectId: getValues('id'),
            no: "",
            specification: "",
            otherSpecs: "",
            selectedProcessGClients: [],
          }]);
          setModifiedGlassesIndexArray([false]);
        }
      } else {
        // 첫 등록시 유리선택 등을 위한 하나의 빈 행이 필요
        const newGlass = {
          id: uuidv4(),
          gprojectId: getValues('id'),
          no: "",
          specification: "",
          otherSpecs: "",
          // comments,
          // gglassId,
          // gglassName,
          // name,
          // no,
          // gprojectGlassPrices: [],
          // gtypeDetailsWithGComponent: [],
          // gtypeId,
          // gtypeName,
          // labelPosition,
          // labelPositionView,
          selectedGcomponentItems: [],
          selectedProcessGClients: [],
          // standard,
        };

        setSelectedGlasses([newGlass]);
        setModifiedGlassesIndexArray([false]);
      }
    }, [selectedRow]
  );
  
  useEffect(
    () => {
      console.log(g04docuGCertificationsSubMaterialProcess);
      // TODO : g04docuGCertificationsSubMaterialProcess는 g04docu DB 설정 데이터, baseSubMaterialProcess는 g04docuGCertificationsSubMaterialProcess에 selectedSubMaterialProcessItems 데이터가 반영된 값
      if (selectedRow) {
        const subMaterialProcessItems = selectedRow.selectedSubMaterialProcessItems;
        if (subMaterialProcessItems && Array.isArray(subMaterialProcessItems) && subMaterialProcessItems.length > 0) {
          const newBaseSubMaterialProcess = [];
          subMaterialProcessItems.forEach(item => {
            const subMaterial = g04docuGCertificationsSubMaterialProcess.find(g04 => g04.id === item.id);
            if (subMaterial) {
              newBaseSubMaterialProcess.push(subMaterial);
              item.code = subMaterial.code;
            }
          });
          setSelectedSubMaterialProcessItems(subMaterialProcessItems);
          setBaseSubMaterialProcess(newBaseSubMaterialProcess);
        } else {
          setSelectedSubMaterialProcessItems(g04docuGCertificationsSubMaterialProcess.map((item, i) => ({ id: item.id, name: item.name, code: item.code, index: i, usable: true/*false*/, value: {}})));
          setBaseSubMaterialProcess(g04docuGCertificationsSubMaterialProcess);
        }
      } else {
        setSelectedSubMaterialProcessItems(g04docuGCertificationsSubMaterialProcess.map((item, i) => ({ id: item.id, name: item.name, code: item.code, index: i, usable: true/*false*/, value: {}})));
        setBaseSubMaterialProcess(g04docuGCertificationsSubMaterialProcess);
      }
    }, [g04docuGCertificationsSubMaterialProcess]
  )

  useEffect(
    () => {
      if (selectedRow) {
        const subMaterialBuildItems = selectedRow.selectedSubMaterialBuildItems;
        if (subMaterialBuildItems && Array.isArray(subMaterialBuildItems) && subMaterialBuildItems.length > 0) {
          setSelectedSubMaterialBuildItems(subMaterialBuildItems);

          const newBaseSubMaterialBuild = [];
          subMaterialBuildItems.forEach(item => {
            const subMaterial = g04docuGCertificationsSubMaterialBuild.find(g04 => g04.id === item.id);
            if (subMaterial) {
              newBaseSubMaterialBuild.push(subMaterial);
            }
          });
          setBaseSubMaterialBuild(newBaseSubMaterialBuild);
        } else {
          setSelectedSubMaterialBuildItems(g04docuGCertificationsSubMaterialBuild.map((item, i) => ({ id: item.id, name: item.name, code: item.code, index: i, usable: true/*false*/, value: {}})));
          setBaseSubMaterialBuild(g04docuGCertificationsSubMaterialBuild);
        }
      } else {
        setSelectedSubMaterialBuildItems(g04docuGCertificationsSubMaterialBuild.map((item, i) => ({ id: item.id, name: item.name, code: item.code, index: i, usable: true/*false*/, value: {}})));
        setBaseSubMaterialBuild(g04docuGCertificationsSubMaterialBuild);
      }
    }, [g04docuGCertificationsSubMaterialBuild]
  )

  const gcertificationObj = (types) => {
    const result = types.map(type => {
      const cert = g04docuGCertificationsProcess.find(gcertification => gcertification.code === type);
      if (cert) {
        const { id, name, code, order, selectedClassifications, classifications } = cert;
        return { id, name, code, order, selectedClassifications, classifications };
      }
      return null;
    }).filter(Boolean); // null 값 제거
  
    // 결과가 하나의 요소만 있으면 객체를 반환, 그렇지 않으면 배열을 반환
    return result.length === 1 ? result[0] : result;
  }
  /****************************************************************************************************************************************************************
   * 아래는 성적서와 성능계산을 할 때 조건이 되는 검색코드를 간략 설명한 것이다. 참고로 성능계산은 가공유리에만 적용한다.
   * 
   * 가공법	            성적서		                   예                                      성능확인서	            예
   * --------------------------------------------------------------------------------------------------------------------------------------------------------
   * 배강도/강화		     유리원판X                    5|H/S	                                  유리원판O	             5|CL|H/S
   * 접합		            유리원판X, 접합필름O          4+0.38|PVB+4	                          유리원판O, 접합필름O	  4|CL+0.38|PVB+4|CL
   * 복층		            유리원판O, 접합필름O          4|CL+12|A+4|CL	                        유리원판O, 접합필름O)	  4|CL+12|A+4|CL
   *                    접합이 있는 경우		         4|CL+0.38|PVB+4|CL+12|A+4|CL		                                4|CL+0.38|PVB+4|CL+12|A+4|CL

   ****************************************************************************************************************************************************************/
  const separateProcessFromGlass = async () => {
    console.log(selectedGlasses)
    console.log(modifiedGlassesIndexArray)

    const newSelectedGlasses = [];

    let idx = 0;
    for await (const glass of selectedGlasses) {
    // selectedGlasses.forEach((glass, idx) => {
      const { selectedGcomponentItems, selectedProcessGClients, aspect } = glass;

      // 선택한 유리를 수정했는지 하지 않았는지 여부
      const modified = modifiedGlassesIndexArray[idx];

      // 가공 스텝을 눌러 저장했는지 하지 않았는지 여부. 단, 선택한 유리에 가공정보가 없는 경우도 notSaved가 true가 되어 불필요하게 아래를 수행하지만 그건 허용함
      // const notSaved = selectedProcessGClients && Array.isArray(selectedProcessGClients) && selectedProcessGClients.length === 0 || selectedProcessGClients === null;
      console.log({ idx, modified/*, selectedProcessGClients, notSaved*/ })
      // 다시 유리 사양을 분석하는 조건. 유리 수정도 하지 않고 DB에 저장도 안된(빈 배열) 상태 (위에서 언급한 것처럼 가공정보가 없는 경우도 DB에 빈 배열이지만 구분할 수 없으므로 분석을 수행한다.)
      // if (!(modified || notSaved)) {
        if (!modified) {
        newSelectedGlasses.push(glass);
        console.log("There is no need to parse anymore.")
        // return;
        idx = idx + 1;
        continue;
      }

      const specificationElements = [];
      let fullSpecification = "";
      selectedGcomponentItems.forEach((gtypeDetails, i) => {
        let productName = "";
        
        // specification 구성 및 specification 구성부품 마련
        gtypeDetails.forEach((item, j) => {
          if (j === 0) { // 각 레이어의 첫번째는 상세 구성요소의 상위 개념 (예를 들면 유리원판, 중공층 등...)으로 스킵
            return;
          }
          
          if (Array.isArray(item.value)) {
            item.value.forEach(i => {
              const { applyType, code } = i;
              if (applyType === 'productName') {
                productName += code ? `${code}|` : "";
                specificationElements.push({ code: i.code, value: i.value });
              }
            })
          } else {
            const itemCode = item.code;
            const { applyType, code } = item.value;
            if (applyType === 'productName') {
              productName += code ? `${GlassUtils.getMaterialItemCodeOrName(itemCode, code)}|` : "";
              specificationElements.push({ code: item.code, value: item.value });
            }
          }
        })
        productName = productName.substring(0, productName.length - 1);
        fullSpecification += (productName !== "" ? `${productName}+` : "");
      })
      
      fullSpecification = fullSpecification.substring(0, fullSpecification.length - 1);
      // const fullSpecification = GlassUtils.getSpecification(selectedGcomponentItems)[0];
  
      console.log(specificationElements);
      console.log(fullSpecification);
      
      // heats: 열처리 있는 단판. 제품명은 빠져야 함(매주 중요). 단, 인증등급시 단판정보가 필요하므로 specificationElements는 단판 포함되어 있어야 한다.
      // => 단판 정보 포함해서 성적서를 찾기도 해야 함. 아래 설명
      /**
       * 강화(배강도 포함)의 경우 성적서를 취득할 때 제품명(원판)이 없는 것이 일반적이나 원판 포함하여 성적서를 취득할 수도 있다.
       * 예를 들면 원칙(대부분)은 5 HS이지만 5 CL HS로 취득하기도 한다. 따라서 자재승인서류 발급시 5 CL HS(검색조건 5|CL|HS)를 입력하면
       * 등록된 성적서 중 5|HS 성적서를 찾으므로 가공공장에서 5 CL HS 성적서만 취득한 경우 성적서를 찾지 못한다.
       * => 자재승인서류에서 해당 가공업체의 성적서를 찾을 때 5|CL|HS 와 5|HS 모두를 검색해봐야 한다. 이때 specificationElements 활용 가능???
       */
      const heats = [];

      const laminations = []; // 접합
      const insulations = []; // 복층
      // TODO : 아래는 공정에 따른 추가 인증서가 필요함. argon이나 hst의 경우 인증서만 필요하고 가공정보에 따로 표시될 필요가 없으므로 굳이 배열일 필요는 없으나 추후를 위해 배열로 저장.
      // 인증서 찾을 때는 배열의 첫번째 요소로 찾음
      // 힛속: F/T HST의 HST가 있으면...
      // 아르곤: AR이 있으면...
      const argons = [];
      const heatSoakTests = [];
  
      let isInsulatedGlassUnit = false;

      let manufacturer = null;
      // <<<주의 : 코드 하드코딩 사용>>>
      let i = 0;
      for await (const element of specificationElements) {
      // specificationElements.forEach((element, i) => {
        const { code, value } = element;
        
        if (code === 'GLASS PRODUCT NAME' && value.gclients && Array.isArray(value.gclients) && value.gclients.length === 1) {
          const manufacturerId = value.gclients[0].id
          manufacturer = gclients.find(gclient => gclient.id === manufacturerId);
        }

        if (code === 'GL HEAT TREATING' && (value.code === 'H/S' || value.code === 'F/T' || value.code === 'F/T HST')) { // 열처리 있는 단판
          let findIndex = -1;
          // GL THICKNESS + GLASS PRODUCT NAME + GL HEAT TREATING
          for(let j=i; j>=0; j--) {
            let targetCode = specificationElements[j].code;
            if (targetCode === 'GL THICKNESS') {
              findIndex = j;
              break;
            }
          }
  
          let type = "";
          let subType = "";
          /**
           * 힛속테스트 성적서는 생산된 실제 제품에 대한 성적서이므로
           * 자재승인서류 제출단계에서는 없는 성적서임
           * 따라서 힛속테스트의 경우 강화유리에 힛속 정보만 추가해둠 
           */
          const { code } = value;
          if (code === 'H/S') {
            type = 'HEAT_STRENGTHENED';
          } else if (code === 'F/T' || code === 'F/T HST') {
            type = 'TEMPERED';
            if (code === 'F/T HST') {
              subType = 'Heat_Soak_Test';
            }
          }
  
          // 두께가 선택되지 않은 경우에도 열처리 정보 출력하도록 수정
          const arrHeat = specificationElements.slice(findIndex === -1 ? i : findIndex, i+1).filter(ele => ele.code !== 'GLASS PRODUCT NAME'); // slice는 배열크기 넘어가도 됨
          const arrHeatWithProductName = specificationElements.slice(findIndex === -1 ? i : findIndex, i+1);

          console.log(specificationElements)
          console.log(arrHeat)
          console.log(arrHeatWithProductName)
          
          // const heatGClients = gclients.map(gclient => {
          //   const { gclientType04docus } = gclient;
          //   const find = gclientType04docus?.filter(gclientType04docu => gclientType04docu.code === type);
          //   // TODO : 자재승인서 발급(프로젝트)의 가공에서 가공업체를 개별 선택시 해당 가공법이 없는 가공업체는 목록에서 빼고 전체 반영시 반영되지 않도록 한다.
          //   // => 기존에는 전체 반영시 일괄적용하기 위해 업체타입이 맞지 않으면 목록에 비활성화된 채로 목록에 추가했었다.
          //   return find?.length > 0 ? { id: gclient.id, name: gclient.name, bizRegNumber: gclient.bizRegNumber, disabled: false } : { id: gclient.id, name: gclient.name, bizRegNumber: gclient.bizRegNumber, disabled: true };
          // });
          // console.log(heatGClients);
          const specification = arrHeat.map(s => s.value.code).join("|");
          const specificationWithProductName = arrHeatWithProductName.map(s => GlassUtils.getMaterialItemCodeOrName(s.code, s.value.code)).join("|");
          console.log({ specification, specificationWithProductName })
          
          let found;
          if (manufacturer?.bizRegNumber === "369-85-01171") { // KCC
            // get_perf_data1에서 조회
            const matched = gtrans.find(i => i.org_name === specificationWithProductName);
            // console.log(matched)
            const foundWithProductName = matched && matched.replace_name && (await selectGPerfData1({ glass: matched.replace_name }));
            if (foundWithProductName) {
              const { res_g_name, res_vlt, res_vlr_ex, res_st, res_sr_ex, res_u_value_ks_w, res_sc, res_shgc, res_rhg } = foundWithProductName;

              found = {
                name: specificationWithProductName/*res_g_name*/.replaceAll("|", " "),
                vlt: res_vlt,
                vlr: null,
                vlr_ex: res_vlr_ex,
                vlr_in: null,
                st: res_st,
                sr_ex: res_sr_ex,
                sc: res_sc,
                shgc: res_shgc,
                u_value_ks: res_u_value_ks_w,
                rhg: res_rhg,
              };
            }
          } else if (manufacturer?.bizRegNumber === "542-88-01592") { // LX. TODO : 현재 검색결과에 대부분 문자열에 공백이 없음 => name에 공백을 넣어 다시 DB를 구성하던가 code값에서 replaceAll("|", " ").replaceAll("+", " + ") 하든가 결정
            found = gglassPerformances.find(performance => performance.code === specification && performance.gclientId === manufacturer.id);
          }
          // console.log(found)
          
          heats.push({
            type,
            subType,
            specification,
            specificationWithProductName,
            // specificationElements: arrHeat,
            specificationElements, // 인증등급시 단판정보가 필요하므로 specificationElements는 단판 포함되어 있어야 한다. TODO : 검색 및 사용시 문제 있는지 추적검토 필요 // TODO : 경량화 : 열처리에 관련된 요소만 정리하고 요소 안에서도 불필요한 데이터는 제거
            // gclients: heatGClients,
            // selectedGClients: [],
            g04docuGCertification: gcertificationObj([type]),
            g04docuGCertificationAdditional: gcertificationObj([
              manufacturer?.bizRegNumber === "369-85-01171" ? 'emax_Heat_treated' :
              manufacturer?.bizRegNumber === "542-88-01592" ? 'LX_SECULITE' : 
              ''
            ].concat(subType === 'Heat_Soak_Test' ? ['Heat_Soak_Test'] : []).filter(Boolean)),
            performanceData: found ? { // 성능확인 데이터 추가
              ...found,
              manufacturer: { id: manufacturer.id, name: manufacturer.name, bizRegNumber: manufacturer.bizRegNumber },
            } : {},
          });
        }
  
        if (code === 'FILM THICKNESS') { // 접합
          // 앞판. FILM THICKNESS 앞으로 GL THICKNESS까지
          // 뒷판. FILM THICKNESS 뒤부터 SPACER THICKNESS 전까지 또는 SPACER THICKNESS가 없다면 끝까지
          let frontIndex = -1;
          let rearIndex = -1;
          for(let j=i; j>=0; j--) {
            let targetCode = specificationElements[j].code;
            if (targetCode === 'GL THICKNESS') {
              frontIndex = j;
              break;
            }
          }
  
          const arrFront = specificationElements.slice(frontIndex, i); // slice는 배열크기 넘어가도 됨
          // console.log({ arrFront })
  
          for(let j=i; j<specificationElements.length-1; j++) {
            if (j > i && specificationElements[j].code === 'SPACER THICKNESS') {
              rearIndex = j;
              break;
            }
          }
  
          const arrRearWithFilm = specificationElements.slice(i, rearIndex === -1 ? specificationElements.length : rearIndex); // slice는 배열크기 넘어가도 됨
          // console.log({ arrRearWithFilm })
          let rearSpecification = "";
          let rearSpecificationWithGlassProductName = "";
          arrRearWithFilm.forEach((s, i) => {
            // <<<주의 : 코드 하드코딩 사용>>>
            if (i > 0 && s.code === 'GLASS PRODUCT NAME') {
              rearSpecificationWithGlassProductName = rearSpecificationWithGlassProductName + "|" + GlassUtils.getMaterialItemCodeOrName(s.code, s.value.code);
              return;
            }

            if (i === 0) {
              rearSpecification = s.value.code;
              rearSpecificationWithGlassProductName = s.value.code;
            } else {
              // <<<주의 : 코드 하드코딩 사용>>>
              /**
               * 아래 코드에서 정규식 사용하는 부분은 접합필름에서 (xxx)제거하기 위한 부분임. (xxx)에 제품명이 있음
               * 그러나 접합필름에 ()쓰지 않기로 결정. 따라서 불필요한 코드이나 추후 대응을 위해 남겨둠
               */
              rearSpecification = rearSpecification + (s.code.indexOf('THICKNESS') >= 0 ? "+" : "|") + GlassUtils.getMaterialItemCodeOrName(s.code, s.value.code);
              rearSpecificationWithGlassProductName = rearSpecificationWithGlassProductName + (s.code.indexOf('THICKNESS') >= 0 ? "+" : "|") + GlassUtils.getMaterialItemCodeOrName(s.code, s.value.code);
            }
          })
          
          // const laminationGClients = gclients.map(gclient => {
          //   const { gclientType04docus } = gclient;
          //   const find = gclientType04docus?.filter(gclientType04docu => gclientType04docu.code === 'LAMINATED');
          //   return find?.length > 0 ? { id: gclient.id, name: gclient.name, bizRegNumber: gclient.bizRegNumber, disabled: false } : { id: gclient.id, name: gclient.name, bizRegNumber: gclient.bizRegNumber, disabled: true };
          // });
          // console.log(laminationGClients);
  
          // <<<주의 : 코드 하드코딩 사용>>>
          const specification = `${arrFront.filter(s => s.code !== 'GLASS PRODUCT NAME').map(s => s.value.code).join("|")}+${rearSpecification}`; // 성적서 검색할 때는 유리원판명 없음
          const specificationWithGlassProductName = `${arrFront.map(s => GlassUtils.getMaterialItemCodeOrName(s.code, s.value.code)).join("|")}+${rearSpecificationWithGlassProductName}`; // 성능계산할 때는 유리원판명 필요
          console.log(specificationWithGlassProductName)
          let found = null;
          if (manufacturer?.bizRegNumber === "369-85-01171") { // KCC
            // get_perf_data1에서 조회
            let foundWithProductName;
            const layers = specificationWithGlassProductName.split("+");
            if (layers.length === 3) {
              const ex_glass = layers[0];
              const matched_ex_glass = gtrans.find(i => i.org_name === ex_glass);
              const in_glass = layers[2];
              const matched_in_glass = gtrans.find(i => i.org_name === in_glass);
              
              foundWithProductName = matched_ex_glass && matched_ex_glass.replace_name && matched_in_glass && matched_in_glass.replace_name && (await selectGPerfData1({ glass: `${matched_ex_glass.replace_name}+${layers[1]}+${matched_in_glass.replace_name}`}));
            }
            
            // console.log(foundWithProductName)
            if (foundWithProductName) {
              const { res_g_name, res_vlt, res_vlr_ex, res_st, res_sr_ex, res_u_value_ks_w, res_sc, res_shgc, res_rhg } = foundWithProductName;
              found = {
                name: specificationWithGlassProductName/*res_g_name*/?.replaceAll("|", " ").replaceAll("+", " + "),
                vlt: res_vlt,
                vlr: null,
                vlr_ex: res_vlr_ex,
                vlr_in: null,
                st: res_st,
                sr_ex: res_sr_ex,
                sc: res_sc,
                shgc: res_shgc,
                u_value_ks: res_u_value_ks_w,
                rhg: res_rhg,
              };
            }
          } else if (manufacturer?.bizRegNumber === "542-88-01592") { // LX
            found = gglassPerformances.find(performance => performance.code === specification && performance.gclientId === manufacturer.id);
          }
          // console.log(found)
          let type = 'LAMINATED';
          laminations.push({
            type,
            specification,
            specificationWithGlassProductName,
            specificationElements: arrFront.concat(arrRearWithFilm), // TODO : 경량화 : 접합에 관련된 요소만 정리 (코드상으로 여기는 되어 있는 것 같음). 요소 안에서도 불필요한 데이터는 제거
            g04docuGCertification: gcertificationObj([type]),
            g04docuGCertificationAdditional: gcertificationObj([
              manufacturer?.bizRegNumber === "369-85-01171" ? 'emax_laminated' :
              manufacturer?.bizRegNumber === "542-88-01592" ? 'LX_LAMILITE' : 
              ''
            ].filter(Boolean)),
            performanceData: found ? { // 성능확인 데이터 추가
              ...found,
              manufacturer: { id: manufacturer.id, name: manufacturer.name, bizRegNumber: manufacturer.bizRegNumber },
            } : {},
          });
        }

        if (code === 'SPACER THICKNESS') { // 복층
            // 복층의 경우 3복층 이상은 specificationElements 안에 SPACER THICKNESS가 여러 개이므로 가공정보가 그 개수만큼 중복된다.
            // 따라서
            // 1. insulations 배열 안에 같은 fullSpecification이 있으면 추가하지 않는다.
            // 2. if (code === 'SPACER THICKNESS') {} 를 specificationElements 안에서 한번만 실행
            // => 1과 2중 2선택
          if (!isInsulatedGlassUnit) {
            /**
             * 가공법에 따른 거래선이 다르므로 다른 가공법의 목록은 기본적으로 거래선이 다르게 구성된다.
             * 하지만 이럴 경우 전체 반영시 목록에 없는 거래선은 설정되지 않는다.
             * 따라서 전체반영이 적용되도록 가공이 가능한 모든 거래선을 목록에 포함하고
             * 해당 가공법이 가능한 거래선은 활성화, 그렇지 않은 거래선은 비활성화한다.
             */
            // 
            // 
            // const insulationGClients = gclients.filter(gclient => {
            //   const { gclientType04docus } = gclient;
            //   const find = gclientType04docus?.filter(gclientType04docu => gclientType04docu.code === 'INSULATED_GLASS_UNIT');
            //   return find?.length > 0 ? true : false;
            // });
            // const insulationGClients = gclients.map(gclient => {
            //   const { gclientType04docus } = gclient;
            //   const find = gclientType04docus?.filter(gclientType04docu => gclientType04docu.code === 'INSULATED_GLASS_UNIT');
            //   return find?.length > 0 ? { id: gclient.id, name: gclient.name, bizRegNumber: gclient.bizRegNumber, disabled: false } : { id: gclient.id, name: gclient.name, bizRegNumber: gclient.bizRegNumber, disabled: true };
            // });
            // console.log(insulationGClients);
            // console.log(JSON.stringify(insulationGClients, null, 2));
            const existGas = [];
            const insulateLayers = [];

            // const isExistArgons = specificationElements.filter(ele => ele.code === 'GAS MATERIAL' && ele.value.code === 'AR');
            specificationElements.forEach((ele, i) => {
              console.log(ele)
              if (ele.code === 'GAS MATERIAL' && ele.value.code !== 'A') {
                existGas.push(ele);
              } else if (ele.code === 'SPACER THICKNESS') {
                insulateLayers.push({ ele, i });
              }
            });

            // get_perf_data2 or get_perf_data3에서 조회 (현재 삼복층까지만 성능확인데이터 계산하고 있음)
            let found;
            if (manufacturer?.bizRegNumber === "369-85-01171") {
              let foundWithProductName;
              const layers = insulateLayers.length;
              if (layers === 1) { // 복층유리
                const spacerIdx = insulateLayers[0].i;
                // console.log(spacerIdx)
                const ex_glass = specificationElements.slice(0, spacerIdx);
                const spacer = specificationElements.slice(spacerIdx, spacerIdx+2);
                const in_glass = specificationElements.slice(spacerIdx+2);
                console.log({ ex_glass, spacer, in_glass })
                foundWithProductName = await selectGPerfData2({
                  ex_glass: GlassUtils.getLayerGlassSpecificationNoParentheses(ex_glass, gtrans), // 접합필름에 ()쓰지 않기로 결정하였으므로 함수안에서 정규식 사용하여 괄호와 괄호안의 내용 삭제하는 코드는 불필요한 코드이나 원자재 데이터를 변경하기 전까지는 사용할 예정. 변경될 수 있음
                  gas_thickness: spacer && Array.isArray(spacer) && spacer.length > 0 && spacer[0].value.code,
                  gas_material: spacer && Array.isArray(spacer) && spacer.length > 1 && spacer[1].value.code,
                  in_glass: GlassUtils.getLayerGlassSpecificationNoParentheses(in_glass, gtrans),
                });
                console.log(foundWithProductName)
              } else if (layers === 2) { // 삼층복층유리
                const spacerIdx1 = insulateLayers[0].i;
                const spacerIdx2 = insulateLayers[1].i;
                // console.log(spacerIdx)
                const ex_glass = specificationElements.slice(0, spacerIdx1);
                const spacer1 = specificationElements.slice(spacerIdx1, spacerIdx1+2);
                const mid_glass = specificationElements.slice(spacerIdx1+2, spacerIdx2);
                const spacer2 = specificationElements.slice(spacerIdx2, spacerIdx2+2);
                const in_glass = specificationElements.slice(spacerIdx2+2);
                // console.log({ ex_glass, spacer1, mid_glass, spacer2, in_glass })
                foundWithProductName = await selectGPerfData3({
                  ex_glass: GlassUtils.getLayerGlassSpecificationNoParentheses(ex_glass, gtrans),
                  gas_thickness1: spacer1 && Array.isArray(spacer1) && spacer1.length > 0 && spacer1[0]?.value.code,
                  gas_material1: spacer1 && Array.isArray(spacer1) && spacer1.length > 1 && spacer1[1]?.value.code,
                  mid_glass: GlassUtils.getLayerGlassSpecificationNoParentheses(mid_glass, gtrans),
                  build_aspect: aspect,
                  gas_thickness2: spacer2 && Array.isArray(spacer2) && spacer2.length > 0 && spacer2[0]?.value.code,
                  gas_material2: spacer2 && Array.isArray(spacer2) && spacer2.length > 1 && spacer2[1]?.value.code,
                  in_glass: GlassUtils.getLayerGlassSpecificationNoParentheses(in_glass, gtrans),
                });
                console.log(foundWithProductName)
              }
              
              if (foundWithProductName) {
                const { res_vlt, res_vlr_ex, res_st, res_sr_ex, res_u_value_ks_w, res_sc, res_shgc, res_rhg } = foundWithProductName;
                found = {
                  name: fullSpecification.replaceAll("|", " ").replaceAll("+", " + "),
                  vlt: res_vlt,
                  vlr: null,
                  vlr_ex: res_vlr_ex,
                  vlr_in: null,
                  st: res_st,
                  sr_ex: res_sr_ex,
                  sc: res_sc,
                  shgc: res_shgc,
                  u_value_ks: res_u_value_ks_w,
                  rhg: res_rhg,
                };
              }
            } else if (manufacturer?.bizRegNumber === "542-88-01592") {
              found = gglassPerformances.find(performance => performance.code === fullSpecification  && performance.gclientId === manufacturer.id);
            }
            console.log(found)
            
            let type = 'INSULATED_GLASS_UNIT';
            insulations.push({
              type,
              specification: fullSpecification,
              specificationElements, // TODO : 경량화 : 요소 안에서 불필요한 데이터는 제거
              g04docuGCertification: gcertificationObj([type]),
              g04docuGCertificationAdditional: gcertificationObj([
                manufacturer?.bizRegNumber === "369-85-01171" ? 'emax_laminated' :
                manufacturer?.bizRegNumber === "542-88-01592" ? 'LX_DUOLITE' : 
                ''
              ].filter(Boolean)),
              performanceData: found ? { // 성능확인 데이터 추가
                ...found,
                manufacturer: { id: manufacturer.id, name: manufacturer.name, bizRegNumber: manufacturer.bizRegNumber },
              } : {},
            });

            if (existGas.length > 0) {
              let type = 'INSULATED_GLAZING';
              insulations.push({
                type,
                specification: fullSpecification,
                specificationElements, // TODO : 경량화 : 요소 안에서 불필요한 데이터는 제거
                g04docuGCertification: gcertificationObj([type]),
                performanceData: found ? { // 성능확인 데이터 추가
                  ...found,
                  manufacturer: { id: manufacturer.id, name: manufacturer.name, bizRegNumber: manufacturer.bizRegNumber },
                } : {},
              });
            }
          }

          isInsulatedGlassUnit = true;
          
        }
        i = i + 1;
        // })
      }
  
      console.log({ heats, laminations, insulations/*, argons, heatSoakTests*/ });
      
      // 해당하는 유리에 가공이 없는 경우가 있으므로 selectedProcessGClients에는 빈 배열추가
      // const concatedArr = [].concat(heats, laminations, insulations); // 가공 전체 모음
      // if (concatedArr.length > 0) {
      //   processes.push(concatedArr);
      // }
      const processes = [].concat(heats, laminations, insulations/*, argons, heatSoakTests*/);
      console.log(processes)
      // 유리에서 다시 가공정보 분석시 제조사/내구성 정보가 없으므로 사용자가 전에 설정하여 저장된 제조사/내구성 정보를 붙여준다.
      processes.forEach((process, i) => {
        const { type, specificationElements, g04docuGCertification } = process;
        // if (type !== 'Heat_Soak_Test' && type !== 'ARGON') {
        // if (!process.certificationOnly) {
          // 제조사
          process.selectedGClients = selectedProcessGClients?.length > i ? selectedProcessGClients[i].selectedGClients : [];
          // 성적서 조회를 위한 내구성
          process.g04docuGCertification.selectedDurability = selectedProcessGClients?.length > i ? selectedProcessGClients[i].g04docuGCertification.selectedDurability : "";

          // 정해진 인증등급이 있다면 설정
          const classification = GlassUtils.getClassification({ type, specificationElements });
          const selectedDurability = g04docuGCertification.selectedDurability;
          if (classification || selectedDurability) {
            const divider =
              g04docuGCertification.classifications && Array.isArray(g04docuGCertification.classifications) && g04docuGCertification.classifications.length > 0 ?
                g04docuGCertification.classifications[0].divider :
                " ";
            
            const fullClassification = `${classification}${classification && selectedDurability ? divider : ""}${selectedDurability}`;
            // console.log({ classification, selectedDurability, divider, fullClassification });
            
            /**
             * classifications은 해당 가공품의 인증규격에 해당하는 종류/등급으로 템플릿임
             * selectedClassifications는 위의 classifications를 가지고 가공 step에서 사용자가 선택한 것에 checked: true 한것
             * 따라서 처음 설정하는 것이라면 selectedClassifications은 undefined임.
             * => 자재승인서류 구성을 위해 종류/등급 선택한 정보는 selectedClassifications에 있음.
             * => 설정하지 않았다면 classifications을 가지고 checked: false로 selectedClassifications 구성
             */
            const { classifications, selectedClassifications } = g04docuGCertification;
            // console.log({ classifications, selectedClassifications });
            process.g04docuGCertification.selectedClassifications =
              selectedClassifications && Array.isArray(selectedClassifications) && selectedClassifications.length > 0 ? 
                selectedClassifications : 
                classifications.map(item => (item.type === fullClassification ? ({ ...item, checked: true }) : ({ ...item, checked: false })))
          } else {
            const { classifications } = g04docuGCertification;
            // console.log(classifications)
            process.g04docuGCertification.selectedClassifications = classifications.map(item => (({ ...item, checked: false })))
          }
        // }
      });
      
      newSelectedGlasses.push({
        ...glass,
        selectedProcessGClients: processes,
      });

      console.log(processes);

      idx = idx + 1;
    // })
    }
    
    console.log(newSelectedGlasses);
    
    if (newSelectedGlasses.length > 0) { // TODO : 템플릿 선택하고 저장하지 않은 채 창닫고 다시 열면 기본 존재해야 할 한 행이 사라지는 오류 해결. 테스트 필요
      setSelectedGlasses(newSelectedGlasses);
      const newModifiedGlassesIndexArray = selectedRow?.selectedGlasses.map(() => false)
      if (newModifiedGlassesIndexArray) {
        setModifiedGlassesIndexArray(newModifiedGlassesIndexArray);
      }
    }
  }

  // useEffect(
  //   () => {
  //     // TODO : 아래가 본 컴포넌트의 상위에서 gclients가 변할 때도 들어오므로 불필요하게 호출되기도 함
  //     console.log(gclients)
  //   }, [gclients]
  // )

  useEffect(
    () => {
      console.log({ selectedRow, selectedGlasses})
    }, [selectedGlasses]
  )

  useEffect(
    () => {
      // console.log(modifyGlass)
      if (modifyGlass) {
        console.log(selectedGlasses);
        separateProcessFromGlass();
        // setModifyGlass(false);
      }
    }, [modifyGlass]
  )

  const handleChangeGGlass = async (e, i) => {
    const gglassId = e.target.value;
    const selGGlass = gglasses.find(gglass => gglass.id === gglassId);
    if (selGGlass) {
      console.log(selGGlass)
      const { name, gtypeId, gtypeCode, gtypeName, selectedG04docuGCertifications, selectedGcomponentItems } = selGGlass;
      const gtypeDetailsWithGComponent = await selectGTypeDetailsWithGComponentDirect(selGGlass.gtypeId);
      // gtypeDetails가 바뀌어었으므로 해당 구조로 selectedGcomponentItems 초기화 해야 함 => 초기화할 것이 아니라 품종에 적용한 기본 속성이 적용되어야 함
      const newSelectedGlasses = selectedGlasses.map((gglass, j) => i === j ? {
        ...gglass,
        gglassId,
        gglassName: name,
        gtypeId,
        gtypeCode,
        gtypeName,
        gtypeDetailsWithGComponent,
        // selectedGcomponentItems: gtypeDetailsWithGComponent.map(gtypeDetail => { // 속성 초기화 (X)
        //   return gtypeDetail.map(detail => {
        //     const { id, name, code } = detail;
        //     return { id, name, code, value: {} }
        //   })
        // }),
        selectedGcomponentItems, // 적용한 기본 속성 (O)
        // TODO: specification 재구성
        specification: GlassUtils.getSpecification(selectedGcomponentItems)[0], // [spec, otherSpec] 임
        selectedG04docuGCertifications,
        selectedProcessGClients: // 유리템플릿을 변경했으므로 선택한 가공업체가 있다면 초기화한다.
          gglass.selectedProcessGClients && Array.isArray(gglass.selectedProcessGClients) ?
              gglass.selectedProcessGClients.map(process => ({ ...process, selectedGClients: [] }))
            : [],
      } : gglass);

      // <<<주의 : 코드 하드코딩 사용>>>
      if (selGGlass.gtypeCode === 'IGU_TRIPLE' || selGGlass.gtypeName === '3중 복층유리') { // 삼중복층일 때
        newSelectedGlasses[i].aspect = "4면시공";
      }

      console.log(newSelectedGlasses)
      console.log(GlassUtils.getSpecification(selectedGcomponentItems))
      
      console.log(modifiedGlassesIndexArray);
      setSelectedGlasses(newSelectedGlasses);

      // TODO : modifiedGlassesIndexArray 수정
      const newModifiedGlassesIndexArray = [].concat(modifiedGlassesIndexArray);
      newModifiedGlassesIndexArray.splice(i, 1, true);
      console.log(newModifiedGlassesIndexArray)
      setModifiedGlassesIndexArray(newModifiedGlassesIndexArray);

      setModifyGlass(true);
    }
  }

  const handleGGlassUpDown = async (type, rownum, itemType) => {
    let newArr;
    
    let newRows = JSON.parse(JSON.stringify(selectedGlasses));
    
    const newModifiedGlassesIndexArray = [].concat(modifiedGlassesIndexArray);
    // up이면 rownum에서 -1한 행과 swap된 것이고, down이면 +1한 행과 swap된 것임
    if (type === "up") {
      if (rownum > 0) {
        newArr = changeArrayOrder(newRows, rownum, -1)

        // 행 변경에 따라 modifiedGlassesIndexArray내 요소를 swap 한다.
        /**
         * swap 예제코드
         * 
         * 1. temp 변수 사용
         * const arr = [1, 2, 3, 4, 5];
         * 
         * let temp = arr[1];
         * arr[1] = arr[2];
         * arr[2] = temp;
         * 
         * console.log(arr); // [1, 3, 2, 4, 5]
         * 
         * 2. 구조 분해 할당 사용
         * const arr = [1, 2, 3, 4, 5];
         * 
         * [arr[1], arr[2]] = [arr[2], arr[1]];
         * 
         * console.log(arr); // [1, 3, 2, 4, 5]
         */
        // [newModifiedGlassesIndexArray[rownum-1], newModifiedGlassesIndexArray[rownum]] = [newModifiedGlassesIndexArray[rownum], newModifiedGlassesIndexArray[rownum-1]]; // TODO : 오류발생. why?
        let temp = newModifiedGlassesIndexArray[rownum-1];
        newModifiedGlassesIndexArray[rownum-1] = newModifiedGlassesIndexArray[rownum];
        newModifiedGlassesIndexArray[rownum] = temp;
      } else {
        return;
      }
    } else if (type === "down") {
      if (rownum < newRows.length - 1) {
        newArr = changeArrayOrder(newRows, rownum, 1)
        
        // [newModifiedGlassesIndexArray[rownum], newModifiedGlassesIndexArray[rownum+1]] = [newModifiedGlassesIndexArray[rownum+1], newModifiedGlassesIndexArray[rownum]]; // TODO : 오류발생. why?
        let temp = newModifiedGlassesIndexArray[rownum+1];
        newModifiedGlassesIndexArray[rownum+1] = newModifiedGlassesIndexArray[rownum];
        newModifiedGlassesIndexArray[rownum] = temp;
      } else {
        return;
      }
    } else {
      newArr = newRows;
    }

    // console.log(newArr)
    
    setSelectedGlasses(newArr);

    console.log(newModifiedGlassesIndexArray)
    setModifiedGlassesIndexArray(newModifiedGlassesIndexArray);

    setModifyGlass(true);
  }

  const addGGlass = async (e, idx) => {
    // 클릭한 바로 아래에 삽입하도록 한다.
    const newSelectedGlasses = JSON.parse(JSON.stringify(selectedGlasses));
    
    const selGGlass = gglasses.find(gglass => gglass.id === selectedGlasses[idx].gglassId);
    
    const selectedGcomponentItems = selectedGlasses[idx].selectedGcomponentItems;

    const newGlass = Object.assign({}, {
      ...selectedGlasses[idx],
      id: uuidv4(),
      no: "",
      specification: "",
      selectedProcessGClients: [],
      // 유리템플릿을 선택하지 않고 추가하면 유리템플릿이 복사되지 않으므로 오류가 발생하여 빈 배열 추가
      selectedGcomponentItems: selectedGcomponentItems && Array.isArray(selectedGcomponentItems) ? selectedGcomponentItems.map((layer, i) => (layer.map(item => ({ ...item, value: {} })))) : [],
      // 유리 데이터 구조변경(개발중이나 개선중. 여기서는 유리품종에서 인증규격 구조 변경)시 복사하지 않고 변경된 selectedG04docuGCertifications 를 새로 가지고 온다.
      selectedG04docuGCertifications: (selGGlass && selGGlass.selectedG04docuGCertifications) ? selGGlass.selectedG04docuGCertifications : [],
      appliedArea: "",
    });

    // <<<주의 : 코드 하드코딩 사용>>>
    if (selGGlass.gtypeCode === 'IGU_TRIPLE' || selGGlass.gtypeName === '3중 복층유리') { // 삼중복층일 때
      newGlass.aspect = "4면시공";
    }

    // console.log(newGlass)
    newSelectedGlasses.splice(idx+1, 0, newGlass);
    
    // console.log(newSelectedGlasses)
    setSelectedGlasses(newSelectedGlasses);

    // 변경된 행 정보를 담고 있는 베열 - 배열요소 추가된 행을 나타내는 false 추가
    const newModifiedGlassesIndexArray = [].concat(modifiedGlassesIndexArray);
    newModifiedGlassesIndexArray.splice(idx+1, 0, false);

    // console.log(newModifiedGlassesIndexArray)
    setModifiedGlassesIndexArray(newModifiedGlassesIndexArray);

    setModifyGlass(true);

    // 접합필름 확인 및 가공부자재 목록 갱신
    updateLaminatedFilmInfo(newSelectedGlasses);
  }

  const removeGGlass = (e, idx) => {
    const newSelectedGlasses = JSON.parse(JSON.stringify(selectedGlasses));
    newSelectedGlasses.splice(idx, 1);
    
    setSelectedGlasses(newSelectedGlasses);

    // modifiedGlassesIndexArray와 selectedGlasses 통합하는 방법(selectedGlasses안에 수정여부 기록)을 고려했으나
    // selectedGlasses안에 수정여부를 기록했다가 저장시 다시 변경하는 번거로움이 있어 별도로 유지하기로 함
    const newModifiedGlassesIndexArray = [].concat(modifiedGlassesIndexArray);
    newModifiedGlassesIndexArray.splice(idx, 1);
    // console.log(newModifiedGlassesIndexArray)
    setModifiedGlassesIndexArray(newModifiedGlassesIndexArray);

    setModifyGlass(true);

    // 접합필름 확인 및 가공부자재 목록 갱신
    updateLaminatedFilmInfo(newSelectedGlasses);
  }

  const handleChangeMultiGcomponentItem = (value, item, gtypeDetailsOrder, gcomponentItemOrder, idx) => {
    // console.log({value, item, gtypeDetailsOrder, gcomponentItemOrder/*, gcomponent*/});
    const itemNew = {
      id: item.id,
      name: item.name,
      value: value.length > 0 ? value.map(v => JSON.parse(v)) : [], // 모두 선택해제되면 []로 설정
    };

    const newSelectedGlasses = selectedGlasses.map((glass, i) => {
      if (i === idx) {
        // return glass.selectedGcomponentItems[gtypeDetailsOrder][gcomponentItemOrder] = itemNew;
        glass.selectedGcomponentItems[gtypeDetailsOrder][gcomponentItemOrder] = itemNew;
        if (glass.selectedProcessGClients && Array.isArray(glass.selectedProcessGClients)) { // 유리템플릿을 변경했으므로 선택한 가공업체가 있다면 초기화한다.
          glass.selectedProcessGClients = glass.selectedProcessGClients.map(process => ({ ...process, selectedGClients: [] }));
        }

        return glass;
      }

      return glass;
    })    
            
    setSelectedGlasses(newSelectedGlasses);

    const newModifiedGlassesIndexArray = [].concat(modifiedGlassesIndexArray);
    newModifiedGlassesIndexArray.splice(idx, 1, true);
    // console.log(newModifiedGlassesIndexArray)
    setModifiedGlassesIndexArray(newModifiedGlassesIndexArray);

    setModifyGlass(true);
  }

  const handleChangeGcomponentItem = (e, item, gtypeDetailsOrder, gcomponentItemOrder, gcomponent, idx) => {
    console.log({ e, item, gtypeDetailsOrder, gcomponentItemOrder, gcomponent, idx })
    // "없음" 선택한 경우 해당 구성요소가 의존하고 있는 구성요소가 있을 경우 안내메시지 띄우고 "없음" 선택안되도록 함
    // properties가 0("없음")인 경우 selectedGcomponentItems[gtypeDetailsOrder]에서 dependentGcomponentItem가 있는 것 중 gcomponentId가 item.id인 것이 있으면 안내 메시지 출력 
    // console.log(selectedGcomponentItems);
    const properties = item.properties.filter(property => property.id === e.target.value);
    if (properties.length <= 0) { // "없음"일 경우
      const gcomponentItems = selectedGlasses[idx].selectedGcomponentItems[gtypeDetailsOrder];
      // console.log(gcomponentItems)
      const result = gcomponentItems.filter(gcomponentItem => {
        return gcomponentItem.value?.dependentGcomponentItem && JSON.parse(gcomponentItem.value?.dependentGcomponentItem).gcomponentId === item.id
      });
      // console.log(result)
      if (result.length > 0) {
        const { name, value } = result[0];
        const messageAlert = (
          <div>
            <span style={{ color: "#1976d2" }}>{`'${name}'`}</span>{`(이)가 '`}
            <span style={{ color: "#1976d2" }}>{`${value.name}`}</span>{`'(으)로 선택된 경우 `}
            <span style={{ color: "#1976d2" }}>{`'${item.name}'`}</span>{`(은)는 `}
            {/* <span style={{ color: "red" }}>{`'없음'`}</span>{`을 선택할 수 없고,`} */}
            <br/>
            <span style={{ color: "#d32f2f",  }}><i><u>{`반드시 값을 선택`}</u></i></span>
            {`해야 합니다.`}
          </div>
        );

        setAlertInfo({
          titleAlert: "안내",
          messageAlert,
          open: true,
        });

        return;
      }
    }
    
    let value = "";
    if (properties.length > 0) {
      value = properties[0];
      value.selectedGClientId = value.gclients && Array.isArray(value.gclients) && value.gclients.length === 1 && value.gclients[0].id || ""; // 원판 제조사 자동 설정 => 해당 원판의 제조사는 한군데라는 전제
    }
    
    // setValue(`${idx}_${gtypeDetailsOrder}_${gcomponentItemOrder}_${item.id}`, value?.id || value); // TODO : 안해도 값이 설정되네???
    // console.log(`${gtypeDetailsOrder}_${gcomponentItemOrder}_${item.id}`);

    const itemNew = {
      id: item.id,
      name: item.name,
      code: item.code, // TODO : 다른 곳도 필요시 코드 추가해야 함
      value,
    };

    const gcomponentItems = selectedGlasses[idx].selectedGcomponentItems;
    
    const newSelectedGlasses = selectedGlasses.map((glass, i) => {
      if (i === idx) {
        glass.selectedGcomponentItems[gtypeDetailsOrder][gcomponentItemOrder] = itemNew;
        // glass.specification = GlassUtils.getSpecification(gcomponentItems)[0]; // 0: 사양(specification), 1: 비고(otherSpecs)
        const [specification] = GlassUtils.getSpecification(gcomponentItems);
        glass.specification = specification;
        if (glass.selectedProcessGClients && Array.isArray(glass.selectedProcessGClients)) { // 유리제품 정보를 변경했으므로 선택한 가공업체가 있다면 초기화한다.
          glass.selectedProcessGClients = glass.selectedProcessGClients.map(process => ({ ...process, selectedGClients: [] }));
        }
      }

      return glass;
    })
    
    console.log(newSelectedGlasses)
    
    setSelectedGlasses(newSelectedGlasses);

    if (/*activeStep === 0 &&*/ (item.code === 'FILM TYPE' || item.code === 'FILM THICKNESS')) {
      updateLaminatedFilmInfo(newSelectedGlasses);
    }

    const newModifiedGlassesIndexArray = [].concat(modifiedGlassesIndexArray);
    newModifiedGlassesIndexArray.splice(idx, 1, true);
    console.log(newModifiedGlassesIndexArray)
    setModifiedGlassesIndexArray(newModifiedGlassesIndexArray);

    setModifyGlass(true);

    return;
    // TODO : 해당 값이 변할 때 의존구성요소값이 있다면 그 구성요소의 값을 설정해야 한다. 값을 변화시킬 수 있는데 화면에 설정값으로 렌터링은 아직...
    if (properties.length > 0 && properties[0]?.dependentGcomponentItem?.length > 0) {
      const dependentGcomponentItem = JSON.parse(properties[0].dependentGcomponentItem);
      // console.log(dependentGcomponentItem)

      let targetGcomponentItemOrder = "";
      gcomponent.forEach((component, idx) => {
        if (component.id === dependentGcomponentItem.gcomponentId) {
          targetGcomponentItemOrder = idx;
        }
      });
      
      // console.log(`${gtypeDetailsOrder}_${targetGcomponentItemOrder}_${dependentGcomponentItem.gcomponentId}`);
      setValue(`${idx}_${gtypeDetailsOrder}_${targetGcomponentItemOrder}_${dependentGcomponentItem.gcomponentId}`, `${dependentGcomponentItem.id}`);
      
      const dependentGcomponentItemNew = {
        id: dependentGcomponentItem.gcomponentId,
        name: dependentGcomponentItem.name,
        value: dependentGcomponentItem,
      }

      const newSelectedGlasses = selectedGlasses.map((glass, i) => {
        if (i === idx) {
          return glass.selectedGcomponentItems[gtypeDetailsOrder][targetGcomponentItemOrder] = dependentGcomponentItemNew;
        }
  
        return glass;
      })

      // TODO : 등록하기에서는 템플릿구조가 선택되지 않아 map 구성이 안되어 있으므로 여기서 구성 필요
      dependentGcomponentMap.set(`${idx}_${gtypeDetailsOrder}_${gcomponentItemOrder}_${item.id}`, {
        ...dependentGcomponentItemNew,
        idx: idx,
        gtypeOrder: gtypeDetailsOrder,
        gcomponentOrder: targetGcomponentItemOrder,
      });

      // console.log(dependentGcomponentMap);
    } else {
      const map = dependentGcomponentMap.get(`${idx}_${gtypeDetailsOrder}_${gcomponentItemOrder}_${item.id}`);
      if (map) {
        setValue(`${map.idx}_${map.gtypeOrder}_${map.gcomponentOrder}_${map.id}`, "");

        const newSelectedGlasses = selectedGlasses.map((glass, i) => {
          if (i === idx) {
            return glass.selectedGcomponentItems[`${map.idx}_map.gtypeOrder`][map.gcomponentOrder] = {
              id: map.id,
              name: map.name,
              value: "",
            };
          }
    
          return glass;
        })
      }
    }
    
    setSelectedGlasses(newSelectedGlasses);

    // TODO : 아래처럼 할지 useEffect 쓸지 추후 검토
    // setSpec(selectedNew);
  }

  // <<<주의 : 코드 하드코딩 사용>>>
  const getWidthByCode = (code) => {
    // TODO : 사이즈 줄이고 설정 변경. 검토 필요
    // let width = 120;
    // if (code === 'GLASS PRODUCT NAME') {
    //   width = 170;
    // } else if (code === 'GL THICKNESS') {
    //   width = 108;
    // } else if (code === 'GL HEAT TREATING') {
    //   width = 107;
    // } else if (code === 'GAS MATERIAL') {
    //   width = 110;
    // } else if (code === 'FILM TYPE') {
    //   width = 110;
    // } else if (code === 'FILM THICKNESS') {
    //   width = 114;
    // } else if (code === 'SPACER THICKNESS') {
    //   width = 124;
    // }
    let width = 100;
    if (code === 'GLASS PRODUCT NAME') {
      width = 150;
    } else if (code === 'GL THICKNESS') {
      width = 98;
    } else if (code === 'GL HEAT TREATING') {
      width = 87;
    } else if (code === 'GAS MATERIAL') {
      width = 98;
    } else if (code === 'FILM TYPE') {
      width = 90;
    } else if (code === 'FILM THICKNESS') {
      width = 94;
    } else if (code === 'SPACER THICKNESS') {
      width = 114;
    }

    return width;
  }

  const generateGGlassByRow = (gtypeDetailsWithGComponent, gcomponentItems, idx) => {
    return gtypeDetailsWithGComponent?.map((gtypeDetail, i) => {
      return (
        <>
        {
          gtypeDetail.map((gcomponent, j) => {
            const { id, name, code, type, properties, multipleYN } = gcomponent;
            // TODO : 같은 레벨의 properties안에서도 applyType이 productName/etc 섞여 있음
            if (type === 'property' &&
                properties &&
                properties.length &&
                properties.length > 0 &&
                properties.filter(property => property.applyType === 'productName').length === properties.length // && // TODO : applyType이 "productName"이 조건이 아니라 별도의 속성이 추가해야 할 수도 있음(2023-03-31)
                // gcomponent.properties.filter(property => property.applyType === 'productName').length > 0
              ) {
              // console.log(gcomponent);
              return (
                <>
                  {
                    multipleYN ? (<FormInputMultipleSelect
                      id="multiple-type-select"                        
                      name={`${idx}_${i}_${j}_${id}`}
                      control={control}
                      label={name}
                      setValue={setValue}
                      onChangeItem={(value) => handleChangeMultiGcomponentItem(value, gcomponent, i, j, gtypeDetail, idx)}
                      dynamicItems={{ selectedGcomponentItems: gcomponentItems, gtypeDetailsIndex: i, gcomponentItemIndex: j, gcomponentItem: gcomponent }} // selectedGcomponentItems[gtypeDetailsIndex][gcomponentItemIndex] 넘기면 값이 고정되므로 안됨
                      options={properties}
                      chipSize={"small"}
                      sx={{ width: 140, ml: 1, mt: 2 }}
                    />) : (<FormInputText
                      select
                      name={`${idx}_${i}_${j}_${id}`}
                      control={control}
                      label={name}
                      onChange={(e) => handleChangeGcomponentItem(e, gcomponent, i, j, gtypeDetail, idx)}
                      options={
                        [{ label: '없음', value: "" }].concat(properties.map(property => { // TODO : 추후 value: {} 했을 때 문제 없는지 체크할 것
                          return {
                            label: property.name,
                            value: property.id,
                          }
                        }))
                      }
                      sx={{
                        width: getWidthByCode(code),
                        ml: 1,
                        mt: 2,
                        // bgcolor: (code === 'SPACER THICKNESS' || code === 'GAS MATERIAL') ? '#e3f2fd' : (code === 'FILM THICKNESS' || code === 'FILM TYPE' ? "#f9fbe7" : ""),
                        bgcolor: (code === 'SPACER THICKNESS' || code === 'GAS MATERIAL'|| code === 'FILM THICKNESS' || code === 'FILM TYPE' ? "" : "#E8F3FF"),
                      }}
                      // 아래에서 속성값이 설정되지 않은 경우 selectedGlasses[idx].selectedGcomponentItems[i][j].value = {} 이므로 selectedGlasses[idx].selectedGcomponentItems[i][j].value.id는 undefined임
                      // 이럴 경우 값이 설정된 후 정렬시 행이동에도 불구하고 값이 이동하지 않음. 참고로 ""가 아닌 {}, null일 경우 동작이 차이가 있음
                      value={
                        selectedGlasses[idx] &&
                        selectedGlasses[idx].selectedGcomponentItems[i] &&
                        selectedGlasses[idx].selectedGcomponentItems[i][j] &&
                        selectedGlasses[idx].selectedGcomponentItems[i][j].value &&
                        selectedGlasses[idx].selectedGcomponentItems[i][j].value.id ?
                          selectedGlasses[idx].selectedGcomponentItems[i][j].value.id :
                          ""
                      }
                      inputProps={{
                        sx: {
                          height: "23px",
                          fontSize: "0.9rem"
                        },
                      }}
                      InputLabelProps={{
                        sx: {
                          // color: "#518eb9",
                          fontSize: "0.9rem",
                          // fontWeight: 1000,
                          "&.MuiOutlinedInput-notchedOutline": { fontSize: "0.9rem" }
                        }
                      }}
                    />)
                  }
                </>
              )
            }
          })
        }
        </>
      )
    })
  }

  const handleChangeNo = (e, idx) => {
    const { value } = e.target;
    const newSelectedGlasses = selectedGlasses.map((gglass, i) => (i === idx ? { ...gglass, no: value } : gglass));
    setSelectedGlasses(newSelectedGlasses);

    setModifyGlass(true);
  }

  const handleChangeAppliedArea = (e, idx) => {
    const { value } = e.target;
    const newSelectedGlasses = selectedGlasses.map((gglass, i) => (i === idx ? { ...gglass, appliedArea: value } : gglass));
    setSelectedGlasses(newSelectedGlasses);

    setModifyGlass(true);
  }

  const handleChangeAspect = (e, checked, idx) => {
    const newSelectedGlasses = selectedGlasses.map((gglass, i) => (i === idx ? { ...gglass, aspect: checked ? '3면시공' : '4면시공' } : gglass));
    newSelectedGlasses[idx].selectedProcessGClients = newSelectedGlasses[idx].selectedProcessGClients.map(process => ({ ...process, selectedGClients: [] }));

    setSelectedGlasses(newSelectedGlasses);

    const newModifiedGlassesIndexArray = [].concat(modifiedGlassesIndexArray);
    newModifiedGlassesIndexArray.splice(idx, 1, true);
    console.log(newModifiedGlassesIndexArray)
    setModifiedGlassesIndexArray(newModifiedGlassesIndexArray);

    setModifyGlass(true);
  }

  // TODO : 목록 헤더 있는 스타일. 단, 이렇게 할 경우 각 항목별로 화면이 작을 때 아래로 밀리므로 헤더 없이 횡으로 나열되도록 수정. 추후 검토 필요
  // const generateGGlasses = () => {
  //   if (renderSubMaterialProcessItems) {
  //     return (
  //       <>
  //         <Grid container spacing={2} sx={{ mt: 2 }}>
  //           <Grid item sm={12}>
  //             <Grid container spacing={1} sx={{ pt: 1, pb: 1, borderBottom: 1, borderColor: '#DCDCDC', bgcolor: '#eaeaea' }}>
  //               <Grid item sm={1} display="flex" justifyContent={"center"} alignItems={"center"} sx={{ borderRight: 1, borderColor: '#FFFFFF' }}>
  //                 {"기능"}
  //               </Grid>
  //               <Grid item sm={2} display="flex" justifyContent={"center"} alignItems={"center"} sx={{ borderRight: 1, borderColor: '#FFFFFF' }}>
  //                 {"품번 및 유리템플릿"}
  //               </Grid>
  //               <Grid item sm={9} display="flex" justifyContent={"center"} alignItems={"center"}>
  //                 {"사양"}
  //               </Grid>
  //             </Grid>
  //           </Grid>
  //         </Grid>
  //         <Grid container spacing={2} sx={{ mt: 2 }}>
  //         {
  //           selectedGlasses?.map((selectedGlass, idx) => {
  //             const { id, no, gprojectId, gglassId, gtypeId, selectedGcomponentItems, gtypeDetailsWithGComponent, specification, otherSpecs } = selectedGlass;

  //             return (
  //               <Grid item sm={12}>
  //                 <Grid container spacing={1} sx={ selectedGlasses.length-1 === idx ? {} : { pb: 2, borderBottom: 1, borderColor: '#DCDCDC' }}>
  //                   <Grid item sm={12} display="flex" alignItems={"center"}>
  //                     <Grid container>
  //                       <Grid item sm={1} display="flex" alignItems={"center"}>
  //                         <Grid container>
  //                           <Grid item sm={2} display="flex" alignItems={"center"}>
  //                             <Avatar variant="rounded" sx={{ width: 24, height: 24, fontSize: 12, mr: 2 }}>{idx+1}</Avatar>
  //                           </Grid>
  //                           <Grid item sm={10} display="flex" justifyContent={"center"} alignItems={"center"}>
  //                             <IconButton
  //                               sx={{ color: theme.palette.grey[600] }}
  //                               size="small"
  //                               onClick={(e) => addGGlass(e, idx)}
  //                             >
  //                               <Add fontSize="inherit" />
  //                             </IconButton>
  //                             <IconButton
  //                               sx={{ color: theme.palette.grey[600] }}
  //                               size="small"
  //                               onClick={(e) => removeGGlass(e, idx)}
  //                               disabled={selectedGlasses.length <= 1 ? true : false}
  //                             >
  //                               <Remove fontSize="inherit" />
  //                             </IconButton>
  //                             <IconButton
  //                               sx={{ color: theme.palette.grey[600] }}
  //                               size="small"
  //                               onClick={(e) => handleGGlassUpDown('up', idx)}
  //                               disabled={idx > 0 ? false : true}
  //                             >
  //                               <KeyboardArrowUp fontSize="inherit" />
  //                             </IconButton>
  //                             <IconButton
  //                               sx={{ color: theme.palette.grey[600] }}
  //                               size="small"
  //                               onClick={(e) => handleGGlassUpDown('down', idx)}
  //                               disabled={idx === selectedGlasses.length - 1 ? true : false}
  //                             >
  //                               <KeyboardArrowDown fontSize="inherit" />
  //                             </IconButton>
  //                           </Grid>
  //                         </Grid>
  //                       </Grid>
  //                       <Grid item sm={2}>
  //                         <FormInputText
  //                           control={control}
  //                           label={"품번"}
  //                           value={no}
  //                           sx={{ width: 120, ml: 1, mr: 1 }}
  //                           onChange={(e) => handleChangeNo(e, idx)}
  //                         />
  //                         <FormInputText
  //                           select
  //                           control={control}
  //                           label={"유리 템플릿"}
  //                           options={gglasses.map(gglass => {
  //                             return {
  //                               label: gglass.name,
  //                               value: gglass.id,
  //                             }
  //                           })}
  //                           onChange={(e) => handleChangeGGlass(e, idx)}
  //                           value={gglassId}
  //                           sx={{ width: 240 }}
  //                         />
  //                       </Grid>
  //                       <Grid item sm={9}>
  //                         { generateGGlassByRow(gtypeDetailsWithGComponent, selectedGcomponentItems, idx) }
  //                         <FormInputText
  //                           control={control}
  //                           label={"사양"}
  //                           value={specification}
  //                           sx={{ width: 200, ml: 1, display: 'none' }}
  //                         />
  //                       </Grid>
  //                     </Grid>
  //                   </Grid>
  //                 </Grid>
  //               </Grid>
  //             )
  //           })
  //         }
  //         </Grid>
  //       </>
  //     );
  //   } else {
  //     return (
  //       <Grid container spacing={2}>
  //         <Grid item xs={12} display="flex" justifyContent={"center"} alignItems={"center"}>
  //           <Box sx={{ width: '100%' }}>
  //             <LinearProgress />
  //           </Box>
  //         </Grid>
  //       </Grid>
  //     );
  //   }
  // }
  const generateGGlasses = () => {
    if (renderSubMaterialProcessItems) {
      return (
        <>
          {/* <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item sm={12}>
              <Grid container spacing={1} sx={{ pt: 1, pb: 1, borderBottom: 1, borderColor: '#DCDCDC', bgcolor: '#eaeaea' }}>
                <Grid item sm={1} display="flex" justifyContent={"center"} alignItems={"center"} sx={{ borderRight: 1, borderColor: '#FFFFFF' }}>
                  {"기능"}
                </Grid>
                <Grid item sm={2} display="flex" justifyContent={"center"} alignItems={"center"} sx={{ borderRight: 1, borderColor: '#FFFFFF' }}>
                  {"품번 및 유리템플릿"}
                </Grid>
                <Grid item sm={9} display="flex" justifyContent={"center"} alignItems={"center"}>
                  {"사양"}
                </Grid>
              </Grid>
            </Grid>
          </Grid> */}
          <div>
          {
            selectedGlasses?.map((selectedGlass, idx) => {
              const { id, no, gprojectId, gglassId, gtypeId, gtypeName, gtypeCode, selectedGcomponentItems, gtypeDetailsWithGComponent, appliedArea, aspect, specification, otherSpecs } = selectedGlass;
              return (
                <Box sx={ selectedGlasses.length-1 === idx ? {} : { pb: 2, borderBottom: 1, borderColor: '#DCDCDC' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                    <Avatar
                      variant="rounded"
                      sx={{
                        width: 24,
                        height: 24,
                        fontSize: 12,
                        mr: 2,
                        mt: 2,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      {idx+1}
                    </Avatar>
                    
                    <IconButton
                      sx={{ color: theme.palette.grey[600], mt: 2 }}
                      size="small"
                      onClick={(e) => addGGlass(e, idx)}
                    >
                      <Add fontSize="inherit" />
                    </IconButton>
                    <IconButton
                      sx={{ color: theme.palette.grey[600], mt: 2 }}
                      size="small"
                      onClick={(e) => removeGGlass(e, idx)}
                      disabled={selectedGlasses.length <= 1 ? true : false}
                    >
                      <Remove fontSize="inherit" />
                    </IconButton>
                    <IconButton
                      sx={{ color: theme.palette.grey[600], mt: 2 }}
                      size="small"
                      onClick={(e) => handleGGlassUpDown('up', idx)}
                      disabled={idx > 0 ? false : true}
                    >
                      <KeyboardArrowUp fontSize="inherit" />
                    </IconButton>
                    <IconButton
                      sx={{ color: theme.palette.grey[600], mt: 2 }}
                      size="small"
                      onClick={(e) => handleGGlassUpDown('down', idx)}
                      disabled={idx === selectedGlasses.length - 1 ? true : false}
                    >
                      <KeyboardArrowDown fontSize="inherit" />
                    </IconButton>
                    <FormInputText
                      control={control}
                      label={"품번"}
                      value={no}
                      sx={{ width: /*120*/80, ml: 1, mr: 1, mt: 2 }}
                      onChange={(e) => handleChangeNo(e, idx)}
                      inputProps={{
                        sx: {
                          height: "23px",
                          fontSize: "0.8rem"
                        },
                      }}
                      InputLabelProps={{
                        sx: {
                          // color: "#518eb9",
                          fontSize: "0.8rem",
                          // fontWeight: 1000,
                          "&.MuiOutlinedInput-notchedOutline": { fontSize: "0.8rem" },
                          "&.MuiInputLabel-outlined": { fontSize: "0.9rem" },
                        }
                      }}
                    />
                    <FormInputText
                      select
                      control={control}
                      label={"유리 템플릿"}
                      options={gglasses.map(gglass => {
                        return {
                          label: gglass.name,
                          value: gglass.id,
                        }
                      })}
                      onChange={(e) => handleChangeGGlass(e, idx)}
                      value={gglassId}
                      sx={{ width: /*240*/200, mt: 2, mr: 2/*, bgcolor: '#e3f2fd'*/ }}
                      inputProps={{
                        sx: {
                          // height: "14px",
                          fontSize: "0.9rem"
                        },
                      }}
                      InputLabelProps={{
                        sx: {
                          // color: "#518eb9",
                          fontSize: "0.9rem",
                          // fontWeight: 1000,
                          "&.MuiOutlinedInput-notchedOutline": { fontSize: "0.9rem" }
                        }
                      }}
                    />
                    { generateGGlassByRow(gtypeDetailsWithGComponent, selectedGcomponentItems, idx) }
                    <FormInputText
                      control={control}
                      label={"사양"}
                      value={specification}
                      sx={{ width: 200, ml: 1, display: 'none' }}
                    />
                    <FormInputText
                      control={control}
                      label={"적용부위"}
                      value={appliedArea}
                      sx={{ width: /*120*/300, ml: 1, mr: 1, mt: 2 }}
                      onChange={(e) => handleChangeAppliedArea(e, idx)}
                      inputProps={{
                        sx: {
                          height: "23px",
                          fontSize: "0.8rem"
                        },
                      }}
                      InputLabelProps={{
                        sx: {
                          // color: "#518eb9",
                          fontSize: "0.8rem",
                          // fontWeight: 1000,
                          "&.MuiOutlinedInput-notchedOutline": { fontSize: "0.8rem" },
                          "&.MuiInputLabel-outlined": { fontSize: "0.9rem" },
                        }
                      }}
                    />
                    {
                      (gtypeCode === 'IGU_TRIPLE' || gtypeName === '3중 복층유리') /* gtypeName 비교논리는 기존에 면시공 기능 추가되기전 3중 복층유리에도 적용하기 위해서임 */&& (
                        <Stack direction="row" display="flex" alignItems="center" sx={{ fontSize: "0.9rem", mt: 1.5 }}>
                          <FormInputCheckbox
                            control={control}
                            onChangeCheckValue={(e, checked) => handleChangeAspect(e, checked, idx)}
                            checked={aspect && aspect === '3면시공' ? true : false}
                          />
                          {"3면시공"}
                        </Stack>
                      )
                    }
                  </Box>
                </Box>
              )
            })
          }
          </div>
        </>
      );
    } else {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12} display="flex" justifyContent={"center"} alignItems={"center"}>
            <Box sx={{ width: '100%' }}>
              <LinearProgress />
            </Box>
          </Grid>
        </Grid>
      );
    }
  }

  const handleChangeManufacturer = (e, idx, gtypeDetailsOrder, gcomponentItemOrder) => {
    const { value } = e.target;
    const newSelectedGlasses = JSON.parse(JSON.stringify(selectedGlasses));
    newSelectedGlasses[idx].selectedGcomponentItems[gtypeDetailsOrder][gcomponentItemOrder].value = {
      ...newSelectedGlasses[idx].selectedGcomponentItems[gtypeDetailsOrder][gcomponentItemOrder].value,
      selectedGClientId: value,
    }

    console.log(newSelectedGlasses);
    setSelectedGClientIdForRawMaterial(value);
    setSelectedGlasses(newSelectedGlasses);
  }

  // 같은 품번 내에서도 같은 제품일 때만 적용해야 한다. 현재 아래는 같은 품번 전체에 적용하고 있음
  // => 그러나 04DOCU는 자재승인서류를 제공하는 서비스로서 해당 제품의 제조사가 아닌 경우도 다른 제조사를 선택할 수 있어야 한다.
  // TODO : 따라서 버튼 클릭시 해당 품번의 제품안에서는 모두 동일한 제조사를 적용한다.
  const handleClickApplyManufacturer = (e, idx, gtypeDetailsOrder, gcomponentItemOrder) => {
    setRenderRawMaterials(false);

    const newSelectedGlasses = JSON.parse(JSON.stringify(selectedGlasses));
    console.log({ idx, gtypeDetailsOrder, gcomponentItemOrder})
    const gclientId = newSelectedGlasses[idx].selectedGcomponentItems[gtypeDetailsOrder][gcomponentItemOrder].value.selectedGClientId;
    console.log(gclientId)
    if (gclientId) {
      newSelectedGlasses[idx].selectedGcomponentItems.forEach((gtypeDetails, i) => {
        gtypeDetails.forEach((item, j) => {
          // <<<주의 : 코드 하드코딩 사용>>>
          if (item.code === 'GLASS PRODUCT NAME') {
            item.value.selectedGClientId = gclientId;
            // console.log(`${idx}_${i}_${j}_${item.id}_gclient`)
            // setValue(`${idx}_${i}_${j}_${item.id}_gclient`, gclientId);
          }
        })
      })
    } else {
      newSelectedGlasses[idx].selectedGcomponentItems.forEach((gtypeDetails, i) => {
        gtypeDetails.forEach((item, j) => {
          // <<<주의 : 코드 하드코딩 사용>>>
          if (item.code === 'GLASS PRODUCT NAME') {
            item.value.selectedGClientId = "";
          }
        })
      })
    }

    console.log(newSelectedGlasses);
    
    setSelectedGlasses(newSelectedGlasses);

    setTimeout(() => setRenderRawMaterials(true), 500); // TODO : 설정 데이터값은 반영되나 화면상에서 render 후 나타나지 않아 강제로 render하기 위해 추가 (다른 방법이 있는지 추후 검토)
  }

  // TODO : 마지막에 선택한 제조사로 일괄 적용
  const handleClickApplyAllManufacturerRawMaterial = () => {
    setRenderRawMaterials(false);

    const newSelectedGlasses = JSON.parse(JSON.stringify(selectedGlasses));
    const gclientId = selectedGClientIdForRawMaterial;
    
    newSelectedGlasses.forEach(glass => {
      const { selectedGcomponentItems } = glass;
      selectedGcomponentItems.forEach((gtypeDetails, i) => {
        gtypeDetails.forEach((item, j) => {
          // <<<주의 : 코드 하드코딩 사용>>>
          if (item.code === 'GLASS PRODUCT NAME') {
            item.value.selectedGClientId = gclientId;
          }
        })
      })
    })

    console.log(newSelectedGlasses);
    setSelectedGClientIdForRawMaterial(undefined);
    setSelectedGlasses(newSelectedGlasses);

    setTimeout(() => setRenderRawMaterials(true), 500); // TODO : 설정 데이터값은 반영되나 화면상에서 render 후 나타나지 않아 강제로 render하기 위해 추가 (다른 방법이 있는지 추후 검토)
  }

  const getRawMaterials = (no, selectedGcomponentItems, idx) => {
    console.log(selectedGcomponentItems);
    const rawMaterials = [];
    selectedGcomponentItems?.forEach((gtypeDetails, i) => {
      gtypeDetails.forEach((item, j) => {
        // if (j === 0) { // 각 레이어의 첫번째는 상세 구성요소의 상위 개념 (예를 들면 유리원판, 중공층 등...)으로 스킵 => 아래 if 문에서 걸러지므로 주석처리
        //   return;
        // }
        
        // 원판 앞은 항상 두께
        // <<<주의 : 코드 하드코딩 사용>>>
        if (item.code === 'GLASS PRODUCT NAME') {
          console.log(item)
          rawMaterials.push({
            id: item.id,
            gtypeDetailsOrder: i,
            gcomponentItemOrder: j,
            // no: selectedGcomponentItems[i].no,
            // TODO : 아래에서 "두께 원판명"인데 둘중 하나만 선택이 안되어도 "설정안됨"으로 함. 그래야 잘못 설정된 것을 더 미리 알 수 있다고 판단
            name: gtypeDetails[j-1].value?.code && item.value?.code ? `${gtypeDetails[j-1].value?.code} ${GlassUtils.getMaterialItemCodeOrName(item.code, item.value?.code)}` : "설정안됨",
            // name: `${item.value.code}`,
            gclients: item.value.gclients,
            selectedGClientId: item.value.selectedGClientId,
            classification: GlassUtils.getClassification({ rawMaterial: item.value, type: 'RAW_MATERIAL' }), // 원자재의 인증등급 정보는 selectedGcomponentItems의 item.value안의 selectedClassifications에 담겨 있다.
          })
        }
      })
    })

    // console.log(rawMaterials);
    
    return (
      <Grid container spacing={1}>
        {
          rawMaterials.map((rawMaterial, i) => {
            // TODO : 아래 그리드 스타일 부분 검토 필요
            let gridStyle = {};
            if (rawMaterials.length === 1) {
              gridStyle = { pt: 0 };
            } else if (rawMaterials.length > 1 && rawMaterials.length-1 === i) {
              gridStyle = { pt: 1 };
            } else if (rawMaterials.length > 1 && i === 0) {
              gridStyle = { pt: 0, pb: 2, borderBottom: 1, borderColor: '#DCDCDC' };
            } else {
              gridStyle = { pt: 1, pb: 2, borderBottom: 1, borderColor: '#DCDCDC' };
            }

            const { gtypeDetailsOrder, gcomponentItemOrder, name, classification, gclients } = rawMaterial;
            console.log(rawMaterial)
            return (
              <Grid item sm={12}>
                <Grid container spacing={1} sx={gridStyle}>
                  <Grid item sm={4} display="flex" alignItems="center">
                    <Grid container>
                      <Grid item sm={6} display="flex" alignItems="center">
                        {name}
                      </Grid>
                      <Grid item sm={6} display="flex" justifyContent="center" alignItems="center" sx={{ borderLeft: 1, borderColor: '#eaeaea', pl: 1 }}>
                        {
                          classification ? <Box sx={{ bgcolor: '#eaeaea', p: 1, borderRadius: 1, mr: 1 }}>{classification}</Box> : <>{"--"}</>
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sm={5} display="flex" alignItems="center">
                    <FormInputText
                      select
                      name={`${idx}_${gtypeDetailsOrder}_${gcomponentItemOrder}_${rawMaterial.id}_gclient`}
                      control={control}
                      label={"제조사"}
                      onChange={(e) => handleChangeManufacturer(e, idx, gtypeDetailsOrder, gcomponentItemOrder)}
                      options={
                        [{ label: '없음', value: "" }] // "없음"은 제조사를 설정하지 않는 경우를 위해 남겨둠. // TODO : 추후 value: {} 했을 때 문제 없는지 체크할 것
                          .concat(
                            // 원자재 제조사를 전부 조회하여 추가하되 중복을 배제하고, 추가된 제조사는 비활성화 처리한다. => 전체 반영시 선택할 수 있게 하기 위함
                            // rawMaterial.gclients ?
                            //   rawMaterial.gclients
                            //     .map(item => ({ label: item.gclient.name, value: item.gclient.id, disabled: false }))
                            //     .concat(manufacturersRawMaterial.filter(m => {
                            //         let find = rawMaterial.gclients.find(g => m.id === g.gclient.id)
                            //         return (find ? false : true );
                            //       })
                            //       .map(m => ({ label: m.name, value: m.id, disabled: true })))
                            // :
                            //   []
                              manufacturersRawMaterial.map(m => {
                                // 임시 : gclient 필요정보 점검
                                // const find = rawMaterial.gclients?.find(g => m.id === g.gclient.id);
                                const find = rawMaterial.gclients?.find(g => m.id === g.id);
                                return { label: m.name, value: m.id, disabled: find ? false : true }
                              })
                            )
                      }
                      sx={{ width: 500, ml: 2 }}
                      value={rawMaterial.selectedGClientId}
                    />
                  </Grid>
                  <Grid item sm={3} display="flex" alignItems="center">
                    <Button onClick={(e) => handleClickApplyManufacturer(e, idx, gtypeDetailsOrder, gcomponentItemOrder)}>{`${no}에 전체반영`}</Button>
                  </Grid>
                </Grid>
              </Grid>
            )
          })
        }
      </Grid>
    )
  }

  const generateRawMaterialsWithGClient = () => {
    return (
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item sm={12}>
          <Grid container spacing={1} sx={{ pt: 1, pb: 1, borderBottom: 1, borderColor: '#DCDCDC', bgcolor: '#eaeaea' }}>
            <Grid item sm={2} display="flex" justifyContent={"center"} alignItems={"center"} sx={{ borderRight: 1, borderColor: '#FFFFFF' }}>
              {"품번"}
            </Grid>
            <Grid item sm={3} display="flex" justifyContent={"center"} alignItems={"center"} sx={{ borderRight: 1, borderColor: '#FFFFFF' }}>
              {"사양"}
            </Grid>
            <Grid item sm={7} display="flex" alignItems={"center"}>
              <Grid container spacing={1}>
                <Grid item sm={12}>
                  <Grid container spacing={1}>
                    <Grid item sm={4} display="flex" justifyContent={"center"} alignItems={"center"} sx={{ borderRight: 1, borderColor: '#FFFFFF' }}>
                      {"원판정보"}
                      <span style={{ marginLeft: 10, fontSize: '14px', color: '#adadad' }}>{"사양 | 인증등급"}</span>
                    </Grid>
                    <Grid item sm={5} display="flex" justifyContent={"center"} alignItems={"center"}>
                      <span style={{ marginLeft: 20}}>{"제조사"}</span>
                      <Button
                        size="small"
                        sx={{ ml: 1 }}
                        onClick={(e) => handleClickApplyAllManufacturerRawMaterial(e)}
                        disabled={selectedGClientIdForRawMaterial === undefined ? true : false}
                      >
                        {
                          manufacturersRawMaterial.find(m => m.id === selectedGClientIdForRawMaterial)?.name ?
                          `${manufacturersRawMaterial.find(m => m.id === selectedGClientIdForRawMaterial)?.name}(으)로 전체반영` :
                          "전체반영" 
                        }
                      </Button>
                    </Grid>
                    <Grid item sm={3} display="flex" alignItems={"center"}>
                      <span style={{ marginLeft: 10 }}>{""}</span>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {
          selectedGlasses?.map((glass, idx) => {
            const { id, no, selectedGcomponentItems, specification } = glass;

            return (
              <Grid item sm={12}>
                <Grid container spacing={1} sx={ selectedGlasses.length-1 === idx ? {} : { pb: 2, borderBottom: 1, borderColor: '#DCDCDC' }}>
                  <Grid item sm={2} display="flex" alignItems={"center"}>
                    <Avatar variant="rounded" sx={{ width: 24, height: 24, fontSize: 12, mr: 2 }}>{idx+1}</Avatar>
                    {no ? no : "설정안됨"}
                  </Grid>
                  <Grid item sm={3} display="flex" alignItems={"center"}>
                    {specification ? specification.replaceAll("|", " ").replaceAll("+", " + ") : "설정안됨"}
                  </Grid>
                  <Grid item sm={7} display="flex" alignItems={"center"}>
                    {
                      renderRawMaterials ? getRawMaterials(no, selectedGcomponentItems, idx) : (
                        // <Grid container spacing={2}>
                        //   <Grid item xs={12} display="flex" justifyContent={"center"} alignItems={"center"}>
                        //     <Box sx={{ width: '100%' }}>
                        //       <LinearProgress />
                        //     </Box>
                        //   </Grid>
                        // </Grid>
                        <></>
                      )
                    }
                    {/* { getRawMaterials(no, selectedGcomponentItems, idx) } */}
                  </Grid>
                </Grid>
              </Grid>
            )
          })
        }
      </Grid>
    )
  }

  const handleChangeMultiProcessGClients = (idx, i, value) => {
    console.log({ idx, i, value });

    const newSelectedGlasses = selectedGlasses.map((glass, j) => {
      if (idx === j) {
        glass.selectedProcessGClients[i].selectedGClients = value.map(i => ({ ...JSON.parse(i), disabled: false }));
      }

      return { ...glass };
    })
    console.log(newSelectedGlasses);

    setSelectedGClientsForProcess(value.map(i => ({ ...JSON.parse(i), disabled: false })));
    setSelectedGlasses(newSelectedGlasses)
  }

  const handleClickApplyManufacturerProcess = (e, idx, i) => {
    // FormInputMultipleSelect는 render하지 않아도 즉각 반영되는데 FormInputText select는 즉각반영되지 않아 원판 제조사 선택화면과 동일하게 하기 위헤 아래를 호출함
    setRenderProcesses(false);

    const selectedGClient = selectedGlasses[idx].selectedProcessGClients[i].selectedGClients;
    console.log(selectedGClient)
    
    const newSelectedGlasses = selectedGlasses.map((glass, j) => {
      if (idx === j) {
        glass.selectedProcessGClients.forEach(gclient => {
          gclient.selectedGClients = selectedGClient;
        })
      }

      return { ...glass };
    })
    console.log(newSelectedGlasses);
    setSelectedGlasses(newSelectedGlasses);

    setTimeout(() => setRenderProcesses(true), 500); // TODO : 추가/삭제시 설정 데이터값은 유지되나 화면상에서 render 후 나타나지 않아 강제로 render하기 위해 추가 (다른 방법이 있는지 추후 검토)
  }

  const handleChangeDurability = (e, idx, i, classification) => {
    console.log({ value: e.target.value, idx, i })

    const newSelectedGlasses = selectedGlasses.map((glass, j) => {
      if (idx === j) {
        console.log(glass.selectedProcessGClients[i])
        const { gstandardClassificationComponents, selectedClassifications } = glass.selectedProcessGClients[i].g04docuGCertification;
        console.log(selectedClassifications)
        let typeCompare = "";
        const durabilityComponents = gstandardClassificationComponents[gstandardClassificationComponents.length - 1]; // 마지막 배열이 내구도(durability)
        const types = Object.keys(durabilityComponents[0]);
        let key = types[0];
        if (key.replace("type", "") === "0") { // 내구도만 있는 경우
          // type만 비교하여 같은 것 checked : true 하고 나머지는 checked : false 해준다.
          typeCompare = e.target.value;
        } else { // 내구도 외에도 인증등급이 앞에 있는 경우
          const selected = selectedClassifications.filter(item => item.checked === true);
          if (selected.length === 1) {
            console.log(selected)
            const { divider, type, detail } = selected[0];
            // selected[0] 예
            // {
            //     "name": "2_classificationStandard",
            //     "type": "A종 U1 III류",
            //     "detail": {
            //         "type0": "A종 U1",
            //         "type1": "III류",
            //         "length0": 6,
            //         "length1": 3
            //     },
            //     "checked": true,
            //     "divider": " "
            // }
            typeCompare = type.replace(`${divider}${detail[key]}`, "") + divider + e.target.value;
          } else { // 내구성을 선택하지 않은 초기상태에서 문제(selected.length === 0)가 있었음
            typeCompare = `${classification}` + selectedClassifications[0].divider + e.target.value; // TODO : selectedClassifications의 size가 1보다 작은 경우가 있을까
          }
        }
        // console.log(typeCompare)
        glass.selectedProcessGClients[i].g04docuGCertification = {
          ...glass.selectedProcessGClients[i].g04docuGCertification,
          selectedDurability: e.target.value,
          selectedClassifications: selectedClassifications.map(item => {
            if (item.type === typeCompare) {
              return {
                ...item,
                checked: true,
              }
            }

            return {
              ...item,
              checked: false,
            }
          }),
        }
      }

      return { ...glass };
    })
    console.log(newSelectedGlasses);
    setSelectedGlasses(newSelectedGlasses)
  }

  /**
   * selectedProcessGClients의 구조
   * [{
   *    type:                   string        가공종류 : 'HEAT_STRENGTHENED', 'TEMPERED', 'LAMINATED', 'INSULATED_GLASS_UNIT')
   *    gclients:               array   []    해당 가공을 할 수 있는 거래선들
   *    selectedGClients:       array   []    위 gclients에서 선택한 거래선들
   *    specification:          string        유리사양
   *    g04docuGCertification:  object  {}    인증규격 정보 (가공법의 정해진 인증등급 정보 - selectedClassifications 포함)
   *    specificationElements:  array   []    specification 상세 구성 정보 (원자재의 인증등급 정보 포함)
   * }, ...]
   */
  const getProcesses = (selectedProcessGClients, idx, no) => {
    // console.log(selectedProcessGClients)
    // console.log(idx)
    return (
      <Grid container spacing={1}>
        {
          selectedProcessGClients?.map((process, i) => {
            console.log(process)
            const { type, subType, g04docuGCertification, specification, specificationElements, gclients, selectedGClients } = process;
            // if (type === 'Heat_Soak_Test' || type === 'INSULATED_GLAZING') {
            //   return <></>;
            // }

            // type04docusGClients는 참조 타입인 배열을 직접 할당
            // 이렇게 할당하면 type04docusGClients는 원본 배열(예: heatGClients)과 동일한 메모리 주소를 가리키게 됨. 즉, 얕은 복사(shallow copy)가 이루어짐
            // 원본 배열의 값을 변경하면 type04docusGClients의 값도 변경됨 주의
            let type04docusGClients = [];
            // TODO : 각 처리별로 세분화할 것
            if (type === 'HEAT_STRENGTHENED') {
              type04docusGClients = heatGClients;
            } else if (type === 'TEMPERED') {
              if (subType === 'Heat_Soak_Test') { // 강화유리에 추가로 힛속테스트이면
                type04docusGClients = temperedHeatSoakGClients;
              } else {
                type04docusGClients = temperedGClients;
              }
            } else if (type === 'LAMINATED') {
              type04docusGClients = laminationGClients;
            } else if (type === 'INSULATED_GLASS_UNIT') {
              type04docusGClients = insulationGClients;
            } else if (type === 'INSULATED_GLAZING') {
              type04docusGClients = insulationGlazingGClients;
            }

            // TODO : 아래 그리드 스타일 부분의 검토 필요
            let gridStyle = {};
            if (selectedProcessGClients.length === 1) {
              gridStyle = { pt: 0 };
            } else if (selectedProcessGClients.length > 1 && selectedProcessGClients.length-1 === i) {
              gridStyle = { pt: 1 };
            } else if (selectedProcessGClients.length > 1 && i === 0) {
              gridStyle = { pt: 0, pb: 2, borderBottom: 1, borderColor: '#DCDCDC' };
            } else {
              gridStyle = { pt: 1, pb: 2, borderBottom: 1, borderColor: '#DCDCDC' };
            }

            // console.log(gclients)
            // console.log(selectedGClients)
            const { gstandardClassificationComponents, gstandardDurabilityYN, classifications, selectedClassifications, selectedDurability } = g04docuGCertification;
            // console.log(process);
            // console.log(g04docuGCertification);
            // console.log(gstandardClassificationComponents);
            let classification = "";
            // 내구도 선택변경시 아래 코드는 문제가 있음
            // if (selectedClassifications && Array.isArray(selectedClassifications) && selectedClassifications.length > 0) {
            //   const selected = selectedClassifications.filter(item => item.checked === true);
            //   if (selected.length > 0) {
            //     if (selectedDurability) {
            //       const divider = classifications && Array.isArray(classifications) && classifications.length > 0 ? classifications[0].divider : " ";
            //       classification = selected[0].type.replace(`${divider}${selectedDurability}`, "");
            //     } else {
            //       classification = selected[0].type;
            //     }
            //   }
            // } else {
              classification = GlassUtils.getClassification({ type, specificationElements });
            // }
            
            return (
              <Grid item xs={12}>
                <Grid container spacing={1} sx={gridStyle}>
                  <Grid item sm={5} display="flex" alignItems="center">
                    <Grid container>
                    <Grid item sm={3} display="flex" justifyContent="center" alignItems="center">
                      <Tooltip title={g04docuGCertification.name} arrow>
                      <Chip 
                        label={g04docuGCertification.name} 
                          color="primary" 
                        variant={"outlined"} 
                        sx={{ mr: 2 }} 
                        size="small"
                        />
                    </Tooltip>
                  </Grid>
                      <Grid item sm={6} display="flex" alignItems="center" sx={{ borderLeft: 1, borderColor: '#eaeaea', pl: 1 }}>
                        {specification.replaceAll("|", " ").replaceAll("+", " + ")}
                      </Grid>
                      <Grid item sm={3} display="flex" justifyContent=/*"flex-end"*/"center" alignItems="center" sx={{ borderLeft: 1, borderColor: '#eaeaea', pl: 1 }}>
                        {
                          classification ? <Box sx={{ bgcolor: '#eaeaea', p: 1, borderRadius: 1, mr: 1 }}>{classification}</Box> : <>{"--"}</>
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sm={1} display="flex" justifyContent=/*"flex-end"*/"center" alignItems="center">
                    {/* <FormInputText
                      select
                      name={`${idx}_${i}`}
                      control={control}
                      onChange={(e) => handleChangeDurability(e, idx, i)}
                      options={
                        gstandardDurabilityYN ? gstandardClassificationComponents[gstandardClassificationComponents.length-1]?.map(item => { // 내구도는 항상 마지막 컬럼임
                          return {
                            label: item[`type${gstandardClassificationComponents.length-1}`],
                            value: item[`type${gstandardClassificationComponents.length-1}`],
                          }
                        }) : []
                      }
                      value={g04docuGCertification.selectedDurability}
                      sx={{ width: 300 }}
                      disabled={!gstandardDurabilityYN}
                    /> */}
                    {
                      gstandardDurabilityYN ? (
                        <FormInputText
                          select
                          name={`${idx}_${i}`}
                          control={control}
                          onChange={(e) => handleChangeDurability(e, idx, i, classification)}
                          options={
                            [{ label: "없음", value: ""}].concat(gstandardClassificationComponents[gstandardClassificationComponents.length-1]?.map(item => { // 내구도는 항상 마지막 컬럼임
                              return {
                                label: item[`type${gstandardClassificationComponents.length-1}`],
                                value: item[`type${gstandardClassificationComponents.length-1}`],
                              }
                            }))
                          }
                          value={g04docuGCertification.selectedDurability}
                          sx={{ width: 300 }}
                          // disabled={!gstandardDurabilityYN}
                        />
                      ) : <>{"--"}</>
                    }
                  </Grid>
                  <Grid item sm={4} display="flex" alignItems="center">
                    <FormInputMultipleSelect
                      id="multiple-type-select"
                      // name={id}
                      label={"제조사"}
                      control={control}
                      setValue={setValue}
                      onChangeItem={(value) => handleChangeMultiProcessGClients(idx, i, value)}
                      // dynamicGClients1={{ selectedProcessGClients, i, selectedGClients: JSON.parse(JSON.stringify(selectedGClients)) }}
                      dynamicGClients1={{ selectedProcessGClients, i, selectedGClients: JSON.parse(JSON.stringify(selectedGClients)) }}
                      options={type04docusGClients.map(gclient => ({ id: gclient.id, name: gclient.name, disabled: false, additional: `${gclient.bizRegNumber}${gclient.ceo ? ` (${gclient.ceo})` : ""}`, /* TODO : 추후 대표자(ceo) 추가 필요 */}))}
                      // options={
                      //   gclientsProcess
                      // }
                      chipSize={"small"}
                      sx={{ width: 320 }}
                      search={true} // 검색 기능 추가
                    />
                  </Grid>
                  <Grid item sm={2} display="flex" alignItems="center">
                    <Button onClick={(e) => handleClickApplyManufacturerProcess(e, idx, i)}>{`${no}에 전체반영`}</Button>
                  </Grid>
                </Grid>
              </Grid>
            )
          })
        }
      </Grid>
    )
  }

  const isExistProcess = (selectedGcomponentItems) => {
    let find = false;
    selectedGcomponentItems?.forEach(gtypeDetails => {
      let findProcess = false;
      gtypeDetails.forEach(item => {
        if (item.code === 'GL HEAT TREATING') {
          if (item.value && item.value.code && (item.value.code === 'H/S' || item.value.code === 'F/T' || item.value.code === 'F/T HST')) {
            return findProcess = true;
          }
        }

        if (item.code === 'FILM THICKNESS' || item.code === 'SPACER THICKNESS') {
          return findProcess = true;
        }
      })

      if (findProcess) {
        return find = true;
      }
    })

    return find;
  }

  const handleClickApplyAllManufacturerProcess = (e, ) => {
    setRenderProcesses(false);
    // console.log(selectedGlasses)
    // TODO : 임시
    // const selectedGClientsToApply = selectedGlasses[1].selectedProcessGClients[0].selectedGClients;
    const selectedGClientsToApply = selectedGClientsForProcess;

    const newSelectedGlasses = JSON.parse(JSON.stringify(selectedGlasses));
    // console.log(newSelectedGlasses);
    newSelectedGlasses.forEach(glass => {
      glass.selectedProcessGClients.forEach(process => {
        process.selectedGClients = selectedGClientsToApply;
      })
    })

    console.log(newSelectedGlasses);

    setSelectedGlasses(newSelectedGlasses);

    setSelectedGClientsForProcess(undefined);

    setTimeout(() => setRenderProcesses(true), 500); // TODO : 추가/삭제시 설정 데이터값은 유지되나 화면상에서 render 후 나타나지 않아 강제로 render하기 위해 추가 (다른 방법이 있는지 추후 검토)
  }

  const generateProcessesWithGClient = () => {
    return (
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item sm={12}>
          <Grid container spacing={1} sx={{ pt: 1, pb: 1, borderBottom: 1, borderColor: '#DCDCDC', bgcolor: '#eaeaea' }}>
            <Grid item sm={1} display="flex" justifyContent={"center"} alignItems={"center"} sx={{ borderRight: 1, borderColor: '#FFFFFF' }}>
              {"품번"}
            </Grid>
            <Grid item sm={3} display="flex" justifyContent={"center"} alignItems={"center"} sx={{ borderRight: 1, borderColor: '#FFFFFF' }}>
              {"사양"}
            </Grid>
            <Grid item sm={8} display="flex" alignItems={"center"}>
              <Grid container spacing={1}>
                <Grid item sm={12}>
                  <Grid container spacing={1}>
                    <Grid item sm={5} display="flex" justifyContent={"center"} alignItems={"center"} sx={{ borderRight: 1, borderColor: '#FFFFFF' }}>
                      {/* <Box sx={{ width: '100%', height: '100%', bgcolor: '#eaeaea', p: 1 }}>{"가공정보"}</Box> */}
                      {"가공정보"}
                      <span style={{ marginLeft: 10, fontSize: '14px', color: '#adadad' }}>{"가공방법 | 사양 | 인증등급"}</span>
                    </Grid>
                    <Grid item sm={1} display="flex" justifyContent={"center"} alignItems={"center"} sx={{ borderRight: 1, borderColor: '#FFFFFF' }}>
                      {/* {"내구도"} */}
                      {"종류/등급"}
                    </Grid>
                    <Grid item sm={4} display="flex" justifyContent={"center"} alignItems={"center"}>
                      <span style={{ marginLeft: 20}}>{"제조사"}</span>
                      <Button
                        size="small"
                        sx={{ ml: 1 }}
                        onClick={(e) => handleClickApplyAllManufacturerProcess(e)}
                        disabled={selectedGClientsForProcess === undefined ? true : false}
                      >
                        {/* {
                          manufacturersRawMaterial.find(m => m.id === selectedGClientIdForRawMaterial)?.name ?
                          `${manufacturersRawMaterial.find(m => m.id === selectedGClientIdForRawMaterial)?.name}(으)로 전체반영` :
                          "전체반영" 
                        } */}
                        {
                          selectedGClientsForProcess?.map(g => g.name).join(",") ?
                            `${selectedGClientsForProcess.map(g => g.name).join(",")}(으)로 전체반영` :
                            "전체반영" 
                        }
                      </Button>
                    </Grid>
                    <Grid item sm={2} display="flex" alignItems={"center"}>
                      <span style={{ marginLeft: 10 }}>{""}</span>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {
          selectedGlasses?.map((glass, idx) => {
            console.log(glass)
            const { id, no, selectedGcomponentItems, selectedProcessGClients, specification } = glass;

            // 가공이 없는 경우 목록에 출력할 필요가 없음
            // <<<주의 : 코드 하드코딩 사용>>> GL HEAT TREATING(H/S, F/T, F/T HST) / FILM THICKNESS / SPACER THICKNESS
            const find = isExistProcess(selectedGcomponentItems);
            // console.log({ no, find })
            return find ? <Grid item sm={12}>
              <Grid container spacing={1} sx={ selectedGlasses.length-1 === idx ? {} : { pb: 2, borderBottom: 1, borderColor: '#DCDCDC' }}>
                <Grid item sm={1} display="flex" alignItems={"center"}>
                  {/* <Avatar variant="rounded" sx={{ width: 24, height: 24, fontSize: 12, mr: 2 }}>{idx+1}</Avatar> */}
                  <Avatar variant="rounded" sx={{ width: 24, height: 24, fontSize: 12, mr: 2 }}>{processNumbering(selectedGlasses, idx)}</Avatar>
                  {no}
                </Grid>
                <Grid item sm={3} display="flex" alignItems={"center"}>
                  {specification.replaceAll("|", " ").replaceAll("+", " + ")}
                </Grid>
                <Grid item sm={8} display="flex" alignItems={"center"}>
                  {
                    renderProcesses ? getProcesses(selectedProcessGClients, idx, no) : <></>
                  }
                </Grid>
              </Grid>
            </Grid> : <></>
          })
        }
      </Grid>
    )
  }

  const processNumbering = (selectedGlasses, index) => {
    let realIndex = 0;
    for(let i=0; i<=index; i++) {
      const glass = selectedGlasses[i];
      const { selectedGcomponentItems } = glass;
      const find = isExistProcess(selectedGcomponentItems);
      if (find) {
        realIndex = realIndex + 1;
      }
    }

    return realIndex;
  }

  const handleChangeSubMaterialItems = (e, item, i, itemType) => {
    // TODO : 의존성 여부 대응 필요
    const { value } = e.target;
    // setValue(`${item.id}_${i}`, value);

    // 제조사가 하나인 경우 자동 선택한다. 제품 "없음" 선택시 제조사 정보는 초기화된다. (value === '' 이고 valueNew는 undefined가 되므로)
    const valueNew = item.gcomponentItems.find(gcomponentItem => gcomponentItem.id === value)
    if (valueNew && valueNew.gclients && Array.isArray(valueNew.gclients) && valueNew.gclients.length === 1) {
      // 임시 : gclient 필요정보 점검
      // valueNew.selectedGClientId = valueNew.gclients[0].gclient.id;
      valueNew.selectedGClientId = valueNew.gclients[0].id;
    }

    // console.log({ value, valueNew })

    let itemNew = {};
    
    let items = [];
    if (itemType === 'SUB_MATERIAL_PROCESS') {
      items = selectedSubMaterialProcessItems;

      itemNew = {
        // id: item.id,
        // name: item.name,
        // value: valueNew,
        ...selectedSubMaterialProcessItems[i],
        value: valueNew,
      };
    } else if (itemType === 'SUB_MATERIAL_BUILD') {
      items = selectedSubMaterialBuildItems;

      itemNew = {
        // id: item.id,
        // name: item.name,
        // value: valueNew,
        ...selectedSubMaterialBuildItems[i],
        value: valueNew,
        // value: valueNew === undefined ? { gclients: [] } : valueNew,
      };
    }
    
    // 아래처럼 하면 배열안에 null인 요소가 발생한다.
    const selectedNew = [].concat(items);
    selectedNew[i] = itemNew;

    // if (selectedNew.length > 0) {
    //   selectedNew = selectedNew.map(selectedSubMaterial => {
    //     if (item.id === selectedSubMaterial.id) {
    //       return itemNew;
    //     }
  
    //     return selectedSubMaterial;
    //   });
    // } else {
    //   selectedNew.push(itemNew);
    // }
    
    console.log(selectedNew)
    if (itemType === 'SUB_MATERIAL_PROCESS') {
      setSelectedSubMaterialProcessItems(selectedNew);
    } else if (itemType === 'SUB_MATERIAL_BUILD') {
      setSelectedSubMaterialBuildItems(selectedNew);
    }
    
    // setValue('49726a78-676f-4067-a765-cb480259ad63_gclient_1', '4aa46e6b-59ce-4dd3-9231-d6d19536de55');
  }

  const handleChangeUsableCheckButton = (e, item, i, itemType) => {
    const { checked } = e.target;
    console.log(checked)
    // setValue(`${item.id}_usable_${i}`, checked);

    let itemNew = {};
    if (itemType === 'SUB_MATERIAL_PROCESS') {
      itemNew = Object.assign({}, selectedSubMaterialProcessItems[i]);
    } else if (itemType === 'SUB_MATERIAL_BUILD') {
      itemNew = Object.assign({}, selectedSubMaterialBuildItems[i]);
    }
    itemNew.usable = checked;
    itemNew.index = i;

    console.log(itemNew);
    console.log(selectedSubMaterialProcessItems)
    
    let items = [];
    if (itemType === 'SUB_MATERIAL_PROCESS') {
      items = selectedSubMaterialProcessItems;
    } else if (itemType === 'SUB_MATERIAL_BUILD') {
      items = selectedSubMaterialBuildItems;
    }

    // 아래처럼 하면 배열안에 null인 요소가 발생한다.
    const selectedNew = [].concat(items);
    selectedNew[i] = itemNew;

    console.log(selectedNew)
    if (itemType === 'SUB_MATERIAL_PROCESS') {
      setSelectedSubMaterialProcessItems(selectedNew);
    } else if (itemType === 'SUB_MATERIAL_BUILD') {
      setSelectedSubMaterialBuildItems(selectedNew);
    }
  }

  const handleChangeManufacturerForSubMaterialItems = (e, item, i, itemType) => {
    // setValue(`${item.id}_gclient_${i}`, e.target.value);
    let items;

    if (itemType === 'SUB_MATERIAL_PROCESS') {
      items = JSON.parse(JSON.stringify(selectedSubMaterialProcessItems));
    } else if (itemType === 'SUB_MATERIAL_BUILD') {
      items = JSON.parse(JSON.stringify(selectedSubMaterialBuildItems));
    }

    // console.log(item)
    // console.log(items);
    items = items.map(selectedSubMaterial => {
      if (selectedSubMaterial && item.id === selectedSubMaterial.id && i === selectedSubMaterial.index) {
        selectedSubMaterial = {
          ...selectedSubMaterial,
          value : {
            ...selectedSubMaterial.value,
            selectedGClientId: e.target.value,
          }
        }
      }

      return selectedSubMaterial;
    });

    // console.log(items);
    if (itemType === 'SUB_MATERIAL_PROCESS') {
      setSelectedSubMaterialProcessItems(items);
    } else if (itemType === 'SUB_MATERIAL_BUILD') {
      setSelectedSubMaterialBuildItems(items);
    }
  }

  const addSubMaterial = (e, g04docu, i, itemType) => {
    // console.log(selectedSubMaterialProcessItems)
    
    if (itemType === 'SUB_MATERIAL_PROCESS') {
      setRenderSubMaterialProcessItems(false);
      // 클릭한 바로 아래에 삽입하도록 한다.
      const newBaseSubMaterialProcess = JSON.parse(JSON.stringify(baseSubMaterialProcess));
      newBaseSubMaterialProcess.splice(i+1, 0, baseSubMaterialProcess[i]);
      setBaseSubMaterialProcess(newBaseSubMaterialProcess);

      const newSelectedSubMaterialProcessItems = JSON.parse(JSON.stringify(selectedSubMaterialProcessItems));
      newSelectedSubMaterialProcessItems.splice(i+1, 0, {
        ...selectedSubMaterialProcessItems[i],
        value: {},
      });

      setTimeout(() => setRenderSubMaterialProcessItems(true), 500); // TODO : 추가/삭제시 설정 데이터값은 유지되나 화면상에서 render 후 나타나지 않아 강제로 render하기 위해 추가 (다른 방법이 있는지 추후 검토)

      setSelectedSubMaterialProcessItems(newSelectedSubMaterialProcessItems.map((item, i) => ({
        ...item,
        index: i,
      })));
    } else if (itemType === 'SUB_MATERIAL_BUILD') {
      setRenderSubMaterialBuildItems(false);
      // 클릭한 바로 아래에 삽입하도록 한다.
      const newBaseSubMaterialBuild = JSON.parse(JSON.stringify(baseSubMaterialBuild));
      newBaseSubMaterialBuild.splice(i+1, 0, baseSubMaterialBuild[i]);
      setBaseSubMaterialBuild(newBaseSubMaterialBuild);

      const newSelectedSubMaterialBuildItems = JSON.parse(JSON.stringify(selectedSubMaterialBuildItems));
      newSelectedSubMaterialBuildItems.splice(i+1, 0, {
        ...selectedSubMaterialBuildItems[i],
        value: {},
      });

      setTimeout(() => setRenderSubMaterialBuildItems(true), 500); // TODO : 추가/삭제시 설정 데이터값은 유지되나 화면상에서 render 후 나타나지 않아 강제로 render하기 위해 추가 (다른 방법이 있는지 추후 검토)

      setSelectedSubMaterialBuildItems(newSelectedSubMaterialBuildItems.map((item, i) => ({
        ...item,
        index: i,
      })));
    }
  }

  const handleSubMaterialUpDown = async (type, rownum, itemType) => {
    if (itemType === 'SUB_MATERIAL_PROCESS') {
      setRenderSubMaterialProcessItems(false);
      
      let newArr;
      // 부자재 목록
      let newRows = JSON.parse(JSON.stringify(baseSubMaterialProcess));

      if (type === "up") {
        if (rownum > 0) {
          newArr = changeArrayOrder(newRows, rownum, -1)
        } else {
          setTimeout(() => setRenderSubMaterialProcessItems(true), 500);
          return;
        }
      } else if (type === "down") {
        if (rownum < newRows.length - 1) {
          newArr = changeArrayOrder(newRows, rownum, 1)
        } else {
          setTimeout(() => setRenderSubMaterialProcessItems(true), 500);
          return;
        }
      } else {
        newArr = newRows;
      }

      setBaseSubMaterialProcess(newArr);
      
      // 부자재 목록 선택 정보
      newRows = JSON.parse(JSON.stringify(selectedSubMaterialProcessItems));
      if (type === "up") {
        if (rownum > 0) {
          newArr = changeArrayOrder(newRows, rownum, -1)
        } else {
          setTimeout(() => setRenderSubMaterialProcessItems(true), 500);
          return;
        }
      } else if (type === "down") {
        if (rownum < newRows.length - 1) {
          newArr = changeArrayOrder(newRows, rownum, 1)
        } else {
          setTimeout(() => setRenderSubMaterialProcessItems(true), 500);
          return;
        }
      } else {
        newArr = newRows;
      }

      setSelectedSubMaterialProcessItems(newArr.map((item, i) => ({
        ...item,
        index: i,
      })));

      setTimeout(() => setRenderSubMaterialProcessItems(true), 500); // TODO : 추가/삭제시 설정 데이터값은 유지되나 화면상에서 render 후 나타나지 않아 강제로 render하기 위해 추가 (다른 방법이 있는지 추후 검토)
    } else if (itemType === 'SUB_MATERIAL_BUILD') {
      setRenderSubMaterialBuildItems(false);
      
      let newArr;
      // 부자재 목록
      let newRows = JSON.parse(JSON.stringify(baseSubMaterialBuild));

      if (type === "up") {
        if (rownum > 0) {
          newArr = changeArrayOrder(newRows, rownum, -1)
        } else {
          setTimeout(() => setRenderSubMaterialBuildItems(true), 500);
          return;
        }
      } else if (type === "down") {
        if (rownum < newRows.length - 1) {
          newArr = changeArrayOrder(newRows, rownum, 1)
        } else {
          setTimeout(() => setRenderSubMaterialBuildItems(true), 500);
          return;
        }
      } else {
        newArr = newRows;
      }

      setBaseSubMaterialBuild(newArr);
      
      // 부자재 목록 선택 정보
      newRows = JSON.parse(JSON.stringify(selectedSubMaterialBuildItems));
      if (type === "up") {
        if (rownum > 0) {
          newArr = changeArrayOrder(newRows, rownum, -1)
        } else {
          setTimeout(() => setRenderSubMaterialBuildItems(true), 500);
          return;
        }
      } else if (type === "down") {
        if (rownum < newRows.length - 1) {
          newArr = changeArrayOrder(newRows, rownum, 1)
        } else {
          setTimeout(() => setRenderSubMaterialBuildItems(true), 500);
          return;
        }
      } else {
        newArr = newRows;
      }

      setSelectedSubMaterialBuildItems(newArr.map((item, i) => ({
        ...item,
        index: i,
      })));

      setTimeout(() => setRenderSubMaterialBuildItems(true), 500); // TODO : 추가/삭제시 설정 데이터값은 유지되나 화면상에서 render 후 나타나지 않아 강제로 render하기 위해 추가 (다른 방법이 있는지 추후 검토)
    }
  }

  const removeSubMaterial = (e, g04docu, i, itemType) => {
    // console.log(selectedSubMaterialProcessItems)
    
    if (itemType === 'SUB_MATERIAL_PROCESS') {
      setRenderSubMaterialProcessItems(false);
      // 클릭한 바로 아래에 삽입하도록 한다.
      const newBaseSubMaterialProcess = JSON.parse(JSON.stringify(baseSubMaterialProcess));
      newBaseSubMaterialProcess.splice(i, 1);
      // console.log(newBaseSubMaterialProcess);
      setBaseSubMaterialProcess(newBaseSubMaterialProcess);

      const newSelectedSubMaterialProcessItems = JSON.parse(JSON.stringify(selectedSubMaterialProcessItems));
      // console.log(selectedSubMaterialProcessItems[i])
      newSelectedSubMaterialProcessItems.splice(i, 1);
      
      // console.log(newSelectedSubMaterialProcessItems.map((item, i) => ({
      //   ...item,
      //   index: i,
      // })));

      setTimeout(() => setRenderSubMaterialProcessItems(true), 500); // TODO : 추가/삭제시 설정 데이터값은 유지되나 화면상에서 render 후 나타나지 않아 강제로 render하기 위해 추가 (다른 방법이 있는지 추후 검토)

      setSelectedSubMaterialProcessItems(newSelectedSubMaterialProcessItems.map((item, i) => ({
        ...item,
        index: i,
      })));
    } else if (itemType === 'SUB_MATERIAL_BUILD') {
      setRenderSubMaterialBuildItems(false);
      // 클릭한 바로 아래에 삽입하도록 한다.
      const newBaseSubMaterialBuild = JSON.parse(JSON.stringify(baseSubMaterialBuild));
      newBaseSubMaterialBuild.splice(i, 1);
      // console.log(newBaseSubMaterialBuild);
      setBaseSubMaterialBuild(newBaseSubMaterialBuild);

      const newSelectedSubMaterialBuildItems = JSON.parse(JSON.stringify(selectedSubMaterialBuildItems));
      // console.log(selectedSubMaterialBuildItems[i])
      newSelectedSubMaterialBuildItems.splice(i, 1);
      
      // console.log(newSelectedSubMaterialBuildItems.map((item, i) => ({
      //   ...item,
      //   index: i,
      // })));

      setTimeout(() => setRenderSubMaterialBuildItems(true), 500); // TODO : 추가/삭제시 설정 데이터값은 유지되나 화면상에서 render 후 나타나지 않아 강제로 render하기 위해 추가 (다른 방법이 있는지 추후 검토)

      setSelectedSubMaterialBuildItems(newSelectedSubMaterialBuildItems.map((item, i) => ({
        ...item,
        index: i,
      })));
    }
  }

  const getItemsCount = (id, items) => {
    const matches = items.filter(item => {
      if (item.id === id) {
        return true;
      }

      return false;
    });

    return matches.length;
  }

  // const setAutoSelectValue = (value) => {
  //   // console.log(value)
  //   const { item, i, itemType, selectedGClientId } = value;
  // }

  const generateSubMaterialProcessItems = () => {
    if (renderSubMaterialProcessItems) {
      return (
        <>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item sm={12}>
              <Grid container spacing={1} sx={{ pt: 1, pb: 1, borderBottom: 1, borderColor: '#DCDCDC', bgcolor: '#eaeaea' }}>
                <Grid item sm={2} display="flex" justifyContent={"center"} alignItems={"center"} sx={{ borderRight: 1, borderColor: '#FFFFFF' }}>
                  {"기능"}
                </Grid>
                <Grid item sm={10} display="flex" justifyContent={"center"} alignItems={"center"} sx={{ borderRight: 1, borderColor: '#FFFFFF' }}>
                  {"가공부자재"}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 2 }}>
          {
            baseSubMaterialProcess?.map((g04docu, i) => {
              const { id, code, name, gcomponentItems } = g04docu;
              const selectedId = selectedSubMaterialProcessItems[i]?.value?.id;
              const manufacturers = gcomponentItems?.find(gcomponentItem => gcomponentItem.id === selectedId);
              const isLaminatedFilm = code === 'LAMINATED_FILM';

              return (
                <Grid item sm={12} sx= {i === baseSubMaterialProcess.length - 1 ? { ml: 1 } : { ml: 1, pb: 2, borderBottom: 1, borderColor: '#DCDCDC' }}>
                  {/* <Stack direction="row"> */}
                  <Grid item sm={12} display="flex" alignItems={"center"}>
                    <Grid container>
                      <Grid item sm={2} display="flex" alignItems={"center"}>
                        <Grid container>
                          <Grid item sm={1} display="flex" alignItems={"center"}>
                            <Avatar variant="rounded" sx={{ width: 24, height: 24, fontSize: 12, mr: 2 }}>{i+1}</Avatar>
                          </Grid>
                          <Grid item sm={11} display="flex" justifyContent={"center"} alignItems={"center"}>
                            <IconButton
                              // color="primary"
                              sx={{ color: theme.palette.grey[600] }}
                              size="small"
                              onClick={(e) => addSubMaterial(e, g04docu, i, 'SUB_MATERIAL_PROCESS')}
                              disabled={isLaminatedFilm} // 접합필름 관련 항목 비활성화
                            >
                              <Add fontSize="inherit" />
                            </IconButton>
                            <IconButton
                              // color="secondary"
                              sx={{ color: theme.palette.grey[600] }}
                              size="small"
                              onClick={(e) => removeSubMaterial(e, g04docu, i, 'SUB_MATERIAL_PROCESS')}
                              disabled={getItemsCount(id, baseSubMaterialProcess) <= 1 || isLaminatedFilm}
                            >
                              <Remove fontSize="inherit" />
                            </IconButton>
                            <IconButton
                              // color="secondary"
                              sx={{ color: theme.palette.grey[600] }}
                              size="small"
                              onClick={(e) => handleSubMaterialUpDown('up', i, 'SUB_MATERIAL_PROCESS')}
                              disabled={i === 0 || isLaminatedFilm}
                            >
                              <KeyboardArrowUp fontSize="inherit" />
                            </IconButton>
                            <IconButton
                              // color="secondary"
                              sx={{ color: theme.palette.grey[600] }}
                              size="small"
                              onClick={(e) => handleSubMaterialUpDown('down', i, 'SUB_MATERIAL_PROCESS')}
                              disabled={i === baseSubMaterialProcess.length - 1 || isLaminatedFilm}
                            >
                              <KeyboardArrowDown fontSize="inherit" />
                            </IconButton>
                            <FormInputCheckbox
                              name={`${id}_usable_${i}`}
                              control={control}
                              onChangeCheckValue={(e) => handleChangeUsableCheckButton(e, g04docu, i, 'SUB_MATERIAL_PROCESS')}
                              disabled={isLaminatedFilm} // 접합필름 관련 항목 비활성화
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item sm={10} display="flex" alignItems={"center"}>
                        <FormInputText
                          select
                          name={`${id}_${i}`}
                          control={control}
                          label={name}
                          onChange={(e) => handleChangeSubMaterialItems(e, g04docu, i, 'SUB_MATERIAL_PROCESS')}
                          options={
                            [{ label: '없음', value: "" }].concat(gcomponentItems ? gcomponentItems.map(gcomponentItem => { // TODO : 추후 value: {} 했을 때 문제 없는지 체크할 것
                              return {
                                label: gcomponentItem.name,
                                value: gcomponentItem.id,
                              }
                            }) : [])
                            // gcomponentItems ? gcomponentItems.map(gcomponentItem => ({ label: gcomponentItem.name, value: gcomponentItem.id })) : []
                          }
                          // options={[]}
                          sx={{ width: 300 }}
                          disabled={isLaminatedFilm} // 접합필름 관련 항목 비활성화
                        />
                        <FormInputText
                          select
                          name={`${id}_gclient_${i}`}
                          control={control}
                          label={"제조사"}
                          onChange={(e) => handleChangeManufacturerForSubMaterialItems(e, g04docu, i, 'SUB_MATERIAL_PROCESS')}
                          options={
                            // [{ label: '없음', value: "" }] // TODO : "없음"은 제조사를 설정하지 않는 경우(?)를 위해 남겨둠
                            //   .concat(manufacturers?.gclients ? manufacturers?.gclients.map(item => {
                            //     return {
                            //       label: item.gclient.name,
                            //       value: item.gclient.id,
                            //     }
                            //   }) : [])
                            // 임시 : gclient 필요정보 점검
                            // manufacturers?.gclients ? manufacturers?.gclients.map(item => ({ label: item.gclient.name, value: item.gclient.id })) : []
                            manufacturers?.gclients ? manufacturers?.gclients.map(item => ({ label: item.name, value: item.id })) : []
                          }
                          autoSelectOneOption={true}
                          // setAutoSelectParam={{ item: g04docu, i, itemType: 'SUB_MATERIAL_PROCESS' }}
                          // setAutoSelectValue={setAutoSelectValue} // 제조사가 자동선택되면 해당 제조사 아이디를 제품(아이템)에 설정해야 한다. => 제품 선택(handleChangeSubMaterialItems 함수)시 gclients가 하나이면 거기에서 selectedGClientId를 설정하면 되겠다.
                          sx={{ width: 300, ml: 1 }}
                          // InputProps={{ readOnly: true }} // 한 제품에 제조사가 여러개인 경우는 제조사를 선택해야 하므로 읽기 전용 주석처리
                          disabled={isLaminatedFilm} // 접합필름 관련 항목 비활성화
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* </Stack> */}
                </Grid>
              )
            })
          }
          </Grid>
        </>
      );
    } else {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12} display="flex" justifyContent={"center"} alignItems={"center"}>
            <Box sx={{ width: '100%' }}>
              <LinearProgress />
            </Box>
          </Grid>
        </Grid>
      );
    }
  }

  const generateSubMaterialBuildItems = () => {
    if (renderSubMaterialBuildItems) {
      return (
        <>
          <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item sm={12}>
                <Grid container spacing={1} sx={{ pt: 1, pb: 1, borderBottom: 1, borderColor: '#DCDCDC', bgcolor: '#eaeaea' }}>
                  <Grid item sm={2} display="flex" justifyContent={"center"} alignItems={"center"} sx={{ borderRight: 1, borderColor: '#FFFFFF' }}>
                    {"기능"}
                  </Grid>
                  <Grid item sm={10} display="flex" justifyContent={"center"} alignItems={"center"} sx={{ borderRight: 1, borderColor: '#FFFFFF' }}>
                    {"시공부자재"}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          <Grid container spacing={2} sx={{ mt: 2 }}>
          {
            baseSubMaterialBuild.map((g04docu, i) => {
              const { id, name, gcomponentItems } = g04docu;
              const selectedId = selectedSubMaterialBuildItems[i]?.value?.id;
              const manufacturers = gcomponentItems?.find(gcomponentItem => gcomponentItem.id === selectedId);
              // console.log(manufacturers)
              return (
                <Grid item sm={12} sx= {i === baseSubMaterialBuild.length - 1 ? { ml: 1 } : { ml: 1, pb: 2, borderBottom: 1, borderColor: '#DCDCDC' }}>
                  {/* <Stack direction="row"> */}
                  <Grid item sm={12} display="flex" alignItems={"center"}>
                    <Grid container>
                      <Grid item sm={2} display="flex" alignItems={"center"}>
                        <Grid container>
                          <Grid item sm={1} display="flex" alignItems={"center"}>
                            <Avatar variant="rounded" sx={{ width: 24, height: 24, fontSize: 12, mr: 2 }}>{i+1}</Avatar>
                          </Grid>
                          <Grid item sm={11} display="flex" justifyContent={"center"} alignItems={"center"}>
                            <IconButton
                              // color="primary"
                              sx={{ color: theme.palette.grey[600] }}
                              size="small"
                              onClick={(e) => addSubMaterial(e, g04docu, i, 'SUB_MATERIAL_BUILD')}
                            >
                              <Add fontSize="inherit" />
                            </IconButton>
                            <IconButton
                              // color="secondary"
                              sx={{ color: theme.palette.grey[600] }}
                              size="small"
                              onClick={(e) => removeSubMaterial(e, g04docu, i, 'SUB_MATERIAL_BUILD')}
                              disabled={getItemsCount(id, baseSubMaterialBuild) <= 1 ? true : false}
                            >
                              <Remove fontSize="inherit" />
                            </IconButton>
                            <IconButton
                              // color="secondary"
                              sx={{ color: theme.palette.grey[600] }}
                              size="small"
                              onClick={(e) => handleSubMaterialUpDown('up', i, 'SUB_MATERIAL_BUILD')}
                              disabled={i > 0 ? false : true}
                            >
                              <KeyboardArrowUp fontSize="inherit" />
                            </IconButton>
                            <IconButton
                              // color="secondary"
                              sx={{ color: theme.palette.grey[600] }}
                              size="small"
                              onClick={(e) => handleSubMaterialUpDown('down', i, 'SUB_MATERIAL_BUILD')}
                              disabled={i === baseSubMaterialBuild.length - 1 ? true : false}
                            >
                              <KeyboardArrowDown fontSize="inherit" />
                            </IconButton>
                            <FormInputCheckbox
                              name={`${id}_usable_${i}`}
                              control={control}
                              onChangeCheckValue={(e) => handleChangeUsableCheckButton(e, g04docu, i, 'SUB_MATERIAL_BUILD')}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item sm={10} display="flex" alignItems={"center"}>
                        <FormInputText
                          select
                          name={`${id}_${i}`}
                          control={control}
                          label={name}
                          onChange={(e) => handleChangeSubMaterialItems(e, g04docu, i, 'SUB_MATERIAL_BUILD')}
                          options={
                            // 제품을 반드시 선택해야 하므로 없음은 삭제. 없음이 있을 경우 제조사 선택해제가 되어야 하나 제조사 빈 값으로 선택된 상태(레이블이 상단에 있는...)가 된다.
                            [{ label: '없음', value: "" }].concat(gcomponentItems ? gcomponentItems.map(gcomponentItem => { // TODO : 추후 value: {} 했을 때 문제 없는지 체크할 것
                              return {
                                label: gcomponentItem.name,
                                value: gcomponentItem.id,
                              }
                            }) : [])
                            // gcomponentItems ? gcomponentItems.map(gcomponentItem => ({ label: gcomponentItem.name, value: gcomponentItem.id })) : []
                          }
                          // options={[]}
                          sx={{ width: 300 }}
                        />
                        <FormInputText
                          select
                          name={`${id}_gclient_${i}`}
                          control={control}
                          label={"제조사"}
                          onChange={(e) => handleChangeManufacturerForSubMaterialItems(e, g04docu, i, 'SUB_MATERIAL_BUILD')}
                          options={
                            // [{ label: '없음', value: "" }] // TODO : "없음"은 제조사를 설정하지 않는 경우(?)를 위해 남겨둠
                            //   .concat(manufacturers?.gclients ? manufacturers?.gclients.map(item => {
                            //     return {
                            //       label: item.gclient.name,
                            //       value: item.gclient.id,
                            //     }
                            //   }) : [])
                            // 임시 : gclient 필요정보 점검
                            // manufacturers?.gclients ? manufacturers?.gclients.map(item => ({ label: item.gclient.name, value: item.gclient.id })) : []
                            manufacturers?.gclients ? manufacturers?.gclients.map(item => ({ label: item.name, value: item.id })) : []
                          }
                          autoSelectOneOption={true}
                          // setAutoSelectParam={{ item: g04docu, i, itemType: 'SUB_MATERIAL_BUILD' }}
                          // setAutoSelectValue={setAutoSelectValue} // 제조사가 자동선택되면 해당 제조사 아이디를 제품(아이템)에 설정해야 한다. => 제품 선택(handleChangeSubMaterialItems 함수)시 gclients가 하나이면 거기에서 selectedGClientId를 설정하면 되겠다.
                          sx={{ width: 300, ml: 1 }}
                          // disabled
                          // InputProps={{ readOnly: true }} // 한 제품에 제조사가 여러개인 경우는 제조사를 선택해야 하므로 읽기 전용 주석처리
                        />
                        {/* TODO : FormInputDropdown & FormInputDropdown1은 현재 반복 구조로는 사용할 수 없고 단일 컴포넌트로만 사용 가능 */}
                        {/* <FormInputDropdown1
                          name={`${id}_gclient_${i}`}
                          control={control}
                          label={"제조사"}
                          onChangeSelectValue={(e) => handleChangeManufacturerForSubMaterialItems(e, g04docu, i, 'SUB_MATERIAL_BUILD')}
                          options={
                            [{ label: '없음', value: "" }] // TODO : "없음"은 제조사를 설정하지 않는 경우(?)를 위해 남겨둠
                              .concat(manufacturers?.gclients ? manufacturers?.gclients.map(item => {
                                return {
                                  label: item.gclient.name,
                                  value: item.gclient.id,
                                }
                              }) : [])
                          }
                          setValue={setValue}
                          // getValues={getValues}
                          // ref={gclientDropDown}
                          // inputProps={
                          //   { readOnly: crudMode === 'R', }
                          // }
                          sx={{ width: 300 }}
                        /> */}
                      {/* </Stack> */}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )
            })
          }
          </Grid>
        </>
      )
    } else {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12} display="flex" justifyContent={"center"} alignItems={"center"}>
            <Box sx={{ width: '100%' }}>
              <LinearProgress />
            </Box>
          </Grid>
        </Grid>
      );
    }
  }

  const setSubMaterialProcessItems = () => {
    selectedSubMaterialProcessItems.forEach((item, i) => {
      // console.log(item)
      setValue(`${item.id}_usable_${i}`, item.usable ? item.usable : false);
      setValue(`${item.id}_${i}`, item.value && item.value.id ? item.value.id : "");
      setValue(`${item.id}_gclient_${i}`, item.value && item.value.selectedGClientId ? item.value.selectedGClientId : "");
    })
  }

  const setSubMaterialBuildItems = () => {
    selectedSubMaterialBuildItems.forEach((item, i) => {
      // setValue(`${item.id}_usable`, item.usable);
      // setValue(item.id, item.value.id);
      // setValue(`${item.id}_gclient`, item.value.selectedGClientId);
      setValue(`${item.id}_usable_${i}`, item.usable ? item.usable : false);
      setValue(`${item.id}_${i}`, item.value && item.value.id ? item.value.id : "");
      setValue(`${item.id}_gclient_${i}`, item.value && item.value.selectedGClientId ? item.value.selectedGClientId : "");
    })
  }

  const generateSubMaterialProcessItemsWithGClient = () => {
    return (<>
      { generateSubMaterialProcessItems() }
      { setSubMaterialProcessItems() }
    </>)
  }

  const generateSubMaterialBuildItemsWithGClient = () => {
    return (<>
      { generateSubMaterialBuildItems() }
      { setSubMaterialBuildItems() }
    </>)
   }

  const [progress, setProgress] = useState(".");
  let timer = React.useRef(undefined);

  // useEffect(() => {
  //   if (!timer.current) {
  //     timer.current = setInterval(() => {
  //       setProgress((prev) => {
  //         if (prev.length < 7) {
  //           return prev = prev + ".";
  //         } else if (prev.length === 7) {
  //           return prev = ".";
  //         }
          
  //         return prev;
  //       });
  //     }, 1000);
  //   }
  // }, []);
  const showProgress = () => {
    timer.current = setInterval(() => {
      setProgress((prev) => {
        if (prev.length < 7) {
          return prev = prev + ".";
        } else if (prev.length === 7) {
          return prev = ".";
        }
        
        return prev;
      });
    }, 1500);
  }

  useEffect(
    () => {
      if (!openBackdrop) {
        if (timer.current) {
          clearInterval(timer.current);
        }
      }
    }, [openBackdrop]
  )

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openBackdrop}
        >
          <Stack direction="column" alignItems="center">
            <CircularProgress color="inherit" sx={{ mb: 2 }} />
            {"가공정보를 생성하고 있습니다."}<br />
            {"잠시만 기다려주세요 "}{progress}
          </Stack>
        </Backdrop>
        <Stepper nonLinear activeStep={activeStep}>
          {steps.map((label, index) => (
            <Step key={label} completed={completed[index]}>
              <StepButton color="inherit" onClick={handleStep(index)}>
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
        <Grid>
        {
          <Paper sx={{ mt: 3, p: 2, overflowX: activeStep === 0 ? 'auto' : 'visible' }}>
            <Grid container spacing={1} sx={{ minWidth: activeStep === 0 ? '1400px' : 'auto' }}>
              <Grid item sm={12}>
                { activeStep === 0 && generateGGlasses() }
                { activeStep === 1 && generateRawMaterialsWithGClient() }
                { activeStep === 2 && generateProcessesWithGClient() }
                { activeStep === 3 && generateSubMaterialProcessItemsWithGClient() }
                { activeStep === 4 && generateSubMaterialBuildItemsWithGClient() }
              </Grid>
            </Grid>
          </Paper>
        }
        </Grid>
      </Box>
      <AlertDialog
        alertInfo={alertInfo}
        setAlertInfo={setAlertInfo}
      />
    </>
  );
};

export default GProjectG04Step;
