import { csrfFetch } from "./csrf.js";
import { gcoreServerUrl } from '../config';

const INIT_G04_DOCU = 'g04docu/initG04docu';
const INIT_G04_DOCUS = 'g04docu/initG04docus';
const INIT_G04_DOCUS_SUB_MATERIAL_PROCESS = 'g04docu/initG04docusSubMaterialProcess';
const INIT_G04_DOCUS_SUB_MATERIAL_BUILD = 'g04docu/initG04docusSubMaterialBuild';
const INIT_G04_DOCUS_PROCESS = 'g04docu/initG04docusProcess';
const SET_G04_DOCU = 'g04docu/setG04docu';
const REMOVE_G04_DOCU = "g04docu/removeG04docu";
const SET_G04_DOCUS = 'g04docu/setG04docus';
const INIT_G04_DOCUS_CHILD = 'g04docu/initG04docusChild';
const SET_G04_DOCUS_CHILD = 'g04docu/setG04docusChild';
const SET_G04_DOCUS_TREE = 'g04docu/setG04docusTree';
const SET_G04_DOCUS_TREE_FIND = 'g04docu/setG04docusTreeFind';
const SET_G04_DOCUS_TOP_LEVEL = 'g04docu/setG04docusTopLevel';
const SET_G04_DOCUS_SELECT_COUNT = 'g04docu/setG04docusSelectCount';
const INIT_G04_DOCUS_SELECT_COUNT = 'g04docu/initG04docusSelectCount';
const SET_G04_DOCUS_ALL_SELECT_COUNT = 'g04docu/setG04docusAllSelectCount';
// const SET_G04_DOCUS_SELECTED_FOR_GGLASS = 'g04docu/setG04docusSelectedForGGlass';
// const INIT_G04_DOCUS_SELECTED_FOR_GGLASS = 'g04docu/initG04docusSelectedForGGlass';
const SET_G04_DOCUS_RAW_MATERIAL = 'g04docu/setG04docusRawMaterial';
const SET_G04_DOCUS_SUB_MATERIAL_PROCESS = 'g04docu/setG04docusSubMaterialProcess';
const SET_G04_DOCUS_SUB_MATERIAL_BUILD = 'g04docu/setG04docusSubMaterialBuild';
const SET_G04_DOCUS_PROCESS = 'g04docu/setG04docusProcess';

const initG04docu = () => ({
  type: INIT_G04_DOCU,
});

const initG04docus = () => ({
  type: INIT_G04_DOCUS,
});

const initG04docusSubMaterialProcess = () => ({
  type: INIT_G04_DOCUS_SUB_MATERIAL_PROCESS,
});

const initG04docusSubMaterialBuild = () => ({
  type: INIT_G04_DOCUS_SUB_MATERIAL_BUILD,
});

const initG04docusProcess = () => ({
  type: INIT_G04_DOCUS_PROCESS,
});

const setG04docu = (g04docu) => ({
  type: SET_G04_DOCU,
  payload: g04docu,
});

const removeG04docu = () => ({
  type: REMOVE_G04_DOCU,
});

const setG04docus = (g04docus) => ({
  type: SET_G04_DOCUS,
  payload: g04docus,
});

const setG04docusRawMaterial = (g04docusRawMaterial) => ({
  type: SET_G04_DOCUS_RAW_MATERIAL,
  payload: g04docusRawMaterial,
});

const setG04docusSubMaterialProcess = (g04docusSubMaterialProcess) => ({
  type: SET_G04_DOCUS_SUB_MATERIAL_PROCESS,
  payload: g04docusSubMaterialProcess,
});

const setG04docusSubMaterialBuild = (g04docusSubMaterialBuild) => ({
  type: SET_G04_DOCUS_SUB_MATERIAL_BUILD,
  payload: g04docusSubMaterialBuild,
});

const setG04docusProcess = (g04docusProcess) => ({
  type: SET_G04_DOCUS_PROCESS,
  payload: g04docusProcess,
});

// setG04docusChild에 빈 배열([])을 설정해도 되지만 조회 후 데이터가 없음이 아닌 초기화라고 명확하게 표현하는 게 좋을 것 같음
const initG04docusChild = () => ({
  type: INIT_G04_DOCUS_CHILD,
});

const setG04docusChild = (glass04docusChild) => ({
  type: SET_G04_DOCUS_CHILD,
  payload: glass04docusChild,
});

const setG04docusTree = (g04docusTree) => ({
  type: SET_G04_DOCUS_TREE,
  payload: g04docusTree,
});

const setG04docusTreeFind = (g04docusTreeFind) => ({
  type: SET_G04_DOCUS_TREE_FIND,
  payload: g04docusTreeFind,
});

const setG04docusTopLevel = (g04docusTopLevel) => ({
  type: SET_G04_DOCUS_TOP_LEVEL,
  payload: g04docusTopLevel,
});

const setG04docusSelectCount = () => ({
  type: SET_G04_DOCUS_SELECT_COUNT,
});

const initG04docusSelectCount = () => ({
  type: INIT_G04_DOCUS_SELECT_COUNT,
});

const setG04docusAllSelectCount = (count) => ({
  type: SET_G04_DOCUS_ALL_SELECT_COUNT,
  payload: count,
});

// const setG04docusSelectedForGGlass = (g04docu) => ({
//   type: SET_G04_DOCUS_SELECTED_FOR_GGLASS,
//   payload: g04docu,
// })

// const initG04docusSelectedForGGlass = () => ({
//   type: INIT_G04_DOCUS_SELECTED_FOR_GGLASS,
// })

export const create = ({ id, division, name, code, type, details, divider, comments, majority, pid, gstandardId }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04docus`, {
    method: "POST",
    body: JSON.stringify({ id, division, name, code, type, details, divider, comments, majority, pid, gstandardId }),
  });
  const data = await response.json();
  dispatch(setG04docu(data.g04docu));
  return response;
};

export const modify = ({ id, division, name, code, type, details, divider, comments, majority, pid, gstandardId }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04docus`, {
    method: "PUT",
    body: JSON.stringify({ id, division, name, code, type, details, divider, comments, majority, pid, gstandardId }),
  });
  const data = await response.json();
  dispatch(setG04docu(data.g04docu));
  return response;
};

export const modifyType = ({ id, type }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04docus/type`, {
    method: "PUT",
    body: JSON.stringify({ id, type }),
  });
  const data = await response.json();
  dispatch(setG04docu(data.g04docu));
  return response;
};

export const modifyDivision = ({ id, division }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04docus/division`, {
    method: "PUT",
    body: JSON.stringify({ id, division }),
  });
  const data = await response.json();
  dispatch(setG04docu(data.g04docu));
  return response;
};

// export const modifyMultipleYN = ({ id, multipleYN }) => async dispatch => {
//   const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04docus/multipleYN`, {
//     method: "PUT",
//     body: JSON.stringify({ id, multipleYN }),
//   });
//   const data = await response.json();
//   dispatch(setG04docu(data.g04docu));
//   return response;
// };

export const selectAll = () => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04docus`);
  const data = await response.json();
  dispatch(setG04docus(data.g04docus));
  return response;
}

export const selectByType = (type) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04docus/type/${type}`);
  const data = await response.json();
  dispatch(setG04docus(data.g04docus));
}

export const selectAllByQuery = () => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04docus/query/selectAll`);
  const data = await response.json();
  dispatch(setG04docusTree(data.g04docusTree));
  return response;
}

export const selectByDivisionByQuery = (division) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04docus/query/selectAll/division/${division}`);
  const data = await response.json();
  dispatch(setG04docusTree(data.g04docusTree));
  return response;
}

export const selectByDivisionAndType = (division, type) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04docus/division/${division}/type/${type}`);
  const data = await response.json();
  dispatch(setG04docus(data.g04docus));
  return response;
}

export const selectByDivisionAndTypeDirect = async (division, type) => {
  const response = await csrfFetch(null, `${gcoreServerUrl}/api/g04docus/division/${division}/type/${type}`);
  const data = await response.json();
  return data.g04docus;
}

export const selectByDivisionAndTypeByQuery = (division, type) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04docus/query/selectAll/division/${division}/type/${type}`);
  const data = await response.json();
  dispatch(setG04docus(data.g04docus));
  return response;
}

export const selectByDivisionAndTypeWithItemsByQuery = (division, type) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04docus/query/selectAllWithItems/division/${division}/type/${type}`);
  const data = await response.json();
  if (division === 'RAW_MATERIAL') {
    dispatch(setG04docusRawMaterial(data.g04docus));
  } else if (division === 'SUB_MATERIAL_PROCESS') {
    dispatch(setG04docusSubMaterialProcess(data.g04docus));
  } else if (division === 'SUB_MATERIAL_BUILD') {
    dispatch(setG04docusSubMaterialBuild(data.g04docus));
  } else if (division === 'PROCESS') {
    dispatch(setG04docusProcess(data.g04docus));
  }
  
  return response;
}

export const selectAllByQueryFind = (id) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04docus/query/selectAllFind/${id}`);
  const data = await response.json();
  dispatch(setG04docusTreeFind(data.g04docusTreeFind));
  return response;
}

export const select = (id) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04docus/${id}`);
  const data = await response.json();
  dispatch(setG04docu(data.g04docu));
  return response;
}

export const initializeG04docus = () => async dispatch => {
  dispatch(initG04docus());
}

export const initializeG04docusSubMaterialProcess = () => async dispatch => {
  dispatch(initG04docusSubMaterialProcess());
}

export const initializeG04docusSubMaterialBuild = () => async dispatch => {
  dispatch(initG04docusSubMaterialBuild());
}

export const initializeG04docusProcess = () => async dispatch => {
  dispatch(initG04docusProcess());
}

export const initializeG04docusChild = () => async dispatch => {
  dispatch(initG04docusChild());
}

export const selectByPid = (pid) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04docus/child/${pid}`);
  const data = await response.json();
  dispatch(setG04docusChild(data.g04docusChild));
  return response;
}

export const selectByPidDirect = async (pid) => {
  const response = await csrfFetch(null, `${gcoreServerUrl}/api/g04docus/child/${pid}`);
  const data = await response.json();
  return data.g04docusChild;
}

export const remove = (id) => async (dispatch) => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04docus/${id}`, {
    method: "DELETE",
  });
  dispatch(removeG04docu());
  return response;
};

export const selectAllTopLevel = () => async (dispatch) => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04docus/query/selectAllTopLevel`);
  const data = await response.json();
  dispatch(setG04docusTopLevel(data.g04docusTopLevel));
  return response;
}

// TODO : 필요없어보여 주석 처리. 검토하고 삭제하면 서버쪽도 삭제할 것
// export const selectGglass04docusByGtypeByQuery = (gtypeDetails) => async dispatch => {
//   const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04docus/query/selectGglass04docusByGtypeByQuery`, {
//     method: "POST",
//     body: JSON.stringify(gtypeDetails),
//   });
//   const data = await response.json();
//   // dispatch(setG04docu(data.g04docusByGtype));
//   return response;
// }

export const increaseG04docusSelectCount = () => async (dispatch) => {
  dispatch(setG04docusSelectCount());
}

export const initializeG04docusSelectCount = () => async (dispatch) => {
  dispatch(initG04docusSelectCount());
}

export const countFindChildAllIncludeMeByQuery = (gtypeId) => async (dispatch) => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/g04docus/query/countFindChildAllIncludeMeByQuery/${gtypeId}`);
  const data = await response.json();
  dispatch(setG04docusAllSelectCount(data.count));
  return response;
}

// export const addG04docusSelectedForGGlass = (g04docu) => async (dispatch) => {
//   dispatch(setG04docusSelectedForGGlass(g04docu));
// }

export const initializeG04docu = () => async (dispatch) => {
  dispatch(initG04docu());
}

// export const initializeG04docusSelectedForGGlass = () => async (dispatch) => {
//   dispatch(initG04docusSelectedForGGlass());
// }

export const reorder = async ({ type, id }) => {
  const response = await csrfFetch(null, `${gcoreServerUrl}/api/g04docus/reorder`, {
    method: "POST",
    body: JSON.stringify({ type, id }),
  });
  
  return response;
}

const initialState = {
  g04docu: null,
  g04docus: [],
  g04docusTree: [],
  g04docusTreeFind: [],
  g04docusChild: [],
  g04docusTopLevel: [],
  g04docusSelectCount: 0,
  g04docusAllSelectCount: 0,
  // g04docusSelectedForGGlass: [],
  g04docusRawMaterial: [],
  g04docusSubMaterialProcess: [],
  g04docusSubMaterialBuild: [],
  g04docusProcess: [],
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case INIT_G04_DOCU:
      newState = Object.assign({}, state, { g04docu: null });
      return newState;
    case INIT_G04_DOCUS:
      newState = Object.assign({}, state, { g04docus: initialState.g04docus });
      return newState;
    case INIT_G04_DOCUS_SUB_MATERIAL_PROCESS:
      newState = Object.assign({}, state, { g04docusSubMaterialProcess: initialState.g04docusSubMaterialProcess });
      return newState;
    case INIT_G04_DOCUS_SUB_MATERIAL_BUILD:
      newState = Object.assign({}, state, { g04docusSubMaterialBuild: initialState.g04docusSubMaterialBuild });
      return newState;
    case INIT_G04_DOCUS_PROCESS:
      newState = Object.assign({}, state, { g04docusProcess: initialState.g04docusProcess });
      return newState;
    case SET_G04_DOCU:
      newState = Object.assign({}, state, { g04docu: action.payload });
      return newState;
    case REMOVE_G04_DOCU:
      newState = Object.assign({}, state, { g04docu: null });
      return newState;
    case SET_G04_DOCUS:
      newState = Object.assign({}, state, { g04docus: action.payload });
      return newState;
    case SET_G04_DOCUS_TREE:
      newState = Object.assign({}, state, { g04docusTree: action.payload });
      return newState;
    case SET_G04_DOCUS_TREE_FIND:
      newState = Object.assign({}, state, { g04docusTreeFind: action.payload });
      return newState;
    case INIT_G04_DOCUS_CHILD:
      newState = Object.assign({}, state, { g04docusChild: [] });
      return newState;
    case SET_G04_DOCUS_CHILD:
      newState = Object.assign({}, state, { g04docusChild: action.payload });
      return newState;
    case SET_G04_DOCUS_TOP_LEVEL:
      newState = Object.assign({}, state, { g04docusTopLevel: action.payload });
      return newState;
    case SET_G04_DOCUS_SELECT_COUNT:
      const count = state.g04docusSelectCount + 1;
      newState = Object.assign({}, state, { g04docusSelectCount: count });
      return newState;
    case INIT_G04_DOCUS_SELECT_COUNT:
      newState = Object.assign({}, state, { g04docusSelectCount: 0 });
      return newState;
    case SET_G04_DOCUS_ALL_SELECT_COUNT:
      newState = Object.assign({}, state, { g04docusAllSelectCount: action.payload });
      return newState;
    // case SET_G04_DOCUS_SELECTED_FOR_GGLASS:
    //   const { pGglass04docuId, prev, next } = action.payload;
      
    //   // 같은 요소의 이전 선택한 값과 같은 것은 삭제
    //   const selectedComponents = state.g04docusSelectedForGGlass.filter(glass04docu => {
    //     if (glass04docu.pGglass04docuId !== pGglass04docuId ) {
    //       return true;
    //     } else if (glass04docu.pGglass04docuId === pGglass04docuId && glass04docu.next == prev) {
    //       return false;
    //     } 
        
    //     return true;
    //   });

    //   // 없을 경우만 추가
    //   const duplicated = selectedComponents.filter(glass04docu => glass04docu.pGglass04docuId === pGglass04docuId && glass04docu.next == next);
    //   if (duplicated.length === 0) {
    //     selectedComponents.push({pGglass04docuId, prev, next });
    //   }
      
    //   newState = Object.assign({}, state, { g04docusSelectedForGGlass: selectedComponents });
    //   return newState;
    // case INIT_G04_DOCUS_SELECTED_FOR_GGLASS:
    //   newState = Object.assign({}, state, { g04docusSelectedForGGlass: [] });
    //   return newState;
    case SET_G04_DOCUS_RAW_MATERIAL:
      newState = Object.assign({}, state, { g04docusRawMaterial: action.payload });
      return newState;
    case SET_G04_DOCUS_SUB_MATERIAL_PROCESS:
      newState = Object.assign({}, state, { g04docusSubMaterialProcess: action.payload });
      return newState;
    case SET_G04_DOCUS_SUB_MATERIAL_BUILD:
      newState = Object.assign({}, state, { g04docusSubMaterialBuild: action.payload });
      return newState;
    case SET_G04_DOCUS_PROCESS:
      newState = Object.assign({}, state, { g04docusProcess: action.payload });
      return newState;
    default:
      return state;
  }
}

export default reducer;
