import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { DataGridPro, GridActionsCellItem, koKR } from '@mui/x-data-grid-pro';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  Box,
  Button,
  Chip,
  Container,
  CssBaseline,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Stack,
  Typography,
  Fab, 
  Zoom,
  useScrollTrigger
} from '@mui/material';
import  {
  Preview,
} from '@mui/icons-material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  FormInputAutoComplete,
  FormInputText,
} from "../form";
import {
  CustomNoRowsOverlay,
  // CustomLoadingOverlay,
} from "../datagrid";
import { StyledTooltip } from "../tooltip";
import {
  dateFormat,
  // differenceInDays,
  objectEmptyCheck,
} from "../../utils";
import { uploadFilePath, fileServerUrl } from '../../config';
import * as gclientActions from "../../store/gclient";
import * as securityActions from "../../store/security";
import * as securityRequestActions from "../../store/securityRequest";
import * as gprojectActions from "../../store/gproject";
import * as gprojectMapActions from "../../store/gprojectMap";
import * as gdomesticAuthMapActions from "../../store/gdomesticAuthMap";
import { useScrollToTop } from "../../utils/scrollUtils";

const theme = createTheme();

const tooltipTop = {
  "& .MuiTooltip-tooltip": {
    borderRadius: "0px",
  }
};

const defaultValues = {
  gclient: {},
};

const columnsProjectMapBasic = [
  {
    field: 'no',
    headerName: '품번',
    width: 100,
    valueGetter: (params) => {
      if (params.value) {
        return params.value;
      } else {
        return "-";
      }
    },
  },
  {
    field: 'specification',
    headerName: '사양',
    width: 300,
    valueGetter: (params) => {
      if (params.value) {
        return params.value.replaceAll("|", " ").replaceAll("+", " + ");
      } else {
        return "-";
      }
    },
  },
  {
    field: 'division',
    headerName: '구분',
    width: 140,
    headerAlign: 'center',
    align: 'center',
    valueGetter: (params) => {
      let division = "";
      const { value } = params;
      if (value === 'RAW_MATERIAL') {
        division = "원자재";
      } else if (value === 'SUB_MATERIAL_PROCESS') {
        division = "가공부자재";
      } else if (value === 'SUB_MATERIAL_BUILD') {
        division = "시공부자재";
      } else if (value === 'PROCESS') {
        division = "공정";
      }
      return division;
    },
  },
  {
    field: 'type',
    headerName: '공정구분',
    width: 140,
    headerAlign: 'center',
    align: 'center',
    valueGetter: (params) => {
      let type = "-";
      const { value } = params;
      if (value === 'HEAT_STRENGTHENED') {
        type = "배강도";
      } else if (value === 'TEMPERED') {
        type = "강화";
      } else if (value === 'LAMINATED') {
        type = "접합";
      } else if (value === 'INSULATED_GLASS_UNIT') {
        type = "복층";
      }
      return type;
    },
  },
  {
    field: 'itemName',
    headerName: '제품명',
    width: 200,
    valueGetter: (params) => {
      // console.log(params.row)
      const { division, selectedGcomponentItems, selectedSubMaterialProcessItems,  selectedSubMaterialBuildItems } = params.row;
      let itemName = "-";
      if (division === 'RAW_MATERIAL') {
        selectedGcomponentItems?.forEach((gtypeDetails, i) => {
          gtypeDetails.forEach((item, j) => {
            if (j === 0) { // 각 레이어의 첫번째는 상세 구성요소의 상위 개념 (예를 들면 유리원판, 중공층 등...)으로 스킵
              return;
            }
            
            const { code, value } = item;
            if (code === 'GLASS PRODUCT NAME') { // <<<주의 : 코드 하드코딩 사용>>>
              const { id, name } = value;
              if (id === params.row.gcomponentItemId) {
                itemName = name;
                return;
              }
            }
          })
        });
      } else if (division === 'SUB_MATERIAL_PROCESS') {
        selectedSubMaterialProcessItems.forEach(item => {
          const { usable, value } = item;
          if (usable && !objectEmptyCheck(value)) {
            const { id, name } = value;
            if (id === params.row.gcomponentItemId) {
              itemName = name;
              return;
            }
          }
        })
      } else if (division === 'SUB_MATERIAL_BUILD') {
        selectedSubMaterialBuildItems.forEach(item => {
          const { usable, value } = item;
          if (usable && !objectEmptyCheck(value)) {
            const { id, name } = value;
            if (id === params.row.gcomponentItemId) {
              itemName = name;
              return;
            }
          }
        })
      }
      return itemName;
    },
  },
];

const Dashboard = () => {
  const [loadedRequest, setLoadedRequest] = useState(false);
  const [pageSizeRequest, setPageSizeRequest] = useState(100);
  const [pageRequest, setPageRequest] = useState(0);
  const [selectionModelRequest, setSelectionModelRequest] = useState([]);
  const [selectedRowsRequest, setSelectedRowsRequest] = useState([]);
  
  const [loadedApproval, setLoadedApproval] = useState(false);
  const [pageSizeApproval, setPageSizeApproval] = useState(100);
  const [pageApproval, setPageApproval] = useState(0);
  const [selectionModelApproval, setSelectionModelApproval] = useState([]);
  const [selectedRowsApproval, setSelectedRowsApproval] = useState([]);

  const [gclients, setGClients] = useState([]);
  const [selectedParticipantId, setSelectedParticipantId] = useState("");
  const [selectedParticipant, setSelectedParticipant] = useState(null);

  // 참여 프로젝트 현황
  const [loadedJoinedProject, setLoadedJoinedProject] = useState(false);
  const [pageJoinedProject, setPageJoinedProject] = useState(0);
  const [pageSizeJoinedProject, setPageSizeJoinedProject] = useState(100);

  // 참여 프로젝트별 유리품종
  const [loadedProjectMap, setLoadedProjectMap] = useState(true);
  const [pageProjectMap, setPageProjectMap] = useState(0);
  const [pageSizeProjectMap, setPageSizeProjectMap] = useState(100);

  // 국내산가공유리제품 인증 프로젝트 현황
  const [loadedGDomesticAuthProject, setLoadedGDomesticAuthProject] = useState(false);
  const [pageGDomesticAuthProject, setPageGDomesticAuthProject] = useState(0);
  const [pageSizeGDomesticAuthProject, setPageSizeGDomesticAuthProject] = useState(100);
  
  // 국내산가공유리제품 인증 프로젝트별 인증상세
  const [loadedGDomesticAuthMap, setLoadedGDomesticAuthMap] = useState(true);
  const [pageGDomesticAuthMap, setPageGDomesticAuthMap] = useState(0);
  const [pageSizeGDomesticAuthMap, setPageSizeGDomesticAuthMap] = useState(100);

  const [columnsProjectMap, setColumnsProjectMap] = useState(columnsProjectMapBasic);
  
  const [selectedGClientId, setSelectedGClientId] = useState("");
  const [selectedGClient, setSelectedGClient] = useState(null);

  const [participantValue, setParticipantValue] = useState(null);

  const { handleSubmit, control, setValue, getValues } = useForm({ defaultValues });

  const sessionUser = useSelector((state) => state.session.sessionUser);
  const rowsToRequest = useSelector((state) => state.securityRequest.securityToRequests);
  const rowsToApproval = useSelector((state) => state.securityRequest.securityToApprovals);
  const rowsProjectMap = useSelector((state) => state.gprojectMap.gprojectMaps);
  const rowsJoinedProject = useSelector((state) => state.gprojectMap.gprojects);
  const rowsGDomesticAuthProject = useSelector((state) => state.gproject.gprojects);
  const rowsGDomesticAuthMap = useSelector((state) => state.gdomesticAuthMap.gdomesticAuthMaps);

  const dispatch = useDispatch();

  const selecteSecurityToRequestsByQuery = (senderId) => dispatch(securityRequestActions.selecteSecurityToRequestsByQuery(senderId))
  const selecteSecurityToApprovalsByQuery = (receiverId) => dispatch(securityRequestActions.selecteSecurityToApprovalsByQuery(receiverId))
  const modifySecurityRequestStatus = ({ id, status }) => dispatch(securityRequestActions.modifyStatus({ id, status }))
  const modifySecurityRequestsByIdsStatus = ({ ids, status }) => dispatch(securityRequestActions.modifyStatusByIds({ ids, status }))
  const addSecurityOpenGClientList = ({ service, gclientId, list }) => dispatch(securityActions.addSecurityOpenGClientList({ service, gclientId, list }))

  const selectAllGClientDirect = () => gclientActions.selectAllDirect()

  // const selectGProjectMaps = () => dispatch(gprojectMapActions.selectAll());
  const selectGProjectMapsByOwnerByQuery = (ownerId) => dispatch(gprojectMapActions.selectAllByOwnerByQuery(ownerId));
  const selectGProjectMapsByGProjectAndOwnerByQuery = (gprojectId, ownerId) => dispatch(gprojectMapActions.selectAllByGProjectAndOwnerByQuery(gprojectId, ownerId));
  const selectJoinedGProjectsByOwnerByQuery = (ownerId) => dispatch(gprojectMapActions.selectGProjectsByOwnerByQuery(ownerId));
  const selectGProjectsBySearchByQuery = (ownerId, searchName, searchSite) => dispatch(gprojectMapActions.selectGProjectsBySearchByQuery(ownerId, searchName, searchSite));
  const initGprojectMaps = () => dispatch(gprojectMapActions.initGprojectMaps());

  const selectAllProjectsOr4docuProjects = (docu04, searchName, searchSite) => dispatch(gprojectActions.selectAllProjectsOr4docuProjects(docu04, searchName, searchSite))
  const selectProjectsOr4docuProjectsByOwner = (docu04, gclientId, searchName, searchSite) => dispatch(gprojectActions.selectProjectsOr4docuProjects(docu04, gclientId, searchName, searchSite))
  const selectGDomestciAuthByGProject = (gprojectId) => dispatch(gdomesticAuthMapActions.selectByGProjectByQuery(gprojectId))

  // 스크롤 트리거 및 스크롤 동작 추가
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const columnsRequest = [
    {
      field: 'funcions',
      headerName: '상태/기능',
      width: 140,
      // editable: true,
      headerAlign: 'center',
      align: 'center',
      // renderCell: (params) => {
      //   const { status } = params.row;
      //   if (status) {
      //     if (status === '요청') {
      //       return <Chip label={status} size="small" color="primary" variant="outlined"></Chip>
      //     } else if (status === '비공개') {
      //       return (
      //         <>
      //           <Button
      //             size="small"
      //             // variant="outlined"
      //             onClick={(e) => handleClickRequest(e, params)}
      //             sx={{ ml: 1, fontWeight: 'bold' }}
      //           >
      //             {"다시 요청"}
      //           </Button>
      //         </>
      //       )
      //     }
      //   }
      // },
      renderCell: (params) => {
        const { status } = params.row;
        if (status) {
          if (status === '요청') {
            return (
              <Button
                disabled
                sx={{ borderRadius: 30, maxWidth: '60px', maxHeight: '60px', minWidth: '60px', minHeight: '60px', backgroundImage: 'url("/requesting.png")', backgroundSize: "60px 60px" }}
              >             
              </Button>
            )
          } else if (status === '비공개') {
            return (
              <Button
                onClick={(e) => handleClickRequestAgain(e, params)}
                sx={{ borderRadius: 30, maxWidth: '60px', maxHeight: '60px', minWidth: '60px', minHeight: '60px', backgroundImage: 'url("/request-again.png")', backgroundSize: "60px 60px" }}
              >             
              </Button>
            )
          }
        }
      },
    },
    {
      field: 'bizRegNumber',
      headerName: '사업자등록번호',
      width: 160,
      headerAlign: 'center',
      align: 'center',
      // editable: true,
    },
    {
      field: 'name',
      headerName: '거래선명',
      width: 240,
      // editable: true,
    },
    {
      field: 'ceo',
      headerName: '대표자',
      width: 240,
      // editable: true,
    },
    {
      field: 'phone',
      headerName: '대표전화번호',
      width: 160,
      // editable: true,
    },
    {
      field: 'email',
      headerName: '회사대표메일',
      width: 200,
      // editable: true,
    },
    {
      field: 'address',
      headerName: '주소',
      width: 240,
      // editable: true,
    },
  ];

  const columnsApproval = [
    {
      field: 'funcions',
      headerName: '상태/기능',
      width: 140,
      // editable: true,
      headerAlign: 'center',
      align: 'center',
      // renderCell: (params) => {
      //   const { status } = params.row;
      //   if (status) {
      //     if (status === '요청' || status === '비공개') {
      //       return (
      //         <>
      //           <Chip label={status} size="small" color="primary" variant="outlined"></Chip>
      //           <Button
      //             size="small"
      //             // variant="outlined"
      //             onClick={(e) => handleClickApproval(e, params)}
      //             sx={{ fontWeight: 'bold' }}
      //           >
      //             {"승인"}
      //           </Button>
      //         </>
      //       )
      //     }
      //   }
      // },
      renderCell: (params) => {
        const { status } = params.row;
        if (status) {
          if (status === '요청') {
            return (
              <>
                <Button
                  onClick={(e) => handleClickApproval(e, params)}
                  sx={{ borderRadius: 30, maxWidth: '60px', maxHeight: '60px', minWidth: '60px', minHeight: '60px', backgroundImage: 'url("/approve.png")', backgroundSize: "60px 60px" }}
                >             
                </Button>
                <Button
                  onClick={(e) => handleClickDisaproval(e, params)}
                  sx={{ borderRadius: 30, maxWidth: '60px', maxHeight: '60px', minWidth: '60px', minHeight: '60px', backgroundImage: 'url("/disaprove.png")', backgroundSize: "60px 60px", ml: 1 }}
                >             
                </Button>
              </>
            )
          } else if (status === '비공개') {
            return (
              <Button
                onClick={(e) => handleClickApproval(e, params)}
                sx={{ borderRadius: 30, maxWidth: '60px', maxHeight: '60px', minWidth: '60px', minHeight: '60px', backgroundImage: 'url("/approve.png")', backgroundSize: "60px 60px" }}
              >             
              </Button>
            )
          }
        }
      },
    },
    {
      field: 'bizRegNumber',
      headerName: '사업자등록번호',
      width: 160,
      // editable: true,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'name',
      headerName: '거래선명',
      width: 240,
      // editable: true,
    },
    {
      field: 'ceo',
      headerName: '대표자',
      width: 240,
      // editable: true,
    },
    {
      field: 'phone',
      headerName: '대표전화번호',
      width: 160,
      // editable: true,
    },
    {
      field: 'email',
      headerName: '회사대표메일',
      width: 200,
      // editable: true,
    },
    {
      field: 'address',
      headerName: '주소',
      width: 240,
      // editable: true,
    },
  ]

  const columnsJoinedProject = [
    {
      field: 'owner',
      headerName: '공급업체',
      width: 200,
      valueGetter: (params) => params.row?.owner?.name,
    },
    {
      field: 'site',
      headerName: '현장명',
      width: 200,
    },
    {
      field: 'siteAddress',
      headerName: '현장주소',
      width: 200,
    },
    {
      field: 'constructionCompanyName',
      headerName: '건설사명',
      width: 160,
    },
    {
      field: 'completionDate',
      headerName: '준공일',
      width: 110,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => params.value && dateFormat(params.value, 'yyyy-MM-dd'),
    },
    {
      field: 'startDate',
      headerName: '시작',
      width: 110,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => dateFormat(params.value, 'yyyy-MM-dd'),
    },
    {
      field: 'endDate',
      headerName: '마감',
      width: 110,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => dateFormat(params.value, 'yyyy-MM-dd'),
    },
  ];

  const columnsGDomesticAuthProject = [
    {
      field: 'gdomesticAuths',
      headerName: '사전인증 발급현황',
      width: 160,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => {
        const { value } = params;
        if (value && Array.isArray(value) && value.length > 0) {
          return `${value.filter(i => i?.authYN === 'Y').length}/${value.length}`;
        } else {
          return "-";
        }
      },
    },
    {
      field: 'owner',
      headerName: '공급업체',
      width: 200,
      valueGetter: (params) => params.row?.owner?.name,
    },
    {
      field: 'site',
      headerName: '현장명',
      width: 200,
    },
    {
      field: 'siteAddress',
      headerName: '현장주소',
      width: 200,
    },
    {
      field: 'constructionCompanyName',
      headerName: '건설사명',
      width: 160,
    },
    {
      field: 'completionDate',
      headerName: '준공일',
      width: 110,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => params.value && dateFormat(params.value, 'yyyy-MM-dd'),
    },
    {
      field: 'startDate',
      headerName: '시작',
      width: 110,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => dateFormat(params.value, 'yyyy-MM-dd'),
    },
    {
      field: 'endDate',
      headerName: '마감',
      width: 110,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => dateFormat(params.value, 'yyyy-MM-dd'),
    },
  ];

  const columnsGDomestciAuthMap = [
    {
      field: 'no',
      headerName: '품번',
      width: 120,
    },
    {
      field: 'specification',
      headerName: '사양',
      width: 300,
      valueGetter: (params) => params.value && params.value.replaceAll("|", " ").replaceAll("+", " + "),
    },
    {
      field: 'gdomesticAuthSerialNo',
      headerName: '인증번호',
      width: 200,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'authDocumentPath',
      headerName: '인증서',
      width: 110,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const { value } = params;
        if (value) {
          return (
            <StyledTooltip
              title={<Stack direction="row" display="flex" alignItems="center">
                <Box sx={{ solid: 1, borderRadius: 1, bgcolor: '#eee', color: '#aaa', p: 1, fontSize: '0.8em', mr: 1 }}>
                  {`미리보기`}
                </Box>
                <Typography variant="subtitle2">{value?.split("/")[value?.split("/")?.length-1]}</Typography>
              </Stack>}
              placement="right"
              sx={tooltipTop}
            >
              <IconButton
                color="primary"
                aria-label="file"
                onClick={() => handleClickViewEachDoc(value)}
                sx={{ color: "#1976D2" }}
              >
                <Preview />
              </IconButton>
            </StyledTooltip>
          );
        }
      }
    },
    {
      field: 'processGClientIds',
      headerName: '가공업체',
      width: 240,
      renderCell: (params) => {
        const processGClients = [];
        const { value } = params;
        if (value) {
          const processGClientIds = value.split(",");
          processGClientIds.forEach(processGClientId => {
            const find = gclients.find(gclient => gclient.id === processGClientId);
            if (find) {
              if (processGClients.length === 0) {
                processGClients.push(<Chip label={find.name} size="small" color="primary" variant="outlined"></Chip>);
              } else {
                processGClients.push(<Chip label={find.name} size="small" color="primary" variant="outlined" sx={{ ml: 1 }}></Chip>);
              }
            }
          });
          return processGClients;
        }
      },
    },
    {
      field: 'status',
      headerName: '상태',
      width: 160,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => {
        let result = "";
        const { value } = params;
        if (value === 'C') {
          result = "신규발급"  
        } else if (value === 'D') {
          result = "폐기"  
        }
        return result;
      },
    },
    {
      field: 'createdAt',
      headerName: '발급일',
      width: 110,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => params.value && dateFormat(params.value, 'yyyy-MM-dd'),
    },
  ];

  const handleClickViewEachDoc = (documentPath) => {
    const path = documentPath.replace(uploadFilePath, fileServerUrl);

    // 캐싱으로 인해 이전 문서가 계속 보이는 문제 해결
    const randNumber = Math.floor(Math.random()*99);

    window.open(`${path}?q=cat&${randNumber}`, "미리보기", 'target="_self"');
  }

  const handleClickRequestAgain = async (e, params) => {
    e.stopPropagation();

    // 자재승인서 발급 부분에서는 id가 gclientId임. GClients LEFT JOIN SecurityRequests
    // 여기서는 SecurityRequests LEFT JOIN GClients 임
    const { id } = params.row;
    await modifySecurityRequestStatus({ id, status: '요청' });

    selectToRequests();
  }

  const handleClickRequestAgainSelected = async () => {
    // console.log(selectionModelRequest)
    // 내부적으로 목록을 반환하므로 redux를 통해 다시 refresh 할 수 있지만 api에서 리턴되는 목록의 세부 항목이 다르므로 selectToRequests 호출
    await modifySecurityRequestsByIdsStatus({ ids: selectionModelRequest, status: '요청' });

    selectToRequests();

    setSelectionModelRequest([]);
    setSelectedRowsRequest([]);
  }

  const handleClickApproval = async (e, params) => {
    e.stopPropagation();

    // console.log(params.row)
    await addSecurityOpenGClientList({
      service: '04docu',
      gclientId: sessionUser.id,
      list: [{ ...params.row, id: params.row.gclientId }]
    });

    selectToApprovals();
  }

  const handleClickDisaproval = async (e, params) => {
    e.stopPropagation();

    const { id } = params.row;
    await modifySecurityRequestStatus({ id, status: '비공개' });

    selectToApprovals();
  }

  const handleClickApprovalSelected = async () => {
    // console.log(selectedRowsApproval)
    await addSecurityOpenGClientList({
      service: '04docu',
      gclientId: sessionUser.id,
      list: selectedRowsApproval.map(row => ({ ...row, id: row.gclientId }),
    )});

    selectToApprovals();
  }

  const selectToRequests = async () => {
    await selecteSecurityToRequestsByQuery(sessionUser.id);
    
    setTimeout(() => setLoadedRequest(true), 300);
  }

  const selectToApprovals = async () => {
    await selecteSecurityToApprovalsByQuery(sessionUser.id);
    
    setTimeout(() => setLoadedApproval(true), 300);
  }

  const selectToRequestsApprovals = async () => {
    await selecteSecurityToRequestsByQuery(sessionUser.id);
    await selecteSecurityToApprovalsByQuery(sessionUser.id);
    
    setTimeout(() => {
      setLoadedRequest(true);
      setLoadedApproval(true);
    }, 300);
  }

  useEffect(
    async () => {
      selectToRequestsApprovals();

      const resGClients = await selectAllGClientDirect();
      setGClients(resGClients);
      
      const { id, type } = sessionUser;
      
      ////////////////////////////////////////////////////////////
      // 1. 참여 프로젝트 현황
      await selectJoinedGProjectsByOwnerByQuery(id);
      setLoadedJoinedProject(true);

      setSelectedParticipantId(id);
      
      // 해당 거래선 세분류에 맞게 컬럼 조정
      adjustColumnsProjectMapByGlient(sessionUser);
      ////////////////////////////////////////////////////////////

      ////////////////////////////////////////////////////////////
      // 2. 국내가공유리제품 사전 인증서
      const docu04 = true;
      if (type === 'ADMIN') { // 시스템 관리자
        await selectAllProjectsOr4docuProjects(docu04);
      } else { // 회원사 관리자나 회원사 사용자 모두 회원사 소속이면 데이터 검색이 가능 (같은 회원사의 사용자인 경우 A 사용자 자재승인서 발급 정보에 B 사용자가 접근이 가능)
        await selectProjectsOr4docuProjectsByOwner(docu04, id);
      }
      setLoadedGDomesticAuthProject(true);
      
      setSelectedGClientId(id);
      ////////////////////////////////////////////////////////////
    }, [dispatch]
  )

  // useEffect(
  //   () => {
  //     initGprojectMaps();
  //   }, [rowsProject]
  // )

  // // TODO : hideWatermark는 여기서 하면 항상 지워짐. 전체에 반영할 것
  // useEffect(
  //   () => {
  //     hideWatermark();
  //     console.log({ rowsToRequest, rowsToApproval })
  //   }
  // )

  const handlePageChangeRequest = (newPage) => {
    setPageRequest(newPage);
  }
  
  const handlePageChangeApproval = (newPage) => {
    setPageApproval(newPage);
  }

  const adjustColumnsProjectMapByGlient = (gclient) => {
    const { gclientType04docus } = gclient;
    // gclientType04docus가 null일 경우 TypeError: Cannot convert undefined or null to object 발생하여 아래 처리
    if (gclientType04docus && !objectEmptyCheck(gclientType04docus) && Array.isArray(gclientType04docus)) {
      const arrColumnNames = [];
      gclientType04docus.forEach(type => {
        const { code } = type;
        if (code === 'HEAT_STRENGTHENED' || code === 'TEMPERED' || code === 'LAMINATED' || code === 'INSULATED_GLASS_UNIT') {
          arrColumnNames.push('품번', '사양', '구분', '공정구분');
        } else if (code === 'MANUFACTURER_RAW_MATERIAL') {
          arrColumnNames.push('품번', '사양', '구분', '제품명');
        } else if (code === 'MANUFACTURER_SUB_MATERIAL_PROCESS' || code === 'MANUFACTURER_SUB_MATERIAL_BUILD') {
          arrColumnNames.push('구분', '제품명');
        }
      })

      const columnNames = [...new Set([...arrColumnNames])]; // 중복제거

      setColumnsProjectMap(columnsProjectMapBasic.filter(column => columnNames.find(name => name === column.headerName)));
    }
  }

  const handleChangeParticipants = async (e, value) => {
    initGprojectMaps();
    
    const participantId = value.id;
    
    setSelectedParticipantId(participantId);
    const newSelectedParticipant = gclients.find(gclient => gclient.id === participantId);
    if (newSelectedParticipant) {
      setSelectedParticipant(newSelectedParticipant);
    }

    // 해당 거래선 세분류에 맞게 컬럼 조정
    adjustColumnsProjectMapByGlient(newSelectedParticipant);
    

    // 참여 프로젝트 검색
    setLoadedJoinedProject(false);
    await selectJoinedGProjectsByOwnerByQuery(participantId);
    setLoadedJoinedProject(true);
  }

  const handlePageChangeJoinedProject = (newPage) => {
    setPageJoinedProject(newPage);
  }
  
  const handlePageChangeProjectMap = (newPage) => {
    setPageProjectMap(newPage);
  }

  const handlePageChangeGDomesticAuthProject = (newPage) => {
    setPageGDomesticAuthProject(newPage);
  }

  const handlePageChangeGDomesticAuthMap = (newPage) => {
    setPageGDomesticAuthMap(newPage);
  }

  var timer;
  const handleChangeSearchParticipants = async (e) => {
    console.log({ name: getValues("searchNameParticipants"), site: getValues("searchSiteParticipants"), selectedParticipantId })
    const searchName = getValues("searchNameParticipants");
    const searchSite = getValues("searchSiteParticipants");
    // // 과도한 API 호출 방지를 위한 debouncing 코드. TODO : 라이브러리 활용 검토
    if (timer) {
      clearTimeout(timer);
    }
    
    // 타이머 설정
    timer = setTimeout(async () => {
      setLoadedJoinedProject(false);
      await selectGProjectsBySearchByQuery(selectedParticipantId, searchName, searchSite);
      setLoadedJoinedProject(true);
    }, 500);
  };

  // const handleChangeGClient = (e, value) => {
  //   // initGprojectMaps();
    
  //   const gclientId = value.id;
    
  //   setSelectedGClientId(gclientId);
  //   const newSelectedGClient = gclients.find(gclient => gclient.id === gclientId);
  //   if (newSelectedGClient) {
  //     setSelectedGClient(newSelectedGClient);
  //   }

  //   // 내 프로젝트 검색
  //   const docu04 = true;
  //   selectProjectsOr4docuProjectsByOwner(docu04, gclientId);
  // }
  
  const handleChangeSearchGDomesticAuth = async (e) => {
    console.log({ name: getValues("searchNameGDomesticAuth"), site: getValues("searchSiteGDomesticAuth"), selectedParticipantId })
    const searchName = getValues("searchNameGDomesticAuth");
    const searchSite = getValues("searchSiteGDomesticAuth");
    // // 과도한 API 호출 방지를 위한 debouncing 코드. TODO : 라이브러리 활용 검토
    if (timer) {
      clearTimeout(timer);
    }
    
    const { id, type } = sessionUser;
    // 타이머 설정
    timer = setTimeout(() => {
      const docu04 = true;
      if (type === 'ADMIN') { // 시스템 관리자
        selectAllProjectsOr4docuProjects(docu04, searchName, searchSite);
      } else { // 회원사 관리자나 회원사 사용자 모두 회원사 소속이면 데이터 검색이 가능 (같은 회원사의 사용자인 경우 A 사용자 자재승인서 발급 정보에 B 사용자가 접근이 가능)
        selectProjectsOr4docuProjectsByOwner(docu04, id, searchName, searchSite);
      }
    }, 500);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="false">
        <CssBaseline />
        <Grid container spacing={1} sx={{ mt: 3 }}>
          <Grid item xs={12} sm={6}>
            <Paper sx={{ p: 1 }}>
              <Box sx={{ p: 1, border: '1px solid #E0E0E0', background: '#F2F2F2', borderRadius: 1 }}>
                <Grid container>
                  <Grid item xs={6} display="flex" alignItems="center">
                    {"열람요청"}
                  </Grid>
                  <Grid item xs={6} display="flex" justifyContent={"flex-end"} alignItems="center">
                    <Button
                      variant="contained"
                      size="small"
                      onClick={handleClickRequestAgainSelected}
                      disabled={selectionModelRequest.length === 0}
                    >
                      {"재요청"}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
              <div style={{ height: 400, width: '100%' }}>
                <DataGridPro
                  localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
                  // density="standard"
                  sx={{ cursor: 'pointer', mt: 2, fontSize: '0.85em' }}
                  initialState={{ pinnedColumns: { right: ['actions'] } }}
                  slots={{
                    noRowsOverlay: CustomNoRowsOverlay,
                    // loadingOverlay: CustomLoadingOverlay,
                    loadingOverlay: LinearProgress,
                  }}
                  headerHeight={64}
                  rowHeight={64}
                  loading={!loadedRequest}
                  rows={rowsToRequest}
                  columns={columnsRequest}
                  pageSize={pageSizeRequest}
                  onPageSizeChange={(newPageSize) => setPageSizeRequest(newPageSize)}
                  rowsPerPageOptions={[1, 10, 20, 50, 100]}
                  onPageChange={handlePageChangeRequest}
                  page={pageRequest}
                  pagination
                  disableColumnMenu
                  checkboxSelection
                  onRowSelectionModelChange={(ids) => {
                    const selectedIDs = new Set(ids);
                    console.log({ ids, rowsToRequest });
                    const newIds = rowsToRequest.filter(row => selectedIDs.has(row.id) && row.status === '비공개');
                    console.log(newIds)
                    // setSelectionModelRequest(ids);
                    setSelectionModelRequest(newIds.map(i => i.id));
                    
                    const selected = rowsToRequest.filter((row) => selectedIDs.has(row.id) && row.status === '비공개');
                    setSelectedRowsRequest(selected);
                  }}
                  selectionModel={selectionModelRequest}
                />
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper sx={{ p: 1 }}>
              <Box sx={{ p: 1, border: '1px solid #E0E0E0', background: '#F2F2F2', borderRadius: 1 }}>
                <Grid container>
                  <Grid item xs={6} display="flex" alignItems="center">
                    {"열람승인"}
                  </Grid>
                  <Grid item xs={6} display="flex" justifyContent={"flex-end"} alignItems="center">
                    <Button
                      variant="contained"
                      size="small"
                      onClick={handleClickApprovalSelected}
                      disabled={selectionModelApproval.length === 0}
                    >
                      {"승인"}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
              <div style={{ height: 400, width: '100%' }}>
                <DataGridPro
                  localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
                  // density="standard"
                  sx={{ cursor: 'pointer', mt: 2, fontSize: '0.85em' }}
                  initialState={{ pinnedColumns: { right: ['actions'] } }}
                  slots={{
                    noRowsOverlay: CustomNoRowsOverlay,
                    loadingOverlay: LinearProgress,
                  }}
                  headerHeight={64}
                  rowHeight={64}
                  loading={!loadedApproval}
                  rows={rowsToApproval}
                  columns={columnsApproval}
                  pageSize={pageSizeApproval}
                  onPageSizeChange={(newPageSize) => setPageSizeApproval(newPageSize)}
                  rowsPerPageOptions={[1, 10, 20, 50, 100]}
                  onPageChange={handlePageChangeApproval}
                  page={pageApproval}
                  pagination
                  disableColumnMenu
                  checkboxSelection
                  onRowSelectionModelChange={(ids) => {
                    const selectedIDs = new Set(ids);
                    setSelectionModelApproval(ids);
                    
                    const selected = rowsToApproval.filter((row) => selectedIDs.has(row.id));
                    setSelectedRowsApproval(selected);
                  }}
                  selectionModel={selectionModelApproval}
                />
              </div>
            </Paper>
          </Grid>
          {/* TODO : 추후 Master-detail datagrid 구현도 검토해 볼 것 */}
          <Grid item xs={12} sm={12}>
            <Paper sx={{ p: 1 }}>
              <Box sx={{ p: 1, border: '1px solid #E0E0E0', background: '#F2F2F2', borderRadius: 1 }}>
                <Grid container>
                  <Grid item xs={12} sm={10} display="flex" alignItems="center">
                    {`참여 프로젝트 현황 (${rowsJoinedProject.length})`}
                  </Grid>
                  {
                    sessionUser.type === 'ADMIN' && (<Grid item xs={12} sm={2} display="flex" justifyContent={"flex-end"} alignItems="center">
                      <FormInputAutoComplete
                        name={"participants"}
                        control={control}
                        label={"거래선명"}
                        options={gclients.map(gclient => ({ label: gclient.name, id: gclient.id }))}
                        setValue={setValue}
                        onCustomChange={handleChangeParticipants}
                        value={participantValue}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        sx={{
                          '& .MuiInputBase-root': {
                            height: '34px',
                          },
                          '& .MuiFormLabel-root': {
                            fontSize: '0.8rem',
                            // marginTop: 0.3,
                          },
                          '& .MuiInputBase-input': {
                            fontSize: '0.8rem',
                          },
                          '& .MuiFormLabel-filled': {
                            marginTop: 0.3,
                          }
                        }}
                      />
                    </Grid>)
                  }
                </Grid>
              </Box>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormInputText
                        name={"searchNameParticipants"}
                        control={control}
                        label={"공급업체 또는 건설사명으로 검색하세요."}
                        onCustomChange={handleChangeSearchParticipants}
                        sx={{
                          mt: 2,
                          '& .MuiInputBase-root': {
                            background: "#E3EEFA"
                          },
                          '& .MuiFormLabel-root' : {
                            color: '#2196f3',
                            fontSize: '0.85rem',
                          },
                          input: { color: '#2196f3', fontSize: '0.85rem' }
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormInputText
                        name={"searchSiteParticipants"}
                        control={control}
                        label={"현장명 또는 현장주소로 검색하세요."}
                        onCustomChange={handleChangeSearchParticipants}
                        sx={{
                          '& .MuiInputBase-root': {
                            background: "#E3EEFA"
                          },
                          '& .MuiFormLabel-root' : {
                            color: '#2196f3',
                            fontSize: '0.85rem',
                          },
                          input: { color: '#2196f3', fontSize: '0.85rem' }
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <div style={{ height: 400, width: '100%' }}>
                        <DataGridPro
                          localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
                          // density="standard"
                          sx={{ cursor: 'pointer', fontSize: '0.85em', mt: 0.1 }}
                          initialState={{ pinnedColumns: { right: ['actions'] } }}
                          slots={{
                            noRowsOverlay: CustomNoRowsOverlay,
                            loadingOverlay: LinearProgress,
                          }}
                          columnHeaderHeight={38}
                          rowHeight={34}
                          loading={!loadedJoinedProject}
                          rows={rowsJoinedProject}
                          columns={columnsJoinedProject}
                          pageSize={pageSizeJoinedProject}
                          onPageSizeChange={(newPageSize) => setPageSizeJoinedProject(newPageSize)}
                          rowsPerPageOptions={[1, 10, 20, 50, 100]}
                          onPageChange={handlePageChangeJoinedProject}
                          page={pageJoinedProject}
                          pagination
                          disableColumnMenu
                          disableMultipleRowSelection={true}
                          onRowSelectionModelChange={async ids => {
                            if (ids.length === 1) {
                              const gprojectId = ids[0];
                              setLoadedProjectMap(false);
                              await selectGProjectMapsByGProjectAndOwnerByQuery(gprojectId, selectedParticipantId);
                              setLoadedProjectMap(true);
                            }
                          }}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div style={{ height: 506, width: '100%' }}>
                    <DataGridPro
                      localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
                      // density="standard"
                      sx={{ cursor: 'pointer', mt: 2, fontSize: '0.85em' }}
                      initialState={{ pinnedColumns: { right: ['actions'] } }}
                      slots={{
                        noRowsOverlay: CustomNoRowsOverlay,
                        loadingOverlay: LinearProgress,
                      }}
                      columnHeaderHeight={38}
                      rowHeight={34}
                      loading={!loadedProjectMap}
                      rows={rowsProjectMap}
                      columns={columnsProjectMap}
                      pageSize={pageSizeProjectMap}
                      onPageSizeChange={(newPageSize) => setPageSizeProjectMap(newPageSize)}
                      rowsPerPageOptions={[1, 10, 20, 50, 100]}
                      onPageChange={handlePageChangeProjectMap}
                      page={pageProjectMap}
                      pagination
                      disableColumnMenu
                      // onRowSelectionModelChange={(ids) => {
                      //   const selectedIDs = new Set(ids);
                      //   setSelectionModelApproval(ids);
                        
                      //   const selected = rowsToApproval.filter((row) => selectedIDs.has(row.id));
                      //   setSelectedRowsApproval(selected);
                      // }}
                      // selectionModel={selectionModelApproval}
                    />
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          {/* TODO : 추후 Master-detail datagrid 구현도 검토해 볼 것 */}
          <Grid item xs={12} sm={12}>
            <Paper sx={{ p: 1 }}>
              <Box sx={{ p: 1, border: '1px solid #E0E0E0', background: '#F2F2F2', borderRadius: 1 }}>
                <Grid container>
                  <Grid item xs={12} sm={12} display="flex" alignItems="center">
                  {
                    console.log(rowsGDomesticAuthProject)
                  }
                    {
                      `국내가공유리제품 사전 인증서 발급 현황 (발급건수 : ${rowsGDomesticAuthProject.map(p => {
                          const { gdomesticAuths } = p;
                          if (gdomesticAuths && Array.isArray(gdomesticAuths)) {
                            return p.gdomesticAuths?.filter(g => g?.authYN === 'Y').length;
                          }
                          return 0;
                        }).reduce(
                        (accumulator, currentValue) => accumulator + currentValue,
                        0,
                      )})`
                    }
                  </Grid>
                  {/* {
                    sessionUser.type === 'ADMIN' && (<Grid item xs={12} sm={2} display="flex" justifyContent={"flex-end"} alignItems="center">
                      <FormInputAutoComplete
                        name={"gclient"}
                        control={control}
                        label={"거래선명"}
                        options={gclients.map(gclient => ({ label: gclient.name, id: gclient.id }))}
                        setValue={setValue}
                        onCustomChange={handleChangeGClient}
                        sx={{
                          '& .MuiInputBase-root': {
                            height: '34px',
                          },
                          '& .MuiFormLabel-root': {
                            fontSize: '0.8rem',
                            // marginTop: 0.3,
                          },
                          '& .MuiInputBase-input': {
                            fontSize: '0.8rem',
                          },
                          '& .MuiFormLabel-filled': {
                            marginTop: 0.3,
                          }
                        }}
                      />
                    </Grid>)
                  } */}
                </Grid>
              </Box>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormInputText
                        name={"searchNameGDomesticAuth"}
                        control={control}
                        label={"공급업체 또는 건설사명으로 검색하세요."}
                        onCustomChange={handleChangeSearchGDomesticAuth}
                        sx={{
                          mt: 2,
                          '& .MuiInputBase-root': {
                            background: "#E3EEFA"
                          },
                          '& .MuiFormLabel-root' : {
                            color: '#2196f3',
                            fontSize: '0.85rem',
                          },
                          input: { color: '#2196f3', fontSize: '0.85rem' }
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormInputText
                        name={"searchSiteGDomesticAuth"}
                        control={control}
                        label={"현장명 또는 현장주소로 검색하세요."}
                        onCustomChange={handleChangeSearchGDomesticAuth}
                        sx={{
                          '& .MuiInputBase-root': {
                            background: "#E3EEFA"
                          },
                          '& .MuiFormLabel-root' : {
                            color: '#2196f3',
                            fontSize: '0.85rem',
                          },
                          input: { color: '#2196f3', fontSize: '0.85rem' }
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <div style={{ height: 400, width: '100%' }}>
                        <DataGridPro
                          localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
                          // density="standard"
                          sx={{ cursor: 'pointer', fontSize: '0.85em', mt: 0.1 }}
                          initialState={{ pinnedColumns: { right: ['actions'] } }}
                          slots={{
                            noRowsOverlay: CustomNoRowsOverlay,
                            loadingOverlay: LinearProgress,
                          }}
                          columnHeaderHeight={38}
                          rowHeight={34}
                          loading={!loadedGDomesticAuthProject}
                          rows={rowsGDomesticAuthProject}
                          columns={columnsGDomesticAuthProject}
                          pageSize={pageSizeGDomesticAuthProject}
                          onPageSizeChange={(newPageSize) => setPageSizeGDomesticAuthProject(newPageSize)}
                          rowsPerPageOptions={[1, 10, 20, 50, 100]}
                          onPageChange={handlePageChangeGDomesticAuthProject}
                          page={pageGDomesticAuthProject}
                          pagination
                          disableColumnMenu
                          disableMultipleRowSelection={true}
                          onRowSelectionModelChange={async ids => {
                            if (ids.length === 1) {
                              // 프로젝트별 국내가공유리제품 사전 인증서 발급 현황
                              const gprojectId = ids[0];
                              setLoadedGDomesticAuthMap(false);
                              await selectGDomestciAuthByGProject(gprojectId);
                              setLoadedGDomesticAuthMap(true);
                            }
                          }}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div style={{ height: 506, width: '100%' }}>
                    <DataGridPro
                      localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
                      // density="standard"
                      sx={{ cursor: 'pointer', mt: 2, fontSize: '0.85em' }}
                      initialState={{ pinnedColumns: { right: ['actions'] } }}
                      slots={{
                        noRowsOverlay: CustomNoRowsOverlay,
                        loadingOverlay: LinearProgress,
                      }}
                      columnHeaderHeight={38}
                      rowHeight={34}
                      loading={!loadedGDomesticAuthMap}
                      rows={rowsGDomesticAuthMap}
                      columns={columnsGDomestciAuthMap}
                      pageSize={pageSizeGDomesticAuthMap}
                      onPageSizeChange={(newPageSize) => setPageSizeGDomesticAuthMap(newPageSize)}
                      rowsPerPageOptions={[1, 10, 20, 50, 100]}
                      onPageChange={handlePageChangeGDomesticAuthMap}
                      page={pageGDomesticAuthMap}
                      pagination
                      disableColumnMenu
                    />
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          {/* 위로 가기 버튼 */}
          <Zoom in={trigger}>
            <Fab
              onClick={scrollToTop}
              color="primary"
              size="small"
              aria-label="scroll back to top"
              sx={{
                position: 'fixed',
                bottom: 16,
                right: 16,
              }}
            >
              <KeyboardArrowUpIcon />
            </Fab>
          </Zoom>
        </Grid>
      </Container>
    </ThemeProvider>
  )
}

export default Dashboard;