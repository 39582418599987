// import id from "date-fns/locale/id/index.js";
import { csrfFetch } from "./csrf.js";

const SET_GWORKORDER_DETAIL_PROCESS = 'gworkOrderDetailProcess/setGWorkOrderDetailProcess';
const REMOVE_GWORKORDER_DETAIL_PROCESS = "gworkOrderDetailProcess/removeGWorkOrderDetailProcess";
const SET_GWORKORDER_DETAIL_PROCESSES = 'gworkOrderDetailProcess/setGWorkOrderDetailProcesses';

const setGWorkOrderDetailProcess = (gworkOrderDetailProcess) => ({
  type: SET_GWORKORDER_DETAIL_PROCESS,
  payload: gworkOrderDetailProcess,
});

const removeGWorkOrderDetailProcess = () => ({
  type: REMOVE_GWORKORDER_DETAIL_PROCESS,
});

const setGWorkOrderDetailProcesses = (gworkOrderDetailProcesses) => ({
  type: SET_GWORKORDER_DETAIL_PROCESSES,
  payload: gworkOrderDetailProcesses,
});

export const selectAllByQuery = () => async dispatch => {
  const response = await csrfFetch(dispatch, "/api/gworkOrderDetailProcesses/query/selectAll");
  const data = await response.json();
  dispatch(setGWorkOrderDetailProcesses(data.gworkOrderDetailProcesses));
  return response;
}

export const selectByGworkOrderDetailId = (gworkOrderDetailId) => async dispatch => {
  const response = await csrfFetch(dispatch, `/api/gworkOrderDetailProcesses/gworkOrderDetailId/${gworkOrderDetailId}`);
  const data = await response.json();
  dispatch(setGWorkOrderDetailProcess(data.gworkOrderDetailProcess));
  return response;
}

const initialState = {
  gworkOrderDetailProcess: null,
  gworkOrderDetailProcesses: [],
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case SET_GWORKORDER_DETAIL_PROCESS:
      newState = Object.assign({}, state, { gworkOrderDetailProcess: action.payload });
      return newState;
    case REMOVE_GWORKORDER_DETAIL_PROCESS:
      newState = Object.assign({}, state, { gworkOrderDetailProcess: null });
      return newState;
    case SET_GWORKORDER_DETAIL_PROCESSES:
      newState = Object.assign({}, state, { gworkOrderDetailProcesses: action.payload });
      return newState;
    default:
      return state;
  }
}

export default reducer;
