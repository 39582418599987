// TODO : 삭제 예정
import { csrfFetch } from "./csrf.js";
import { gcoreServerUrl } from '../config';

const SET_GSUB_ITEM = 'gsubItem/setGSubItem';
const REMOVE_GSUB_ITEM = "gsubItem/removeGSubItem";
const INIT_GSUB_ITEMS = 'gsubItem/initGSubItems';
const SET_GSUB_ITEMS = 'gsubItem/setGSubItems';
const SET_GSUB_ITEMS_NOT_MINES = 'gsubItem/setGSubItemsNotMines';

const setGSubItem = (gsubItem) => ({
  type: SET_GSUB_ITEM,
  payload: gsubItem,
});

const removeGSubItem = () => ({
  type: REMOVE_GSUB_ITEM,
});

const initGSubItems = () => ({
  type: INIT_GSUB_ITEMS,
});

const setGSubItems = (gsubItems) => ({
  type: SET_GSUB_ITEMS,
  payload: gsubItems,
});

const setGSubItemsNotMines = (gsubItemsNotMines) => ({
  type: SET_GSUB_ITEMS_NOT_MINES,
  payload: gsubItemsNotMines,
});

export const create = ({ id, name, code, gsubId, g04docuId, comments }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gsubItems`, {
    method: "POST",
    body: JSON.stringify({ id, name, code, gsubId, g04docuId, comments }),
  });
  const data = await response.json();
  dispatch(setGSubItem(data.gsubItem));
  return response;
};

export const modify = ({ id, name, code, gsubId, g04docuId, comments }) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gsubItems`, {
    method: "PUT",
    body: JSON.stringify({ id, name, code, gsubId, g04docuId, comments }),
  });
  const data = await response.json();
  dispatch(setGSubItem(data.gsubItem));
  return response;
};

export const selectAll = () => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gsubItems`);
  const data = await response.json();
  dispatch(setGSubItems(data.gsubItems));
  return response;
}

export const selectAllDirect = async () => {
  const response = await csrfFetch(null, `${gcoreServerUrl}/api/gsubItems/`);
  const data = await response.json();
  return data.gsubItems;
}

export const selectAllByQuery = () => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gsubItems/query/selectAll`);
  const data = await response.json();
  dispatch(setGSubItems(data.gsubItems));
  return response;
};

export const selectAllByGsubId = (gsubId) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gsubItems/gsubId/${gsubId}`);
  const data = await response.json();
  dispatch(setGSubItems(data.gsubItems));
  return response;
}

export const selectNotMinesByQuery = (gclientId) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gsubItems/query/selectAll/notMines/gclient/${gclientId}`);
  const data = await response.json();
  dispatch(setGSubItemsNotMines(data.gsubItemsNotMines));
  return response;
};
  
export const select = (id) => async dispatch => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gsubItems/${id}`);
  const data = await response.json();
  dispatch(setGSubItem(data.gsubItem));
  return response;
}

export const init = () => dispatch => {
  // TODO : 초기값 선택 여부 검토
  // dispatch(setGSubItem(null));
  dispatch(setGSubItem(initialState.gsubItem));
}

export const initializeGSubItems = () => dispatch => {
  dispatch(initGSubItems());
}

export const remove = (id) => async (dispatch) => {
  const response = await csrfFetch(dispatch, `${gcoreServerUrl}/api/gsubItems/${id}`, {
    method: "DELETE",
  });
  dispatch(removeGSubItem());
  return response;
};

export const reorder = async ({ type, id }) => {
  const response = await csrfFetch(null, `${gcoreServerUrl}/api/gsubItems/reorder`, {
    method: "POST",
    body: JSON.stringify({ type, id }),
  });
  
  return response;
}

const initialState = {
  gsubItem: null,
  gsubItems: [],
  gsubItemsNotMines: [],
};

function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case SET_GSUB_ITEM:
      newState = Object.assign({}, state, { gsubItem: action.payload });
      return newState;
    case REMOVE_GSUB_ITEM:
      newState = Object.assign({}, state, { gsubItem: null });
      return newState;
    case INIT_GSUB_ITEMS:
      newState = Object.assign({}, state, { gsubItems: initialState.gsubItems });
      return newState;
    case SET_GSUB_ITEMS:
      newState = Object.assign({}, state, { gsubItems: action.payload });
      return newState;
    case SET_GSUB_ITEMS_NOT_MINES:
      newState = Object.assign({}, state, { gsubItemsNotMines: action.payload });
      return newState;
    default:
      return state;
  }
}

export default reducer;
